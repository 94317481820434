const featuredThumbnails = [
  {
    hero: 'heroImages/c-featured-automotive-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-automotive.jpg',
  },
  {
    hero: 'heroImages/c-featured-awards-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-awards.jpg',
  },
  {
    hero: 'heroImages/c-featured-beverage-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-beverage.jpg',
  },
  {
    hero: 'heroImages/c-featured-candy-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-candy.jpg',
  },
  {
    hero: 'heroImages/c-featured-cases-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-cases.jpg',
  },
  {
    hero: 'heroImages/c-featured-consumer-commerce-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-consumer-commerce.jpg',
  },
  {
    hero: 'heroImages/c-featured-consumer-electronics-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-consumer-electronics.jpg',
  },
  {
    hero: 'heroImages/c-featured-explore-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-explore.jpg',
  },
  {
    hero: 'heroImages/c-featured-finance-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-finance-hero.jpg',
  },
  {
    hero: 'heroImages/c-featured-flight-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-flight.jpg',
  },
  {
    hero: 'heroImages/c-featured-health-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-health.jpg',
  },
  {
    hero: 'heroImages/c-featured-hotel-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-hotel.jpg',
  },
  {
    hero: 'heroImages/c-featured-music-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-music.jpg',
  },
  {
    hero: 'heroImages/c-featured-outdoors-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-outdoors.jpg',
  },
  {
    hero: 'heroImages/c-featured-pharma-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-pharma.jpg',
  },
  {
    hero: 'heroImages/c-featured-responses-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-responses.jpg',
  },
  {
    hero: 'heroImages/c-featured-science-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-science.jpg',
  },
  {
    hero: 'heroImages/c-featured-sports-entertainment-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-sports-entertainment.jpg',
  },
  {
    hero: 'heroImages/c-featured-technology-hero.jpg',
    thumbnail: 'thumbnailImages/c-featured-technology.jpg',
  },
];

const defaultHero = '/images/hero/Starfish-HeroImage-Default-Collections_5100x1200-WPP-2022.jpg';
const defaultThumbnail = '/images/hero/Starfish-HeroImage-Default-Collections_5100x1200-WPP-2022.jpg';

const baseUrl = 'https://cistrdev.blob.core.windows.net/ci-discovery-portal-ui';
const dir = 'themes/';

const build = (image) => `${baseUrl}/${dir}/${image}`;

const urlBuilder = (index) => {
  const adjustedIndex = index % featuredThumbnails.length;
  const { hero, thumbnail } = featuredThumbnails[adjustedIndex];

  return {
    heroImage: build(hero),
    thumbnailImage: build(thumbnail),
  };
};

let index = 0;
export const featuredTheme = function featuredThemeProperty() {
  const image = urlBuilder(index);
  index += 1;

  return image;
};

export const defaultTheme = function defaultThemeProperty(isThumbnailEditable) {
  return {
    get heroImage() {
      return defaultHero;
    },
    get thumbnailImage() {
      return defaultThumbnail;
    },
    get isThumbnailEditable() {
      return (typeof isThumbnailEditable === 'undefined') ? true : isThumbnailEditable;
    },
  };
};

const theme = function theme(collection) {
  return collection?.isFeatured ? featuredTheme() : defaultTheme;
};

const collectionThemes = {
  featuredThemeProperty: featuredTheme,
  defaultThemeProperty: defaultTheme,
  theme,
};

export default collectionThemes;
