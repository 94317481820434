import moment from 'moment';
import { discoveryThemeFactory } from '@/lib/themes/DiscoveryTheme';
import { collectionFactory } from '@/lib/business/models/Collection';

class ListCollection {
  collection = null;

  name = '';

  id = 0;

  collectionId = 0;

  isSelected = false;

  heroImage = null;

  thumbnailImage = null;

  assetCount = 0;

  created = null;

  isConfidential = false;

  pinned = false;

  starred = false;

  isFeatured = false;

  description = '';

  theme = null;

  constructor(listCollection) {
    if (listCollection == null) {
      return;
    }

    this.collection = listCollection.collection;

    this.name = this.collection.name;
    this.id = this.collection.collectionId;
    this.collectionId = this.id;

    this.assetCount = listCollection.collectionAssetsCount;
    this.collection.collectionAssetsCount = this.assetCount;
    this.created = moment(this.collection.created);

    this.isConfidential = this.collection.isConfidential;

    this.pinned = listCollection.pinned;
    this.starred = listCollection.starred;

    this.description = this.collection?.description;

    this.theme = discoveryThemeFactory(this.collection?.theme);
    console.log(this.theme);
  }

  /**
   *
   * @param collectionDetails {Collection}
   */
  update(collectionDetails) {
    if (collectionDetails.collectionId === this.id) {
      this.name = collectionDetails.name;
      this.assetCount = collectionDetails.collectionAssets?.length;
      this.description = collectionDetails.description;
    }
  }

  /**
   * This return raw location that could be resolved to a url
   */
  get rawLocation() {
    return { name: 'CollectionDetails', params: { id: this.id } };
  }

  /**
   * @returns {{name: string, location: {name: string, params: {id: number}}, type: string}}
   */
  shareableObject() {
    return {
      name: this.name,
      type: 'Collection',
      location: this.rawLocation,
    };
  }
}

export default ListCollection;

export const listCollectionFactory = (listCollection) => {
  const {
    collection,
    pinned,
    starred,
    currentUser,
  } = listCollection;
  collection.collectionAssetsCount = listCollection.collectionAssetsCount;
  collection.pinned = pinned;
  collection.starred = starred;

  return collectionFactory(collection, currentUser);
};

export const listCollectionListFactory = (list) => list?.map(listCollectionFactory);
