export default {
  /**
   * @type{DeploymentConnection}
   */
  deployments: null,

  /**
   * Flags to check if static data is fetched
   */
  userEditStaticDataFetched: false,

  deploymentIndices: [],
};
