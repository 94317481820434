import * as Azure from '@azure/storage-blob';
import fileDownload from 'js-file-download';

const assetDownloader = {
  async getFileData(fileUrl, updateProgress = () => {}) {
    try {
      const blobClient = new Azure.BlockBlobClient(fileUrl);

      const properties = await blobClient.getProperties();
      const { contentLength } = properties;

      const onProgress = (progress) => updateProgress({
        fileSize: contentLength,
        loadedBytes: progress.loadedBytes,
      });

      const downloadResult = await blobClient.download(null, null, {
        onProgress,
      });
      const data = await downloadResult.blobBody;
      return data;
    } catch (e) {
      console.log('Download error', e);
      return null;
    }
  },
  handleFileDownload(data, filename) {
    try {
      fileDownload(data, filename);
    } catch (e) {
      console.log('File download error', e);
    }
  },
  async download(fileUrl, filename, updateProgress = () => {}) {
    const file = await this.getFileData(fileUrl, updateProgress);
    this.handleFileDownload(file, filename);
  },
};

export default assetDownloader;
