// Asset Model

import { Model } from '@vuex-orm/core';

export default class Asset extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'asset'

  static primaryKey = 'AssetId'

  // note from Steve (12/10/20): i tried to make these non-generic types
  // but netcore was not instantiating object correctly on backend
  // need to study more

  // List of all fields (schema) of the Asset model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      AssetId: this.attr(0),
      DocId: this.attr(null),
      // TempId: this.attr(null),
      FileName: this.attr(null),
      StatusId: this.attr(1),
      EstimatedValueId: this.attr(null),
      EngagementTypeId: this.attr(null),
      SubmissionDate: this.attr(new Date().toISOString().substr(0, 10)),
      BusinessResultId: this.attr(null),
      NewBusinessUrl: this.attr(null),
      CompanyLocationProfileEtc: this.attr(null),

      DefaultPreviewId: this.attr(null),
      FileExtensionId: this.attr(null),
      PreviewSetId: this.attr(null),
      BlobName: this.attr(null),
      ExternalDocId: this.attr(null),
      IsVideo: this.attr(false),
      VideoAssetId: this.attr(null),
      CreatedByUser: this.attr(null),
      ReferenceUrl: this.attr(null),
      ExternalSource: this.attr(null),
      ExternalId: this.attr(null),

      StoragePath: this.attr(null),
      ArchiveTierId: this.attr(1),
      IsFeatured: this.attr(false),
      IsConfidential: this.attr(true),
      Title: this.attr(null),
      Description: this.attr(null),
      DeploymentIndexId: this.attr(null),
      OriginalFileName: this.attr(null),
      TermsAndConditions: this.attr(null),
      Notes: this.attr(null),

      // Created: this.attr(null),
      // Updated: this.attr(null),
      // CreatedByUserId: this.attr(null),
      // UpdatedByUserId: this.attr(null),

      ArchiveTier: this.attr(null),
      BusinessResult: this.attr(null),
      EngagementType: this.attr(null),
      EstimatedValue: this.attr(null),

      AssetCampaigns: this.attr([]),
      AssetCapabilities: this.attr([]),
      AssetContentTypes: this.attr([]),
      AssetContributors: this.attr([]),
      AssetIndustries: this.attr([]),
      AssetKeywords: this.attr([]),
      AssetMarkets: this.attr([]),
      AssetOrganizations: this.attr([]),
      AssetRegions: this.attr([]),
      AssetCountries: this.attr([]),
      AssetCities: this.attr([]),
      AssetTags: this.attr([]),
      AssetTopics: this.attr([]),
      CollectionAssets: this.attr([]),
      ConfidentialAssetUsers: this.attr([]),
    };
  }
}
