import faker from 'faker';
import helper from '@/lib/util/dataSimulator/helper';

const sections = [
  'General Information',
  'Capabilities',
  'Technology & Data',
];

const questions = [
  'Describe how your Agency\'s structure and how your experience will'
  + ' benefit ACME Co in cost and performance',
  'Provide a list your core competencies',
  'List your top five skills/capabilities that differentiate your Agency from your competitors',
  'Provide a high-level process map, from project start to completion. Indicate the '
  + 'critical paths and the cost drivers associates',
  'Describe your cost tracking and notification process',
  'Describe your process to define or manage projects on time and within budget',
  'Describe your process to deliver agreed upon deliverables on time, with a high degree of '
  + 'quality',
  'Name and address of the Parent Company',
  'The date upon which Agency was formed or incorporated',
  'Global reach and locations',
  'Phone Number',
  'Email Address',
];

const responseTypes = [
  'Responses',
  'Cases',
  'Experts',
  'Videos',
];

const createResponsesForType = (response) => helper.getRandomArray()(() => ({
  type: response,
  text: faker.lorem.paragraphs(),
  filename: faker.system.commonFileName('pdf'),
}));

const pickRandomQuestions = () => helper.randomIndices(2, 4).map((index) => ({
  question: questions[index],
  responses: responseTypes.flatMap(createResponsesForType),
  selected: [],
  hasSelectedResponses() {
    return this.selected?.length > 0;
  },
  selectedResponses() {
    return this.selected?.reduce((acc, response) => {
      const responseType = response.type;
      (acc[responseType] = acc[responseType] || []).push(response);
      return acc;
    }, {});
  },
}));

const generateSections = () => sections.map((section) => ({
  section,
  questions: pickRandomQuestions(),
  hasSelectedQuestions() {
    return this.questions?.some((question) => question.hasSelectedResponses());
  },
  selectedQuestions() {
    return this.questions?.filter((question) => question.hasSelectedResponses());
  },
}));

const responseGenerator = {
  questions: pickRandomQuestions,
  sections: generateSections,
};

export default responseGenerator;
