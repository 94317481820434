import SearchMetrics from './SearchMetrics';

class SearchRequestInsight {
  /**
   * @type {Number}
   */
  totalRequests = 0;

  /**
   *  @type {SearchMetrics}
   */
  topSearched = null;

  constructor(totalRequests = 0, topSearched = null) {
    this.totalRequests = totalRequests;
    this.topSearched = topSearched;
  }

  get insights() {
    return [this.topSearched];
  }

  get totalRequestsCount() {
    return this.totalRequests;
  }

  static fromObject(insight) {
    if (insight == null) {
      return new SearchRequestInsight();
    }

    const {
      topSearched,
      totalRequests,
    } = insight;

    const searchedMetrics = SearchMetrics.fromList('topSearched', 'Top Searched', topSearched);

    return new SearchRequestInsight(totalRequests, searchedMetrics);
  }
}

export default SearchRequestInsight;
