import { ApiFactory } from '@/api/api-factory';

const queryResponseClient = ApiFactory.getQueryResponseClient();

export default {
  getResponse({ dispatch }, query) {
    const doGetResponse = async (index) => {
      await queryResponseClient.getResponse(query, index.deploymentIndexId);
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', doGetResponse, { root: true });
  },
};
