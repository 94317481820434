import { mix } from 'mixwith';
import { ConnectionMixin, ContainsQueryMixin } from '@/lib/graphql/mixins';
import { Asset } from '@/lib/business';

const AssetConnection = class AssetConnection extends mix(ConnectionMixin(Asset))
  .with(ContainsQueryMixin) {
  /**
   *
   * @returns {[Asset]}
   */
  get assets() {
    return this.items;
  }
};

export default AssetConnection;
