class PaginationInput {
  /**
   * Get first records
   * @type {number}
   */
  first;

  /**
   * Get next results after the current cursor
   * @type {string}
   */
  after = null;

  /**
   * Get last n records
   * @type{number}
   */
  last;

  /**
   * Get previous results before the current cursor
   * @type {string}
   */
  before = null;

  constructor(
    {
      first = 10,
      after = null,
      last = null,
      before = null,
    } = {},
  ) {
    this.last = last;
    this.after = after;
    this.first = first;
    this.before = before;
  }
}

export default PaginationInput;
