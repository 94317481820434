import actions from './actions';
import state from './state';
import mutations from './mutations';

import userStore from '../users/store';
import deploymentStore from '../deployments/store';

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  modules: {
    users: userStore,
    deployments: deploymentStore,
  },
};
