/**
 * This class holds the count a list of particular type in the database
 * @param listCount
 * @constructor
 */
const ListCount = function ListCount(listCount) {
  console.log('Creating list count', listCount);
  this.count = listCount?.count || 0;
  this.type = listCount?.type || '';
};

Object.defineProperties(ListCount.prototype, {
  code: {
    get() {
      return this.type?.toLowerCase() || '';
    },
  },
});

export default ListCount;

export const listCountFactory = (data) => new ListCount(data);

export const listCountListFactory = (list) => list?.map(listCountFactory) || [];

/**
 *
 * @param list{[ListCount]}
 * @returns {Map<string, ListCount>}
 */
export const listCountMapFactory = (list) => list?.reduce((acc, item) => {
  const { code } = item;
  acc[code] = item;
  return acc;
}, {});
