const shorten = (text, length) => {
  if (!text) {
    return '';
  }
  return `${text.split(' ').slice(0, length).join(' ')} ...`;
};

const IndexerSearchResult = (content) => ({
  title: content && content.metadata_storage_name.split('.')[0],
  contentText: content && shorten(content.content, 50),
  filename: content && content.metadata_storage_name,
  fileType: content && content.metadata_storage_file_extension,
  text: content && content.content,
});

export default IndexerSearchResult;
