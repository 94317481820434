import userRoutes from '../users/routes/index';

export default {
  path: '/admin',
  components: {
    main: () => import(/* webpackChunkName: "admin" */ '@/admin/pages/AdminView.vue'),
  },
  meta: {
    requiresAuth: true,
    title: 'Admin',
    breadcrumb: [
      {
        name: 'Manage',
      },
    ],
  },
  children: [
    userRoutes,
  ],
};
