const documentTitle = (fileName) => fileName && fileName.split('.').slice(0, -1).join('.');

const fileExtension = (filename) => {
  if (!filename || filename === null) {
    return '';
  }

  const elements = filename.split('.');
  if (elements.length === 1) {
    return '';
  }

  console.log('Returning filename', filename);
  return `.${elements.pop()}`;
};

/**
 *
 * @param list {[]}
 * @param keyExtractor {}
 * @returns {*|[]|RegExp}
 */
const groupBy = (list, keyExtractor) => list && list.reduce((acc, item) => {
  const key = keyExtractor(item);
  (acc[key] = acc[key] || []).push(item);
  return acc;
}, {});

export default documentTitle;

export {
  groupBy,
  fileExtension,
};
