class FiltersList {
  /**
   *
   * @type {[{ key, value }]}
   */
  #filterList = [];

  /**
   *
   * @returns {{key, value}[]}
   */
  getSelectedFilters() {
    return this.#filterList;
  }

  /**
   *
   * @param filter{ {key, value }}
   */
  remove(filter) {
    this.#filterList = [...this.#filterList, filter];
  }

  /**
   *
   * @returns {FiltersList}
   */
  clear() {
    this.#filterList = [];
    return this;
  }
}

export default FiltersList;
