import { userGraphClient } from '@/admin/users/client/UsersGraphClient';

export default {
  async getUsers({ commit }, { query, pageInput }) {
    try {
      const result = await userGraphClient.getUsers(query, pageInput);
      console.log('Setting result in actions', result);
      commit('setUsers', result);
    } catch (e) {
      console.log(e);
    }
  },

  async getUser({ dispatch }, user) {
    try {
      console.log('getting deployments');
      dispatch('admin/deployments/getDeployments', null, { root: true });
      await userGraphClient.getUser(user);
    } catch (e) {
      console.log(e);
    }
  },
};
