class Face {
  /**
   *
   * @type {number}
   */
  age = 0;

  /**
   *
   * @type {string}
   */
  gender = '';

  /**
   *
   * @type {*[]}
   */
  faceBoundingBox = [];

  constructor(faceObj) {
    this.age = faceObj?.age;
    this.gender = faceObj?.gender;
    this.faceBoundingBox = faceObj?.faceBoundingBox;
  }
}

export default Face;
