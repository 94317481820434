import AssetStream from '@/documentAssets/models/AssetStream';
import PaginationInput from '@/lib/graphql/models/PaginationInput';
import FetchAssetDetailsChoices from '@/lib/business/models/FetchAssetDetailsChoices';

const assetStreamFactory = (deploymentIndexId) => [];

class AssetStreamsService {
  client = null;

  streamFactory = assetStreamFactory;

  /**
   *
   * @param client {AssetsGraphQlClient}
   */
  constructor(client) {
    this.client = client;
  }

  /**
   *
   * @param deploymentIndexId
   * @returns {Promise<[AssetStream, AssetStream, AssetStream, AssetStream]>}
   */
  getStreams(deploymentIndexId) {
    return Promise.resolve(this.streamFactory(deploymentIndexId));
  }

  /**
   *
   * @param stream{AssetStream}
   * @param count
   * @returns {Promise<AssetStream>}
   */
  async fetchDataForStream(stream, count = 15) {
    const pageInfo = new PaginationInput();
    pageInfo.first = count;

    stream.loading = true;
    const result = await this.client.getAssets(null, pageInfo, stream.order, stream.filter);
    stream.loading = false;
    stream.items = result;

    return stream;
  }

  /**
   *
   * @param streams{[AssetStream]}
   * @param count
   * @returns {Promise<{[p: string]: PromiseSettledResult<*>}>}
   */
  async fetchDataForStreams(
    streams,
    count = 15,
    indexId = 0,
  ) {
    // console.log('Fetching data for streams');
    const fetchFn = this.fetchDataForStream;

    const promises = streams.map((stream) => this.fetchDataForStream(
      stream,
      count,
      indexId,
      stream.assetDetailChoices,
    ));

    const updatedStreams = await Promise.allSettled(promises);

    return updatedStreams;
  }
}

export default AssetStreamsService;

/**
 *
 * @param graphQlClient
 * @returns {AssetStreamsService}
 */
export const assetStreamServiceFactory = (graphQlClient) => new AssetStreamsService(graphQlClient);
