import BaseVideoInsight from '@/lib/search/discovery/classes/insights/video/BaseVideoInsight';

class VideoNamedLocation extends BaseVideoInsight {
  /**
   *
   * @type {number}
   */
  confidence = 0.0;

  description = '';

  referenceId = '';

  referenceUrl = '';

  seenDuration = '';

  constructor(data) {
    super(data);

    this.confidence = data?.confidence;
    this.description = data?.description;
    this.referenceId = data?.referenceId;
    this.referenceUrl = data?.referenceUrl;
    this.seenDuration = data?.seenDuration;
  }
}

export default VideoNamedLocation;
