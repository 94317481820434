class DeploymentIndexUser {
  deploymentIndexId = 0;

  userId = 0;

  deploymentIndex = null;

  constructor({ userId, deploymentIndexId, deploymentIndex }) {
    this.userId = userId;
    this.deploymentIndexId = deploymentIndexId;
    this.deploymentIndex = deploymentIndex;
  }
}

export default DeploymentIndexUser;
