class EditPreviewInput {
  document = null;

  previewType = null;

  /**
   *
   * @param document{{InsightContent|DiscoveryContent|Asset}}
   * @param previewType{String}
   */
  constructor(document, previewType) {
    this.document = document;
    this.previewType = previewType;
  }
}

export default EditPreviewInput;

export const EditablePreviewTypes = {
  Thumbnail: 'Thumbnail',
  Hero: 'Hero',
  Preview: 'Preview',
};
