<template>

    <!-- <li v-bind:class="{ 'text-danger': HasError }" class="mb-4">
      <v-card color="white" width="100%">
        <v-toolbar dense color="#e5e5e5">
          <div class="black--text"><span class="font-weight-bold ">file: {{ item.FileName }}</span>
            <div>size: {{ getReadableFileSizeString(item.filesize) }}</div>
          </div>
        </v-toolbar>
        <v-card-text class="pa-0 pb-2 ma-0 file-upload-log"
        ><div><v-progress-linear :value="item.loadedPercentage"></v-progress-linear></div>
        <div class="black--text pl-4">{{ item.status_display }}</div>
        <div class="black--text pl-4" v-show="item.HasError">There were errors with this process.
            <v-card color="black" flat tile class="ma-4 ml-0">
            <v-card-text
            style="max-height:89.75px"
            class="overflow-y-auto"
            >{{item.Error}}</v-card-text>
          </v-card>
          </div>
        </v-card-text> -->

      <!-- file: {{ item.filename }} -> size: {{ getReadableFileSizeString(item.filesize) }}
      <v-progress-linear :value="item.loadedPercentage"></v-progress-linear>
      -> {{ item.status_display }}
      <span v-show="item.HasError"> -> {{item.Error}}</span> -->
     <!-- </v-card>
    </li> -->
    <v-card rounded class="transparent mb-3" :width="$vuetify.breakpoint.mdAndUp ? '800px' : '93vw'">
      <v-card-text class="black--text">
        <div class="font-weight-bold">{{fileLabel}}: {{ item.FileName }}</div>
        <div v-show="!item.AlreadyUploaded">
          <div class="mb-4">Size: {{ getReadableFileSizeString(item.filesize) }}</div>
          <v-progress-linear color="#c58a28" :value="item.loadedPercentage" height="25"></v-progress-linear>
        </div>
        <div class="font-weight-bold font-italic">{{ status_display }}</div>
        <br />
        <div v-show="item.HasError" class="pa-2">
          <span v-show="item.HasError"> -> {{item.Error}}</span>
        </div>

        <div style="margin-left: 60px;" v-if="previewFilesLength > 0">
          <div>File(s) associated as preview:</div>
          <upload-file v-for="item in previewFiles" :key="item.FileName" :item="item"></upload-file>
        </div>

      </v-card-text>
    </v-card>
</template>
<script>

import {
  mapState,
} from 'vuex';

export default {
  name: 'UploadFile',
  props: [
    'item',
  ],
  components: {
  },
  data() {
    return {
      status_display: '',
    };
  },
  created() {

  },
  computed: {
    ...mapState('uploader', [
    ]),
    HasError() {
      return this.item.HasError && this.item.Error !== '';
    },
    previewFiles() {
      if (this.item.FoundPreviewAssetInfos) {
        return this.item.FoundPreviewAssetInfos;
      }
      return [];
    },
    previewFilesLength() {
      if (this.previewFiles) {
        return this.previewFiles.length;
      }
      return 0;
    },
    fileLabel() {
      if (this.item.AlreadyUploaded) {
        return 'Updating Asset';
      }
      return 'File';
    },
  },

  watch: {
    item: {
      handler(val) {
        this.status_display = val.status_display;
      },
      deep: true,
    },
  },

  methods: {
    getReadableFileSizeString(fileSizeInBytes) {
      let i = -1;
      const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
        fileSizeInBytes /= 1024;
        i += 1;
      } while (fileSizeInBytes > 1024);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    },
  },
};
</script>

<style lang="scss">
.text-danger {
  color: red;
}
.file-upload-log {
  min-height:50px;
}
</style>
