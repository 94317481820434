import { mapActions, mapGetters, mapState } from 'vuex';

import assetMixin from '@/components/search/mixins/assetMixin';
import shareMixin from '@/lib/ui/mixins/shareMixin';
import { AssetEditProperty } from '@/lib/ui/dialogs/assets/EditableAsset';

export default {
  mixins: [
    shareMixin,
    assetMixin,
  ],
  data: () => ({
    selectedInsight: null,
    selectBasicInsight: true,
    insightsWhitelist: {
      indexer_organizations: true,
      indexer_brandnames: true,
      indexer_Agencyclients: true,
      indexer_Agencytopics: true,
      indexer_Agencykeywords: true,
      indexer_landmarks: true,
      indexer_people: true,
      ageDistribution: true,
      genderDistribution: true,
      sentiments: true,
      videoTopics: true,
      videoLocations: true,
      celebrities: true,
      videoKeywords: true,
      videoLLocations: true,
      videoFaces: true,
    },
  }),
  computed: {
    ...mapGetters('common', [
      'selectedDeploymentIndexPreviewable',
    ]),
    ...mapGetters('discovery', [
      'fragments',
      'recommendedCases',
    ]),
    ...mapState('discovery', [
      'loading',
      'questions',
      'documentInsight',
      'currentDocument',
      'documentInteraction',
    ]),
    ...mapState('common', [
      'rightPanelName',
      'showRightPanel',
      'fetchCount',
      'indexPermissions',
    ]),
    currentDocumentId() {
      return this.$route.params.id;
    },
    celebrities() {
      return this.documentInsight?.celebrities || [];
    },
    contentTypes() {
      const contentTypes = this.currentDocument
        .contentTypeGroups?.map(
          (contentType) => ({ name: contentType, field: 'content_type_groups' }),
        );
      return contentTypes;
    },
    hasCelebrities() {
      return this.celebrities && this.celebrities.length > 0;
    },
    hasIndexData() {
      return this.currentDocument?.hasIndexData;
    },
    fragmentList() {
      return this.contentFragments?.fragments?.slice(1, 2);
    },
    contentFragments() {
      return this.currentDocument?.fragments;
    },
    description() {
      return this.asset?.description || this.synopsis;
    },
    synopsis() {
      return this.currentDocument?.description;
    },
    showSynopsis() {
      return this.currentDocument?.showSynopsis && this.synopsis;
    },
    displayableInsights() {
      if (!this.currentDocument || !this.currentDocument.insightList) {
        return [];
      }

      return this.currentDocument.insightList.displayableComplexInsights;
    },
    recommendedDocuments() {
      if (this.currentDocument === null || this.currentDocument.id === null) {
        return this.recommendedCases?.splice(0, this.fetchCount);
      }

      /* console.log('%c reccomended contents', 'color:brown');
      console.log(this.recommendedCases); */

      return this.recommendedCases
        && this.recommendedCases
          .filter((content) => content && (content.id !== this.currentDocument?.id))
          .slice(0, this.fetchCount);
    },
    videoInsights() {
      return this.currentDocument?.videoInsights;
    },
    downloadUrl() {
      return this.currentDocument?.downloadURL;
    },
    contributors() {
      return this.currentDocument?.experts || this.documentInsight?.asset?.contributors || [];
    },
    owners() {
      const authorFromAsset = (documentInsight) => {
        const author = documentInsight?.asset?.primaryAuthor;

        if (author == null) {
          return [];
        }

        return [author];
      };

      return this.currentDocument?.owners || authorFromAsset(this.documentInsight);
    },
    keyContacts() { 
      return this.currentDocument?.keyContacts || [];
    },
    asset() {
      return this.documentInsight?.asset;
    },
    imageDescription() {
      return this.currentDocument?.imageDescription;
    },
    captions() {
      return this.imageDescription?.captions;
    },
    URL() {
      return this.currentDocument?.URL;
    },
    hasRecommendedContent() {
      return this.recommendedDocuments && this.recommendedDocuments.length > 0;
    },
    showCaptions() {
      return this.currentDocument?.showCaptions;
    },
    assetAttributes() {
      const uploader = this.currentDocument?.document;
      console.log('assetAttributes', uploader);

      if (uploader == null) {
        return [];
      }

      const clients = uploader.clients || [];
      const organizations = uploader.agencies || [];
      const cities = uploader.cities || [];

      const clientAttributes = clients.map((client) => ({
        key: 'clients',
        value: client,
      }));
      const organizationsAttributes = organizations.map((organization) => ({
        key: 'agencies',
        value: organization,
      }));
      const cityAttributes = cities.map((city) => ({
        key: 'cities',
        value: city,
      }));

      return [...clientAttributes, ...organizationsAttributes, ...cityAttributes];
    },
    fileTypeLabel() {
      return this.asset?.fileExtensionFileTypeCategory || '';
    },
    documentContent() {
      return this.currentDocument?.content || '';
    },
    basicInsights() {
      const filter = this.insightsFilter;

      const filterList = (list) => (list || []).filter(filter) || [];
      return filterList(this.currentDocument?.insightList?.listableBasicInsights);
    },
    chartableInsights() {
      return this.currentDocument?.insightList?.chartableInsights || [];
    },
    chartOptions() {
      const chartOptions = this.currentDocument?.chartOptions || [];
      console.log('Chart options', chartOptions);
      const options = (chartOptions)
        .filter((option) => option.chartKey in this.insightsWhitelist);

      console.log('Filtered Chart options', options);
      return options;
    },
    filename() {
      return this.currentDocument && this.selectedDocument?.filename;
    },
    decodedPath() {
      return this.currentDocument?.URL;
    },
    path() {
      return this.currentDocument?.path;
    },
    token() {
      return this.currentDocument?.token;
    },
    manualInsights() {
      return this.currentDocument?.uploader?.insightList?.listableBasicInsights || [];
    },
    documentTags() {
      const autoTags = this.currentDocument?.insightList?.listableBasicInsights || [];
      const manualTags = this.manualInsights;

      const results = [...autoTags, ...manualTags];
      return results;
    },
    isMetadataPanelOpen() {
      return this.showRightPanel && this.rightPanelName === 'edit_metadata';
    },
    terms() {
      return this.currentDocument?.termsAndConditions || '';
    },
    showConfidentialWarning() {
      return this.currentDocument?.isConfidential;
    },
    insightsFilter() {
      const filter = (insight) => !insight.isManual && insight.key in this.insightsWhitelist;
      return filter;
    },
    sentiments() {
      return this.videoInsights?.sentiments;
    },
    storageSize() {
      return this.currentDocument?.storageSize || undefined;
    },
    isLiked() {
      if (this.documentInteraction == null || !this.documentInteraction.liked) {
        return false;
      }

      return 'filled';
    },
    showAnalyzeButton() {
      return this.indexPermissions.canDo('analyzeInsights');
    },
  },
  methods: {
    ...mapActions('discovery', [
      'download',
      'openPreviewModalWindow',
      'openContentInsightModalWindow',
      'openEditDocumentProperties',
      'likeDocument',
    ]),
    ...mapActions('uploader', [
      'openEditMetadata',
      'openAddToCollection',
    ]),
    ...mapActions('common', [
      'openPanel',
    ]),
    randomItem(items) {
      return items[Math.floor(Math.random() * items.length)];
    },
    openInsightModal() {
      this.onInsightSelected(null);
    },
    onModalClosed(value) {
      this.showInsight = value;
    },
    openPreviewModal() {
      this.showDocument(this.currentDocument);
    },
    resultTitle() {
      return 'Results';
    },
    /**
     *
     * @param insightValue {SelectedInsightValue}
     */
    onInsightSelected(insightValue) {
      const isManual = (insightValue?.insight && insightValue?.insight.isManual);

      if (isManual) {
        if (!this.isMetadataPanelOpen) {
          this.localEditMetadata();
        }
      } else {
        this.openContentInsightModalWindow({
          document: this.currentDocument,
          insight: insightValue,
          phrase: this.searchString,
        });
      }
    },
    downloadDocument() {
      this.download(this.currentDocument?.asset);
    },
    localAddToCollection() {
      this.openAddToCollection(this.currentDocument.id);
    },
    localEditMetadata() {
      this.openEditMetadata(this.currentDocument.id);
    },
    editDescription() {
      this.openEditDocumentProperties({
        document: this.currentDocument,
        editableProperty: AssetEditProperty.DESCRIPTION,
      });
    },
    shareDocument() {
      this.share(this.currentDocument);
    },
    onDocumentDeleted(document) {
      console.log('deleted', document);
      // this.removeDocumentFromSearchResult(document);
      this.$router.go(-1);
    },
    like() {
      this.likeDocument(this.currentDocument.id);
    },
  },
};
