class StateObserver {
  constructor() {
    this.subscribers = new Map();
  }

  subscribe(key, callback) {
    if (!this.subscribers.has(key)) {
      this.subscribers.set(key, []);
    }
    this.subscribers.get(key).push(callback);
  }

  notify(key, value) {
    if (this.subscribers.has(key)) {
      this.subscribers.get(key).forEach((callback) => {
        callback(value);
      });
      this.subscribers.delete(key); // Remove subscribers after notifying to ensure one-time execution
    }
  }
}

export default StateObserver;
