import { mix } from 'mixwith/mixwith';
import { concat, of, map } from 'rxjs';

import LocationTally from './LocationTally';
import locationNameMixin from './mixins/locationNameMixin';
import CityTally from './CityTally';
import expandedValueMixin from './mixins/expandedValueMixin';

class RegionTally extends mix(LocationTally).with(locationNameMixin, expandedValueMixin) {
  cities = [];

  constructor(data, name) {
    super(data, name);
    this.buildCities(data);
  }

  addCity(city) {
    this.cities.push(city);
    return this;
  }

  buildCities(regionsData) {
    const { cities } = regionsData || {};
    if (!cities) {
      return;
    }

    const observable = Object.entries(cities)
      .map(([city, data]) => CityTally.fromObjectObservable(data, city));

    concat(...observable)
      .subscribe((city) => this.addCity(city));
  }

  /**
   *
   * @param data
   * @param name
   * @returns {RegionTally}
   */
  static fromObject(data, name = '') {
    return new RegionTally(data, name);
  }

  /**
   *
   * @param data
   * @param name
   * @returns {Observable<RegionTally>}
   */
  static fromObjectObservable(data, name) {
    return of(data)
      .pipe(map((region) => RegionTally.fromObject(region, name)));
  }
}

export default RegionTally;
