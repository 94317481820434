export class TextFragments {
  content = null;

  fragments = [];

  synopsis = '';

  constructor(fragments) {
    this.fragments = fragments;

    if (fragments && fragments.length > 0) {
      const synopsis = fragments[0];
      this.synopsis = synopsis;
    }
  }
}

const emptyPhraseFragments = (text) => {
  const contentCopy = text.replace(/\n{2,}/g, '\n').replace(/\s{2,}/, ' ').trim();
  const splitter = new RegExp(/(.|\n){1,200}/g);
  const matches = contentCopy.match(splitter);

  const fragments = new TextFragments();
  fragments.synopsis = matches && matches[0];

  return fragments;
};

const fragmentText = (text) => (phrase) => {
  const content = Array.isArray(text) ? text.join(' ') : text;
  if (!content) {
    return new TextFragments(['']);
  }

  if (!phrase || phrase === '*') {
    return emptyPhraseFragments(content);
  }

  /* removing highlighting code, because that messes up with the splitting */
  let contentCopy = content.replace(/<b.*?>/g, '').replace(/<\/b>/g, '');

  const fragments = [];

  const regExp = new RegExp(phrase, 'ig');
  const replaceRegExp = new RegExp(`(${phrase})`, 'ig');

  const matches = contentCopy && contentCopy.match(regExp);

  if (matches) {
    matches.forEach((value) => {
      let startIndex = 0;
      let len = 400;

      if (value.length > 150) {
        startIndex = contentCopy.indexOf(value);
        len = value.length;
      } else {
        if (contentCopy.indexOf(value) < 200) {
          startIndex = 0;
        } else {
          startIndex = contentCopy.indexOf(value) - 200;
        }

        len = 400 + value.length;
      }

      const fragment = contentCopy.substr(startIndex, len);
      if (fragment && fragment !== '\n') {
        fragments.push(`...${fragment}...`.replace(
          replaceRegExp, '<span class="highlighted-text">$1</span>',
        ));
      }
      contentCopy = contentCopy.replace(value, '');
    });
  }
  return new TextFragments(fragments, phrase);
};

export const fragmentTextAsync = (text) => (phrase) => {
  const promise = new Promise((resolve) => {
    resolve(fragmentText(text)(phrase));
  });

  return promise;
};

/**
 *
 * @param text
 * @returns {function(*=): Promise<TextFragments>}
 */
export default fragmentText;
