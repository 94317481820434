import BaseVideoInsight from '@/lib/search/discovery/classes/insights/video/BaseVideoInsight';

class VideoBrand extends BaseVideoInsight {}

export default VideoBrand;

/**
 *
 * @param data
 * @returns {*|VideoBrand}
 */
export const videoBrandFactory = (data) => data && new VideoBrand(data);

/**
 *
 * @param list
 * @returns {*|*[VideoBrand]}
 */
export const videoBrandListFactory = (list) => (list && list.map(videoBrandFactory)) || [];
