import axios from 'axios';
import common from '@/config/common';
import authProvider from '@/setup/auth-provider';

export const starfishHttpClientFactory = (auth, discoverySearchConfig) => {
  const httpClient = axios.create({
    baseURL: discoverySearchConfig.discoveryEndPoint(),
  });

  httpClient.interceptors.request.use(async (config) => {
    const token = await auth.bearerToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  }, (error) => Promise.reject(error));

  return httpClient;
};

const starfishHttpClient = () => starfishHttpClientFactory(authProvider(), common);
export default starfishHttpClient;
