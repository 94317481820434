import chroma from 'chroma-js';
/* eslint no-bitwise: ["error", { "allow": ["^"] }] */
const compliment = (color) => chroma(color).num() ^ 0xFFFFFF;

const getPalette = () => () => [
  '#6B15D5',
  '#386DAC',
  '#CAA4FA',
  '#2BFFC1',
  '#FF5B00',
  '#020450',
  '#FF5800',
  '#D6D972',
  '#C6EAFD',
  '#E5ADB2',
  '#C5AD84',
];

const getPaletteComplementary = (color) => (count) => {
  const chromaColor = chroma(color || '#7c65a5');
  return chroma.scale([chromaColor, compliment(color)])
    .mode('lab')
    .colors(count);
};

const singleColor = (color) => (count) => Array
  .from({ length: count }, (_, i) => color);

const multiColor = (colors) => () => colors || getPalette()();

const paletteFactory = ({ primaryColor, colors }) => (chartType) => {
  const multicolorCharts = {
    pie: true,
    'bubble-pack': true,
    time: true,
  };

  if (chartType in multicolorCharts) {
    return multiColor(colors);
  }

  return singleColor(primaryColor);
};

const colors = (count) => getPaletteComplementary(null)(count);

const getChroma = (color) => chroma(color);

export default {
  getPaletteComplementary: getPaletteComplementary,
  getPalette: paletteFactory,
  colors,
  getChroma,
  systemColor: '#7c65a5',
  compliment,
  defaultColors: getPalette()(),
};
