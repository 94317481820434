import facetIconFactory from '@/lib/search/discovery/classes/facetIconFactory';
import { decodeHtml } from '@/plugins/discoveryFilters';
import { createIterableFromRawList } from '../../../util';
import FacetValue from './facetValue';
import Metrics from '../../../charts/models/Metrics';

const FacetOrderType = {
  numericDescending: 'numeric-desc',
  numericAscending: 'numeric-asc',
  alphabeticDescending: 'alpha-desc',
  alphabeticAscending: 'alpha-asc',
};

const escapeComma = (value) => {
  console.log('escaping', value);
  console.log(value);
  return (value || '').replace(/,/g, '~~~');
};

export const facetFieldMapping = {
  content_type_groups: 'Content Group',
  content_type_group: 'Content Group',
  'uploader/content_types': 'Content Type',
  'uploader/capabilities': 'Capabilities',
  'uploader/industries': 'Industries',
  'uploader/tags': 'Tags',
  metadata_storage_file_extension: 'File Extension',
  'uploader/organizations': 'Agencies',
  'uploader/organization': 'Agency',
  'Agencyclients/name': 'Clients',
  'indexer_Agencyclients/name': 'Clients',
  indexer_Agencyclients: 'Clients',
  'Ogilvyclients/name': 'Clients',
  'indexer_Ogilvyclients/name': 'Clients',
  'uploader/partners': 'Partners',
  'uploader/clients': 'Clients',
  brandNames: 'Brands',
  'vidsummarizedInsights/brands/name': 'Brands',
  'indexer_vidsummarizedInsights/brands/name': 'Brands',
  'videoIndexer/summarizedInsights/brands/name': 'Brands',
  'indexer_videoIndexer/summarizedInsights/brands/name': 'Brands',
  'uploader/brands': 'Brands',
  organizations: 'Organizations',
  'uploader/regions': 'Region',
  'uploader/countries': 'Country',
  'uploader/cities': 'City',
  'videoIndexer/summarizedInsights/namedLocations/name': 'Places',
  'indexer_videoIndexer/summarizedInsights/namedLocations/name': 'Places',
  'vidsummarizedInsights/namedLocations/name': 'Places',
  'indexer_vidsummarizedInsights/namedLocations/name': 'Places',
  landmarkNames: 'Landmarks',
  indexer_landmarkNames: 'Landmarks',
  locations: 'Locations',
  indexer_locations: 'Locations',
  'uploader/primaryAuthor': 'Author',
  'uploader/primary_author': 'Author',
  author___contact: 'Author',
  'uploader/contributors': 'Experts',
  contributors___experts: 'Experts',
  'uploader/collections': 'Collections',
  'Agencytopics/name': 'Topics',
  'indexer_Agencytopics/name': 'Topics',
  indexer_Agencytopics: 'Topics',
  'Ogilvytopics/name': 'Topics',
  'indexer_Ogilvytopics/name': 'Topics',
  'vidsummarizedInsights/topics/name': 'Topics',
  'indexer_vidsummarizedInsights/topics/name': 'Topics',
  'videoIndexer/summarizedInsights/topics/iptcName': 'Topics',
  'indexer_videoIndexer/summarizedInsights/topics/iptcName': 'Topics',
  'uploader/topics': 'Topics',
  keyphrases: 'Keyphrases',
  'categories/name': 'Categories',
  'uploader/keywords': 'Keywords',
  'vidsummarizedInsights/keywords/name': 'Keywords',
  'indexer_vidsummarizedInsights/keywords/name': 'Keywords',
  'videoIndexer/summarizedInsights/keywords/name': 'Keywords',
  'indexer_videoIndexer/summarizedInsights/keywords/name': 'Keywords',
  'objects/object': 'Image Objects',
  'indexer_objects/object': 'Image Objects',
  'description/tags': 'Image Tags',
  'indexer_description/tags': 'Image Tags',
  people: 'People',
  'vidsummarizedInsights/faces/name': 'People',
  'indexer_vidsummarizedInsights/faces/name': 'People',
  'videoIndexer/summarizedInsights/faces/name': 'People',
  'indexer_videoIndexer/summarizedInsights/faces/name': 'People',
  celebrityNames: 'Celebrities',
  'videoIndexer/summarizedInsights/namedPeople/name': 'Celebrities',
  'indexer_videoIndexer/summarizedInsights/namedPeople/name': 'Celebrities',
  'uploader/status': 'Status',
  'uploader/archive_tier': 'Archive Tier',

  'Agencyawards/name': 'Awards',
  'indexer_Agencyawards/name': 'Awards',
  'Ogilvyawards/name': 'Awards',

  'vidsummarizedInsights/topics/iptcName': 'Sector',
  'indexer_vidsummarizedInsights/topics/iptcName': 'Sector',
  isOgilvyOS: 'Ogilvy Framework',
  'uploader/products': 'Products',
  'uploader/confidential_users': 'Confidential Users',
  'uploader/business_transformations': 'Business Transformations',
  metadata_storage_last_modified: 'Last Modified',
  'indexer_categories/name': 'Categories',
  'indexer_Agencykeywords/name': 'Business Keywords',
  indexer_Agencykeywords: 'Business Keywords',
  indexer_brandnames: 'Brands',
  file_type_category: 'Document Type',
  business_tags: 'Tags',
  business_topics: 'Topics',
  agency_groups: 'OpCos',
  agencies: 'Agency',
  target_market_groups: 'Target Group',

  'indexer_videoIndexer/summarizedInsights/topics/name': 'Topics',
};

const customTags = [
  'indexer_Agencytopics/name',
  'indexer_Agencyawards/name',
  'indexer_Agencyclients/name',
  'indexer_Agencykeywords/name',
];

const isCustomTag = (key) => customTags.includes(key);

export const ignoredFacetFields = [
  'uploader/engagement_type',
  'categories/detail/celebrities/name',
  'indexer_categories/detail/celebrities/name',
  'categories/detail/landmarks/name',
  'indexer_categories/detail/landmarks/name',
  'tags/name',
  'videoIndexer/summarizedInsights/topics/name',
  'uploader/os_terms',
  'uploader/business_result',
  'uploader/is_featured',
  'uploader/created',
  'uploader/is_confidential',
  'uploader/updated',
  'fileUpload',
  'title',
  'description',
  'terms_and_conditions',
  'notes',
  'published_date',
  'is_featured',
  'storage_path',
  'created',
  'updated',
  'indexer_quantities',
  'indexer_urls',
  'indexer_keyphrases',
  'indexer_emails',
  'metadata_storage_file_extension',
  'indexer_tags/name',
  'file_name',
];

export const facetGrouping = {
  Content: [
    'content_type_groups',
    'content_types',
    'uploader/content_types',
    'sectors',
    'industries',
    'sub_industries',
    'keywords',
    'tags',
    'topics',
    'business_tags',
    'business_topics',
    'campaigns',
    'type_of_engagement',
    'type_of_engagements',
    'asset_visibility',
    'asset_visibilities',
    'confidential',
  ],
  Document: [
    'file_type_category',
    'file_extension',
  ],
  Organizations: [
    'agency_groups',
    'agency_types',
    'agencies',
    'offerings',
    'uploader/capabilities',
    'capabilities',
    'capabalities',
    'businesses',
    'services',
    'clients',
    'brands',
    'uploader/partners',
    'partners',
  ],
  Locations: [
    'uploader/regions',
    'regions',
    'sub_regions',
    'uploader/countries',
    'countries',
    'uploader/cities',
    'cities',
  ],
  'Business Results': [
    'business_results',
    'business_result',
  ],
  'Recognition/Awards': [
    'award_shows',
    'award_names',
    'award_results',
  ],
  Audience: [
    'target_market_groups',
    'target_groups',
    'target_markets',
  ],
  'Users Collections & Groups': [
    'primary_authors',
    'author___contact',
    'uploader/primaryAuthor',
    'uploader/primary_author',
    'contributors',
    'contributors___experts',
    'uploader/contributors',
    'uploader/collections',
    'collections',
    'created_by',
    'updated_by',
  ],
  Status: [
    'uploader/status',
    'uploader/archive_tier',
    'status',
    'archive_tier',
  ],
};

const noUnknownFaces = (facetValue) => !facetValue.name.toLowerCase().includes('unknown');
const chartValueFilter = {
  'vidsummarizedInsights/faces/name': noUnknownFaces,
  'indexer_vidsummarizedInsights/faces/name': noUnknownFaces,
  'videoIndexer/summarizedInsights/faces/name': noUnknownFaces,
  'indexer_videoIndexer/summarizedInsights/faces/name': noUnknownFaces,
};

export const facetGroupLabels = Object.keys(facetGrouping);
const groupedFacetKeys = Object.values(facetGrouping).flatMap((list) => list)
  .reduce((acc, item) => {
    acc[item] = item;
    return acc;
  }, {});

export const filterGroupedFacets = (groupName) => (facets) => {
  if (!groupName || !facets) return [];
  const groupFacetKeys = facetGrouping[groupName] || [];
  return groupFacetKeys.map((key) => (facets[key] || null)).filter((item) => item != null) || [];
};

export const ungroupedFacets = (facets) => facets.filter(
  (facet) => !(facet.key in groupedFacetKeys),
);

const properName = (key) => {
  if (typeof key === 'undefined') {
    return '';
  }

  const field = (key || '')
    .replace(/^metadata_storage_/, '')
    .replace(/^uploader\//, '')
    .replace('indexer_', '');

  const capitalize = ([f, ...r]) => f.toUpperCase() + r.join('');
  const humanize = (s) => s.split('_').map(capitalize).join(' ');

  return humanize(field);
};

export const facetNameFactory = (key) => facetFieldMapping[key] || properName(key);

/**
 *
 * @param fv1 {FacetValue}
 * @param fv2 {FacetValue}
 */
const sortByCountAsc = (fv1, fv2) => fv1.count - fv2.count;

/**
 *
 * @param fv1 {FacetValue}
 * @param fv2 {FacetValue}
 */
const sortByCountDesc = (fv1, fv2) => fv2.count - fv1.count;

const sortByName = (direction = 'asc') => (fv1, fv2) => {
  const name1 = (direction === 'asc' ? fv1.name : fv2.name).toUpperCase();
  const name2 = (direction === 'asc' ? fv2.name : fv1.name).toUpperCase();

  if (name1 > name2) return 1;
  if (name1 < name2) return -1;

  return 0;
};

const sortByNameAsc = sortByName('asc');
const sortByNameDesc = sortByName('desc');

const topValues = (values, n) => values
  && values.sort(({ count: a }, { count: b }) => b - a)
  && values.slice(0, n);

const sortOptions = {
  'alpha-asc': sortByNameAsc,
  'alpha-desc': sortByNameDesc,
  'numeric-asc': sortByCountAsc,
  'numeric-desc': sortByCountDesc,
};

/**
 *
 * @param a{Facet}
 * @param b{Facet}
 */
export const defaultFacetSort = (a, b) => {
  const diff = a.order - b.order;
  if (diff !== 0) {
    return diff;
  }

  return a.name.localeCompare(b.name);
};

/**
 *
 * @type {function(...[*]=)}
 * A function that takes a raw facet and returns a FacetValue object
 */
const facetValueFactory = createIterableFromRawList(FacetValue.fromObject);

const countTotal = (values) => values?.length || 0;

const isAutoTag = (key) => (key || '').startsWith('indexer_') || (key || '')
  .startsWith('metadata_storage_');

const isManualTag = (key) => !isAutoTag(key);

const facetType = (key) => {
  if (isManualTag(key)) return 'manual';

  return 'automatic';
};

/**
 *
 * @param key{string}
 * @returns {string}
 */
const facetCategory = (key) => {
  if (isManualTag(key)) {
    return 'Standard Tags';
  }

  if (key.startsWith('indexer_videoIndexer')) {
    return 'Auto Tags: Videos';
  }

  return 'Auto Tags: Documents & Images';
};

const sortOpposites = {
  'numeric-asc': 'numeric-desc',
  'numeric-desc': 'numeric-asc',
  'alpha-asc': 'alpha-desc',
  'alpha-desc': 'alpha-asc',
};

class Facet extends Metrics {
  selected = false;

  selectedValues = {};

  values = [];

  key = ''

  totalCount = 0;

  icon = '';

  /**
   * SPecifies if the facetable index field is automatic (index) or manual (uploader)
   * @type {string}
   */
  facetType = 'automatic';

  /**
   * Specifies if the facet is Manual, Auto or Video
   * @type {string}
   */
  facetCategory = 'Automatic';

  currentSort = 'numeric-desc';

  sortCache = {};

  alias = '';

  isManualTagFacet = false;

  isCustomTag = false;

  displayInFilter = false;

  groupName = '';

  groupOrder = 0;

  order = 0;

  constructor(facet, sortBy = FacetOrderType.numericDescending) {
    const name = facet.displayName;
    const rawValues = (facet && facet.values) || [];
    const values = facetValueFactory(rawValues);

    super(name, values);
    this.values = values;
    this.facet = facet;
    this.$rawValues = rawValues;

    this.sortCache[sortBy] = this.values;

    this.key = this.facet?.name || '';

    this.totalCount = countTotal(this.values);

    this.facetType = facetType(this.key);
    this.facetCategory = facetCategory(this.key);

    this.isManualTagFacet = isManualTag(this.key);
    this.isCustomTag = isCustomTag(this.key);
    this.icon = facetIconFactory(this.key);
    this.currentSort = sortBy;

    this.groupName = facet?.groupName;
    this.displayInFilter = facet?.displayInFilter;

    this.groupOrder = facet?.groupOrder;
    this.order = facet?.order;

    this.isVideoFacet = false;
  }

  getKey() {
    return this.key;
  }

  getFacetValues() {
    return this.values;
  }

  setName() {
    this.name = facetNameFactory(this.key);
  }

  selectValue(value, isSelected) {
    this.selectedValues[value] = isSelected;
  }

  unSelectFacetValue(value) {
    const facetValue = this.values.find((fv) => fv.name === value);

    if (facetValue) {
      facetValue.selected = false;
    }

    this.setAsSelected();
  }

  setValuesAsSelected(selectedValues) {
    const promise = new Promise((resolve) => {
      this.values.forEach((filterValue) => {
        if (selectedValues.includes(filterValue.name)) {
          filterValue.selected = true;
        }
      });
      this.setAsSelected();
      resolve(this.values);
    });

    return promise;
  }

  /**
   * Clears all the values selected.
   */
  clear() {
    this.values.forEach((filterValue) => {
      filterValue.selected = false;
    });

    this.selected = false;
  }

  setAsSelected() {
    this.selected = this.values.some((filterValue) => filterValue.selected);
  }

  /**
   * This method returns current key and selected values if any values are selected
   */
  get queryValues() {
    const values = this.values
      .filter((filterValue) => filterValue.selected)
      .map((filterValue) => filterValue.name);

    return (values && values.length > 0 && { key: this.key, value: values }) || null;
  }

  get queryList() {
    if (this.queryValues === null) {
      return [];
    }

    const { value } = this.queryValues;
    return value.map((val) => `${this.key}_${escapeComma(val)}`);
  }

  get queryString() {
    return (this.queryList && this.queryList.join(','));
  }

  top(n) {
    return topValues(this.values, n);
  }

  topChartValues(top) {
    if (this.key in chartValueFilter) {
      const filter = chartValueFilter[this.key];
      return topValues(this.values.filter(filter), top);
    }

    return topValues(this.values, top);
  }

  /**
   *
   * @param sortBy
   * @returns {*[FacetValue]|*}
   */
  sorted() {
    const newSort = this.currentSort;

    /* if it's already sorted, return from the cache */
    if (newSort in this.sortCache) {
      return this.sortCache[newSort];
    }

    /* check if we have the opposite of the sortBy done, if yes return that reversed.
      for example: sortBy is alpha-asc. if we have already sorted list by alpha-desc,
      reverse the list
     */
    const oppositeSort = sortOpposites[newSort];
    if (oppositeSort in this.sortCache) {
      const opp = this.sortCache[oppositeSort] || [];
      this.sortCache[newSort] = [...opp].reverse();
      return this.sortCache[newSort];
    }

    const comparer = sortOptions[newSort] || sortByCountDesc;
    const list = [...this.values || []].sort(comparer);
    this.sortCache[newSort] = list;
    return list;
  }

  static async createFacetWithSelectedValues(facetKey, flatValues) {
    const values = flatValues.map((name) => ({ name, count: 1 }));
    const facet = new Facet({ name: facetKey, values });
    await facet.setValuesAsSelected(flatValues);
    return facet;
  }

  static fromKeyAndValue(key, values, isVideo = false) {
    const displayName = facetNameFactory(key);
    const facet = new Facet({ displayName, name: key, values });
    facet.isVideoFacet = true;
    return facet;
  }
}

export default Facet;

/**
 *
 * @type {function(...[*]=)}
 * A function that takes a raw facet and returns a FacetValue object
 */
const facetsListFactory = createIterableFromRawList(
  (facet, sortBy = 'numeric-desc') => new Facet(facet, sortBy),
);

const facetOrdering = [
  {
    icon: 'mdi-order-numeric-descending',
    value: FacetOrderType.numericDescending,
    label: 'Document Count',
  },
  {
    icon: 'mdi-order-numeric-ascending',
    value: FacetOrderType.numericAscending,
    label: 'Document Count',
  },
  {
    icon: 'mdi-order-alphabetical-descending',
    value: FacetOrderType.alphabeticDescending,
    label: 'Alphabetically',
  },
  {
    icon: 'mdi-order-alphabetical-ascending',
    value: FacetOrderType.alphabeticAscending,
    label: 'Alphabetically',
  },
];

export {
  facetsListFactory,
  facetValueFactory,
  facetOrdering,
  FacetOrderType,
};
