import User, { userFactory } from '@/lib/business/models/User';

class EntityAssignment {
  user = null;

  roleCode = '';

  /**
   *
   * @param user{User}
   * @param roleCode{string}
   */
  constructor(user, roleCode) {
    this.user = user;
    this.roleCode = roleCode;
  }

  static fromObject(object) {
    const user = userFactory.createFromGraphDb(object.user);
    return new EntityAssignment(user, object.roleCode);
  }

  static fromList(list) {
    return list.map(EntityAssignment.fromObject);
  }
}

export default EntityAssignment;
