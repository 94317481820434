const fileGroups = [
  [/^\.(ppt|pptx|pps)$/, 'default-icon-powerpoint.png', 'presentation', ['ppt', 'pptx', 'pps']],
  [/^\.(gif|jpg|jpeg|png)$/, 'default-icon-image.png', 'image', ['gif', 'jpg', 'jpeg', 'png']],
  [/^\.(xls|xlsx|numbers)$/, 'default-icon-excel.png', 'spreadsheet', ['xls', 'xlsx', 'numbers']],
  [/^\.(doc|docx|pages)$/, 'default-icon-word.png', 'document', ['doc', 'docx', 'pages']],
  [/^\.(avi|mov|wmv|mp4|m4v)$/, 'default-icon-video.png', 'video',
    ['avi', 'mov', 'wmv', 'mp4', 'm4v']],
  [/^\.(pdf)$/, 'default-icon-pdf.png', 'pdf', ['pdf']],
  [/^\.(html|.htm)$/, 'default-icon-html.png', 'html', ['html', 'htm']],
  [/^\.(key)$/, 'default-icon-keynote.png', 'keynote', ['key']],
  [/^\.(mp3)$/, 'default-icon-audio.png', 'audio', ['mp3']],
  [/^\.(indd)$/, 'default-icon-indesign.png', 'indesign', ['indd']],
  [/^\.(psd)$/, 'default-icon-psd.png', 'psd', ['psd']],
  [/^\.(zip)$/, 'default-icon-zip.png', 'zip', ['zip']],
  [/^\.(tif|tiff)$/, 'default-icon-tiff.png', 'tiff', ['tif', 'tiff']],
];

const check = (extension) => ([pattern]) => new RegExp(pattern, 'i').test(extension);

export const FileTypes = {
  IMAGE: 'image',
  VIDEO: 'video',
  DOCUMENT: 'document',
  PDF: 'pdf',
  HTML: 'html',
  SPREADSHEET: 'spreadsheet',
  PRESENTATION: 'presentation',
  KEYNOTE: 'keynote',
  AUDIO: 'audio',
  INDESIGN: 'indesign',
  PSD: 'psd',
  ZIP: 'zip',
};

const fileTypesUtil = {
  allFileTypes() {
    return fileGroups.flatMap((rule) => rule.slice(-1)[0]);
  },

  is(extension) {
    const fileGroup = fileGroups.find(check(extension));

    if (fileGroup) {
      const [, , type] = fileGroup;
      return type;
    }
    return null;
  },

  isImage(extension) {
    return this.is(extension) === FileTypes.IMAGE;
  },

  isVideo(extension) {
    return this.is(extension) === FileTypes.VIDEO;
  },

  isKeyNote(extension) {
    return this.is(extension) === FileTypes.KEYNOTE;
  },
  isAudio(extension) {
    return this.is(extension) === FileTypes.AUDIO;
  },
  
  isPathValid(path) {
    try {
      return new URL(path) && true;
    } catch {
      return false;
    }
  },
};

export default fileTypesUtil;
