export default {
  children: [
    {
      path: 'home',
      name: 'group-details-home',
      component: () => import(
        /* webpackChunkName: "group-details-home" */
        '@/components/groups/groupDetail/GroupDetailHome.vue'
      ),
      meta: {
        editableType: 'group',
        mobileName: 'group',
        pageTitle: 'Group Pinboard',
      },
    },
    {
      path: 'collections',
      name: 'group-details-collections',
      component: () => import(
        /* webpackChunkName: "group-details-collections" */
        '@/components/groups/groupDetail/GroupDetailCollections.vue'
      ),
      meta: {
        editableType: 'group',
        mobileName: 'group',
        pageTitle: 'Group Collections',
      },
    },
    {
      path: 'members',
      name: 'group-details-members',
      component: () => import(
        /* webpackChunkName: "group-details-members" */
        '@/components/groups/groupDetail/GroupDetailMembers.vue'
      ),
      meta: {
        editableType: 'group',
        mobileName: 'group',
        pageTitle: 'Group Members',
      },
    },
  ],
};
