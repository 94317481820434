import common from '../config/common';

const SearchIndexApi = (httpClient) => {
  const endpoint = common.ciSearchEndPoint();
  const apiVersion = common.ciSearchApiVersion();
  const apiKey = common.ciSearchApiKey();

  // axios.defaults.headers.common['api-key'] = apiKey;

  return {
    suggestions: async (query) => {
      const URL = `${endpoint}docs/suggest`;
      const { data } = await httpClient.get(URL, {
        headers: {
          'api-key': apiKey,
        },
        params: {
          'api-version': apiVersion,
          search: query,
          suggesterName: 'sg',
          top: 8,
        },
      });

      const suggestions = data?.value?.map((result) => result['@search.text']);
      console.log('Suggestions');
      console.log(data);

      return (suggestions && [...new Set(suggestions)]) || [];
    },

    search: (query, top, count) => {
      const URL = `${endpoint}docs`;
      return httpClient.get(URL, {
        params: {
          'api-version': apiVersion,
          search: query,
          $top: top || 20,
          $count: count === true,
        },
      });
    },
  };
};

export default SearchIndexApi;
