import GraphQlClient from '@/lib/graphql/models/GraphQlClient';
import { updateUser } from '@/admin/users/models/StarfishUser';
import queries from '../graphql/queries';
import UsersPagedResult from '../models/UsersPagedResult';

class UsersGraphClient extends GraphQlClient {
  /**
   *
   * @param usersQuery
   * @param pageInfo{PaginationInput}
   * @returns {Promise<UsersPagedResult>}
   */
  async getUsers(usersQuery = null, pageInfo = null) {
    const query = usersQuery || queries.users;
    const variables = {
      ...(pageInfo || {}),
    };

    const { users } = await this.query(query, variables);
    const result = new UsersPagedResult(users);
    result.setQuery(query);

    console.log(result);
    return result;
  }

  /**
   *
   * @param user{StarfishUser}
   * @returns {Promise<StarfishUser>}
   */
  async getUser(user) {
    const query = queries.userById;
    const variables = {
      id: user.id,
    };

    const { userById } = await this.query(query, variables);

    return userById && updateUser(user, userById);
  }
}

export default UsersGraphClient;

export const userGraphClient = new UsersGraphClient();
