import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';

import './plugins/charts';
import DiscoveryObserver from '@/plugins/DiscoveryObserver';
import App from './App.vue';

import './sass/styles.scss';
import regularRouter from './router';
import maintenanceRouter from './router/maintenance';
import store from './store';
import vuetify from './plugins/vuetify';
import documentTypeIconService from './plugins/documentType';
import searchResultMapper from './plugins/discoverySearchResultMapper';
import discoveryFilters from './plugins/discoveryFilters';
import navUrlTransformer from './plugins/navigation';
import starfishRouter from './plugins/navigation/router/starfishRouter';

Vue.use(VueClipboard);
Vue.use(documentTypeIconService);
Vue.use(searchResultMapper);
Vue.use(discoveryFilters);
Vue.use(DiscoveryObserver);
Vue.use(navUrlTransformer);
Vue.use(starfishRouter);

Vue.config.productionTip = false;

const getRouter = () => {
  const isInMaintenance = process.env.VUE_APP_IN_MAINTENANCE
    && process.env.VUE_APP_IN_MAINTENANCE === 'true';
  console.log('are we in maintenance', isInMaintenance);
  if (isInMaintenance) {
    return maintenanceRouter;
  }

  return regularRouter;
};

const router = getRouter();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
