import MetricItem from '@/lib/charts/models/MetricItem';

const SearchMetricItem = class SearchMetricItem extends MetricItem {
  constructor(count = 0, name = '') {
    super(name, count);
  }

  static fromObject({ count, query }) {
    return new SearchMetricItem(count, query);
  }
};

export default SearchMetricItem;
