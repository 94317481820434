import InteractionInsight from '@/lib/insights/models/InteractionInsight';

class DocumentInsightsClient {
  /**
   *
   * @type {AxiosInstance}
   */
  httpClient = null;

  baseUrl = 'v2/assets/';

  /**
   *
   * @param httpClient{AxiosInstance}
   */
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  /**
   *
   * @param docId
   * @returns {Promise<InteractionInsight>}
   */
  async getInsight(docId) {
    const url = `${this.baseUrl}${docId}/insights`;
    const { data } = await this.httpClient.get(url);

    return InteractionInsight.fromObject(data);
  }

  async like(docId) {
    const url = `${this.baseUrl}${docId}/insights/likes`;

    try {
      await this.httpClient.post(url);
    } catch (e) {
      console.error(e);
    }

    return this.getInsight(docId);
  }

  async unLike(docId) {
    const url = `${this.baseUrl}${docId}/insights/likes`;

    try {
      await this.httpClient.delete(url);
    } catch (e) {
      console.error(e);
    }
    return this.getInsight(docId);
  }

  async markViewed(docId) {
    const url = `${this.baseUrl}${docId}/insights/views`;

    try {
      await this.httpClient.post(url);
    } catch (e) {
      console.error(e);
    }
    return this.getInsight(docId);
  }
}

export default DocumentInsightsClient;
