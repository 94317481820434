import AssistantConversation from '../lib/models/AssistantConversation';

export default {
  resetConversation(state) {
    state.conversation = new AssistantConversation();
  },
  resetSummaryConversation(state) {
    state.summaryConversation = new AssistantConversation();
    state.shortSummaryConversation = new AssistantConversation();
    state.fullSummaryLoaded = false;
    state.shortSummaryLoaded = false;
  },
  resetCaseStudyConversation(state) {
    state.caseStudyConversation = new AssistantConversation();
    state.caseStudyConversationLoaded = false;
  },
  resetCustomCaseStudyConversation(state) {
    state.customCaseStudyConversation = new AssistantConversation();
  },
  resetExtractThemesConversation(state) {
    state.extractThemesConversation = new AssistantConversation();
    state.themeConversationLoaded = false;
  },
  resetExtractToneConversation(state) {
    state.extractToneConversation = new AssistantConversation();
    state.toneConversationLoaded = false;
  },
  resetNewsletterConversation(state) {
    state.newsletterConversation = new AssistantConversation();
    state.newsLetterConversationLoaded = false;
  },
  resetSlidePresentationConversation(state) {
    state.slidePresentationConversation = new AssistantConversation();
    state.slidePresentationConversationLoaded = false;
  },
  resetCustomPresentationSlideConversation(state) {
    state.customPresentationSlideConversation = new AssistantConversation();
  },
  resetSocialpostConversation(state) {
    state.socialpostConversation = new AssistantConversation();
    state.socialPostConversationLoaded = false;
  },
  resetResponseWriterConversation(state) {
    state.responseWriterConversation = new AssistantConversation();
  },
  resetImageAnalyzerConversation(state) {
    state.imageAnalyzerConversation = new AssistantConversation();
  },
  resetPreviewAnalyzerConversation(state) {
    state.previewAnalyzerConversation = new AssistantConversation();
  },
  resetImageAltTagConversation(state) {
    state.imageAltTagConversation = new AssistantConversation();
  },
  resetImageCaptionConversation(state) {
    state.imageCaptionConversation = new AssistantConversation();
  },
  resetInsightsConversation(state) {
    state.insightsConversation = new AssistantConversation();
    state.insightsConversationLoaded = false;
  },
  resetShareMessageConversation(state) {
    state.shareMessageConversation = new AssistantConversation();
    state.shareMessageConversationLoaded = false;
  },
  resetCustomSocialpostConversation(state) {
    state.customSocialpostConversation = new AssistantConversation();
  },
  resetCustomNewsletterConversation(state) {
    state.customNewsletterConversation = new AssistantConversation();
  },
  resetDocumentAnalyzerConversation(state) {
    state.documentAnalyzerConversation = new AssistantConversation();
  },
  setFullSummaryLoaded(state, value) {
    state.fullSummaryLoaded = value;
  },
  setShortSummaryLoaded(state, value) {
    state.shortSummaryLoaded = value;
  },
  setConversationDocId(state, docId) {
    state.conversationDocId = docId;
  },
  setNewsLetterConversationLoaded(state, value) {
    state.newsLetterConversationLoaded = value;
  },
  setSlidePresentationConversationLoaded(state, value) {
    state.slidePresentationConversationLoaded = value;
  },
  setSocialPostConversationLoaded(state, value) {
    state.socialPostConversationLoaded = value;
  },
  setThemeConversationLoaded(state, value) {
    state.themeConversationLoaded = value;
  },
  setToneConversationLoaded(state, value) {
    state.toneConversationLoaded = value;
  },
  setCaseStudyConversationLoaded(state, value) {
    state.caseStudyConversationLoaded = value;
  },
  setInsightsConversationLoaded(state, value) {
    state.insightsConversationLoaded = value;
  },
  setAssistantMessageLoadingStatus(state, value) {
    state.assistantMessageLoading = value;
  },
  setPenalTriggeredFrom(state, value) {
    state.penalTriggeredFrom = value;
  },
  setShareMessageConversationLoaded(state, value) {
    state.shareMessageConversationLoaded = value;
  },
};
