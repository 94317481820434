import Facet, { FacetOrderType } from '@/lib/search/discovery/classes/facet';

class IndexFieldDescription {
  name = '';

  displayName = '';

  groupName = '';

  displayInFilter = false;

  displayInInsights = false;

  isCollection = false;

  isSearchable = false;

  isFilterable = false;

  isSortable = false;

  isFacetable = false;

  isKey = false;

  isRetrievable = true;

  constructor(name = '', displayName = '', groupName = '', displayInFilter = false) {
    this.name = name;
    this.displayName = displayName;
    this.groupName = groupName;

    this.displayInFilter = displayInFilter;
    this.displayInInsights = displayInFilter;
  }

  createFrom(factory) {
    return factory(this);
  }

  static fromObject(data) {
    if (data == null || typeof data === 'undefined') {
      return new IndexFieldDescription();
    }

    const {
      name,
      displayName,
      groupName,
      displayInFilter,
    } = data;
    return new IndexFieldDescription(name, displayName, groupName, displayInFilter);
  }
}

export default IndexFieldDescription;

export const indexFieldDescriptionFactory = IndexFieldDescription.fromObject;
