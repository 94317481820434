class FetchAssetDetailsChoices {
  fetchThumbnails = false;

  thumbnailType = 'default';

  fetchInteractionMetrics = false;

  fetchVisibility = false;

  docIds = [];

  constructor(
    fetchThumbnails = false,
    thumbnailType = 'default',
    fetchInteractionMetrics = false,
    fetchVisibility = false,
  ) {
    this.fetchThumbnails = fetchThumbnails;
    this.thumbnailType = thumbnailType;
    this.fetchInteractionMetrics = fetchInteractionMetrics;
    this.fetchVisibility = fetchVisibility;
  }

  static fromObject({
    fetchThumbnails = false,
    thumbnailType = 'default',
    fetchInteractionMetrics = false,
    fetchVisibility = false,
  }) {
    return new FetchAssetDetailsChoices(
      fetchThumbnails,
      thumbnailType,
      fetchInteractionMetrics,
      fetchVisibility,
    );
  }
}

export default FetchAssetDetailsChoices;
