import AwardsData from './AwardsData';
import FilterForm from './filters/FilterForm';

class AwardsResult {
  /**
   *
   * @type {AwardsData}
   */
  awardsData = null;

  /**
   *
   * @type {FilterForm}
   */
  filterForm = null;

  constructor(data) {
    this.buildAwardData(data);
    this.buildFilterForm(data);
  }

  buildAwardData(data) {
    const { awardsData, resultTotals } = data;

    this.awardsData = new AwardsData(awardsData, resultTotals);
    console.log('buildAwardData');
    console.log(this.awardsData);
  }

  buildFilterForm(data) {
    const { filterForm } = data;
    this.filterForm = new FilterForm(filterForm);
  }

  updateAwardData(awardData) {
    this.awardsData = awardData;
  }
}

export default AwardsResult;
