import BaseVideoInsight from '@/lib/search/discovery/classes/insights/video/BaseVideoInsight';

class VideoTopic extends BaseVideoInsight {
  iabName = '';

  iptcName = '';

  referenceUrl = '';

  confidence = 0.0;

  constructor(data) {
    super(data);

    this.iabName = data?.iabName;
    this.iptcName = data?.iptcName;
    this.referenceUrl = data?.referenceUrl;
    this.confidence = data?.confidence;
  }
}

export default VideoTopic;
