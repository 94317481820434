const QueryResponseClient = function QueryResponseClient(httpClient) {
  this.httpClient = httpClient;
  this.endpoint = 'v2/reponseGenerator';
};

/**
 * @param question
 * @param indexId
 * @returns {*}
 */
QueryResponseClient.prototype.getResponse = function getResponse(question, indexId) {
  const params = {
    query: question.question,
    index: indexId,
  };

  return this.httpClient.post(`${this.endpoint}/getResponses`, params).then(({ data }) => {
    question.setResponse(data);
    return question;
  });
};

export default QueryResponseClient;
