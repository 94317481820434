class Page {
  pageId = 0;

  name = '';

  path = '';

  code = '';

  iconValue = '';

  constructor(pageId, name, code, path, iconValue) {
    this.pageId = pageId;
    this.name = name;
    this.path = path;
    this.code = code;
    this.iconValue = iconValue;
  }

  /**
   *
   * @param name
   * @param path
   * @param code
   * @param pageId
   * @returns {Page}
   */
  static from({
    name,
    path,
    code,
    pageId,
    iconValue,
  }) {
    return new Page(pageId, name, code, path, iconValue);
  }

  /**
   *
   * @param pages
   * @returns {Page[]}
   */
  static fromList(pages) {
    return (pages || []).map(Page.from);
  }
}

export default Page;
