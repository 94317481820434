import { concat } from 'rxjs';
import RegionTally from './RegionTally';
import LocationTally from './LocationTally';

class AwardsData {
  /**
   *
   * @type {[RegionTally]}
   */
  regions = [];

  awardNames = [];

  /**
   *
   * @type {LocationTally}
   */
  totals = null;

  constructor(data = {}, totals = {}) {
    if (Object.entries(data)) {
      this.buildRegions(data);
    }

    this.buildTotals(totals);
  }

  buildRegions(data) {
    const observables = AwardsData.buildRegionsTallyObservable(data);
    this.updateWithObservables(observables);
  }

  buildTotals(totals) {
    this.totals = LocationTally.fromObject(totals).setTally(totals);
  }

  updateWithObservables(observables) {
    this.regions = [];
    concat(...observables)
      .subscribe((region) => this.regions.push(region));
  }

  get awards() {
    if (this.awardNames?.length === 0) {
      this.awardNames = this.buildAwardNames();
    }

    return this.awardNames;
  }

  buildAwardNames() {
    const awardNames = this.regions.flatMap((region) => region.awardNames);
    return [...new Set(awardNames)];
  }

  /**
   *
   * @param data
   * @returns {Observable<RegionTally>[]}
   */
  static buildRegionsTallyObservable(data) {
    return Object.entries(data)
      .map(([region, regionData]) => RegionTally.fromObjectObservable(regionData, region));
  }
}

export default AwardsData;
