import { mix } from 'mixwith/mixwith';
import documentThumbnailUrlFetcher
from '@/lib/search/discovery/classes/mixins/documentThumbnailUrlFetcher';
import starfishHttpClient from '@/api/starfishHttpClientFactory';
import Content from '@/lib/search/discovery/classes/content';
import AssetThumbnailFetcher from '@/lib/search/discovery/services/AssetThumbnailFetcher';
import AssetDisplayDetail from '@/lib/business/models/AssetDisplayDetail';
import withPageActions from '@/lib/search/discovery/classes/mixins/withPageActions';
import AssetDisplayDetailsFetcher from '@/lib/search/discovery/services/AssetDisplayDetailsFetcher';

const httpClient = starfishHttpClient();
const thumbnailFetcher = new AssetThumbnailFetcher(httpClient);
const detailsFetcher = new AssetDisplayDetailsFetcher(httpClient);

export default class DocumentWithUrls extends mix(Content)
  .with(documentThumbnailUrlFetcher, withPageActions) {
  asset = new AssetDisplayDetail();

  previewUrl = null;

  isLoading = false;

  get docId() {
    return this.asset.docId;
  }

  get URL() {
    return this.previewUrl;
  }

  constructor(doc) {
    super(doc.document);
    this.asset = (doc.asset && AssetDisplayDetail.fromObject(doc.asset)) || new AssetDisplayDetail();
    this.asset.title = this.title;
    this.previewUrl = doc.asset?.thumbnailUrl;
    this.assetId = this.asset?.assetId;
  }
}

export const documentWithUrlsFactory = (rawDocuments, fetchThumbnails) => (thumbnailType) => {
  // console.log('Raw documents');
  // console.log(rawDocuments, fetchThumbnails);
  const documents = rawDocuments.map((doc) => new DocumentWithUrls(doc, httpClient));

  // console.log('documentWithUrlsFactory', documents);
  if (fetchThumbnails) {
    thumbnailFetcher.fetchThumbnails(documents, thumbnailType);
  }

  return documents;
};

/**
 *
 * @param rawDocuments
 * @param fetchAssetDetailsChoices {FetchAssetDetailsChoices}
 */
export const documentsWithDetailsFactory = (rawDocuments, fetchAssetDetailsChoices = null) => {
  // console.log('documentsWithDetailsFactory, raw', rawDocuments);
  const documents = rawDocuments.map((doc) => new DocumentWithUrls(doc, httpClient));
  // console.log('documentsWithDetailsFactory, docs', documents);
  detailsFetcher.fetchDetailsSequentially(documents, fetchAssetDetailsChoices);

  return documents;
};
