import gql from 'graphql-tag';

export const assetFields = gql`
    fragment assetListFields on Asset {
      assetId
      fileName
      originalFileName
      storagePath
      thumbnailUrl
      token
      title
      description
      docId
      created
      updated
      submissionDate
      deploymentIndexId
      createdByUserId
      updatedByUserId
      assetContributors {
        isPrimary
        user {
          userId
          givenName
          lastName
          email
        }
      }
      selectedSet{
        selectedValues {
          flattenedFieldName
          flattenedFieldCode
          value
          softDeleted
        }
      },
      interactionInsight{
        likes,
        views,
        downloads,
        id,
      },
      isAccessible
    }
`;

export default {
  assetFields,
};
