class PermissionsChecker {
  permissionsMap = {};

  constructor(map = {}) {
    this.permissionsMap = map;
  }

  canDo(permission) {
    return (permission in this.permissionsMap) && this.permissionsMap[permission];
  }
}

export default PermissionsChecker;
