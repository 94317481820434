class VideoAppearance {
  endSeconds = 0;

  /**
   *
   * @type {}
   */
  endTime = null;

  startSeconds = 0;

  startTime = null;

  constructor(appearance) {
    this.endSeconds = appearance?.endSeconds;
    this.endTime = appearance?.endTime;

    this.startSeconds = appearance?.startSeconds;
    this.startTime = appearance?.startTime;
  }
}

export default VideoAppearance;
