<template>
  <!--<v-list class="portal-links mt-3 pl-0" dense nav> -->
    <div>
      <navigation-group
        v-for="group in sortedItems"
        :key="group.code"
        :nav-group="group"
        :expand="group.code == 'mainNavigationPageGrouping'">
      </navigation-group>
    </div>
  <!--</v-list>-->
</template>

<script>
import NavigationGroup from '@/components/ui/navigation/NavigationGroup.vue';

export default {
  name: 'NavigationList',
  components: { NavigationGroup },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    sortedItems() {
      return this.items.slice().sort((a, b) => a.order - b.order);
    },
  },
};
</script>

<style scoped>

</style>
