import { mix } from 'mixwith';
import { ConnectionMixin, ContainsQueryMixin } from '@/lib/graphql/mixins';
import DeploymentIndex from './DeploymentIndex';

const DeploymentIndexConnection = class DeploymentIndexConnection extends mix(
  ConnectionMixin(DeploymentIndex),
).with(ContainsQueryMixin) {
  /**
   *
   * @returns {[DeploymentIndex]}
   */
  get indices() {
    return this.items;
  }
};

export default DeploymentIndexConnection;
