import GraphQlClient from '@/lib/graphql/models/GraphQlClient';
import queries from '@/documentAssets/graphql/queries';
import AssetConnection from '@/documentAssets/models/AssetConnection';
import PaginationInput from '@/lib/graphql/models/PaginationInput';

class AssetsGraphQlClient extends GraphQlClient {
  async getAssets(assetsQuery, pageInfo, order, where) {
    const selectedQuery = assetsQuery || queries.assetListQuery;

    const variables = {
      ...pageInfo,
      order,
      where,
    };

    const { assets } = await this.query(selectedQuery, variables);
    return new AssetConnection(assets);
  }

  async getLatestUploads(deploymentIndexId, count = 20) {
    const pageInfo = new PaginationInput();
    pageInfo.first = count;
    const order = {
      created: 'DESC',
    };
    const where = {
      deploymentIndexId: {
        eq: deploymentIndexId,
      },
    };

    const assets = await this.getAssets(null, pageInfo, order, where);
    return assets;
  }

  async getNewCases(deploymentIndexId, count = 20) {
    const pageInfo = new PaginationInput();
    pageInfo.first = count;

    const order = {
      created: 'DESC',
    };
    const where = {
      and: {
        deploymentIndexId: {
          eq: deploymentIndexId,
        },
        selectedSet: {
          selectedValues: {
            some: {
              and: {
                value: { eq: 'Case Study' },
                flattenedFieldCode: { eq: 'content_types' },
              },
            },
          },
        },
      },
    };

    const assets = await this.getAssets(null, pageInfo, order, where);
    return assets;
  }
}

export default AssetsGraphQlClient;

export const assetsGraphQlClient = new AssetsGraphQlClient();
