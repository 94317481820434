class AmpPlayerSettings {
  viewUrl = '';

  viewAccessToken = '';

  constructor(viewUrl = '', viewAccessToken = '') {
    this.viewUrl = viewUrl;
    this.viewAccessToken = viewAccessToken;
  }

  static fromObject(data) {
    const { viewURL, viewAccessToken } = data;
    return new AmpPlayerSettings(viewURL, viewAccessToken);
  }
}

export default AmpPlayerSettings;
