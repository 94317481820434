import DocumentAssetsActionTypes from '@/documentAssets/store/DocumentAssetsActionTypes';
import { assetsGraphQlClient } from '@/documentAssets/client/AssetsGraphQlClient';
import DocumentAssetsMutationTypes from '@/documentAssets/store/DocumentAssetsMutationTypes';
import { assetStreamServiceFactory } from '@/documentAssets/client/AssetStreamsService';
import { ApiFactory } from '@/api/api-factory';
import { assetStreamRestServiceFactory } from '@/documentAssets/client/AssetStreamRestService';
import FetchAssetDetailsChoices from '@/lib/business/models/FetchAssetDetailsChoices';

const discoveryApi = ApiFactory.getDiscoveryClient();

const assetStreamService = assetStreamRestServiceFactory();

export default {
  async [DocumentAssetsActionTypes.getAssetsForDashboard]({
    commit,
    dispatch,
    state,
    rootState,
  }) {
    const fetch = async (deploymentIndex) => {
      const indexId = deploymentIndex.deploymentIndexId;
      const count = rootState.common.fetchCount;

      setTimeout(async () => {
        state.loadingLatestUploads = true;
        const assets = await assetsGraphQlClient.getLatestUploads(indexId, count);
        commit(DocumentAssetsMutationTypes.setLatestUploads, assets);
        state.loadingLatestUploads = false;
      });

      setTimeout(async () => {
        state.loadingNewCases = true;
        const assets = await assetsGraphQlClient.getNewCases(indexId, count);
        commit(DocumentAssetsMutationTypes.setNewCases, assets);
        state.loadingNewCases = false;
      });
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', fetch, { root: true });
  },

  async fetchAssetStreams({ commit, rootState, dispatch }) {
    const fetch = async (deploymentIndex) => {
      const indexId = deploymentIndex.deploymentIndexId;
      const { fetchCount } = rootState.common;

      const streams = await assetStreamService.getStreams(indexId);
      await commit('setStreams', streams);

      assetStreamService.fetchDataForStreams(streams, fetchCount, indexId);
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', fetch, { root: true });
  },

  async fetchFeaturedContent({ state, rootState, dispatch }) {
    const fetch = async (deploymentIndex) => {
      const indexId = deploymentIndex.deploymentIndexId;
      const { fetchCount } = rootState.common;

      const streams = state.featuredContents;
      assetStreamService.fetchDataForStreams(
        streams,
        fetchCount,
        indexId,
        FetchAssetDetailsChoices.fromObject({
          fetchThumbnails: true,
          thumbnailType: 'featuredAssetImage',
        }),
      );
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', fetch, { root: true });
  },
};
