import Metrics from '@/lib/charts/models/Metrics';
import AssetMetricItem from '@/lib/search/discovery/classes/charting/AssetMetricItem';

const AssetMetrics = class AssetMetrics extends Metrics {
  key = '';

  constructor(key, name, values) {
    super(name, values);
    this.key = key;
  }

  static fromList(key, name, rawValues) {
    const values = rawValues.map(AssetMetricItem.fromObject);

    return new AssetMetrics(key, name, values);
  }
};

export default AssetMetrics;
