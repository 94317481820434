/**
 * Extracts the highlighted text from captions
 * @param captions
 * @returns {[string, string]|[undefined, undefined]}
 */
const extractFromCaptions = (captions) => {
  if (typeof captions === 'undefined' || captions == null || captions.length === 0) {
    return null;
  }

  const { text, highlights } = captions[0];
  return [text, highlights];
};

/**
 *
 * @param highlights
 * @returns {[string, string]|[*, *]}
 */
const extractFromHighlights = (highlights) => {
  if (typeof highlights === 'undefined' || highlights == null) {
    return null;
  }

  const { content } = highlights;
  if (typeof content === 'undefined' || content.length === 0) {
    return null;
  }

  const [text] = content;

  return [text, text];
};

const extractHighlightedText = (captions, highlights) => extractFromCaptions(captions)
  || extractFromHighlights(highlights)
  || ['', ''];

/**
 * Object the holds the highlights
 * @param captions
 * @param highlights
 * @param content
 * @constructor
 */
const DocumentHighlights = function DocumentHighlights(captions, highlights, content) {
  this.text = '';
  this.highlightedText = '';

  this.isHighlightExtracted = false;

  this.captions = captions;
  this.highlights = highlights;
  this.content = content;
};

Object.defineProperty(DocumentHighlights.prototype, 'highlight', {
  get() {
    if (!this.isHighlightExtracted) {
      const [text, highlight] = extractHighlightedText(this.captions, this.highlights);
      this.text = text;
      this.highlightedText = highlight;
      this.isHighlightExtracted = true;
    }
    return this.highlightedText;
  },
});

export default DocumentHighlights;
