class SearchReport {
  reportName = '';

  deploymentIndexName = '';

  deploymentIndexId = 0;

  query = '';

  date = '';

  year = 0;

  month = '';

  day = '';

  userId = 0;

  userName = '';

  userEmail = '';

  type = '';

  source = '';

  page = '';

  resultCount = '';

  constructor(searchReport) {
    if (searchReport == null) {
      return;
    }
    this.reportName = searchReport.reportName;
    this.deploymentIndexName = searchReport.deploymentIndexName;
    this.deploymentIndexId = searchReport.deploymentIndexId;
    this.query = searchReport.query;
    this.date = searchReport.date;
    this.year = searchReport.year;
    this.month = searchReport.month;
    this.day = searchReport.day;
    this.userId = searchReport.userId;
    this.userName = searchReport.userName;
    this.userEmail = searchReport.userEmail;
    this.type = searchReport.type.toLowerCase() === 'full' ? 'Keyword' : searchReport.type;
    this.source = searchReport.source;
    this.page = searchReport.page;
    this.resultCount = searchReport.resultCount;
  }

  static fromObject(data) {
    if (data == null) {
      return null;
    }

    return new SearchReport(data);
  }

  static fromObjects(list) {
    if (list == null) {
      return null;
    }

    return list.map((data) => this.fromObject(data));
  }
}

export default SearchReport;
