// CollectionAsset Model

// Not used yet
// This is part of Vuex-Orm
// I(steve) was hoping to temporarily use it
// to create object instances
// while exploring Vuex-Orm

import { Model } from '@vuex-orm/core';

export default class CollectionAsset extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'collection_asset'

  // List of all fields (schema) of the CollectionAsset model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      email: this.attr(''),
    };
  }
}
