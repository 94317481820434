import { userFactory } from '@/lib/business/models/User';
import { roleFactory } from '@/lib/business/models/Role';

const UserWithRole = function userWithRole(user) {
  /**
   *
   * @type {User}
   */
  this.user = userFactory.create(user?.user);

  /**
   *
   * @type {Role}
   */
  this.role = roleFactory.create(user.role);
};

export default UserWithRole;
