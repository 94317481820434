import BasicInsight from '@/lib/search/discovery/classes/insights/basicInsight';
import Facet from '@/lib/search/discovery/classes/facet';

/**
 *
 * @param item{BaseVideoInsight}
 * @returns {boolean|*}
 */
const isListable = (item) => item.isListable;

class VideoTagInsight extends BasicInsight {
  throwAway = true;

  name = '';

  isVideo = true;

  constructor(name, key, values) {
    super(key, values);
    this.name = name;
    this.label = name;
  }

  get hasInsights() {
    const hasValues = this.values?.some(isListable);
    console.log('hasInsights', this.key, hasValues);

    return this.values?.some(isListable);
  }

  get isManual() {
    return !this.hasInsights;
  }

  set isManual(value) {
    this.throwAway = value;
  }

  createChartData() {
    const values = this.values
      .filter(isListable)
      .map(({ name, count }) => ({ name, count }));

    return Facet.fromKeyAndValue(this.key, values);
  }
}

export default VideoTagInsight;
