import BaseInsight from './baseInsight';

export default class ComplexInsight extends BaseInsight {
  valueKey = 'name';

  confidenceKey = 'confidence';

  constructor(key, values) {
    super(key, values);

    if (values && values.length > 0) {
      const row = values[0];
      const valueKeys = Object.keys(row);
      const valueKey = valueKeys.find((k) => k === 'name' || this.key.includes(k));

      this.valueKey = valueKey;

      /* let's assume that confidence value is confidence ot score */
      if (Object.keys(row).includes('score')) {
        this.confidenceKey = 'score';
      }
    }
  }

  get topValues() {
    const comparer = (a, b) => b[this.confidenceKey] - a[this.confidenceKey];
    return [...new Set(this.values.sort(comparer).map((value) => value[this.valueKey]))];
  }

  get basicValues() {
    return this.values.map((value) => value[this.valueKey]);
  }

  static getBasicValues(key, values) {
    if (values && values.length > 0) {
      const row = values[0];
      const valueKeys = Object.keys(row);
      const valueKey = valueKeys.find((k) => k === 'name' || key.includes(k));

      const basicValues = values.map((value) => value[valueKey]);
      return basicValues;
    }

    return [];
  }
}
