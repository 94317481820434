import AssetDataInsight from '@/lib/dashboards/models/AssetDataInsight';

const DashboardClient = function DashboardClient(httpClient) {
  this.httpClient = httpClient;
  this.endpoint = 'v2/dashboards';
};

const dashboardClientMethods = {
  /**
   *
   * @param searchParameters{SearchParameters}
   * @returns {Promise<null|AssetDataInsight>}
   */
  async getInsight(searchParameters) {
    const uri = `${this.endpoint}/${searchParameters.indexId}`;

    try {
      const { data } = await this.httpClient.post(uri, searchParameters);
      const insight = new AssetDataInsight(data, searchParameters.indexId);
      return insight;
    } catch (e) {
      console.error('Error Fetching insights', e);
    }

    return null;
  },
};

DashboardClient.prototype = dashboardClientMethods;

export default DashboardClient;
