class MetricItem {
  name = '';

  count = 0;

  constructor(name = '', count = 0) {
    this.name = name;
    this.count = count;
  }
}

export default MetricItem;
