import BaseVideoInsight from '@/lib/search/discovery/classes/insights/video/BaseVideoInsight';

class VideoSentiment extends BaseVideoInsight {
  sentimentKey = '';

  referenceUrl = '';

  seenDurationRatio = 0;

  constructor(data) {
    super(data);

    this.sentimentKey = data?.sentimentKey;
    this.name = this.sentimentKey;
    this.referenceUrl = data?.referenceUrl;
    this.seenDurationRatio = data?.seenDurationRatio;
  }
}

export default VideoSentiment;
