import VuexORM from '@vuex-orm/core';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import { httpClientFactory } from '@/api/api-factory';
import commonConfig from '@/config/common';
import CollectionAsset from '../models/CollectionAsset';
import Asset from '../models/Asset';

const axios = httpClientFactory('default');

VuexORM.use(VuexORMAxios, {
  axios,
  baseURL: commonConfig.discoveryEndPoint(),
});

// Create a new instance of Database.
const database = new VuexORM.Database();

// Register Models to Database.
database.register(CollectionAsset);
database.register(Asset);

export default database;
