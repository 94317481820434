import Vue from 'vue';
import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';
import database from '@/store/orm';
// import VuexORMGraphQL from '@vuex-orm/plugin-graphql';
import common from './common';
// import commonConfig from '../config/common';
import discovery from './discovery';
import uploader from './uploader';
import responseGenerator from './responseGenerator';
import adminStore from '../admin/store';
import documentAssetsStore from '../documentAssets/store';
import awardsStore from '../awards/store';
import assistant from '../openai/store';

// // import { graphClient } from '@/plugins/apollo';
// import CustomAdapter from './orm/custom-adapter';
//
// const options = {
//   defaultOptions: {
//     watchQuery: {
//       fetchPolicy: 'cache-and-network',
//     },
//   },
//   // connectionQueryMode: 'nodes',
//   database: database,
//   url: commonConfig.graphQlEndPoint(),
//   includeExtensions: false,
//   debug: true,
//   adapter: new CustomAdapter(), // ,
//   // apolloClient: graphClient
// };
//
// VuexORM.use(VuexORMGraphQL, options);

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    discovery,
    uploader,
    responseGenerator,
    admin: adminStore,
    assets: documentAssetsStore,
    awards: awardsStore,
    assistant,
  },
  plugins: [VuexORM.install(database)],
});
