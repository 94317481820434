import { discoveryThemeFactory } from '@/lib/themes/DiscoveryTheme';

/**
 *
 * @param httpClient {AxiosInstance}
 * @param endpoints {{save: string, fetch: string}}
 * @constructor
 */
const ThemeClient = function ThemeClient(httpClient, endpoints) {
  this.httpClient = httpClient;
  this.endpoints = endpoints;
};

ThemeClient.prototype.getTheme = async function getTheme(item) {
  const { save } = this.endpoints;
  const url = `${save}/${item.id}`;

  const { data } = await this.httpClient.get(url);
  const theme = discoveryThemeFactory(data);
  item.theme = theme;

  return item;
};

ThemeClient.prototype.fetchThemes = async function fetchThemes(list) {
  if (list != null && list.length > 0) {
    const ids = list.map((item) => item.id);
    const { fetch } = this.endpoints;
    const url = `${fetch}`;

    const { data } = await this.httpClient.post(url, ids);
    if (data != null) {
      list.forEach((item) => {
        if (item.id in data) {
          item.theme = discoveryThemeFactory(data[item.id]);
        }
      });
    }
  }
};

/**
 *
 * @param item
 * @param theme{DiscoveryTheme}
 * @returns {Promise<any>}
 */
ThemeClient.prototype.saveTheme = async function saveTheme(item, theme) {
  const { save } = this.endpoints;
  const url = `${save}/${item.id}`;
  const formData = theme.toFormData(item.id);

  const { data } = await this.httpClient.put(url, formData, {
    headers: {
      'Content-Type': 'multipart-form-data',
    },
  });
  console.log('Saved Theme');
  console.log(data);

  return discoveryThemeFactory(data);
};

export default ThemeClient;
