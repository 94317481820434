import { facetsListFactory } from './facet';
import { contentListFactory } from './content';

export default class DocumentResult {
  #facets = null;

  #facetsWithKeyCache = {};

  /**
   *
   * @type {[DocumentWithUrls]}
   */
  contents = [];

  constructor(documentResult) {
    this.documentResult = documentResult;
    this.#facetsWithKeyCache = {};

    this.rawFacets = (documentResult && documentResult.facets) || [];
    this.rawContents = (documentResult && documentResult.documents) || [];
    this.rawTags = (documentResult && documentResult.tags) || [];

    /*    this.contents = contentListFactory(
      this.token,
      this.idField,
      documentResult?.bindingField || 'doc_id',
    )(this.rawContents); */
  }

  get idField() {
    return this.documentResult && this.documentResult.idField;
  }

  get searchId() {
    return this.documentResult && this.documentResult.searchId;
  }

  /**
   *
   * @returns string
   */
  get token() {
    return this.documentResult && this.documentResult.token;
  }

  /**
   *
   * @returns number
   */
  get count() {
    return this.documentResult && this.documentResult.totalRecords;
  }

  /**
   * Array<Facet>
   * @returns {[Facet]}
   */
  get facets() {
    const facets = this.#facets
      || (this.#facets = facetsListFactory(this.rawFacets));
    return facets;
  }

  /**
   *
   * @returns Array<Facet>
   */
  get tags() {
    return facetsListFactory(this.rawTags);
  }

  /**
   *
   * @param facetKey
   * @returns {Facet}
   */
  facetsWithKey(facetKey) {
    if (this.#facetsWithKeyCache[facetKey]) {
      return this.#facetsWithKeyCache[facetKey];
    }

    console.log('%cgetting facets with key', 'color: orange', facetKey);
    const result = this.facets?.find((facet) => facet.key === facetKey);
    this.#facetsWithKeyCache[facetKey] = result;

    return result;
  }
}
