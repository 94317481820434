/**
 * Use this if you need to cache objects by identifier.
 * @param list
 * @param identifier
 * @constructor
 */
const ObjectRepo = function ObjectRepo(list, identifier) {
  this.identifier = identifier;

  this.dict = list && list.reduce((acc, item) => {
    const key = identifier(item);
    acc[key] = item;
    return acc;
  }, {});
};

ObjectRepo.prototype.isPresent = function isPresent(item) {
  const key = this.identifier(item);
  return key in this.dict;
};

ObjectRepo.prototype.add = function isPresent(item) {
  const key = this.identifier(item);
  this.dict[key] = item;
  return this;
};

ObjectRepo.prototype.remove = function isPresent(item) {
  const key = this.identifier(item);

  if (key in this.dict) {
    delete this.dict[key];
  }

  return this;
};

ObjectRepo.prototype.get = function get(key) {
  return this.dict[key] || null;
};

export default ObjectRepo;
