class PaginatedResult {
  itemsLoaded = false;

  totalRecords = 0;

  currentPage = 0;

  rows = [];

  constructor(totalRecords = 0, currentPage = 0, rows = []) {
    this.totalRecords = totalRecords;
    this.currentPage = currentPage;
    this.rows = rows;
  }

  getTotalPageCount(recordsPerPage) {
    const itemCount = this.totalRecords;
    const pageCount = (itemCount - (itemCount % recordsPerPage)) / recordsPerPage;
    if (itemCount % recordsPerPage === 0) {
      return pageCount;
    }
    return pageCount + 1;
  }

  reset() {
    this.currentPage = 0;
    this.totalRecords = 0;
    this.rows = [];
    this.itemsLoaded = false;
  }

  remove(finder) {
    if (finder != null) {
      this.rows = this.rows.filter((item) => !finder(item));
    }

    return this;
  }

  add(item) {
    this.rows = [item, ...this.rows];
  }

  get hasResult() {
    return this.totalRecords > 0;
  }
}

export default PaginatedResult;
