<template>
  <div id='google_translate_element'></div>
</template>

<script>
export default {
  name: 'LanguageSelector',
  data: () => ({
    googleTranslateScriptLoaded: false,
  }),
  computed: {

  },
  methods: {
    loadGoogleTranslateScript() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      document.head.appendChild(script);
      // this.googleTranslateElementInit();
      this.googleTranslateScriptLoaded = true;
    },
    // googleTranslateElementInit() {
    //   if (typeof google !== 'undefined' && google.translate && google.translate.TranslateElement) {
    //     const translator = new google.translate.TranslateElement({
    //       pageLanguage: 'en',
    //       layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
    //     }, 'google_translate_element');
    //   } else {
    //     console.error('Google Translate API is not fully loaded yet.');
    //   }
    // },
  },
  mounted() {
    if (!this.googleTranslateScriptLoaded) {
      this.loadGoogleTranslateScript();
    }
  },
};

function googleTranslateElementInit() {
  if (typeof google !== 'undefined' && google.translate && google.translate.TranslateElement) {
    const translator = new google.translate.TranslateElement({
      pageLanguage: 'en',
      layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
    }, 'google_translate_element');
  } else {
    console.error('Google Translate API is not fully loaded yet.');
  }
}

window.googleTranslateElementInit = googleTranslateElementInit; 
</script>

<style scoped>
/* Hide Google Translate bar */
/*.goog-te-banner-frame {
  display: none !important;
}*/

/* Hide Google Translate toolbar */
/*.goog-te-menu-frame.skiptranslate {
  display: none !important;
}*/

/* Style the dropdown menu */
.goog-te-combo {
  visibility: '';
}
</style>
