import moment from 'moment';

const hasExpired = (date, allowedDurationInMinutes) => {
  if (date == null) {
    return true;
  }
  const now = moment();
  const minutesElapsed = now.diff(moment(date), 'minutes');
  return allowedDurationInMinutes >= minutesElapsed;
};
class PageActions {
  /**
   *
   * @type {[PageElement]}
   */
  actions = [];

  lastUpdated = null;

  isLoading = false;

  actionsMap = {};

  constructor(actions = []) {
    this.actions = actions || [];
    this.actionsMap = this.actions.reduce((acc, action) => {
      acc[action.pageElementId] = true;
      return acc;
    }, {});
  }

  /**
   *
   * @param action{PageElement}
   * @returns {PageActions}
   */
  addAction(action) {
    if (!(action.pageElementId in this.actionsMap)) {
      this.actions.push(action);
    }

    this.setLastUpdated();
    return this;
  }

  setActions(actions) {
    actions.reduce((acc, value) => acc.addAction(value), this);
    this.isLoading = false;
    return this;
  }

  clear() {
    this.actions = [];
    this.setLastUpdated();
    return this;
  }

  setLastUpdated() {
    this.lastUpdated = new Date();
    return this;
  }

  needsData() {
    return (this.actions?.length === 0 || hasExpired(this.lastUpdated));
  }
}

export default PageActions;
