import AssistantConversation from '@/openai/lib/models/AssistantConversation';

export default {
  conversation: new AssistantConversation(),
  summaryConversation: new AssistantConversation(),
  shortSummaryConversation: new AssistantConversation(),
  caseStudyConversation: new AssistantConversation(),
  customCaseStudyConversation: new AssistantConversation(),
  extractThemesConversation: new AssistantConversation(),
  extractToneConversation: new AssistantConversation(),
  newsletterConversation: new AssistantConversation(),
  customNewsletterConversation: new AssistantConversation(),
  slidePresentationConversation: new AssistantConversation(),
  customPresentationSlideConversation: new AssistantConversation(),
  socialpostConversation: new AssistantConversation(),
  customSocialpostConversation: new AssistantConversation(),
  responseWriterConversation: new AssistantConversation(),
  imageAnalyzerConversation: new AssistantConversation(),
  previewAnalyzerConversation: new AssistantConversation(),
  imageAltTagConversation: new AssistantConversation(),
  imageCaptionConversation: new AssistantConversation(),
  insightsConversation: new AssistantConversation(),
  shareMessageConversation: new AssistantConversation(),
  documentAnalyzerConversation: new AssistantConversation(),
  fullSummaryLoaded: false,
  shortSummaryLoaded: false,
  conversationDocId: null,
  newsLetterConversationLoaded: false,
  slidePresentationConversationLoaded: false,
  socialPostConversationLoaded: false,
  themeConversationLoaded: false,
  toneConversationLoaded: false,
  caseStudyConversationLoaded: false,
  insightsConversationLoaded: false,
  assistantMessageLoading: false,
  shareMessageConversationLoaded: false,
  penalTriggeredFrom: '',
};
