import {
  listCountListFactory,
  listCountMapFactory,
} from '@/lib/business/models/lists/ListCount';

const CountListsResult = function CountListsResult(data) {
  this.deploymentIndexId = data?.deploymentIndexId || 0;
  this.counts = listCountListFactory(data?.counts);
  this.map = this.counts && listCountMapFactory(this.counts);
};

/**
 *
 * @param type{string}
 * @returns {*|ListCount}
 */
CountListsResult.prototype.countOf = function countOf(type) {
  return this.map[type.toLowerCase()];
};

export default CountListsResult;

export const countListResultFactory = (data) => new CountListsResult(data);
