import { asyncFunction } from '@/lib/util';
import VideoTranscript from '@/lib/search/discovery/classes/insights/video/VideoTranscript';

class VideoTranscriptList {
  /**
   *
   * @type {[VideoTranscript]}
   */
  transcripts = [];

  constructor(props) {
    const insight = props && props[0];
    console.log(insight);
    asyncFunction(() => {
      this.transcripts = insight?.insights?.transcript && insight?.insights?.transcript.map(
        (t) => new VideoTranscript(t),
      );
    });
  }
}

export default VideoTranscriptList;
