import { render, staticRenderFns } from "./MaintenanceMessage.vue?vue&type=template&id=7b4b150b&scoped=true"
import script from "./MaintenanceMessage.vue?vue&type=script&lang=js"
export * from "./MaintenanceMessage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b4b150b",
  null
  
)

export default component.exports