export const CustomizationPanelTypes = {
  PAGE_ELEMENTS: 'page_elements',
  CSS_THEMES: 'css_themes',
  VISUAL_ELEMENTS: 'visual_elements',
};

class CustomizationPanelManager {
  titles = {
    page_elements: 'Page Elements',
    css_themes: 'CSS Themes',
    visual_elements: 'Site Default Imagery',
  }

  panelTitle = '';

  showPageElements = false;

  showCssElements = false;

  showVisualElements = false;

  setPanelTitle(customizationType) {
    this.panelTitle = this.titles[customizationType];
  }

  setPanelType(panelType) {
    this.resetPanelTypes();
    switch (panelType) {
      case CustomizationPanelTypes.PAGE_ELEMENTS:
        this.showPageElements = true;
        break;
      case CustomizationPanelTypes.CSS_THEMES:
        this.showCssElements = true;
        break;
      case CustomizationPanelTypes.VISUAL_ELEMENTS:
        this.showVisualElements = true;
        break;
      default:
        this.showPageElements = true;
    }
  }

  resetPanelTypes() {
    this.showPageElements = false;
    this.showCssElements = false;
    this.showVisualElements = false;
  }
}

export default CustomizationPanelManager;
