const { VUE_APP_OKTA_CLIENT_ID, VUE_APP_OKTA_ISSUER, VUE_APP_OKTA_AUTH_TYPE } = process.env;

function getBaseURL() {
  const { protocol, hostname, port } = window.location;
  let URL = `${protocol}//${hostname}`;

  if (port) {
    URL = `${URL}:${port}`;
  }
  console.log('BASE URL', URL);
  return URL;
}

export default {
  clientId: () => VUE_APP_OKTA_CLIENT_ID,
  issuer: () => VUE_APP_OKTA_ISSUER,
  redirectUri: () => `${getBaseURL()}/implicit/callback`,
  authType: () => VUE_APP_OKTA_AUTH_TYPE || 'oauth',

  /**
   *
   * @param auth{Auth}
   * @returns {Promise<*>}
   */
  token(auth) {
    if (this.authType() === 'oauth') {
      return auth.getAccessToken();
    }

    return auth.getIdToken();
  },
};
