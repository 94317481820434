class FeaturedCollection {
  id = 0;

  name = '';

  thumbnail = '';

  collectionId = 0;

  constructor(id = 0, name = '', thumbnailUrl = '') {
    this.id = id;
    this.name = name;
    this.thumbnail = thumbnailUrl;
    this.collectionId = id;
  }

  get rawLocation() {
    return { name: 'CollectionDetails', params: { id: this.id } };
  }

  shareableObject() {
    return {
      name: this.name,
      type: 'Collection',
      location: this.rawLocation,
    };
  }

  /**
   *
   * @param id
   * @param name
   * @param thumbnail
   * @returns {FeaturedCollection}
   */
  static fromObject({ id = 0, name = '', thumbnail = '' }) {
    return new FeaturedCollection(id, name, thumbnail);
  }

  /**
   *
   * @param list
   * @returns {FeaturedCollection[]}
   */
  static fromList(list) {
    const factory = FeaturedCollection.fromObject;

    return (list || []).map(factory);
  }
}

export default FeaturedCollection;
