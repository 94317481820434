/* eslint-disable camelcase */
class UploaderBase {
  unique_uuid = '';

  file_name = '';

  submission_date = null;

  reference_url = '';

  storage_path = '';

  is_featured = '';

  is_confidential = '';

  title = '';

  // primary_author = '';

  author___contact = '';

  created = null;

  updated = null;

  region = '';

  country = '';

  city = '';

  archive_tier = '';

  business_result = '';

  engagement_type = '';

  estimated_value = '';

  status = '';

  brands = [];

  business_transformations = [];

  campaigns = [];

  capabilities = [];

  clients = [];

  organizations = [];

  partners = [];

  content_types = [];

  contributors = [];

  contributors___experts = [];

  industries = [];

  subindustries = [];

  organization_industries = [];

  organization_subindustries = [];

  keywords = [];

  target_markets = [];

  agency_terms = [];

  products = [];

  tags = [];

  topics = [];

  collections = [];

  confidential_users = [];

  created_by = '';

  updated_by = '';

  description = '';

  data = null;

  constructor(data) {
    this.data = data;
  }
}

export default UploaderBase;
