import { systemAlertService, SystemAlertTypes } from '@/lib/systemAlerts/SystemAlertsService';

export default {
  userName: (state) => state.activeUser && state.activeUser.name,
  userEmail: (state) => state.activeUser && state.activeUser.email,
  isAuthenticated: (state) => state.activeUser !== null,
  userFirstName: (state) => state.activeUser && state.activeUser.given_name,
  hasPicture: (state) => state.activeUser && state.activeUser.profile,
  avatar: (state) => state.activeUser && (state.activeUser.profile || state.activeUser.name),
  rightPanel: (state) => state.rightPanel,
  deployments: (state) => state.deployments,
  deploymentindexes: (state) => state.userDeployments?.currentDeployment?.deploymentIndexes || [],
  selectedDeployment: (state) => state.userDeployments?.currentDeployment,
  selectedDeploymentIndex: (state) => state.userDeployments?.currentDeployment?.currentIndex,
  unreadNotifications: (state) => state.discoveryNotifier.unreadCount,
  selectedDeploymentIndexPreviewable: (state) => state
    .userDeployments?.currentDeployment?.currentIndex?.isPreviewable,
  hasBusyError: (state) => {
    const hasError = state.busyErrors.length > 0;
    console.log('getter hasError');
    console.log(hasError);
    return hasError;
  },
  indexColor: (state) => state.currentDeployment?.primaryColor,
  isSearchInitiated: (state) => state.searchLog.source !== '',
  myIndices: (state) => state.userDeployments?.allIndexes() || [],
  isMaintenanceAlertAcknowledged: () => systemAlertService.getIsSystemAlertAcknowledged(SystemAlertTypes.MAINTENANCE),
  isOpenAiAcknowledged: () => systemAlertService.getIsSystemAlertAcknowledged(SystemAlertTypes.OPEN_AI),
};
