import moment from 'moment/moment';

/**
 *
 * @param title{String}
 * @param message{String}
 * @param subject{*}
 * @constructor
 */
const DiscoveryNotification = function DiscoveryNotification(title, message, subject) {
  this.title = title;
  this.message = message;
  this.read = false;
  this.date = moment.now();
  this.subject = subject;
};

DiscoveryNotification.prototype.markAsRead = function markAsRead() {
  this.read = true;
};

DiscoveryNotification.prototype.getSharable = function getSharable() {
  if (this.subject == null || typeof this.subject.shareableObject !== 'function') {
    return null;
  }

  return this.subject.shareableObject();
};

export default DiscoveryNotification;
