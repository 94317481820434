export default class SearchLogInput {
  source = '';

  page = '';

  constructor(location = '', page = '') {
    this.source = location;
    this.page = page;
  }
}

export const SearchLocations = {
  FACET: 'facetPanel',
  HOME: 'homePage',
  GLOBAL: 'global',
  URL: 'url',
};
