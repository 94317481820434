import FilterField from './FilterField';

class FilterGroup {
  groupName = '';

  /**
   *
   * @type {[FilterField]}
   */
  filterFields = [];

  constructor(data) {
    this.groupName = data.groupName;
    this.buildFilterFields(data?.filterFields || []);
  }

  get allFilterValues() {
    return this.filterFields?.flatMap((filter) => filter.filterFieldValues);
  }

  buildFilterFields(fields) {
    this.filterFields = FilterField.fromList(fields);
  }

  static fromObject(data) {
    return new FilterGroup(data);
  }

  static fromList(list) {
    return list?.map(FilterGroup.fromObject) || [];
  }
}

export default FilterGroup;
