import { contentListFactory } from '@/lib/search/discovery/classes/content';

class DiscoveryQuestion {
  question = '';

  /**
   *
   * @type {[Content]}
   */
  answers = [];

  /**
   *
   * @type {string}
   */
  token = '';

  constructor(data) {
    this.question = data?.qa?.question;
    this.token = data?.token;

    this.answers = contentListFactory(this.token, 'doc_id')(data?.results);
  }
}

export default DiscoveryQuestion;
