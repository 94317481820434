import Metrics from '@/lib/charts/models/Metrics';
import SearchMetricItem from './SearchMetricItem';

const SearchMetrics = class SearchMetrics extends Metrics {
  key = '';

  constructor(key, name, values) {
    super(name, values);
    this.key = key;
  }
  
  static fromList(key, name, rawValues) {
    if (rawValues) {
      const values = rawValues.map(SearchMetricItem.fromObject);
  
      return new SearchMetrics(key, name, values);
    }
    return null;
  }
};

export default SearchMetrics;
