class Leagues {
  leagueName = '';

  startIndex = 0;

  endIndex = 0;

  constructor(data) {
    this.leagueName = data.leagueName;
    this.startIndex = data.startIndex;
    this.endIndex = data.endIndex;
  }

  /**
   *
   * @param that{ {leagueName: string, startIndex: number, endIndex: number} }
   * @returns {boolean}
   */
  equals(that) {
    return (this.leagueName === that.leagueName
      && this.startIndex === that.startIndex && this.endIndex === that.endIndex);
  }

  /**
   *
   * @param data{ {leagueName: string, startIndex: number, endIndex: number} }
   * @returns {Leagues}
   */
  static fromObject(data) {
    return new Leagues(data);
  }

  /**
   *
   * @param list{[{leagueName: string, startIndex: number, endIndex: number}]}
   * @returns {Leagues[]}
   */
  static fromList(list = []) {
    return list.map(Leagues.fromObject);
  }

  /**
   * finds the League matching the input
   * @param list{Leagues[]}
   * @param item{ {leagueName: string, startIndex: number, endIndex: number} }
   *
   * @returns {Leagues|undefined}
   */
  static find(list, item) {
    return list.find((listItem) => listItem.equals(item));
  }
}

export default Leagues;
