import ThemeClient from '@/lib/themes/ThemeClient';
import IndexDescription from '@/lib/deployments/models/IndexDescription';
import AppObjectSetting from '@/lib/deployments/models/AppObjectSetting';
import { indexFieldDescriptionFactory } from '@/lib/deployments/models/IndexFieldDescription';
import PermissionsChecker from '@/lib/acl/PermissionsChecker';
import CachingService from '@/lib/util/caching/CachingService';

import { Observable } from 'rxjs';

const buildKey = (indexId) => `index_permissions_${indexId}`;

const DeploymentIndexClient = function DeploymentIndexClient(httpClient) {
  this.httpClient = httpClient;
  this.endpoint = 'v2/dashboards';

  this.cache = new CachingService();

  this.themeClient = new ThemeClient(httpClient, {
    save: 'v2/dashboards/theme',
    fetch: 'v2/dashboards/themes',
  });
};

/**
 *
 * @param index{DeploymentIndex}
 * @returns {Promise<void>}
 */
DeploymentIndexClient.prototype.saveIndex = async function saveIndex(index) {
  const copy = { ...index };
  const { theme } = index;

  delete copy.theme;
  delete theme.heroImage;
  delete theme.thumbnailImage;

  await this.updateIndex(copy);

  const themeResult = await this.themeClient.saveTheme(index, theme);
  return themeResult;
};

/**
 * Updates Deployment Index
 * @param index{DeploymentIndex}
 * @returns {Promise<DeploymentIndex>}
 */
DeploymentIndexClient.prototype.updateIndex = async function updateIndex(index) {
  const { data } = await this.httpClient.put(`${this.endpoint}/${index.id}`, index);
  return data;
};

/**
 *
 * @param index{DeploymentIndex}
 * @returns {Promise<IndexDescription>}
 */
DeploymentIndexClient.prototype.describe = async function describe(index) {
  const url = `v2/search/describe/${index.id}`;
  const { data } = await this.httpClient.get(url, {
    params: { customize: true },
  });

  const indexDescription = IndexDescription.fromObject(data, indexFieldDescriptionFactory);
  return indexDescription;
};
/**
 *
 * @param index
 * @returns {Promise<*>}
 */
DeploymentIndexClient.prototype.getTheme = async function getTheme(index) {
  await this.themeClient.getTheme(index);
  return index;
};

DeploymentIndexClient.prototype.getSettings = async function getSettings(deploymentIndex) {
  const url = `v2/deploymentIndexes/${deploymentIndex.deploymentIndexId}/customization`;
  const { data } = await this.httpClient.get(url);
  const object = AppObjectSetting.fromObject(data);
  deploymentIndex.setCustomizationSettings(object);
};

DeploymentIndexClient.prototype.getPermissions = async function getPermissions(indexId, codes) {
  const key = buildKey(indexId);
  const cachedItems = await this.cache.get(key);

  if (cachedItems != null) {
    return new PermissionsChecker(cachedItems || {});
  }

  const url = `v2/deploymentIndexes/${indexId}/checkPermissions`;
  const { data } = await this.httpClient.post(url, codes);
  const checker = new PermissionsChecker(data.data || {});
  await this.cache.set(key, data.data, 5 * 24 * 60 * 1000);
  return checker;
};

DeploymentIndexClient.prototype.checkPermissionsObserver = function checkPermissions(
  index,
  codes,
) {
  const observable = new Observable(async (observer) => {
    try {
      const checker = await this.getPermissions(index.deploymentIndexId, codes);
      console.log('Cache: got permissions', checker);
      observer.next(checker);
    } catch (e) {
      console.error('Error while Checking permissions', e);
      observer.next(new PermissionsChecker());
    }
  });

  return observable;
};

DeploymentIndexClient.prototype.getContainerSasToken = async function getContainerSasToken(deploymentIndexId, containerName, blobName) {
  const params = { containerName, blobName };
  const url = `v2/deploymentIndexes/${deploymentIndexId}/getSasToken`;
  const { data } = await this.httpClient.get(url, { params });
  return data;
};

DeploymentIndexClient.prototype.getStorageAccountInfo = async function getStorageAccountInfo(deploymentIndexId, blobName) {
  const params = { blobName };
  const url = `v2/deploymentIndexes/${deploymentIndexId}/getStorageAccountInfo`;
  const { data } = await this.httpClient.get(url, { params });
  return data;
};

export default DeploymentIndexClient;
