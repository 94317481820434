import UserDeployments from '@/lib/deployments/models/UserDeployments';

/**
 *
 * @param httpClient {AxiosInstance}
 * @constructor
 */
const DeploymentsClient = function DeploymentsClient(httpClient) {
  this.httpClient = httpClient;
  this.endpoint = 'v2/deployments';
};

/**
 *
 * @returns {Promise<UserDeployments>}
 */
DeploymentsClient.prototype.deployments = async function deployments() {
  const { data } = await this.httpClient.get(this.endpoint);
  return new UserDeployments(data);
};

/**
 *
 * @param deploymentIndex{DeploymentIndex}
 * @returns {Promise<void>}
 */
DeploymentsClient.prototype.setDefaultIndex = async function setDefaultIndex(index) {
  const uri = `${this.endpoint}/setDefaultIndex/${index.deploymentIndexId}`;
  await this.httpClient.put(uri);
};

/**
 *
 * @param deploymentIndex{DeploymentIndex}
 * @returns {Promise<void>}
 */
DeploymentsClient.prototype.duplicateDeploymentIndex = async function duplicateDeploymentIndex(data) {
  console.log(' in duplicateDeploymentIndexeeeeee');
  console.log(data);
  const uri = `${this.endpoint}/duplicateIndex`;
  await this.httpClient.post(uri, data);
};

/**
 *
 * @param generateAssetMetadataSets{DeploymentIndex}
 * @returns {Promise<void>}
 */
DeploymentsClient.prototype.generateAssetMetadataSets = async function generateAssetMetadataSets(data) {
  console.log(' in generateAssetMetadataSets');
  console.log(data);
  const uri = `${this.endpoint}/generateAssetMetadataSets`;
  await this.httpClient.post(uri, data);
};

export default DeploymentsClient;
