/**
 *
 * @param discoveryDocument {Content}
 */
import documentTitle, { fileExtension } from '@/lib/search/discovery/helpers';

const convertToSharepointDocument = (discoveryDocument) => {
  Object.defineProperties(discoveryDocument, {
    filename: {
      get() {
        return this.document
          && this.document.metadata_spo_item_name;
      },
    },
    title: {
      get() {
        return this.filename && documentTitle(this.filename);
      },
    },
    fileType: {
      get() {
        const ft = fileExtension(this.filename);
        return ft;
      },
    },
    URL: {
      get() {
        return this.document
          && this.document.metadata_spo_item_weburi;
      },
    },
    hasIndexData: {
      get() {
        return this.document
          && this.document.metadata_spo_site_library_item_id !== null;
      },
    },
    decodedPath: {
      get() {
        if (this.hasIndexData) {
          return this.URL;
        }
        return this.document?.decodedPath;
      },
    },
  });
};

export default convertToSharepointDocument;
