import {
  CollectionFormInput,
} from '@/lib/business/models';
import { listCollectionListFactory } from '@/lib/business/models/ListCollection';
import FeaturedCollectionList from '@/lib/business/models/FeaturedCollectionList';
import ThemeClient from '@/lib/themes/ThemeClient';
import Collection, { collectionFactory } from '@/lib/business/models/Collection';
import { discoveryThemeFactory } from '@/lib/themes/DiscoveryTheme';
import { countListResultFactory } from '@/lib/business/models/lists/CountListsResult';
import CollectionAsset from '@/lib/business/models/CollectionAsset';
import PaginatedResult from '@/lib/business/models/lists/PaginatedResult';
import SearchClient from '@/lib/search/SearchClient';
import CustomizationsClient from '@/lib/customizations/CustomizationsClient';
import FeaturedCollection from '@/lib/business/models/collections/FeaturedCollection';
import AppObjectInsightsClient from '../insights/AppObjectInsightsClient';

class DiscoveryBusinessClient {
  /**
   *
   * @type {AxiosInstance}
   */
  httpClient = null;

  /**
   *
   * @type {SearchClient}
   */
  searchClient = null;

  constructor(httpClient) {
    this.httpClient = httpClient;
    this.endpoint = 'v2/collections';

    this.themeClient = new ThemeClient(httpClient, {
      save: `${this.endpoint}/theme`,
      fetch: `${this.endpoint}/themes`,
    });

    this.searchClient = new SearchClient(httpClient);
    this.customizationsClient = new CustomizationsClient(httpClient);
    this.appObjectInsightsClient = new AppObjectInsightsClient(httpClient);
  }

  async myCollections(type, deploymentIndexId, rowsPerPage = 30, currentPage = 0) {
    const params = {
      type,
      deploymentIndexId,
      rowsPerPage,
      currentPage,
    };
    const { data } = await this.httpClient.get(this.endpoint, { params });
    const collections = listCollectionListFactory(data.rows);
    setTimeout(() => this.fetchAppObjectSettings(collections));
    // setTimeout(() => this.fetchCollectionsViews(collections));
    data.rows = collections;
    return data;
  }

  async getCollection(id) {
    const { data } = await this.httpClient.get(`${this.endpoint}/get/${id}`);
    const collection = collectionFactory(data);
    setTimeout(() => this.fetchAppObjectSetting(collection));
    return collection;
  }

  async getCollectionDetailsForEdit(collection) {
    const { data } = await this.httpClient.get(`${this.endpoint}/get/${collection.id}`);

    collection.update(data);
    return collection;
  }

  async saveCollection(collection, deploymentIndexId) {
    const copy = { ...collection };
    const { theme } = copy;

    delete copy.theme;
    delete theme.heroImage;
    delete theme.thumbnailImage;
    let savedCollection = null;

    if (collection.id && collection.id > 0) {
      savedCollection = await this.updateCollection(copy, deploymentIndexId);
    } else {
      savedCollection = await this.createCollection(copy, deploymentIndexId);
    }

    const saveCollectionResult = collectionFactory(savedCollection);
    setTimeout(() => this.saveTheme(saveCollectionResult, collection.theme));
    return saveCollectionResult;
  }

  async updateCollection(collection, deploymentIndexId) {
    const { data } = await this.httpClient
      .put(`${this.endpoint}/save/${collection.collectionId}`, {
        collection,
        deploymentIndexId,
      });

    return data;
  }

  async createCollection(collection, deploymentIndexId) {
    const { data } = await this.httpClient
      .post(`${this.endpoint}`, {
        collection,
        deploymentIndexId,
      });

    return data;
  }

  /**
   *
   * @param collection{ListCollection|Collection}
   * @param theme{DiscoveryTheme}
   * @returns {Promise<void>}
   */
  async saveTheme(collection, theme) {
    const savedTheme = await this.themeClient.saveTheme(collection, theme);
    collection.theme = savedTheme;
  }

  async getCollectionFormInputData(deploymentIndexId) {
    console.log(`getting form for index${deploymentIndexId}`);
    const { data } = await this.httpClient.get(`v2/collectionForm/input/${deploymentIndexId}`);
    return new CollectionFormInput(data);
  }

  /**
   *
   * @param collection{ListCollection|Collection}
   * @returns {Promise<void>}
   */
  async updatePinForCollection(collection) {
    const action = collection.pinned ? 'pin' : 'unpin';
    const url = `${this.endpoint}/${action}/${collection.collectionId}`;

    const { data } = await this.httpClient.post(url);
    return data;
  }

  async deleteCollection(collection) {
    const url = `${this.endpoint}/${collection.collectionId}`;
    const { data } = await this.httpClient.delete(url);
    return data;
  }

  /**
   *
   * @param collection{ListCollection|Collection}
   * @returns {Promise<void>}
   */
  async updateStarForCollection(collection) {
    const action = collection.starred ? 'star' : 'unstar';
    const url = `${this.endpoint}/${action}/${collection.collectionId}`;

    const { data } = await this.httpClient.post(url);
    return data;
  }

  async featuredCollections(deploymentIndexId, imageType = 'featuredCollectionImage') {
    const url = `${this.endpoint}/featured/${deploymentIndexId}`;
    const { data } = await this.httpClient.get(url);

    const collections = FeaturedCollection.fromList(data);
    const featuredCollections = new FeaturedCollectionList(collections);

    setTimeout(() => this.fetchThumbnails(collections, imageType));
    // setTimeout(() => this.fetchCollectionsViews(collections));
    return featuredCollections;
  }

  async markAsFeaturedCollection(collection, deploymentIndexId) {
    const collectionId = collection.id;
    const url = `${this.endpoint}/markAsFeatured/${collectionId}/${deploymentIndexId}`;
    const { data } = await this.httpClient.post(url);

    return data;
  }

  async removeFromFeaturedCollection(collection, deploymentIndexId) {
    const collectionId = collection.id;
    const url = `${this.endpoint}/removeAsFeatured/${collectionId}/${deploymentIndexId}`;
    const { data } = await this.httpClient.post(url);

    return data;
  }

  async getCollectionAssets(collectionId, rowsPerPage = 30, page = 0, pinned = false) {
    const url = `v2/collections/${collectionId}/assets`;
    const params = { rowsPerPage, page, pinned };
    try {
      const { data } = await this.httpClient.get(url, { params });
      const assets = CollectionAsset.createList(data?.rows || []);
      const result = new PaginatedResult(data?.count, data?.currentPage);
      result.rows = assets;
      return result;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async searchCollectionAssets(collectionId, searchParams) {
    const url = `v2/collections/${collectionId}/assets/search`;
    try {
      const searchResult = await this.searchClient
        .setEndPoint(url)
        .search(searchParams);

      return searchResult;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * Fetches the count of collections falling under certain criteria
   * @param deploymentIndexId
   * @returns {Promise<CountListsResult>}
   */
  async countCollections(deploymentIndexId) {
    const url = `${this.endpoint}/count`;
    const params = {
      deploymentIndexId,
    };

    const { data } = await this.httpClient.get(url, { params });
    return countListResultFactory(data);
  }

  /**
   *
   * @param collections [ListCollection|Collection]
   * @returns {Promise<void>}
   */
  fetchThemes(collections) {
    return this.themeClient.fetchThemes(collections);
  }

  fetchAppObjectSettings(collections) {
    return this.customizationsClient.getCustomizationsList(collections);
  }

  fetchAppObjectSetting(collection) {
    return this.customizationsClient.getCustomizations(collection);
  }

  fetchCollectionsViews(collections) {
    this.appObjectInsightsClient.getEntityViews(collections, 'collection');
  }

  /**
   *
   * @param collection{Collection}
   * @param assetId
   * @returns {Promise<{Collection}>}
   */
  async removeAsset(collection, assetId) {
    const url = `${this.endpoint}/removeAsset/${collection.id}/${assetId}`;
    const { data } = await this.httpClient.put(url);

    if (data) {
      collection.collectionAssets = collection.collectionAssets?.filter(
        (collectionAsset) => collectionAsset.asset.assetId !== assetId,
      ) || [];
    }
    return collection;
  }

  fetchThumbnails(collections, imageType = 'featuredCollectionImage') {
    setTimeout(async () => {
      const settingsDict = await this.customizationsClient.getPropertySetting(collections, imageType);
      collections.forEach((collection) => {
        const url = (settingsDict[collection.id] || { value: '' }).value;
        collection.thumbnail = url;
      });
    });
  }
}

DiscoveryBusinessClient.prototype.newCollection = function newCollection() {
  return new Collection({
    name: '',
    description: '',
    theme: discoveryThemeFactory(),
    isConfidential: true,
  });
};

export default DiscoveryBusinessClient;
