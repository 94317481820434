import { from, map } from 'rxjs';
import AwardsResult from '@/awards/models/AwardsResult';
import AwardsData from '@/awards/models/AwardsData';
import LeaderboardResult from '@/awards/models/leaderboards/LeaderboardResult';
import Leaderboard from '@/awards/models/leaderboards/Leaderboard';

class AwardsClient {
  /**
   *
   * @type {AxiosInstance}
   */
  http = null;

  /**
   *
   * @param httpClient{AxiosInstance}
   */
  constructor(httpClient) {
    this.http = httpClient;
  }

  /**
   *
   * @param indexId
   * @returns {Observable<AwardsResult>}
   */
  getAwardsDataObservable(indexId) {
    const url = 'getAwardsData';
    const params = {
      deploymentIndexId: indexId,
    };

    const awardsObservable = from(this.http.get(url, { params }))
      .pipe(map(({ data }) => new AwardsResult(data)));

    return awardsObservable;
  }

  /**
   *
   * @param indexId{int}
   * @param filterForm{FilterForm}
   * @returns {Observable<Observable<RegionTally>[]>}
   */
  getFilteredData(indexId, filterForm) {
    const url = 'getFilteredAwardsData';
    const postData = {
      currentFilterValues: filterForm.selectedFilterValues,
    };

    const params = {
      deploymentIndexId: indexId,
    };

    const observables = from(this.http.post(url, postData, { params }))
      .pipe(map(({ data }) => new AwardsData(data.awardsData, data.resultTotals)));

    return observables;
  }

  /**
   *
   * @param indexId
   * @returns {Observable<LeaderboardResult>}
   */
  getLeaderboardObservable(indexId) {
    const url = 'getAwardsLeaderboard';
    const params = {
      deploymentIndexId: indexId,
    };

    const awardsObservable = from(this.http.get(url, { params }))
      .pipe(map(({ data }) => new LeaderboardResult(data)));

    return awardsObservable;
  }

  /**
   *
   * @param indexId{number}
   * @param filterForm{filterForm}
   * @param league{Leagues}
   * @returns {Observable<Leaderboard>}
   */
  getFilteredLeaderboard(indexId, filterForm, league) {
    const url = 'getFilteredAwardsLeaderboard';

    const postData = {
      currentFilterValues: filterForm.selectedFilterValues,
      selectedLeaderboardLeague: league,
    };

    const params = {
      deploymentIndexId: indexId,
    };

    const observables = from(this.http.post(url, postData, { params }))
      .pipe(map(({ data }) => Leaderboard.build(data.leaderboardData)));

    return observables;
  }
}

export default AwardsClient;
