import DocumentAssetsMutationTypes from '@/documentAssets/store/DocumentAssetsMutationTypes';

export default {
  [DocumentAssetsMutationTypes.setLatestUploads](state, data) {
    state.latestUploadsConnection = data;
  },
  [DocumentAssetsMutationTypes.setNewCases](state, data) {
    state.newCasesConnection = data;
  },
  setStreams(state, streams) {
    state.assetStreams = streams;
  },
};
