import { mix } from 'mixwith';
import DeploymentIndexUserConnection from '@/admin/users/models/DeploymentIndexUserConnection';
import DeploymentUserConnection from '@/admin/users/models/DeploymentUserConnection';
import WithAuthMixin from '@/admin/users/models/mixins/WithAuthMixin';

/**
 *
 * @param user{StarfishUser}
 * @param userObject
 * @returns {StarfishUser}
 */
export const updateUser = (user, userObject) => {
  const {
    id,
    givenName,
    lastName,
    email,
    deploymentIndexUsers = null,
    deploymentUsers = {},
    auth = null,
  } = userObject;

  if (id) {
    user.id = id;
  }
  user.givenName = givenName;
  user.lastName = lastName;
  user.email = email;

  if (deploymentIndexUsers) {
    user.deploymentIndexUsers = new DeploymentIndexUserConnection(
      deploymentIndexUsers,
    );

    setTimeout(() => {
      (user.deploymentIndexUsers.items || []).forEach(({ deploymentIndex }) => {
        console.log('setting assigned indices', deploymentIndex);
        user.assignedIndices.push(deploymentIndex.id);
      });
    });
  }

  user.deploymentUsers = deploymentUsers && new DeploymentUserConnection(
    deploymentUsers,
  );
  if (auth) {
    user.auth = auth;
  }

  return user;
};

class StarfishUser extends mix(Object).with(WithAuthMixin) {
  id = '';

  userId = 0;

  givenName = '';

  lastName = '';

  email = '';

  deploymentIndexUsers = null;

  deploymentUsers = null;

  auth = null;

  assignedIndices = [];

  constructor(userObject) {
    super();
    updateUser(this, userObject);
  }

  get fullName() {
    return `${this.givenName} ${this.lastName}`;
  }
}

export default StarfishUser;
