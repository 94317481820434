<template>
    <v-alert
      v-model="alert"
      class="maintenance_alert"
      close-text="Close Alert"
      color="primarybutton"
      dark
      dismissible
      style=""
    >
    <maintenance-message />
    </v-alert>
</template>

<script>
import MaintenanceMessage from '@/components/maintenance/MaintenanceMessage.vue';

export default {
  name: 'MaintenanceAlert',
  components: { MaintenanceMessage },
  props: {
    value: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    alert: {
      get() {
        console.log('MaintenanceAlert', this.value);
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss">
.temp_toggle_button {
  position:absolute;
  left: 50%;
  top: 30px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index:10;
}

.maintenance_alert {
  position: fixed;
  top: 60px;
  margin: 0 auto;
  z-index:10
}

@media screen and (min-width: 600px) {
  .maintenance_alert {
    left: 50%;
    transform: translate(-50%, -50%);
    top: 120px;
  }
}
</style>
