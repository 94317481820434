import DeploymentIndexConnection from '../../deploymentIndices/models/DeploymentIndexConnection';

/**
 * Updates current deployment object with the values in data
 * @param deployment{Deployment}
 * @param data{deploymentId, name, deploymentIndices}
 */
export const updateDeployment = (deployment, data = {
  id: null,
  deploymentId: 0,
  name: null,
  deploymentIndices: null,
}) => {
  const {
    id,
    deploymentId,
    name,
    deploymentIndices,
  } = data;

  if (name) deployment.name = name;

  if (deploymentId) {
    deployment.deploymentId = deploymentId;
  }

  if (deploymentIndices) {
    deployment.deploymentIndexConnection = new DeploymentIndexConnection(deploymentIndices);
  }

  if (id) {
    deployment.id = id;
  }
};

class Deployment {
  id = null;

  deploymentId = 0;

  name = '';

  deploymentIndexConnection = new DeploymentIndexConnection();

  constructor(data = {
    id: null,
    deploymentId: 0,
    name: '',
    deploymentIndices: {},
  }) {
    updateDeployment(this, data);
  }
}

export default Deployment;
