import moment from 'moment';
import filesize from 'file-size';
import he from 'he';
import { initials } from '@/lib/util';

const shortenText = (text, length) => text && `${text.split(' ').slice(0, length).join(' ')} ...`;
const capitalize = ([f, ...r]) => f.toUpperCase() + r.join('');
export const humanize = (s) => s.split('_').map(capitalize).join(' ');
const stripPrefix = (s) => s.replace(/^metadata_storage_/, '');
const roundedNumber = (n, places) => n && n.toFixed(places || 1);
const formatDate = (date, format) => date && moment(date).format(format || 'MMM DD, YYYY');
const fileSize = (size) => size && filesize(size).human('jedec');
const ago = (date) => date && moment(date).fromNow();
const titleCase = (input) => input.toLowerCase().replace(/\b\w/g, (i) => i.toUpperCase());
export const decodeHtml = (content) => he.decode(content);

const filters = {
  install: (Vue) => {
    Vue.filter('capitalize', capitalize);
    Vue.filter('humanize', humanize);
    Vue.filter('stripPrefix', stripPrefix);
    Vue.filter('roundNumber', roundedNumber);
    Vue.filter('formatDate', formatDate);
    Vue.filter('initials', initials);
    Vue.filter('fileSize', fileSize);
    Vue.filter('ago', ago);
    Vue.filter('decodeHtml', decodeHtml);
    Vue.filter('titleCase', titleCase);
  },
};

export default filters;
export const shorten = shortenText;
