import InteractionInsight from '@/lib/insights/models/InteractionInsight';
import FetchAssetDetailsChoices from '@/lib/business/models/FetchAssetDetailsChoices';

class AssetDisplayDetailsFetcher {
  /**
   *
   * @type {AxiosInstance}
   */
  http = null;

  endpoint = 'v2/assets/listDetails';

  /**
   *
   * @param http {AxiosInstance}
   */
  constructor(http) {
    this.http = http;
  }

  /**
   *
   * @param documents {DocumentWithUrls[]}
   * @param choices {FetchAssetDetailsChoices}
   * @returns {Promise<void>}
   */
  async fetchAssetDetails(documents, choices) {
    const docIds = documents.map((doc) => doc.docId);
    choices.docIds = docIds;

    const { data } = await this.http.post(this.endpoint, choices);

    documents.forEach((document) => {
      const detail = data[document.docId];

      if (detail && choices.fetchVisibility) {
        document.asset.isAccessible = detail.isAccessible;
        document.isLoading = true;
      }

      if (detail && choices.fetchThumbnails) {
        document.previewUrl = detail.thumbnailUrl;
        document.asset.thumbnailUrl = detail.thumbnailUrl;
      }

      if (detail && choices.fetchInteractionMetrics) {
        const insight = InteractionInsight.fromObject(detail.interactionInsight);
        document.asset.interactionInsight = insight;
      }
    });
  }

  fetchThumbnails(documents, thumbnailType = 'default') {
    const choices = new FetchAssetDetailsChoices(true, thumbnailType);
    this.fetchAssetDetails(documents, choices);
  }

  async fetchVisibility(documents) {
    documents.forEach((doc) => {
      doc.isLoading = true;
    });
    const choices = new FetchAssetDetailsChoices();
    choices.fetchVisibility = true;

    this.fetchAssetDetails(documents, choices);
  }

  fetchInteraction(documents) {
    const choices = new FetchAssetDetailsChoices();
    choices.fetchInteractionMetrics = true;

    this.fetchAssetDetails(documents, choices);
  }

  fetchEssentials(documents, choices) {
    if (choices.fetchVisibility) {
      this.fetchVisibility(documents);
    }

    /* if (choices.fetchThumbnails) {
      this.fetchThumbnails(documents, choices.thumbnailType);
    } */
  }

  async fetchDetailsSequentially(documents, choices) {
    await this.fetchEssentials(documents, choices);

    if (choices.fetchInteractionMetrics) {
      this.fetchInteraction(documents);
    }
  }
}

export default AssetDisplayDetailsFetcher;
