const defaultEditableFeatures = {
  hasDetails: {
    get() {
      return true;
    },
  },
  hasTheme: {
    get() {
      return true;
    },
  },
  hasHero: {
    get() {
      return true;
    },
  },
  hasThumbnail: {
    get() {
      return true;
    },
  },
  hasMembers: {
    get() {
      return true;
    },
  },
  editableName: {
    get() {
      return this.name;
    },
    set(value) {
      this.name = value;
    },
  },
  editableDescription: {
    get() {
      return this.description;
    },
    set(value) {
      this.description = value;
    },
  },
  editableNameLabel: {
    get() {
      return 'Name';
    },
  },
  editableDescriptionLabel: {
    get() {
      return 'Description';
    },
  },
  hasName: {
    get() {
      return true;
    },
  },
  hasDescription: {
    get() {
      return true;
    },
  },
  isConfidentialLabel: {
    get() {
      return 'Visibility';
    },
  },
  hasConfidentialProperty: {
    get() {
      return false;
    },
  },
  isConfidentialValue: {
    get() {
      return this.isConfidential;
    },
    set(value) {
      this.isConfidential = value;
    },
  },
  hasIsSmartValue() {
    return true;
  },
  isSmartValue: {
    get() {
      if (typeof this.isSmart === 'undefined') {
        return false;
      }
      return this.isSmart;
    },
    set(value) {
      this.isSmart = value;
    },
  },
};

const setEditableFeaturesProperty = (object, properties) => {
  Object.defineProperties(object, { ...defaultEditableFeatures, ...properties });
};

export default setEditableFeaturesProperty;

export const EditableTypes = {
  COLLECTION: 'collection',
  GROUP: 'group',
  DEPLOYMENT_INDEX: 'deploymentIndex',
};
