import { mapActions } from 'vuex';
import shareMixin from '@/lib/ui/mixins/shareMixin';
import searchMixin from '@/components/search/mixins/searchMixin';

export default {
  mixins: [
    shareMixin,
    searchMixin,
  ],
  data: () => ({
    previewContent: null,
    openModal: false,
    openInsightsModal: false,
  }),
  computed: {
    openPreview: {
      get() {
        return this.openModal;
      },
      set(value) {
        this.openModal = value;
        if (!value) {
          this.previewContent = null;
        }
      },
    },
    showInsight: {
      get() {
        return this.openInsightsModal;
      },
      set(value) {
        this.openInsightsModal = value;
      },
    },
  },
  methods: {
    ...mapActions('discovery', [
      'getDocument',
      'download',
      'openPreviewModalWindow',
      'openContentInsightModalWindow',
    ]),
    ...mapActions('uploader', [
      'openEditMetadata',
      'openAddToCollection',
      'openUploader',
    ]),
    /**
     *
     * @param document {InsightContent|DiscoveryContent|Asset}
     */
    showDocument(content) {
      console.log('Asset mixins, showing preview');
      console.log(content);
      this.openPreviewModalWindow(content);
    },
    onModalClosed() {
      this.openPreview = false;
    },
    /**
     *
     * @param document {InsightContent|DiscoveryContent|Asset}
     */
    showInsightsModal(content) {
      this.openContentInsightModalWindow({
        document: content,
      });
    },
    closeInsight() {
      this.showInsight = false;
    },
    editMetadata(content) {
      // console.log('Edit Metadata', content);

      const docId = content?.id;
      this.openEditMetadata(docId);
    },
    addToCollection(content) {
      const docId = content?.docId;
      this.openAddToCollection(docId);
    },
  },
};
