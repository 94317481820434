<template>
  <v-dialog
  :persistent="$vuetify.breakpoint.smAndUp"
  :fullscreen="$vuetify.breakpoint.xsOnly"
  :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : 'dialog-transition'"
  v-model="openDialog" v-resize="onResize" :width="width">
    <template v-if="currentDialogType">
      <component :is="component" @close="close" :item="item"></component>
    </template>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
  discoveryDialogFactory,
} from '@/lib/ui/dialogs/DiscoveryDialogsFactory';
import DiscoveryDialogOptions from '@/components/ui/dialogs/DiscoveryDialogOptions';

const dialogsFactory = discoveryDialogFactory();

export default {
  name: 'DiscoveryDialogContainer',
  data: () => ({
  }),
  computed: {
    ...mapState('common', [
      'currentDialogType',
      'showDiscoveryDialog',
    ]),
    openDialog: {
      get() {
        return this.showDiscoveryDialog;
      },
      set(value) {
        this.setDialogState(value);
      },
    },
    dialogType() {
      if (this.currentDialogType === null) {
        return '';
      }

      if (typeof this.currentDialogType === 'string') {
        return this.currentDialogType;
      }

      if (this.currentDialogType instanceof DiscoveryDialogOptions) {
        return this.currentDialogType.dialogType;
      }

      return '';
    },
    closedCallback() {
      if (!(this.currentDialogType instanceof DiscoveryDialogOptions)) {
        return null;
      }

      return this.currentDialogType?.onClosed;
    },
    item() {
      if (!(this.currentDialogType instanceof DiscoveryDialogOptions)) {
        return null;
      }

      return this.currentDialogType.item;
    },
    dialog() {
      return dialogsFactory.get(this.dialogType);
    },
    component() {
      return this.dialog.component;
    },
    width: {
      get() {
        return this.dialog?.width || `${450}px`;
      },
      set(value) {
        if (this.dialog != null) {
          this.dialog.width = value;
          this.component.width = value;
        }
      },
    },
  },
  methods: {
    ...mapActions('common', [
      'setDialogState',
      'closeDialog',
    ]),
    close(value) {
      if (this.closedCallback != null) {
        this.closedCallback(value);
      }

      this.closeDialog();
    },
    onResize() {
      this.width = `${window.innerWidth}px`;
      this.height = `${window.innerHeight}px`;
    },
  },
};
</script>

<style scoped>

</style>
