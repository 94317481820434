class AuthApi {
  constructor(api, authProvider) {
    this.api = api;
    this.authProvider = authProvider;
  }

  getUser() {
    return this.authProvider.getUser();
  }

  login() {
    return this.authProvider.loginRedirect();
  }

  async logout() {
    await this.authProvider.logout();
  }
}

export default AuthApi;
