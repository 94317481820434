export default [
  {
    path: '',
    name: 'response-generator-home',
    component: () => import(
      /* webpackChunkName: "response-generator-home" */
      '../../components/responseGenerator/ResponseGeneratorHome.vue'
    ),
    meta: {
      requiresAuth: true,
      pageTitle: 'Response Generator',
    },
  },
  {
    path: 'new',
    name: 'qna-demo',
    component: () => import(
      '../../views/tools/ResponseGeneratorDemo.vue'
    ),
    meta: {
      requiresAuth: true,
    },
  },
];
