const DiscoveryNotifier = function DiscoveryNotifier(audioFileUrl) {
  /**
   *
   * @type {HTMLAudioElement}
   */
  this.ding = new Audio(audioFileUrl || '/sounds/ding.mp3');
  this.notifying = false;

  /**
   *
   * @type {[DiscoveryNotification]}
   */
  this.notifications = [];

  const self = this;

  Object.defineProperty(self, 'unreadNotifications', {
    get() {
      return self.notifications?.filter((notification) => !notification.read);
    },
  });

  Object.defineProperty(self, 'unreadCount', {
    get() {
      return self.unreadNotifications?.length;
    },
  });
};

/**
 * Plays the 'ding' if there is only on unread notification or if force is set to true
 * @param force{Boolean}
 */
DiscoveryNotifier.prototype.playSound = function playSound(force = false) {
  if ((force || this.unreadCount === 1) && !this.notifying) {
    this.notifying = true;

    this.ding.play().then(() => {
      this.notifying = false;
    });
  }
};

/**
 *
 * @param notification{DiscoveryNotification}
 */
DiscoveryNotifier.prototype.add = function add(notification) {
  this.notifications.unshift(notification);
  this.playSound(true);
};

export default DiscoveryNotifier;
