import he from 'he';
import MetricItem from '@/lib/charts/models/MetricItem';

export const decodeHtml = (content) => he.decode(content);

class FacetValue extends MetricItem {
  selected = false;

  #htmlName = null;

  constructor(name = '', count = 0, selected = false) {
    super(name, count);
    this.selected = selected;
  }

  get htmlName() {
    if (this.#htmlName == null) {
      this.#htmlName = decodeHtml(this.name);
    }

    return this.#htmlName;
  }

  static fromObject({ name, count }) {
    return new FacetValue(name, count);
  }
}

export default FacetValue;
