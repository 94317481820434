/**
 *
 * @type {
 * {
 *  showCaptions: {get(): (function(): *)|boolean|(function(*=): boolean)|*},
 *  showSynopsis: {get(): *}, hasContentAnalyzerProperties: {get(): boolean}
 *  }
 * }
 */
const contentAnalyzerProperties = {
  /**
   * Property to check whether an object has content analyzer properties
   */
  hasContentAnalyzerProperties: {
    get() {
      return true;
    },
  },

  showSynopsis: {
    get() {
      return !(this.isImage || this.isVideo);
    },
  },

  showCaptions: {
    get() {
      return this.isImage && this.imageDescription;
    },
  },
};

/**
 * Sets properties required for content analyzer
 * @param object
 * @returns {contentAnalyzerProperties}
 */
const setContentAnalyserProperties = (object) => Object.defineProperties(
  object,
  contentAnalyzerProperties,
);

export default setContentAnalyserProperties;
