import { assetStreamCreator } from '@/documentAssets/models/AssetStream';

export default {
  latestUploadsConnection: null,
  newCasesConnection: null,
  loadingLatestUploads: false,
  loadingNewCases: false,
  assetStreams: [],

  featuredContents: [
    assetStreamCreator(
      {
        name: 'Featured Content',
        code: 'featuredContent',
        thumbnailType: 'featuredAssetImage',
      },
    ),
    assetStreamCreator(
      {
        name: 'Spotlight Content',
        code: 'spotlightContent',
        thumbnailType: 'featuredAssetImage',
      },
    ),
  ],
};
