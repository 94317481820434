import InteractionInsight from '@/lib/insights/models/InteractionInsight';
import { shareableLocation } from '@/lib/util/shareables';

class AssetDisplayDetail {
  isAccessible = true;

  interactionInsight = new InteractionInsight();

  assetId = 0;

  docId = '';

  title = '';

  constructor(
    assetId = 0,
    docId = '',
    isAccessible = true,
    insight = new InteractionInsight(),
  ) {
    this.assetId = assetId;
    this.docId = docId;
    this.isAccessible = isAccessible;
    this.interactionInsight = insight;
  }

  get rawLocation() {
    return { name: 'content-page', params: { id: this.docId } };
  }

  shareableObject(preview = true) {
    return {
      name: this.title,
      type: 'Document',
      location: shareableLocation(this.rawLocation)(preview),
    };
  }

  static fromObject({
    assetId, docId, isAccessible, interactionInsight,
  }) {
    return new AssetDisplayDetail(
      assetId, docId,
      isAccessible,
      (interactionInsight && InteractionInsight.fromObject(interactionInsight)),
    );
  }
}

export default AssetDisplayDetail;
