class ChatMessage {
  role = '';

  message = '';

  constructor(role, message) {
    this.role = role;
    this.message = message;
  }
}
export default ChatMessage;
