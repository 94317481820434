export const EditSectionTypes = {
  DETAILS: 0,
  THEME: 1,
  MEMBERS: 2,
};

const CollectionGroupEditSections = function EditSections() {
  this.openSection = [EditSectionTypes.DETAILS];
};

CollectionGroupEditSections.prototype.setOpenPanel = function setOpenPanel(value) {
  this.openSection = value;
};

export default CollectionGroupEditSections;
