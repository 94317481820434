const documentThumbnailUrlFetcher = (superClass) => class extends superClass {
  http = null;

  constructor(payload, http) {
    super(payload);
    this.http = http;
  }
};

export default documentThumbnailUrlFetcher;
