const externalLinkPattern = new RegExp('^https?:');
export const isExternalLink = (path) => externalLinkPattern.test(path);

const pattern = new RegExp('{{(?<name>.*?)}}');
class UrlTransformer {
  config = {};

  constructor(config) {
    this.config = config;
  }

  transform(path) {
    if (path == null || path.trim() === '') {
      return path;
    }

    const match = path.match(pattern);

    if (match == null) {
      return path;
    }

    const key = match.groups.name;

    if (key in this.config) {
      return this.config[key];
    }

    return path;
  }
}

export default UrlTransformer;
