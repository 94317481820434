import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('common', [
      'openSharePanel',
    ]),
    share(item) {
      this.openSharePanel(item);
    },
  },
};
