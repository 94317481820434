import { Observable } from 'rxjs';
import PageElement from '@/lib/ui/models/PageElement';
import VideoIndexerWidgetSettings from '@/lib/videos/models/VideoIndexerWidgetSettings';

class VideoInsightsService {
  /**
   *
   * @type {AxiosInstance}
   */
  http = null;

  baseUrl = 'v2/videos';

  /**
   *
   * @param http{AxiosInstance}
   */
  constructor(http) {
    this.http = http;
  }

  async getInsightsObservable(docId) {
    const url = `${this.baseUrl}/${docId}/widget`;

    const { data } = await this.http.get(url);
    const settings = data.data;
    const widgetSettings = VideoIndexerWidgetSettings.fromObject(settings);

    return widgetSettings;
  }
}

export default VideoInsightsService;
