import gql from 'graphql-tag';

import { pageInfo } from '@/lib/graphql/fragments';
import { userListFields, userDetailFields } from './fragments';

/**
 *
 * @type {DocumentNode}
 */
const users = gql`
    query users ($first: Int, $after: String, $last: Int, $before: String) {
      users (first: $first, after: $after, last: $last, before: $before){
        pageInfo{
          ...pageInfoFields
        }
        nodes {
          ...userFields
        }
        totalCount
      }
    }
    ${pageInfo}
    ${userListFields}
`;

const userById = gql`
    query userById($id: ID!) {
      userById(id: $id) {
        ...userDetailFields
      }
    }
    ${userDetailFields}
`;

export default {
  users,
  userById,
};
