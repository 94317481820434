export default {
  path: '/awards',
  components: {
    main: () => import(/* webpackChunkName: "awards-pages" */ '../views/awards/AwardsView.vue'),
  },
  meta: {
    requiresAuth: true,
    hideSearchFromHeader: false,
    showRightPanel: false,
  },
  redirect: { name: 'awardresults' },
  children: [
    {
      path: 'home',
      name: 'awards-home',
      component: () => import(
        /* webpackChunkName: "awards-home" */
        '@/awards/pages/home/AwardsHomePage.vue'
      ),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        showRightPanel: false,
        showFilters: false,
        mobileName: 'awardshome',
      },
    },
    {
      path: 'results',
      name: 'awardresults',
      component: () => import(
        /* webpackChunkName: "leaderboards" */
        '@/awards/pages/results/ResultsPage.vue'
      ),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        showRightPanel: false,
        showFilters: false,
        mobileName: '',
        pageTitle: 'Award Results',
      },
    },
    {
      path: 'leaderboard',
      name: 'awardsleaderboards',
      component: () => import(
        /* webpackChunkName: "leaderboards" */
        '@/awards/pages/leaderboard/LeaderBoardsPage.vue'
      ),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        showRightPanel: false,
        showFilters: false,
        mobileName: '',
        pageTitle: 'Award Leaderboards',
      },
    },
    {
      path: 'showcase',
      name: 'showcase',
      component: () => import(
        /* webpackChunkName: "leaderboards" */
        '@/awards/pages/showcase/ShowcasePage.vue'
      ),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        showRightPanel: false,
        showFilters: false,
        mobileName: 'showcase',
        pageTitle: 'Award Showcase',
      },
    },
    {
      path: 'leaderboardandresults',
      name: 'leaderboardandresults',
      component: () => import(
        /* webpackChunkName: "leaderboards" */
        '@/awards/pages/leaderboard/LeaderBoardsPage.vue'
      ),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        showRightPanel: false,
        showFilters: false,
        mobileName: 'leaderboardandresults',
      },
    },
    {
      path: 'insights/cannes',
      name: 'awardInsights',
      component: () => import(
        /* webpackChunkName: "leaderboards" */
        '@/awards/pages/results/CannesAwardsInsights.vue'
      ),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        showRightPanel: false,
        showFilters: false,
        mobileName: 'cannesInsights',
        pageTitle: 'Cannes Lions Award Advance Analytics Performance Tracker Dashboard',
      },
    },
  ],
};
