/**
 * This function checks whether elements of this list are of primitive types.
 * @param list
 */
import BasicInsight from './basicInsight';
import { createIterableFromRawList } from '../../../../util';
import FaceInsight from './faceInsight';
import ComplexInsight from './complexInsight';
import Face from './face';
import { VideoTagInsight, VideoIndexer } from './video';

const isBasicInsightType = (list) => (typeof list !== 'string')
  && (list?.length > 0 && typeof list[0] !== 'object');

const isComplexInsightType = (list) => (typeof list !== 'string')
  && (list?.length > 0 && typeof list[0] === 'object');

/**
 *
 * @param insight{ComplexInsight}
 * @returns {BasicInsight}
 */
const convertToBasicInsight = (insight) => new BasicInsight(
  insight.key,
  insight.basicValues,
);

class InsightList {
  isManual = false;

  basicInsights = [];

  basicInsightFields = [];

  complexInsightFields = [];

  complexInsights = [];

  allBasicInsightValues = [];

  faces = [];

  insightTags = {};

  /**
   *
   * @type {FaceInsight}
   */
  facesInsight = null;

  /**
   * The indexer returns a lot of insights. This is a list of fields that the UI can use
   * @type {string[]}
   */
  #displayableComplexInsightFields = [
    'Ogilvyawards',
    'Ogilvytopics',
    'Ogilvyclients',
    'brands',
    'tags',
    'objects',
  ];

  /**
   *
    * @type {string[]}
   */
  #basicInsightListableBlacklist = {
    indexer_celebrityNames: true,
    indexer_dateTimes: true,
    indexer_quantities: true,
    indexer_urls: true,
    indexer_emails: true,
  };

  #listableBasicInsights = null;

  content = null;

  insightsRepo = {};

  /**
   *
   * @type {VideoIndexer|null}
   */
  videoIndexer = null;

  constructor(content, isManual = false) {
    this.isManual = isManual;
    this.content = content;
    this.insightsRepo = {};
  }

  get tagType() {
    return this.isManual ? 'manual' : 'auto';
  }

  get listableBasicInsights() {
    /**
     *
     * @param insight: BaseInsight
     * @returns {boolean}
     */
    const check = (insight) => {
      /*
      console.log(`listableBasicInsights: Checking ${insight.key} ...`);
      console.log(insight.values);
      console.log(insight.values && insight.values.length > 0);
      */

      if (!(insight instanceof BasicInsight
        || insight instanceof ComplexInsight
        || insight instanceof VideoTagInsight)) {
        return false;
      }

      return insight.values && insight.values.length > 0
        && !(insight.key in this.#basicInsightListableBlacklist);
    };

    const transform = (insight) => {
      if (insight instanceof BasicInsight) {
        return insight;
      }

      if (insight instanceof ComplexInsight) {
        const bi = convertToBasicInsight(insight);
        return bi;
      }

      return insight;
    };

    if (this.#listableBasicInsights == null) {
      const insights = [...this.basicInsights, ...(this.videoIndexer?.listableInsights || [])];

      this.#listableBasicInsights = insights
        .filter(check)
        .map(transform);
    }

    return this.#listableBasicInsights;
  }

  get listableAutoInsights() {
    const check = (insight) => !insight.isManual;
    return this.listableBasicInsights?.filter(check) || [];
  }

  /**
   * Returns a list of ComplexInsights which can be displayed on the UI
   * @returns {*[ComplexInsight]}
   */
  get displayableComplexInsights() {
    const fields = this.#displayableComplexInsightFields;

    return this.complexInsights.filter(
      /**
       *
       * @param insight {ComplexInsight}
       * @returns {boolean}
       */
      (insight) => fields.includes(insight.key),
    ).sort((f1, f2) => fields.indexOf(f1.key) - fields.indexOf(f2.key));
  }

  get insightsChartOptions() {
    const facesOption = (faceInsight) => {
      if (faceInsight == null || !faceInsight.hasInsights) {
        return [];
      }

      const ageOption = {
        chartKey: 'ageDistribution',
        label: 'Age Detection',
        isFaceInsight: true,
      };

      const genderDistribution = {
        chartKey: 'genderDistribution',
        label: 'Gender Distribution',
        isFaceInsight: true,
      };

      return [ageOption, genderDistribution];
    };

    const basicInsightsOption = this.listableAutoInsights?.map(
      (insight) => ({ label: insight.label, chartKey: insight.key, isFaceInsight: false }),
    ) || [];

    const videoChartOptions = this.videoIndexer?.chartOptions || [];
    return [...basicInsightsOption, ...facesOption(this.facesInsight), ...videoChartOptions];
  }

  get chartableInsights() {
    const insights = [...this.basicInsights, ...(this.videoIndexer?.listableInsights || [])];

    const filtered = insights
      .filter((insight) => !insight.isManual);
    console.log('Filtered', filtered);
    return filtered;
  }

  /**
   *
   * @param insight {BasicInsight}
   * @returns {InsightList}
   */
  addBasicInsight(insight) {
    this.basicInsightFields.push(insight.key);
    this.basicInsights.push(insight);

    if (insight.key === 'keyphrases') {
      this.allBasicInsightValues = insight.values;
    }
    return this;
  }

  /**
   *
   * @param insight {FaceInsight}
   * @returns {InsightList}
   */
  addFaceInsight(insight) {
    this.faces = insight.values;
    this.facesInsight = insight;
    this.complexInsightFields.push(insight.key);

    return this;
  }

  /**
   *
   * @param insight {ComplexInsight}
   * @returns {InsightList}
   */
  addComplexInsight(insight) {
    this.complexInsightFields.push(insight.key);
    this.complexInsights.push(insight);
    return this;
  }

  /**
   *
   * @param insight {BasicInsight|ComplexInsight|FaceInsight}
   * @returns {InsightList}
   */
  addInsight(insight) {
    this.insightsRepo[insight.key] = insight;

    if (insight instanceof BasicInsight) {
      return this.addBasicInsight(insight);
    }

    if (insight instanceof FaceInsight) {
      return this.addFaceInsight(insight);
    }

    return this.addComplexInsight(insight);
  }

  getInsight(key) {
    return this.insightsRepo[key] || null;
  }

  getInsights(keys) {
    if (this.videoIndexer == null) {
      return keys.map((key) => this.getInsight(key)).filter((insight) => insight != null);
    }
    console.log('Getting insights from video');
    return this.videoIndexer.listableInsights;
  }

  /**
   *
   * @param key {String}
   * @param list {[]}
   * @returns {BasicInsight|null}
   */
  static createBasicInsight(key, list) {
    if (!isBasicInsightType(list)) {
      return null;
    }

    return new BasicInsight(key, list, this.isManual);
  }

  /**
   *
   * @param key
   * @param list
   */
  static createFaceList(key, list) {
    const faceKeys = {
      faces: true,
      indexer_faces: true,
    };

    if (!(key in faceKeys)) {
      return null;
    }

    return new FaceInsight(
      key,
      createIterableFromRawList((face) => new Face(face))(list),
    );
  }

  static createComplexInsight(key, list) {
    if (!isComplexInsightType(list)) {
      return null;
    }

    const values = ComplexInsight.getBasicValues(key, list);

    return new BasicInsight(key, values);
  }

  static createInsight(field, insightValues) {
    const insight = this.createBasicInsight(field, insightValues || [])
      || this.createFaceList(field, insightValues || [])
      || this.createComplexInsight(field, insightValues || []);

    return insight;
  }

  /**
   *
   * @param insightContent {Object}
   * @param fields {[String]}
   * @returns {Promise<unknown>|Promise<InsightList>}
   */
  static create(insightContent, fields) {
    if (!fields) {
      return Promise.resolve(new InsightList());
    }
    const promise = new Promise((resolve) => {
      const insightList = new InsightList();
      fields.forEach((field) => {
        const insight = this.createInsight(field, insightContent[field] || []);

        if (insight !== null) {
          insightList.addInsight(insight);
        }
      });

      if (insightContent != null && insightContent['indexer_videoIndexer'] != null) {
        console.log('creating video indexer');
        insightList.videoIndexer = new VideoIndexer(insightContent['indexer_videoIndexer']);
      }

      resolve(insightList);
    });

    return promise;
  }

  static createInsightList(document, instanceFields, isManual = false) {
    const promise = new Promise((resolve) => {
      document.insightList = new InsightList(document, isManual);
      const documentFields = Object.keys(document);

      documentFields.forEach((field) => {
        if (!(field in instanceFields)) {
          const insight = this.createInsight(field, document[field]);
          if (insight !== null) {
            insight.isManual = isManual;
            document.insightList.addInsight(insight);
          }
        }
      });

      resolve(document);
    });

    return promise;
  }
}

export default InsightList;
