/**
 *
 * @param httpClient {AxiosInstance}
 * @constructor
 */
import {
  Collection,
} from '@/lib/business/models';
import Group, { groupFactory } from '@/lib/business/models/groups/Group';
import { createGroupsFromList } from '@/lib/business/models/groups/ListGroups';
import ThemeClient from '@/lib/themes/ThemeClient';
import { discoveryThemeFactory } from '@/lib/themes/DiscoveryTheme';
import FeaturedCollectionList from '@/lib/business/models/FeaturedCollectionList';
import { countListResultFactory } from '@/lib/business/models/lists/CountListsResult';
import CustomizationsClient from '@/lib/customizations/CustomizationsClient';
import FeaturedGroup from '@/lib/business/models/groups/FeaturedGroup';
import AppObjectInsightsClient from '../insights/AppObjectInsightsClient';

const DiscoveryGroupClient = function DiscoveryGroupClient(httpClient) {
  this.httpClient = httpClient;
  this.endpoint = 'v2/groups';

  this.themeClient = new ThemeClient(httpClient, {
    save: `${this.endpoint}/theme`,
    fetch: `${this.endpoint}/themes`,
  });

  this.customizationsClient = new CustomizationsClient(httpClient);
  this.appObjectInsightsClient = new AppObjectInsightsClient(httpClient);
};

DiscoveryGroupClient.prototype.fetchThemes = function fetchThemes(groups) {
  setTimeout(() => this.themeClient.fetchThemes(groups));
};

DiscoveryGroupClient.prototype.fetchAppObjectSettings = function fetchAppObjectSettings(groups) {
  setTimeout(() => this.customizationsClient.getCustomizationsList(groups));
};

DiscoveryGroupClient.prototype.fetchAppObjectSetting = function fetchAppObjectSetting(group) {
  setTimeout(() => this.customizationsClient.getCustomizations(group));
};

DiscoveryGroupClient.prototype.fetchThumbnails = function fetchThumbnails(groups, imageType = 'featuredGroupImage') {
  setTimeout(async () => {
    const settingsDict = await this.customizationsClient.getPropertySetting(groups, imageType);
    groups.forEach((group) => {
      const url = (settingsDict[group.id] || { value: '' }).value;
      group.thumbnail = url;
    });
  });
};
DiscoveryGroupClient.prototype.fetchGroupsViews = function fetchGroupsViews(groups) {
  setTimeout(() => this.appObjectInsightsClient.getEntityViews(groups, 'group'));
};

DiscoveryGroupClient.prototype.fetchCollectionsViews = function fetchCollectionsViews(collections) {
  setTimeout(() => this.appObjectInsightsClient.getEntityViews(collections, 'collection'));
};

/**
 *
 * @param type {string}
 * @returns {Promise<Group[]>}
 */
DiscoveryGroupClient.prototype.groups = async function groups(type, deploymentIndexId, rowsPerPage = 30, currentPage = 0) {
  const params = {
    type: type || 'my',
    deploymentIndexId,
    rowsPerPage,
    currentPage,
  };

  const { data } = await this.httpClient.get(this.endpoint, { params });
  const groupsList = createGroupsFromList(data.rows);
  await this.fetchAppObjectSettings(groupsList);
  // this.fetchGroupsViews(groupsList);
  data.rows = groupsList;
  return data;
};

DiscoveryGroupClient.prototype.get = async function group(id) {
  const { data } = await this.httpClient.get(`${this.endpoint}/${id}`);
  const result = groupFactory(data);
  await this.fetchAppObjectSetting(result);
  if (result?.collections?.length > 0) {
    await this.fetchAppObjectSettings(result?.collections);
    await this.fetchCollectionsViews(result?.collections);
  }
  return result;
};

/**
 *
 * @param group{ListGroup|Group}
 * @returns {Promise<*>}
 */
DiscoveryGroupClient.prototype.updatePin = async function updatePin(group) {
  const action = group.pinned ? 'pin' : 'unpin';
  const uri = `${action}/${group.groupId}`;

  const { data } = await this.httpClient.put(`${this.endpoint}/${uri}`);
  return data;
};

/**
 *
 * @param group{ListGroup|Group}
 * @returns {Promise<*>}
 */
DiscoveryGroupClient.prototype.updateStar = async function updateStar(group) {
  const action = group.starred ? 'star' : 'unstar';
  const uri = `${action}/${group.groupId}`;

  const { data } = await this.httpClient.put(`${this.endpoint}/${uri}`);
  return data;
};

DiscoveryGroupClient.prototype.newGroup = function newGroup() {
  return new Group({
    name: '',
    description: '',
    theme: discoveryThemeFactory(),
    groupUsers: [],
    isConfidential: true,
  });
};

/**
 *
 * @param group{Group}
 * @returns {Promise<Group>}
 */
DiscoveryGroupClient.prototype.createGroup = async function createGroup(group, deploymentIndexId) {
  const groupDataOnly = { ...group };
  const { theme } = groupDataOnly;

  delete groupDataOnly.theme;

  const { data } = await this.httpClient.post(this.endpoint, {
    group: groupDataOnly,
    deploymentIndexId,
  });
  const updatedGroup = group
    .updateId(data)
    .setMembers(data.groupUsers);

  setTimeout(() => this.themeClient.saveTheme(updatedGroup, theme));
  setTimeout(() => this.fetchAppObjectSetting(updatedGroup));
  return updatedGroup;
};

/**
 *
 * @param group{Group}
 * @returns {Promise<Group>}
 */
DiscoveryGroupClient.prototype.update = async function updateGroup(group, deploymentIndexId) {
  const groupDataOnly = { ...group };
  const { theme } = groupDataOnly;

  delete groupDataOnly.theme;

  const { data } = await this.httpClient.put(`${this.endpoint}/${group.id}`, {
    group: groupDataOnly,
    deploymentIndexId,
  });
  setTimeout(() => this.themeClient.saveTheme(group, theme));
  return group
    .updateId(data)
    .setMembers(data.groupUsers);
};

/**
 *
 * @param group{Group}
 * @returns {Promise<Group>}
 */
DiscoveryGroupClient.prototype.save = async function save(group, deploymentIndexId) {
  if (group.id > 0) {
    return this.update(group, deploymentIndexId);
  }

  return this.createGroup(group, deploymentIndexId);
};

DiscoveryGroupClient.prototype.getAvailableCollections = async function collections(
  groupId, deploymentIndexId,
) {
  const params = {
    id: groupId,
    deploymentIndex: deploymentIndexId,
  };

  const { data } = await this.httpClient.get(
    'v2/collectionForm/availableCollectionsForGroup',
    { params },
  );
  return data?.map((collection) => new Collection(collection));
};

DiscoveryGroupClient.prototype.getAvailableGroups = async function availableGroups(
  collectionId, deploymentIndexId,
) {
  const params = {
    id: collectionId,
    deploymentIndex: deploymentIndexId,
  };

  const { data } = await this.httpClient.get(
    'v2/collectionForm/availableGroupsForCollection',
    { params },
  );

  return data?.map((collection) => new Group(collection));
};

/**
 *
 * @param group{Group}
 * @param collections{Collection[]}
 * @returns {Promise<Group>}
 */
DiscoveryGroupClient.prototype.addCollectionsToGroup = async function addToGroup(
  group, collections,
) {
  const uri = `${this.endpoint}/addCollections/${group.id}`;
  const { data } = await this.httpClient.put(uri, collections);

  if (data && data.length > 0) {
    const newCollections = data.map(
      (collectionGroup) => new Collection(collectionGroup.collection),
    );

    group.addCollections(newCollections);
  }

  return group;
};

DiscoveryGroupClient.prototype.associateCollectionToGroup = async function associateCollection(
  collection, groupIds,
) {
  const uri = `${this.endpoint}/associateCollectionToGroups/${collection.id}`;
  const { data } = this.httpClient.put(uri, groupIds);

  return data;
};

DiscoveryGroupClient.prototype.featuredGroups = async function getFeaturedGroups(
  deploymentIndexId, imageType = 'featuredGroupImage',
) {
  const url = `${this.endpoint}/featured/${deploymentIndexId}`;
  const { data } = await this.httpClient.get(url);

  const groups = FeaturedGroup.fromList(data); // createGroupsFromList(data);
  const featuredGroups = new FeaturedCollectionList(groups);

  this.fetchThumbnails(groups, imageType);
  // this.fetchGroupsViews(groups);
  return featuredGroups;
};

DiscoveryGroupClient.prototype.markAsFeaturedGroup = async function markAsFeaturedGroup(
  group, deploymentIndexId,
) {
  const groupId = group.id;
  const url = `${this.endpoint}/markAsFeatured/${groupId}/${deploymentIndexId}`;
  const { data } = await this.httpClient.post(url);

  return data;
};

DiscoveryGroupClient.prototype.removeFromFeaturedGroup = async function removeFromFeaturedGroup(
  group, deploymentIndexId,
) {
  const groupId = group.id;
  const url = `${this.endpoint}/removeAsFeatured/${groupId}/${deploymentIndexId}`;
  const { data } = await this.httpClient.post(url);

  return data;
};

DiscoveryGroupClient.prototype.getCounts = async function getCounts(deploymentIndexId) {
  const url = `${this.endpoint}/count`;
  const params = {
    deploymentIndexId,
  };

  const { data } = await this.httpClient.get(url, { params });
  return countListResultFactory(data);
};

DiscoveryGroupClient.prototype.removeCollection = async function removeCollection(
  group, collection,
) {
  try {
    const groupId = group.id;
    const collectionId = collection.id;

    const url = `${this.endpoint}/${groupId}/collections/${collectionId}`;
    const { data } = await this.httpClient.delete(url);

    group.removeCollection(collectionId);
    return data;
  } catch (e) {
    console.error(e);
  }

  return null;
};

DiscoveryGroupClient.prototype.deleteGroup = async function deleteGroup(group) {
  const url = `${this.endpoint}/${group.groupId}`;
  const { data } = await this.httpClient.delete(url);
  return data;
};

export default DiscoveryGroupClient;

/**
 *
 * @param httpClient
 * @returns {DiscoveryGroupClient}
 */
export const discoveryGroupClient = (httpClient) => new DiscoveryGroupClient(httpClient);
