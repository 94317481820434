/**
 * the purpose of this class is to store collection details in memory, so that they could be reused
 * without fetching from the server all the time.
 */
class DocumentDetailsCache {
  cache = {};

  /**
   * Adds a collection into the cache
   * @param document {InsightContent}
   */
  add(document) {
    this.cache[document.id] = document;
  }

  /**
   * Returns a promise, with collection if it's in the cache
   * @param documentId
   * @returns {Promise<InsightContent>}
   */
  get(documentId) {
    return new Promise((resolve, reject) => {
      if (this.cache[documentId]) {
        resolve(this.cache[documentId]);
      } else {
        reject(new Error('Document Not Found'));
      }
    });
  }

  /**
   * Clears the cache
   */
  clear() {
    this.cache = {};
  }
}

export default DocumentDetailsCache;
