import AmpPlayerSettings from './AmpPlayerSettings';

class VideoIndexerWidgetSettings {
  /**
   *
   * @type {AmpPlayerSettings}
   */
  playerSettings = null;

  /**
   *
   * @type {string}
   */
  videoAccessToken = '';

  /**
   *
   * @type {string}
   */
  videoId = '';

  /**
   *
   * @type {string}
   */
  videoAccountId = '';

  /**
   *
   * @param playerSettings {AmpPlayerSettings}
   * @param videoAccessToken {string}
   */
  constructor(playerSettings = null, videoAccessToken = 's', videoId = '', accountId = '') {
    this.playerSettings = playerSettings;
    this.videoAccessToken = videoAccessToken;
    this.videoAccountId = accountId;
    this.videoId = videoId;
  }

  static fromObject(data) {
    const {
      playerSetting, videoAccessToken, videoId, videoAccountId,
    } = data;
    const ampSettings = AmpPlayerSettings.fromObject(playerSetting);
    return new VideoIndexerWidgetSettings(ampSettings, videoAccessToken, videoId, videoAccountId);
  }
}

export default VideoIndexerWidgetSettings;
