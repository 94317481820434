import collectionThumbnailProperties
from '@/lib/business/models/themes/collectionThumbnailProperties';

const defaultTheme = collectionThumbnailProperties.defaultThemeProperty();

const DiscoveryTheme = function DiscoveryTheme(theme) {
  this.heroImage = theme?.heroUrl || defaultTheme.heroImage;
  this.thumbnailImage = theme?.thumbnailUrl || defaultTheme.thumbnailImage;

  this.thumbnailFile = null;
  this.heroFile = null;

  this.themeEntityId = theme?.themeEntityId;
  this.thumbnailUrl = theme?.thumbnailUrl;
  this.heroUrl = theme?.heroUrl;
  this.themeColor = theme?.themeColor;
  this.secondaryThemeColor = theme?.secondaryThemeColor;
};

DiscoveryTheme.prototype.toFormData = function toFormData(entityId) {
  const formData = new FormData();
  formData.append('thumbnailFile', this.thumbnailFile);
  formData.append('heroFile', this.heroFile);
  formData.append('themeColor', this.themeColor);
  formData.append('themeEntityId', entityId);

  return formData;
};

Object.defineProperty(DiscoveryTheme.prototype, 'isThumbnailEditable', {
  get() {
    return true;
  },
});

export default DiscoveryTheme;

export const discoveryThemeFactory = (theme) => new DiscoveryTheme(theme);

export const discoveryThemeWithNoThumbnail = (theme) => {
  const discoveryTheme = discoveryThemeFactory(theme);
  Object.defineProperty(discoveryTheme, 'isThumbnailEditable', {
    get() {
      return false;
    },
  });

  return discoveryTheme;
};
