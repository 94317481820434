const locationNameMixin = (superClass) => class extends superClass {
  name = '';

  constructor(data, name) {
    super(data);
    this.name = name;
  }

  get name() {
    return this.name;
  }

  set name(value) {
    this.name = value;
  }

  setName(name) {
    this.name = name;
    return this;
  }
};

export default locationNameMixin;
