<template>
    <v-snackbar light top v-model="showSnackBar"
    transition="slide-y-transition"
    :color="color">
      <div class="text-center"><span :class="textClass">{{ message }}</span></div>
    </v-snackbar>
</template>
<script>
export default {
  name: 'SnackbarMessage',
  data: () => ({
    showSnackBar: false,
    message: '',
    color: '',
    textClass: '',
  }),
  methods: {
    show(data) {
      this.message = data.message;
      this.color = data.color;
      this.textClass = data.textClass;
      this.showSnackBar = true;
    },
  },
};
</script>

<style>
@keyframes shake {
  10%,
  90% {
    transform: translate3d(0, -1px, 0);
  }

  20%,
  80% {
    transform: translate3d(0, 2px, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(0, -4px, 0);
  }

  40%,
  60% {
    transform: translate3d(0, 4px, 0);
  }
}

.apply-shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.v-snack__wrapper.v-sheet {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
</style>
