import leaderboardItem from './LeaderboardItem';

class Leaderboard {
  /**
   *
   * @type {[LeaderboardItem]}
   */
  scores = [];

  maxPoints = -Infinity;

  currentYear = 0;

  previousYear = 0;

  addItem(item) {
    const leaderBoardItem = leaderboardItem.fromObject(item);

    this.scores.push(leaderBoardItem);

    this.currentYear = leaderBoardItem.currentYear;
    this.previousYear = leaderBoardItem.previousYear;

    this.maxPoints = Math.max(leaderBoardItem.currentYearPoints, this.maxPoints);

    return this;
  }

  /**
   *
   * @param leaderboardData
   * @returns {Leaderboard}
   */
  static build(leaderboardData) {
    return (leaderboardData || [])
      .reduce((lb, item) => lb.addItem(item), new Leaderboard());
  }
}

export default Leaderboard;
