import { mix } from 'mixwith';
import BaseInsight from './baseInsight';
import BubbleChartMixin from './charting/BubbleChartMixin';

class BasicInsight extends mix(BaseInsight).with(BubbleChartMixin) {
  get topValues() {
    return this.values;
  }
}

export default BasicInsight;
