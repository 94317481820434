const {
  VUE_APP_DISCOVERY_ENDPOINT, VUE_APP_SEARCH_INDEX_NAME,
  VUE_APP_SEARCH_SERVICE_NAME, VUE_APP_SEARCH_API_KEY, VUE_APP_SEARCH_API_VERSION,
  VUE_APP_AZURE_CONTAINER_URL, VUE_APP_AZURE_SAS_TOKEN, VUE_APP_AZURE_CONTAINER_NAME,
  VUE_APP_ENV, VUE_APP_ZC_LICENSE, VUE_APP_RELEASE_ID, VUE_APP_STARFISH_DESIGN_PACK,
} = process.env;

const isInMaintenance = process.env.VUE_APP_IN_MAINTENANCE
  && process.env.VUE_APP_IN_MAINTENANCE === 'true';
export default {
  discoveryEndPoint: () => VUE_APP_DISCOVERY_ENDPOINT,
  graphQlEndPoint: () => VUE_APP_DISCOVERY_ENDPOINT.replace('/api', '/graphql'),
  ciSearchEndPoint: () => `https://${VUE_APP_SEARCH_SERVICE_NAME}.search.windows.net/indexes/`
    + `${VUE_APP_SEARCH_INDEX_NAME}/`,
  ciSearchApiVersion: () => VUE_APP_SEARCH_API_VERSION,
  ciSearchApiKey: () => VUE_APP_SEARCH_API_KEY,
  ciSearchApiSearchUri: () => 'docs',
  azureContainerURL: () => VUE_APP_AZURE_CONTAINER_URL,
  azureStorageSasToken: () => VUE_APP_AZURE_SAS_TOKEN,
  azureStorageContainerName: () => VUE_APP_AZURE_CONTAINER_NAME,
  env: VUE_APP_ENV,
  chartLicense: () => VUE_APP_ZC_LICENSE && [VUE_APP_ZC_LICENSE],
  releaseId: () => VUE_APP_RELEASE_ID,
  designGuidelines: () => VUE_APP_STARFISH_DESIGN_PACK,
  isInMaintenance,
};
