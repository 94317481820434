import CachingService from '@/lib/util/caching/CachingService';

export const SystemAlertTypes = {
  MAINTENANCE: 'maintenance',
  OPEN_AI: 'open-ai',
};

class SystemAlertsService {
  /**
   *
   * @type {CachingService}
   */
  cache = null;

  constructor() {
    this.cache = new CachingService();
  }

  /**
   *
   * @param value
   * @returns {Promise<boolean>}
   */
  async setSystemAlertAcknowledged(type) {
    const done = await this.cache.set(type, 'ack');
    return done;
  }

  /**
   *
   * @returns {Promise<boolean>}
   */
  async getIsSystemAlertAcknowledged(type) {
    const isAlerted = await this.cache.get(type);
    const ack = (isAlerted && (isAlerted === 'ack')) || false;

    return ack;
  }
}

export default SystemAlertsService;

export const systemAlertService = new SystemAlertsService();
