import Vue from 'vue';
import Auth from '@okta/okta-vue/';
import oktaConfig from '../config/okta';

Vue.use(Auth, {
  issuer: oktaConfig.issuer(),
  client_id: oktaConfig.clientId(),
  redirect_uri: oktaConfig.redirectUri(),
  scope: 'openid profile email',
});

const auth = Vue.prototype.$auth;
auth.bearerToken = () => {
  if (oktaConfig.authType() === 'oauth') {
    return Vue.prototype.$auth.getAccessToken();
  }

  return Vue.prototype.$auth.getIdToken();
};

const authProvider = () => auth;

export default authProvider;
