import helper from '@/lib/util/dataSimulator/helper';
import { EditableTypes } from '@/lib/business/models/editable/editableType';
import textExtractor from '../../lib/search/discovery/classes/textExtractor';

const { randomizeList } = helper;

export default {
  paginatedSearchResults: (state) => {
    if (!state.discoverSearchResult.documentResult) {
      return [];
    }

    const results = state.discoverSearchResult.documentResult.contents;

    return results || [];
  },
  totalDocumentsFound: (state) => state.discoverSearchResult.count,
  totalPages: (state) => {
    if (!state.discoverSearchResult.count) {
      return 0;
    }

    console.log(`Calculating totalPages ${state.discoverSearchResult.count} / ${state.rowsPerPage}`);
    return state.discoverSearchResult.count / state.rowsPerPage === 1 ? 1 : Math.ceil(
      state.discoverSearchResult.count / state.rowsPerPage,
    );
  },
  facetList: (state) => state.discoverSearchResult && state.discoverSearchResult.facetList,
  trendingContent: (state) => {
    if (!state.ciSearchResult.documentResult.contents) {
      return [];
    }
    return randomizeList(state.ciSearchResult.documentResult.contents);
  },
  recommendedContent: (state) => {
    if (state.ciSearchResult.documentResult.contents === null
    || state.ciSearchResult.documentResult.contents.length === 0) {
      return [];
    }
    return randomizeList(state.ciSearchResult.documentResult.contents);
  },
  recommendedCases: (state) => {
    if (state.discoverSearchResult.validDocuments === null
      || state.discoverSearchResult.validDocuments.length === 0) {
      return [];
    }

    return randomizeList(state.discoverSearchResult.validDocuments);
  },
  myCollections: (state) => {
    if (!state.ciSearchResult.documentResult.contents) {
      return [];
    }

    return state.ciSearchResult.documentResult.contents;
  },
  latestCollectionActivity: (state) => {
    if (!state.ciSearchResult.documentResult.contents) {
      return [];
    }

    return state.ciSearchResult.documentResult.contents;
  },
  newCases: (state) => {
    if (!state.ciSearchResult.documentResult.contents) {
      return [];
    }

    return state.ciSearchResult
      .documentResult
      .contents?.filter((content) => content.documentType?.startsWith('Case'));
  },
  myWatchedTopic: (state) => {
    if (!state.ciSearchResult.value) {
      return [];
    }

    return state.ciSearchResult.documentResult.contents;
  },
  homepage: (getters) => ({
    trendingContent: getters.trendingContent,
    recommendedContent: getters.recommendedContent,
  }),

  /**
   *
   * @param state
   * @returns {function(*=): Promise<unknown>}
   */
  fragments: (state) => (text) => textExtractor(text)(state.searchString),

  celebrityProfileUrl: (state) => (name) => {
    const celebrity = state.documentInsight?.celebrities?.find(
      (celeb) => celeb.name === name,
    );

    return celebrity?.thing?.image?.thumbnailUrl;
  },

  groupTags: (state) => state.groups?.flatMap(({ tags }) => tags),

  editableEntity: (state) => (type) => {
    console.log('Getting editable entity', type);
    if (type === EditableTypes.COLLECTION) {
      return state.selectedCollection;
    }

    if (type === EditableTypes.GROUP) {
      return state.selectedGroup;
    }

    return state.selectedEditableEntity;
  },

  hasAnswers: (state) => state.discoverSearchResult?.answers?.length > 0,

  answers: (state) => state.discoverSearchResult?.answersAndDocuments || [],

  firstAnswer: (state) => state.discoverSearchResult?.answers[0],

  sasToken: (state) => state.discoverSearchResult?.documentResult?.token,

  baseMetricFilters: (state) => state.baseMetrics?.facetList,

  isSemanticSearch: (state) => state.searchParams.queryType === 'semantic',

  formattedSearchString: (state) => state.searchString?.replace('\\:', ':'),

  searchDocuments: (state) => state.discoverSearchResult.documentResult.contents || [],

  collectionFacetList: (state) => state.collectionAssetSearchResult
    && (state.collectionAssetSearchResult?.facetList || []),
};
