import ObjectRepo from '@/lib/util/ObjectRepo';

const emptyRepo = () => new ObjectRepo([], (collection) => collection.id);
const comparor = ((a, b) => {
  if (a.isFeaturedDate == null && b.isFeaturedDate == null) {
    return 0;
  }
  if (a.isFeaturedDate == null) {
    return 1;
  }
  if (b.isFeaturedDate == null) {
    return -1;
  }
  
  return new Date(b.isFeaturedDate) - new Date(a.isFeaturedDate);
});

const FeaturedCollectionList = function FeaturedCollectionList(collections) {
  this.collections = [];
  this.repo = emptyRepo();

  this.addCollections(collections, true);
};

FeaturedCollectionList.prototype.isFeatured = function isFeatured(collection) {
  return this.repo.isPresent(collection);
};

FeaturedCollectionList.prototype.add = function add(collection) {
  if (!this.repo.isPresent(collection)) {
    this.repo.add(collection);
    this.collections.push(collection);
  }

  collection.isFeatured = true;
  return this;
};

FeaturedCollectionList.prototype.addAndSort = function add(collection) {
  this.add(collection);
  this.collections.sort(comparor);
  return this;
};

FeaturedCollectionList.prototype.remove = function remove(collection) {
  if (this.repo.isPresent(collection)) {
    this.repo.remove(collection);

    collection.isFeatured = false;

    this.collections = this.collections.filter((item) => item.id !== collection.id);
  }

  return this;
};

FeaturedCollectionList.prototype.addCollections = function addCollections(list, setTheme = false) {
  if (list && list.length > 0) {
    list.forEach((item) => {
      this.add(item, setTheme);
    });

    this.collections.sort(comparor);
  }
};

FeaturedCollectionList.prototype.checkIfIsFeatured = function checkIfIsFeatured(list) {
  if (list && list.length > 0) {
    list.forEach((item) => {
      item.isFeatured = this.isFeatured(item);

      if (item.isFeatured) {
        item.updated = new Date();
      }
    });
  }
};

FeaturedCollectionList.prototype.reset = function reset(collections) {
  this.collections = [];
  this.repo = emptyRepo();
  this.addCollections(collections);
};

FeaturedCollectionList.prototype.top = function top(n) {
  return this.collections?.slice(0, n || 5);
};

Object.defineProperty(FeaturedCollectionList.prototype, 'hasFeaturedItems', {
  get() {
    return this.collections && this.collections.length > 0;
  },
});

export default FeaturedCollectionList;
