import moment from 'moment';
import { mix } from 'mixwith';
import AutomaticConstructorMixin from '@/lib/mixins/AutomaticConstructorMixin';
import UploaderBase from '@/lib/search/discovery/classes/UploaderBase';
import InsightList from '@/lib/search/discovery/classes/insights/insightList';

const instanceFields = {
  unique_uuid: true,
  submission_date: true,
  storage_path: true,
  is_confidential: true,
  title: true,
  // primary_author: true,
  author___contact: true,
  created: true,
  updated: true,
  region: true,
  country: true,
  city: true,
  archive_tier: true,
  business_result: true,
  engagement_type: true,
  estimated_value: true,
  status: true,
  created_by: true,
  updated_by: true,
  description: true,
  file_name: true,
};

const fieldFactories = {
  submission_date: (date) => date && moment(date),
  created: (date) => date && moment(date),
  updated: (date) => date && moment(date),
};

const UploaderIndex = class UploaderIndex extends mix(UploaderBase)
  .with(AutomaticConstructorMixin(fieldFactories)) {
  /**
   *
   * @type {InsightList}
   */
  insightList = null;

  constructor(data) {
    super(data);

    InsightList.createInsightList(this, instanceFields, true);
  }
};

export default UploaderIndex;
