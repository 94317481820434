import FilterForm from '../filters/FilterForm';
import Leagues from './Leagues';
import Leaderboard from './Leaderboard';

class LeaderboardResult {
  /**
   *
   * @type {FilterForm}
   */
  filterForm = null;

  /**
   *
   * @type {[Leagues]}
   */
  leaderboardLeagues = [];

  /**
   *
   * @type {Leaderboard}
   */
  leaderboardData = null;

  /**
   *
   * @type {Leagues}
   */
  selectedLeaderboardLeague = null;

  deploymentIndexId = 0;

  constructor(data) {
    this.buildForm(data);
    this.buildLeagues(data);
    this.buildLeaderboardData(data);
  }

  get selectedLeagueIndex() {
    if (this.selectedLeaderboardLeague == null) {
      return -1;
    }

    const index = this.leaderboardLeagues
      .findIndex((league) => league.equals(this.selectedLeaderboardLeague));

    return index;
  }

  set selectedLeagueIndex(value) {
    const league = this.leaderboardLeagues[value];
    this.selectedLeaderboardLeague = league;
  }

  buildForm(data) {
    if ('filterForm' in data) {
      const { filterForm } = data;
      this.filterForm = new FilterForm(filterForm);
    }

    return this;
  }

  buildLeagues(data) {
    if ('leaderboardLeagues' in data) {
      const { leaderboardLeagues, selectedLeaderboardLeague } = data;
      this.leaderboardLeagues = Leagues.fromList(leaderboardLeagues);
      this.selectedLeaderboardLeague = Leagues
        .find(this.leaderboardLeagues, selectedLeaderboardLeague);
    }

    return this;
  }

  /**
   *
   * @param leaderboard{Leaderboard}
   * @returns {LeaderboardResult}
   */
  updateLeaderboard(leaderboard) {
    this.leaderboardData = leaderboard;
    return this;
  }

  buildLeaderboardData(data) {
    if ('leaderboardData' in data) {
      const { leaderboardData } = data;

      const leaderboard = Leaderboard.build(leaderboardData);
      this.updateLeaderboard(leaderboard);
    }

    return this;
  }
}

export default LeaderboardResult;
