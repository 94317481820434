<template>
  <!--<v-row align="center" class="flex-column upload-file-list"
  no-gutters justify="center" v-show="assetInfos.length > 0" style="max-width:calc(50vw + 32px)">
    <v-card color="grey" flat tile class="mt-2" style="max-width:calc(50vw + 32px)">
      <v-card-text class="pa-0">
    <ul class="overflow-y-auto ma-4">
      <upload-file v-for="item in assetInfos" :key="item.Filename" :item="item"></upload-file>
    </ul>
      </v-card-text>
    </v-card> -->
<!--    <div v-show="errorLength > 0" class="mt-2" style="max-width:calc(50vw + 32px)">
      <v-card color="grey" flat tile >
        <v-card-text class="pa-0 pb-2 black error&#45;&#45;text"
        ><div class="pt-4 px-4">Other Errors</div>
      <ul class="overflow-y-auto ma-4 upload_other_errors">
        <li v-for="otherError in uploadErrors" :key="otherError">
          {{ otherError }}
        </li>
      </ul>
        </v-card-text>
      </v-card>
    </div>-->
  <!--</v-row> -->
  <div>
    <upload-file v-for="item in assetInfos" :key="item.FileName" :item="item"></upload-file>
  </div>

</template>
<script>

import {
  mapState,
} from 'vuex';

import UploadFile from '@/components/uploader/UploadFile.vue';

export default {
  name: 'UploadFileList',

  components: {
    UploadFile,
  },

  data() {
    return {
    };
  },
  created() {

  },
  computed: {
    ...mapState('uploader', [
      'assetInfos',
    ]),
  },

  methods: {
  },
};
</script>

<style lang="scss">
  .upload-file-list {
    ul {
      list-style: none;
      padding:0;
      min-width: 50vw;
      max-height:326px;
      &.upload_other_errors {
        max-height:100px;
      }
      li:last-child {
        margin-bottom:0!important;
      }
    }
  }
</style>
