import { mix } from 'mixwith';
import PagedResult from '@/lib/graphql/models/PagedResult';
import IterableResultMixin from './IterableResultMixin';
import PageInfoMixin from './PageInfoMixin';
import TypedListMixin from './TypedListMixin';

const ConnectionMixin = (type) => class Connection extends mix(PagedResult).with(
  PageInfoMixin,
  TypedListMixin(type),
  IterableResultMixin,
) {};

export default ConnectionMixin;
