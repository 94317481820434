import gql from 'graphql-tag';

import { pageInfo } from '@/lib/graphql/fragments';
import { assetFields } from '@/documentAssets/graphql/fragments';

const assetListQuery = gql`
    query getAssets(
      $first: Int,
      $after: String,
      $last: Int,
      $before: String,
      $order: [AssetSortInput!],
      $where: AssetFilterInput,
    ) {
        assets(
          first: $first,
          after: $after,
          last: $last,
          before: $before,
          order: $order,
          where: $where,
        ) {
          pageInfo {
            ...pageInfoFields
          }
          nodes {
            ...assetListFields
          }
          totalCount
        }
    }
    ${pageInfo}
    ${assetFields}
`;

export default {
  assetListQuery,
};
