import Facet from '@/lib/search/discovery/classes/facet';
import { groupBy } from '../../helpers';
import BaseInsight from './baseInsight';

const group = (list) => (keyFn) => {
  const grouped = groupBy(list, keyFn);
  const types = Object.keys(grouped);

  return [grouped, types];
};

const mapGroup = (list) => (keyFn) => (mapFn) => {
  const [grouped, types] = group(list)(keyFn);
  return types.map((type) => mapFn(type, grouped, list));
};

export default class FaceInsight extends BaseInsight {
  get genderDistribution() {
    return mapGroup(this.values)((face) => face.gender)(
      (gender, grouped, list) => {
        const count = (grouped[gender] || []).length;
        return { name: gender, count };
      },
    );
  }

  get genderPercentages() {
    if (!this.values) {
      return {};
    }

    return mapGroup(this.values)((face) => face.gender)(
      (gender, grouped, list) => {
        const percentage = ((grouped[gender] || []).length / list.length) * 100;
        return { gender, percentage };
      },
    );
  }

  get genderChartData() {
    const values = this.genderDistribution;
    return new Facet({ name: this.key, values });
  }

  get ageDistribution() {
    if (!this.values) {
      return {};
    }

    const distribution = {};

    this.values.forEach((face) => {
      const { age, gender } = face;
      if (!distribution[age]) {
        distribution[age] = {};
      }

      if (!distribution[age][gender]) {
        distribution[age][gender] = 1;
      } else {
        distribution[age][gender] += 1;
      }
    });

    return Object.keys(distribution).map((age) => ({
      age,
      ...distribution[age],
    }));
  }

  get ageChartData() {
    console.log('Age data');
    console.log(this.ageDistribution);

    const values = this.ageDistribution?.map(
      ({ age, gender }) => ({ name: age, count: gender }),
    );

    return new Facet({ name: this.key, values });
  }
}
