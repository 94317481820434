import { mix } from 'mixwith';
import PagedResult from '@/lib/graphql/models/PagedResult';
import { IterableResultMixin, PageInfoMixin, TypedListMixin } from '@/lib/graphql/mixins';
import DeploymentIndexUser from '@/admin/users/models/DeploymentIndexUser';

const DeploymentIndexUserConnection = class DeploymentIndexUserConnection extends mix(
  PagedResult,
).with(
  PageInfoMixin,
  TypedListMixin(DeploymentIndexUser),
  IterableResultMixin,
) {
  get deploymentIndexUsers() {
    return this.items || [];
  }
};

export default DeploymentIndexUserConnection;
