const extractAwardNames = (data) => Object.keys(data || {})
  .map((name) => name.replace(/^total_/, ''));

class AwardsTally {
  tallyData = {};

  /**
   *
   * @type {[string]}
   */
  awardNames = [];

  constructor(data) {
    this.tallyData = data;

    this.awardNames = extractAwardNames(data);
  }

  /**
   *
   * @param awardName
   * @returns {number}
   */
  get(awardName) {
    const withTotal = `total_${awardName}`;

    if (!(withTotal in this.tallyData)) {
      return 0;
    }

    return this.tallyData[withTotal];
  }

  get awardNames() {
    return this.awardNames;
  }

  static fromObject(data) {
    return new AwardsTally(data);
  }
}

export default AwardsTally;
