import faker from 'faker';

const getRandomInt = (min, max) => {
  const upper = max || 10;
  const lower = min || 0;

  return faker.random.number({ min: lower, max: upper });
};

/**
 * Creates a list of objects of random size.
 * @param minElements
 * @param maxElements
 * @returns {function(*=): unknown[]}
 */
const getRandomArray = (minElements, maxElements) => (factory) => {
  const length = getRandomInt(minElements, maxElements);
  return Array.from({ length }, factory);
};

/**
 * Creates a list of random numbers from min to max
 * @param min
 * @param max
 * @returns {[Number]}
 */
const randomIndices = (min, max) => getRandomArray(min, max)(() => getRandomInt(0, max));

const createContributor = () => ({
  contributor: faker.name.findName(),
  avatar: faker.internet.avatar(),
  modifiedDate: faker.date.past(),
  descriptiion: faker.company.bsAdjective(),
});

const createPeople = (number) => {
  const count = number || faker.random.number({ min: 3, max: 10 });
  return getRandomArray(1, count)(createContributor);
};

const randomizeList = (list) => list
  && randomIndices(0, (list?.length - 1)).map((index) => list[index]);

const helper = {
  getRandomInt,
  getRandomArray,
  randomIndices,
  createContributor,
  createPeople,
  randomizeList,
};

export default helper;
