<template>
  <v-menu v-model="openMenu" attach=".deployment_menu_attach" nudge-bottom="40px">
    <v-list>
      <template v-for="index in indexes">
        <v-list-item :class="{ active: isActive(index) }" :disabled="isActive(index)"
          @click="indexSelected(index)" class="brandcolour--text"
          :key="index.deploymentIndexId">{{ index.name}}</v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'DeploymentIndexSelectorMenu',
  props: {
    value: {
      type: Object,
    },
    indexes: {
      type: Array,
      default: () => [],
    },
    showMenu: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    openMenu: {
      get() {
        return this.showMenu;
      },
      set(value) {
        this.$emit('update:showMenu', value);
      },
    },
  },
  methods: {
    isActive(index) {
      return this.value && this.value.deploymentIndexId === index.deploymentIndexId;
    },
    indexSelected(index) {
      this.$emit('index-selected', index);
    },
  },
};
</script>

<style scoped>
.active {
  color: #e64a19;
}
</style>
