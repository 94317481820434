import axios from 'axios';

// Setting up Okta Auth.
import { DiscoveryBusinessClient } from '@/lib/business';
// import FakeGroupsApi from '@/lib/util/api/FakeGroupsApi';
import { discoveryGroupClient } from '@/lib/business/DiscoveryGroupClient';
import QueryResponseClient from '@/lib/business/models/responseGenerator/api/QueryResponseClient';
import DeploymentsClient from '@/lib/deployments/api/DeploymentsClient';
import DashboardClient from '@/lib/dashboards/api/DashboardClient';
import DeploymentIndexClient from '@/lib/deployments/api/DeploymentIndexClient';
import DocumentInsightsClient from '@/lib/documents/DocumentInsightsClient';
import CustomizationsClient from '@/lib/customizations/CustomizationsClient';
import NavMenuItemsFetcher from '@/lib/ui/api/NavMenuItemsFetcher';
import PageActionsService from '@/lib/ui/api/PageActionsService';
import AppObjectInsightsClient from '@/lib/insights/AppObjectInsightsClient';

import AssetAclService from '@/lib/assets/AssetAclService';
import ReportsClient from '@/lib/reports/ReportsClient';
import VideoInsightsService from '@/lib/videos/VideoInsightsService';
// import AssetStreamRestService from '@/documentAssets/client/AssetStreamRestService';
import { starfishHttpClientFactory } from '@/api/starfishHttpClientFactory';

import authProvider from '../setup/auth-provider';
import common from '../config/common';
import AuthApi from './AuthApi';
import SearchIndexApi from './SearchIndexApi';
import { DiscoverySearchClient } from '../lib/search/discovery';
import AwardsClient from '../awards/api/AwardsClient';
import StarfishAssistant from '../openai/lib/StarfishAssistant';

/**
 * The default AxiosInstance creator, when you don't need to customise the instance.
 * @returns {AxiosInstance}
 */
const defaultHttpClientFactory = () => axios.create();

/**
 * Default AxiosInstance. This will be injected to any Service requiring Axios by default unless
 * a custom AxiosInstance creator is specified. See httpClientFactory
 * @type {AxiosInstance}
 */
const api = defaultHttpClientFactory(authProvider(), common);

export default api;

/**
 * Enumeration of all the services that this factory creates.
 * @type {{SEARCH: string, AUTH: string, DISCOVERY: string}}
 */
export const ApiNames = {
  AUTH: 'auth',
  DISCOVERY: 'discovery',
  SEARCH: 'search',
  GROUPS: 'groups',
  DISCOVERY_BUSINESS: 'discoveryBusiness',
  RESPONSE_GENERATOR: 'responseGenerator',
  DEPLOYMENTS: 'deployments',
  DASHBOARD: 'dashboard',
  DEPLOYMENT_INDEXES: 'deploymentIndexes',
  DOCUMENT_INSIGHTS: 'documentInsights',
  CUSTOMIZATION: 'customizations',
  NAVIGATION: 'navigation',
  PAGE_ACTIONS: 'pageActions',
  ASSET_ACL: 'assetAclService',
  VIDEO_INSIGHTS: 'videoInsights',
  AWARDS: 'awards',
  REPORTS: 'reports',
  APPOBJECT_INSIGHTS: 'appObjectInsights',
  ASSISTANT: 'assistant',
  HTTP: 'http',
  ASSET_STREAM_REST: 'assetStreamRest',
};

/**
 * We need axios for various purpose.
 * 1. Connecting with the .net API
 * 2. Azure function directly
 * for each of these purposes, you need to instanciate the axios client differently. Like for
 * talking to .net dicovery api service, you need to attach JWT token in the Authorisation header.
 * For Azure Services, we need to add api-key to the headers. This factory's intension is to create
 * those axios intances based on the need.
 * @param name
 * @returns {AxiosInstance}
 */
export const httpClientFactory = (name) => {
  let httpClient = null;

  switch (name) {
    case ApiNames.DISCOVERY:
    case ApiNames.DISCOVERY_BUSINESS:
    case ApiNames.GROUPS:
    case ApiNames.RESPONSE_GENERATOR:
    case ApiNames.DEPLOYMENTS:
    case ApiNames.DASHBOARD:
    case ApiNames.DEPLOYMENT_INDEXES:
    case ApiNames.DOCUMENT_INSIGHTS:
    case ApiNames.CUSTOMIZATION:
    case ApiNames.NAVIGATION:
    case ApiNames.PAGE_ACTIONS:
    case ApiNames.ASSET_ACL:
    case ApiNames.VIDEO_INSIGHTS:
    case ApiNames.AWARDS:
    case ApiNames.REPORTS:
    case ApiNames.APPOBJECT_INSIGHTS:
    case ApiNames.ASSISTANT:
    case ApiNames.HTTP:
    case ApiNames.ASSET_STREAM_REST:
      httpClient = starfishHttpClientFactory(authProvider(), common);
      break;
    default:
      httpClient = api;
      break;
  }

  return httpClient;
};

const apiRepository = {};

/**
 * A factory object which creates APi service requested by name. Having this factory ensures that,
 * we dont need to go through the Api Service creation everywhere we need one.
 *
 * @type {{getDiscoveryClient(): (DiscoverySearchClient|null), get(*=): (*|null)}}
 */
export const ApiFactory = {

  get(name) {
    let apiInstance = apiRepository[name] || null;

    if (apiInstance) {
      return apiInstance;
    }

    const httpClient = httpClientFactory(name);

    switch (name) {
      /* Creates Okta's Auth service */
      case ApiNames.AUTH:
        apiInstance = new AuthApi(httpClient, authProvider());
        break;

      /* Creates Discovery Search Client for searching through .net Discovery API */
      case ApiNames.DISCOVERY:
        apiInstance = new DiscoverySearchClient(httpClient);
        break;

      /* Creates instance of the search client that talks to azure cognitive search using JS */
      case ApiNames.SEARCH:
        apiInstance = SearchIndexApi(httpClient);
        break;
      case ApiNames.GROUPS:
        apiInstance = discoveryGroupClient(httpClient);
        break;
      case ApiNames.DISCOVERY_BUSINESS:
        apiInstance = new DiscoveryBusinessClient(httpClient);
        break;
      case ApiNames.RESPONSE_GENERATOR:
        apiInstance = new QueryResponseClient(httpClient);
        break;
      case ApiNames.DEPLOYMENTS:
        apiInstance = new DeploymentsClient(httpClient);
        break;
      case ApiNames.DASHBOARD:
        apiInstance = new DashboardClient(httpClient);
        break;
      case ApiNames.DEPLOYMENT_INDEXES:
        apiInstance = new DeploymentIndexClient(httpClient);
        break;
      case ApiNames.DOCUMENT_INSIGHTS:
        apiInstance = new DocumentInsightsClient(httpClient);
        break;
      case ApiNames.CUSTOMIZATION:
        apiInstance = new CustomizationsClient(httpClient);
        break;
      case ApiNames.NAVIGATION:
        apiInstance = new NavMenuItemsFetcher(httpClient);
        break;
      case ApiNames.PAGE_ACTIONS:
        apiInstance = new PageActionsService(httpClient);
        break;
      case ApiNames.ASSET_ACL:
        apiInstance = new AssetAclService(httpClient);
        break;
      case ApiNames.VIDEO_INSIGHTS:
        apiInstance = new VideoInsightsService(httpClient);
        break;
      case ApiNames.AWARDS:
        apiInstance = new AwardsClient(httpClient);
        break;
      case ApiNames.REPORTS:
        apiInstance = new ReportsClient(httpClient);
        break;
      case ApiNames.APPOBJECT_INSIGHTS:
        apiInstance = new AppObjectInsightsClient(httpClient);
        break;
      case ApiNames.ASSISTANT:
        apiInstance = new StarfishAssistant(httpClient);
        break;
      default:
        return httpClient;
    }

    apiRepository[name] = apiInstance;
    return apiInstance;
  },

  /**
   *
   * @returns {DiscoverySearchClient|null}
   */
  getDiscoveryClient() {
    return this.get(ApiNames.DISCOVERY);
  },

  /**
   *
   * @returns {*|AxiosInstance}
   */
  getSearchIndexClient() {
    return this.get(ApiNames.SEARCH);
  },

  /**
   *
   * @returns {*|DiscoveryGroupClient}
   */
  getGroupsApi() {
    return this.get(ApiNames.GROUPS);
  },

  /**
   *
   * @returns {*|DiscoveryBusinessClient}
   */
  getBusinessApi() {
    return this.get(ApiNames.DISCOVERY_BUSINESS);
  },

  /**
   *
   * @returns {*|DiscoveryGroupClient}
   */
  getDiscoveryGroupClient() {
    return this.get(ApiNames.GROUPS);
  },

  /**
   *
   * @returns {*|QueryResponseClient}
   */
  getQueryResponseClient() {
    return this.get(ApiNames.RESPONSE_GENERATOR);
  },

  /**
   *
   * @returns {*|DeploymentsClient}
   */
  getDeploymentsClient() {
    return this.get(ApiNames.DEPLOYMENTS);
  },

  /**
   *
   * @returns {*|DashboardClient}
   */
  getDashboardClient() {
    return this.get(ApiNames.DASHBOARD);
  },

  /**
   *
   * @returns {*|DeploymentIndexClient}
   */
  getDeploymentIndexClient() {
    return this.get(ApiNames.DEPLOYMENT_INDEXES);
  },

  /**
   *
   * @returns {DocumentInsightsClient}
   */
  getDocumentInightsClient() {
    return this.get(ApiNames.DOCUMENT_INSIGHTS);
  },

  /**
   *
   * @returns {CustomizationsClient}
   */
  getCustomizationsClient() {
    return this.get(ApiNames.CUSTOMIZATION);
  },

  /**
   *
   * @returns {NavMenuItemsFetcher}
   */
  getNavMenuFetcher() {
    return this.get(ApiNames.NAVIGATION);
  },

  /**
   *
   * @returns {PageActionsService}
   */
  getPageActionsService() {
    return this.get(ApiNames.PAGE_ACTIONS);
  },

  /**
   *
   * @returns {AssetAclService}
   */
  getAssetAclService() {
    return this.get(ApiNames.ASSET_ACL);
  },

  /**
   *
   * @returns {VideoInsightsService}
   */
  getVideoInsightsService() {
    return this.get(ApiNames.VIDEO_INSIGHTS);
  },

  /**
   *
   * @returns {AwardsClient}
   */
  getAwardsClient() {
    return this.get(ApiNames.AWARDS);
  },

  /**
   *
   * @returns {ReportsClient}
   */
  getReportsClient() {
    return this.get(ApiNames.REPORTS);
  },

  getAppObjectInsightsClient() {
    return this.get(ApiNames.APPOBJECT_INSIGHTS);
  },

  /**
   *
   * @returns {StarfishAssistant}
   */
  getStarfishAssistant() {
    return this.get(ApiNames.ASSISTANT);
  },

  getAssetStreamRestApiService() {
    return this.get(ApiNames.ASSET_STREAM_REST);
  },
};
