import Vue from 'vue';
// import Asset from '../models/Asset';

const alphabetizeList = (list) => list.sort((a, b) => {
  if (a.name < b.name) { return -1; }
  if (a.name > b.name) { return 1; }
  return 0;
});

export default {
  setCollections(state, data) {
    state.collections = alphabetizeList(data);
  },
  setMetadataSets(state, data) {
    state.metadataSets = alphabetizeList(data);
  },
  setDeploymentMetadataSet(state, data) {
    state.deploymentMetadataSet = data;
  },
  setIndexMetadataSet(state, data) {
    state.indexMetadataSet = data;
  },
  setSelectedSubsetId(state, data) {
    state.selectedSubsetId = data;
  },
  setSelectedMasterSetId(state, data) {
    state.selectedMasterSetId = data;
  },
  setMetadataSetData(state, data) {
    state.metadataSetData = data;
  },
  setSelectedSetFieldValues(state, data) {
    state.selectedSetFieldValues = data;
  },
  resetSelections(state) {
    state.resetSelections = !state.resetSelections;
  },
  setSelectedDocId(state, data) {
    state.selectedDocId = data;
  },
  updateMultipleSelectedSetFieldValues(state, { setFieldValues, setField }) {
    const associativeName = setField.fieldId;
    const sfvWithoutAssociateName = state.selectedSetFieldValues.filter((sfv) => sfv.fieldId !== associativeName);
    state.selectedSetFieldValues = sfvWithoutAssociateName.concat(setFieldValues);
  },
  updateSingleSelectedSetFieldValue(state, { setFieldValue, setField }) {
    const associativeName = setField.fieldId;
    const sfvWithoutAssociateName = state.selectedSetFieldValues.filter((sfv) => sfv.fieldId !== associativeName);
    sfvWithoutAssociateName.push(setFieldValue);
    state.selectedSetFieldValues = sfvWithoutAssociateName;
  },
  setAsset(state, data) {
    state.Asset = data;
  },
  setAssetRelations(state, data) {
    state.AssetRelations = data;
  },
  resetMetadata(state) {
    state.Metadata = JSON.parse(JSON.stringify(state.MetadataModel));
  },
  setMetadata(state, data) {
    state.Metadata = data;
  },
  resetAssetCollections(state) {
    // state.selectedAssets = [];
    // state.AssetCollections = JSON.parse(JSON.stringify(state.AssetCollectionsModel));
    state.uploadFiles = [];
    state.selectedSetFieldValues = [];
  },
  resetAsset(state) {
    // state.Asset = new Asset();
    // // console.log(Asset);
    // state.Asset = JSON.parse(JSON.stringify(state.AssetModel));
    state.uploadFiles = [];
    state.selectedSetFieldValues = [];
    // Vue.set(state, 'selectedSetFieldValues', []);
  },
  setAssetCollections(state, data) {
    state.AssetCollections = data;
  },
  setSelectedAssets(state, data) {
    state.selectedAssets = data;
  },
  setUploaderInitialized(state, data) {
    state.uploaderInitialized = data;
  },
  addUploadFiles(state, data) {
    state.uploadFiles.push(...Array.from(data));
  },
  addUploadPreviewFiles(state, data) {
    state.uploadPreviewFiles.push(...Array.from(data));
  },
  replaceUploadFiles(state, data) {
    state.uploadFiles = data;
  },
  replaceUploadPreviewFiles(state, data) {
    state.uploadPreviewFiles = data;
  },
  addAssetKeyword(state, data) {
    // figure better way for this later
    data.name = data.Keyword.name;
    state.keywords.push(data);
    state.Asset.AssetKeywords.push(data);
  },
  refreshAssetKeyword(state, { oldItem, newItem }) {
    // figure better way for this later !!!!!!

    const index1 = state.Asset.AssetKeywords.findIndex((ca) => ca.name === oldItem.name);
    if (index1 >= 0) state.Asset.AssetKeywords.splice(index1, 1);

    const index2 = state.keywords.findIndex((ca) => ca.name === oldItem.name);
    if (index2 >= 0) state.keywords.splice(index2, 1);

    newItem.name = newItem.Keyword.name;
    state.keywords.push(newItem);
    state.Asset.AssetKeywords.push(newItem);
  },
  addAssetContributor(state, data) {
    // figure better way for this later
    data.name = data.User.name;
    data.givenName = data.User.givenName;
    data.email = data.User.email;
    data.lastName = data.User.lastName;
    state.users.push(data);
    state.Asset.AssetContributors.push(data);
  },
  refreshAssetContributor(state, { oldItem, newItem }) {
    // figure better way for this later !!!!!!

    const index1 = state.Asset.AssetContributors.findIndex((ca) => ca.email === oldItem.email);
    if (index1 >= 0) state.Asset.AssetContributors.splice(index1, 1);

    const index2 = state.users.findIndex((ca) => ca.email === oldItem.email);
    if (index2 >= 0) state.users.splice(index2, 1);

    newItem.name = newItem.User.name;
    newItem.givenName = newItem.User.givenName;
    newItem.lastName = newItem.User.lastName;
    newItem.email = newItem.User.email;
    state.users.push(newItem);
    state.Asset.AssetContributors.push(newItem);
  },
  setAssetDefaultCollection(state, selectedCollection) {
    const uploaderCollection = state.collections.find((c) => c.collectionId
      === selectedCollection.collectionId);

    state.Asset.CollectionAssets.push(uploaderCollection);
  },
  setPrimaryAuthor(state, data) {
    if (data) {
      data.IsPrimary = true;
    }
    state.primaryAuthor = data;
  },
  setNonPrimaryContributors(state, data) {
    state.nonPrimaryContributors = data;
  },
  setClientOrganizations(state, data) {
    state.clientOrganizations = data;
  },
  setRespondingOrganizations(state, data) {
    state.respondingOrganizations = data;
  },
  setPartnerOrganizations(state, data) {
    state.partnerOrganizations = data;
  },
  setAssetCountries(state, data) {
    state.AssetRelations.AssetCountries = data;
  },
  setUploadToCollectionFlag(state, uploadToCollection) {
    state.uploadToCollection = uploadToCollection;
  },

  addAssetInfo(state, data) {
    // console.log('push assetInfos');
    // console.log(state);
    // console.log(data);

    state.assetInfos.push(data);
  },
  clearAssetInfos(state) {
    state.assetInfos = [];
  },
  clearPreviewAssetInfos(state) {
    state.previewAssetInfos = [];
  },
  resetUploader(state) {
    state.assetInfos = [];
    state.uploadFiles = [];
    state.previewAssetInfos = [];
    state.uploadPreviewFiles = [];
    state.uploadedAmount = 0;
    state.uploadedProgress = 0;
  },
  resetClient(state) {
    state.Asset.AssetCampaigns = [];
    // Vue.set(state.Asset, 'AssetCampaigns', []);
  },
  setEditMetatadataAssetInfo(state, data) {
    state.editMetatadataAssetInfo = data;
  },
  updateProgress(state, data) {
    const { isPreview } = data;
    const { filename } = data;

    const fileInfo = state.assetInfos.find((ai) => ai.OriginalFileName === filename);

    fileInfo.loadedBytes = data.event.loadedBytes;
    fileInfo.loadedPercentage = (fileInfo.loadedBytes / fileInfo.filesize) * 100;

    if (fileInfo.filesize > 0 && fileInfo.filesize === fileInfo.loadedBytes) {
      fileInfo.status = 'Uploaded';
      fileInfo.status_display = 'Uploaded';
    }
  },
  updatePreviewProgress(state, data) {
    const { isPreview } = data;
    const { filename } = data;

    const fileInfo = state.previewAssetInfos.find((pai) => pai.OriginalFileName === filename);
    fileInfo.loadedBytes = data.event.loadedBytes;
    fileInfo.loadedPercentage = (fileInfo.loadedBytes / fileInfo.filesize) * 100;
    if (fileInfo.filesize > 0 && fileInfo.filesize === fileInfo.loadedBytes) {
      fileInfo.status = 'Uploaded';
      fileInfo.status_display = 'Uploaded';
    }

    // console.log('foundFileInfo 1');
    let foundFileInfo;
    // foundFileInfo = rootState.uploader.assetInfos.flatMap((ai) => ai.FoundPreviewAssetInfos)?.find((pai) => pai.OriginalFileName === data.filename);
    state.assetInfos.forEach((ai) => {
      ai.FoundPreviewAssetInfos.forEach((fpai) => { 
        if (fpai.OriginalFileName === filename) {
        // if (fpai.ParentUniqueId === ai.UniqueId && fpai.OriginalFileName === filename) {
          foundFileInfo = fpai; 
        }
      });
    });
    // console.log('foundFileInfo 2');
    // console.log(JSON.stringify(foundFileInfo));
    
    foundFileInfo.loadedBytes = data.event.loadedBytes;
    foundFileInfo.loadedPercentage = (foundFileInfo.loadedBytes / foundFileInfo.filesize) * 100;
    if (foundFileInfo.filesize > 0 && foundFileInfo.filesize === foundFileInfo.loadedBytes) {
      // console.log('foundFileInfo finallllllll 3');
      foundFileInfo.status = 'Uploaded';
      foundFileInfo.status_display = 'Uploaded';
    }
  },
  updateProgressMessage(state) {
    state.totalFilesize = 0;
    state.totalUploadedBytes = 0;
    if (state.assetInfos) {
      state.assetInfos.forEach((fi) => {
        state.totalFilesize += fi.filesize;
        state.totalUploadedBytes += fi.loadedBytes;
      });
    }
    if (state.previewAssetInfos) {
      state.previewAssetInfos.forEach((pai) => {
        state.totalFilesize += pai.filesize;
        state.totalUploadedBytes += pai.loadedBytes;
      });
    }
    state.totalProgress = ((state.totalUploadedBytes / state.totalFilesize) * 100).toFixed(1);
    // if (state.numberUploadInProgresss > 0) {
    //   commit('common/setConsoleBusy', { busy: true, message: `Uploading assets... ${totalProgress}%.` }, { root: true });
    // }
  },
  updateAssetInfo(state, assetInfo) {
    const masterAssetInfo = state.assetInfos.find((ai) => ai.UniqueId === assetInfo.UniqueId);
    masterAssetInfo.BlobName = assetInfo.BlobName;
    masterAssetInfo.FileName = assetInfo.FileName;
    masterAssetInfo.StoragePath = assetInfo.StoragePath;
    masterAssetInfo.status_display = assetInfo.status_display;

    masterAssetInfo.AssetId = assetInfo.AssetId;
    masterAssetInfo.FileName = assetInfo.FileName;
    masterAssetInfo.Status = assetInfo.Status;
    masterAssetInfo.DocId = assetInfo.DocId;
    masterAssetInfo.IsVideo = assetInfo.IsVideo;
    masterAssetInfo.Error += assetInfo.Error;
    masterAssetInfo.HasError = assetInfo.HasError;
    masterAssetInfo.FoundPreviewAssetInfos = assetInfo.FoundPreviewAssetInfos;

    masterAssetInfo.ShortLivedSas = assetInfo.ShortLivedSas;
  },
  updatePreviewAssetInfo(state, previewAssetInfo) {
    const masterAssetInfo = state.previewAssetInfos.find((pai) => pai.UniqueId === previewAssetInfo.UniqueId);
    masterAssetInfo.BlobName = previewAssetInfo.BlobName;
    masterAssetInfo.FileName = previewAssetInfo.FileName;
    masterAssetInfo.StoragePath = previewAssetInfo.StoragePath;
    masterAssetInfo.status_display = previewAssetInfo.status_display;
    masterAssetInfo.HasError = previewAssetInfo.HasError;
    masterAssetInfo.Error += previewAssetInfo.Error;
    masterAssetInfo.ShortLivedSas = previewAssetInfo.ShortLivedSas;

    state.assetInfos.forEach((ai) => {
      if (ai.FoundPreviewAssetInfos) {
        ai.FoundPreviewAssetInfos.forEach((fpai) => { 
          if (fpai.OriginalFileName === previewAssetInfo.OriginalFileName) {
            // if (fpai.ParentUniqueId === ai.UniqueId && fpai.OriginalFileName === filename) {
            fpai.BlobName = previewAssetInfo.BlobName;
            fpai.FileName = previewAssetInfo.FileName;
            fpai.StoragePath = previewAssetInfo.StoragePath;
            fpai.status_display = previewAssetInfo.status_display;
            fpai.HasError = previewAssetInfo.HasError;
            fpai.Error += previewAssetInfo.Error;
            fpai.ShortLivedSas = previewAssetInfo.ShortLivedSas;
          }
        });
      }
    });
  },
  setUploaderPanelOpened(state, val) {
    state.uploaderPanelOpened = val;
  },
};
