import Role from '@/lib/business/models/Role';
import modelListFactory from '@/lib/business/models/helper';
import User from '@/lib/business/models/User';
import DeploymentIndexFilter from '@/lib/business/models/DeploymentIndexFilter';

class CollectionFormInput {
  /**
   *
   * @type {[Role]}
   */
  roles = [];

  /**
   *
   * @type {[User]}
   */
  users = [];

  /**
   *
   * @type {[Filter]}
   */
  filters = [];

  isFetched = false;

  constructor(props) {
    if (props != null) {
      this.roles = Role.createList(props.roles);
      this.users = modelListFactory(User.factory())(props.users);
      this.filters = props.filters;
      this.isFetched = true;
    }
  }
}

export default CollectionFormInput;
