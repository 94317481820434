class DiscoveryDialogOptions {
  /**
   *
   * @type {string}
   */
  dialogType = '';

  /**
   *
   * @type {*}
   */
  item = null;

  /**
   *
   * @type {Function}
   */
  onClosed = null;

  /**
   *
   * @param dialogType{string}
   * @param item{*}
   * @param onClosed{({*}) => * }
   */
  constructor(dialogType, item, onClosed) {
    this.dialogType = dialogType;
    this.item = item;
    this.onClosed = onClosed;
  }
}

export default DiscoveryDialogOptions;
