import gql from 'graphql-tag';
import { pageInfo } from '@/lib/graphql/fragments';
import deploymentFields from './fragments';

const deploymentTreeQueries = gql`
    query getDeploymentsForTree {
      deployments {
        pageInfo {
          ...pageInfoFields
        }
        nodes {
          ...deploymentTreeFields
        }
      }
    }
    ${deploymentFields.deploymentsTreeFields}
    ${pageInfo}
`;

const deploymentQueries = {
  deploymentTreeQueries,
};

export default deploymentQueries;
