import PaginationInput from '@/lib/graphql/models/PaginationInput';

const PageInfoMixin = (superClass) => class extends superClass {
  get hasPreviousPage() {
    if (super.hasPreviousPage) return super.hasPreviousPage;

    return this.pageInfo?.hasPreviousPage;
  }

  get hasNextPage() {
    if (super.hasNextPage) return super.hasNextPage;

    return this.pageInfo?.hasNextPage;
  }

  get endCursor() {
    if (super.endCursor) return super.endCursor;

    return this.pageInfo?.endCursor;
  }

  get startCursor() {
    if (super.startCursor) return super.startCursor;

    return this.pageInfo?.startCursor;
  }

  get totalCount() {
    if (super.totalCount) return super.totalCount;
    return 0;
  }

  /**
   * Returns parameters to fetch the next page
   * @param recordsCount
   * @returns {PaginationInput}
   */
  nextPage(recordsCount) {
    const pageInput = new PaginationInput();
    pageInput.after = this.endCursor;

    if (recordsCount || recordsCount > 0) {
      pageInput.first = recordsCount;
    }

    return pageInput;
  }

  /**
   * Returns paramenters to fetch the last page
   * @param recordsCount
   * @returns {PaginationInput}
   */
  previousPage(recordsCount) {
    const pageInput = new PaginationInput();
    pageInput.before = this.startCursor;

    if (recordsCount || recordsCount > 0) {
      pageInput.last = recordsCount;
    }

    return pageInput;
  }

  /**
   * Translates the cursor based pagination to offset based one.
   * @param pageNumber
   * @param recordsPerPage
   * @returns {PaginationInput}
   */
  toPage(pageNumber, recordsPerPage = 10) {
    if (pageNumber <= 0) {
      return new PaginationInput({ first: recordsPerPage });
    }

    const lastRecord = pageNumber * recordsPerPage;

    /* if you're towards the end of the list, we might not need the whole recordsPerPage */
    if (lastRecord >= this.totalCount) {
      const recordsToFetch = this.totalCount - ((pageNumber - 1) * recordsPerPage);
      return new PaginationInput({ last: recordsToFetch });
    }

    return new PaginationInput({ first: lastRecord, last: recordsPerPage });
  }

  /**
   * Calculates the number of pages required to show the whole data based on the number of
   * records displayed per page
   * @param recordsPerPage
   * @returns {number}
   */
  countPages(recordsPerPage) {
    if (this.totalCount === 0 || recordsPerPage === 0) return 0;

    const pages = Math.floor(this.totalCount / recordsPerPage);

    /* having a remainder means we have more records. if remainder is 0 return pages */
    if (this.totalCount % recordsPerPage === 0) {
      return pages;
    }

    /* we need an extra page to show the records */
    return pages + 1;
  }
};

export default PageInfoMixin;
