class AssetThumbnailFetcher {
  /**
   *
   * @type {AxiosInstance}
   */
  http = null;

  url = 'v2/assets/thumbnails'

  constructor(http) {
    this.http = http;
  }

  async fetchThumbnails(documents, thumbnailType) {
    const docIds = documents.map((doc) => doc.document.id);
    const input = {
      docIds,
      thumbnailType,
    };

    const { data } = await this.http.post(this.url, input);

    const { thumbnails } = data;

    documents.forEach((doc) => {
      const url = thumbnails[doc.id] || '';
      doc.previewUrl = url;
      doc.isLoading = false;
    });

    return documents;
  }
}

export default AssetThumbnailFetcher;
