import InsightsBubbleChart
from '@/lib/search/discovery/classes/insights/charting/InsightsBubbleChart';

const BubbleChartMixin = (superClass) => class extends superClass {
  #chart = null;

  createBubbleChart(content) {
    if (this.#chart != null) {
      return this.#chart;
    }

    this.#chart = new InsightsBubbleChart(this.key, this.name, this.values, content);
    return this.#chart;
  }
};

export default BubbleChartMixin;
