class FilterValue {
  fieldCode = '';

  fieldValue = '';

  fieldValueOrder = '';

  selected = '';

  constructor(data) {
    this.fieldCode = data.fieldCode;
    this.fieldValue = data.fieldValue;
    this.fieldValueOrder = data.fieldValueOrder;
    this.selected = data.selected;
  }

  get key() {
    return this.fieldCode;
  }

  get value() {
    return this.fieldValue;
  }

  /**
   *
   * @param that{FilterValue}
   */
  equals(that) {
    return (this.fieldCode === that.fieldCode && this.fieldValue === that.fieldValue);
  }

  static fromObject(data) {
    return new FilterValue(data);
  }

  static fromList(list) {
    return list?.map(FilterValue.fromObject) || [];
  }
}

export default FilterValue;
