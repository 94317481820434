class EntityAssignmentInput {
  /**
   *
   * @type {[int]}
   */
  userIds = [];

  /**
   *
   * @type {[EntityAssignmentInputRow]}
   */
  assignments = [];

  constructor(userIds, assignments) {
    this.userIds = userIds;
    this.assignments = assignments;
  }

  /**
   *
   * @param assignments{EntityAssignmentResult}
   */
  static fromEntityAssignmentResult(assignments, roleCode) {
    console.log(assignments);
    const userIds = assignments.users.map((user) => user.userId);

    console.log('user ids', userIds);

    const assignmentsInput = [{ entityId: assignments.entityId, roleCode }];

    return new EntityAssignmentInput(userIds, assignmentsInput);
  }
}

export default EntityAssignmentInput;
