import { mapActions, mapState, mapMutations } from 'vuex';
import { RightPanels } from '@/lib/ui/panels/RightPanelFactory';

export default {
  computed: {
    ...mapState('assistant', [
      'conversation',
    ]),
    ...mapState('discovery', [
      'currentDocument',
    ]),
    ...mapState('common', [
      'indexPermissions',
    ]),

    showOpenAi() {
      return (this.indexPermissions.canDo('viewOpenAi') || this.indexPermissions.canDo('viewAiInteract'));
    },
    currentDocId() {
      return this.currentDocument.id;
    },
  },
  methods: {
    ...mapMutations('assistant', [
      'resetConversation',
      'resetInsightsConversation',
      'resetSummaryConversation',
      'setAssistantMessageLoadingStatus',
      'setPenalTriggeredFrom',
    ]),
    ...mapActions('assistant', [
      'includeContentMessage',
      'getSuggestions',
      'generateInsightsSummary',
      'summarize',
    ]),
    ...mapActions('common', [
      'openPanel',
    ]),

    async openAskBot() {
      this.resetConversation();
      this.setPenalTriggeredFrom('analyzer');
      this.setAssistantMessageLoadingStatus(true);
      this.openPanel(RightPanels.ASK_BOT);
      this.includeContentMessage(this.currentDocId);
      await this.getSuggestions(this.conversation);
      this.setAssistantMessageLoadingStatus(false);
    },
    async showSummaryPanel() {
      this.resetSummaryConversation();
      this.setAssistantMessageLoadingStatus(true);
      this.openPanel(RightPanels.AI_CONTENT_SUMMARY);
      await this.summarize(this.currentDocId);
      this.setAssistantMessageLoadingStatus(false);
    },
    async showAutomatedInsightsPanel() {
      this.resetInsightsConversation();
      this.setAssistantMessageLoadingStatus(true);
      this.openPanel(RightPanels.AUTOMATED_INSIGHTS);
      await this.generateInsightsSummary(this.currentDocId);
      this.setAssistantMessageLoadingStatus(false);
    },
    openSubmenu(functionName) {
      this.$refs.parentMenuRef.isActive = false;
      this[functionName]();
    },
    openAIThemeExtractor() {
      this.openPanel(RightPanels.AI_THEME_EXTRATOR);
    },
    openAIToneExtractor() {
      this.openPanel(RightPanels.AI_TONE_EXTRATOR);
    },
    openPreviewAnalyzer() {
      this.openPanel(RightPanels.AI_PREVIEW_ANALYZER);
    },
    openAICaseStudy() {
      this.openPanel(RightPanels.AI_WRITING_ASSISTANT_CASE_STUDY);
    },
    openAISlidePresentation() {
      this.openPanel(RightPanels.AI_SLIDE_PRESENTATION);
    },
    openAINewsletter() {
      this.openPanel(RightPanels.AI_NEWSLETTER);
    },
    openAISocialPost() {
      this.openPanel(RightPanels.AI_SOCIAL_POST);
    },
    openAltTagGenerator() {
      this.openPanel(RightPanels.AI_IMAGE_ALT_TAG_GENERATOR);
    },
    openImageCaptionGenerator() {
      this.openPanel(RightPanels.AI_IMAGE_CAPTION_GENERATOR);
    },
  },
};
