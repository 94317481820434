import AssetMetrics from '@/lib/search/discovery/classes/charting/AssetMetrics';

class AssetEngagementInsight {
  /**
   *
   * @type {AssetMetrics}
   */
  topLiked = null;

  /**
   *
   * @type {AssetMetrics}
   */
  topViewed = null;

  /**
   *
   * @type {AssetMetrics}
   */
  topDownloaded = null;

  /**
   * @type {Number}
   */
  totalViews = 0;

  /**
   * @type {Number}
   */
  totalDownloads = 0;

  /**
   * @type {Number}
   */
  totalLikes = 0;

  /**
   *
   * @param topLiked{AssetMetrics}
   * @param topViewed{AssetMetrics}
   * @param topDownloaded{AssetMetrics}
   */
  constructor(topLiked = null, topViewed = null, topDownloaded = null, totalDownloads = 0, totalLikes = 0, totalViews = 0) {
    this.topLiked = topLiked;
    this.topDownloaded = topDownloaded;
    this.topViewed = topViewed;
    this.totalLikes = totalLikes;
    this.totalDownloads = totalDownloads;
    this.totalViews = totalViews;
  }

  get insights() {
    return [this.topViewed, this.topLiked, this.topDownloaded];
  }

  get totalEngagementCount() {
    return this.totalDownloads + this.totalViews + this.totalLikes;
  }

  static fromObject(insight) {
    if (insight == null) {
      return new AssetEngagementInsight();
    }

    const {
      topViewed,
      topLiked,
      topDownloaded,
      summarisedAssetInsightsResult,
    } = insight;

    const { totalDownloads, totalLikes, totalViews } = summarisedAssetInsightsResult;

    const viewedMetrics = AssetMetrics.fromList('topViewed', 'Top Viewed', topViewed);
    const likedMetrics = AssetMetrics.fromList('topLiked', 'Top Liked', topLiked);
    const downloadedMetrics = AssetMetrics.fromList('topDownloaded', 'Top Downloaded', topDownloaded);

    return new AssetEngagementInsight(likedMetrics, viewedMetrics, downloadedMetrics, totalDownloads, totalLikes, totalViews);
  }
}

export default AssetEngagementInsight;
