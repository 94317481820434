import { userFactory } from '@/lib/business/models/User';
import Role from '@/lib/business/models/Role';

class CollectionUser {
  /**
   *
   * @type {User}
   */
  user = null;

  /**
   *
   * @type {Role}
   */
  role = null;

  constructor(collectionUser) {
    if (collectionUser == null) {
      return;
    }

    this.user = userFactory.create(collectionUser.user);
    this.role = new Role(collectionUser.role);
  }

  get fullName() {
    return this.user.fullName;
  }

  get email() {
    return this.user.email;
  }

  static createList(list) {
    return list?.map((collectionUser) => new CollectionUser(collectionUser));
  }
}

export default CollectionUser;
