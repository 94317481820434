/**
 * The purpose of this mixins is to convert a complex result object into iterable.
 * @param superClass
 * @returns {{new(): {}, prototype: {}}}
 * @constructor
 */
const IterableResultMixin = (superClass) => class extends superClass {
  * [Symbol.iterator]() {
    const items = this.items || [];
    for (let i = 0; i < items.length; i += 1) {
      yield items[i];
    }
  }
};

export default IterableResultMixin;
