export default class ContentHighlighter {
  text = '';

  phrase = '';

  occurences = [];

  count = 0;

  currentOccurence = null;

  #currentOccurenceIndex = -1;

  hasContentToHighlight = false;

  /**
   *
   * @param text {String}
   * @param phrase {String}
   * @param occurences {[String]}
   */
  constructor(text, phrase, occurences) {
    this.text = text;
    this.phrase = phrase;
    this.count = occurences?.length;
    this.occurences = occurences;

    this.hasContentToHighlight = text && text.trim() !== '';

    if (this.count > 0) {
      this.currentOccurenceIndex = 0;
    }
  }

  get html() {
    return this.text;
  }

  get occurenceAndCount() {
    return this.phrase && this.count && `${this.phrase} (${this.count})`;
  }

  get currentOccurenceIndex() {
    return this.#currentOccurenceIndex;
  }

  set currentOccurenceIndex(index) {
    console.log(`Setting current occurence index ${index}`);
    this.#currentOccurenceIndex = index;

    if (index > -1 && this.occurences.length > 0) {
      this.currentOccurence = this.occurences[index];
      console.log(this.currentOccurence);
    }
  }

  next() {
    if (this.#currentOccurenceIndex < this.count - 1) {
      this.currentOccurenceIndex = this.#currentOccurenceIndex + 1;
    }
  }

  previous() {
    if (this.#currentOccurenceIndex > 0) {
      this.currentOccurenceIndex = this.#currentOccurenceIndex - 1;
    }
  }

  get hasNext() {
    return this.count > 0 && this.currentOccurenceIndex < (this.count - 1);
  }

  get hasPrevious() {
    return this.count > 0 && this.currentOccurenceIndex >= 0;
  }
}

export const highlight = (content) => (phrase) => {
  console.log('Highlighter start', new Date());
  if (!phrase || phrase.trim() === '') {
    return new ContentHighlighter(content);
  }

  const regExp = new RegExp(phrase, 'ig');
  let index = -1;
  const occurences = [];
  const className = phrase.replace(/[^a-zA-Z ]/g, ' ').replace(/\s+/g, '_');

  const replacedText = content && content.replace(regExp, (str, matchIndex) => {
    index += 1;
    const id = `${className}-${index}`;
    occurences.push({ id, matchIndex });

    return `<span id="${id}" class="highlighted-text">${str}</span>`;
  });

  console.log('Highlighter ends', new Date());
  return new ContentHighlighter(replacedText, phrase, occurences);
};
