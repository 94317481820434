import { of, from, map } from 'rxjs';
import Asset from '@/lib/business/models/Asset';
import FetchAssetDetailsChoices from '@/lib/business/models/FetchAssetDetailsChoices';
import {
  documentsWithDetailsFactory,
  documentWithUrlsFactory,
} from '@/lib/search/discovery/classes/documentWithUrls';
import DiscoverySearchResult from './discovery/classes/discoverySearchResult';

class SearchClient {
  /**
   *
   * @type {AxiosInstance}
   */
  http = null;

  /**
   *
   * @type {string}
   */
  endpoint = null;

  /**
   *
   * @param http{AxiosInstance}
   */
  constructor(http) {
    this.http = http;
  }

  /**
   *
   * @param endpoint{string}
   */
  setEndPoint(endpoint) {
    this.endpoint = endpoint;
    return this;
  }

  /**
   *
   * @param searchParams
   * @returns {Promise<DiscoverySearchResult>}
   */
  async search(searchParams) {
    try {
      const { data } = await this.http.post(
        this.endpoint,
        searchParams.toQuery(),
        {
          params: {
            fetchThumbnails: true,
            thumbnailType: 'default',
            fetchInteractionMetrics: false,
            fetchVisibility: false,
          },
        },
      );
      const result = new DiscoverySearchResult(data);

      const choices = new FetchAssetDetailsChoices(false, 'default', true, true);
      const documents = documentsWithDetailsFactory(data.documents, choices);

      result.documentResult.contents = documents;
      result.assetDataLoaded = true;
      // this.getAssetsMatchingDocIds(result).subscribe();

      return result;
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data?.error;
        throw new Error(errorMessage);
      } else {
        // Something happened in setting up the request and triggered an Error
        throw new Error(error.message);
      }
    }
  }

  /**
   *
   * @param searchResult {DiscoverySearchResult}
   */
  getAssetsMatchingDocIds(searchResult) {
    const contents = searchResult?.documentResult?.contents;

    if (!contents || contents.length <= 0) {
      return of(contents);
    }

    const docIds = contents.map((content) => content.docId);
    const answerKeys = searchResult.answers?.map((a) => a.key) || [];

    const assetIds = [...new Set([...docIds, ...answerKeys])];
    const observable = from(this.http.post('v2/insights/assetsWithDocIds', assetIds))
      .pipe(map(({ data }) => {
        const cache = {};
        contents.forEach((content) => {
          const docId = (content.docId || '').toLowerCase();
          const asset = data[docId] || null;
          const assetModel = new Asset(asset);
          cache[docId] = assetModel;
          content.setAsset(cache[docId]);
        });

        searchResult.assetsRepository = cache;
        searchResult.answers?.forEach((answer) => {
          const { key } = answer;
          if (!(key in cache)) {
            cache[key] = new Asset(data[key] || null);
          }

          answer.asset = cache[key];
        });
        searchResult.assetDataLoaded = true;
        return contents;
      }));

    return observable;
  }
}

export default SearchClient;
