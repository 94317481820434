const Answer = function Answer({ key, text, highlights }) {
  this.key = key;
  this.text = text;
  this.highlights = highlights;

  /**
   *
   * @type {Asset}
   */
  this.asset = null;
};

Object.defineProperty(Answer.prototype, 'rawLocation', {
  get() {
    return { name: 'content-page', params: { id: this.key } };
  },
});
export default Answer;
