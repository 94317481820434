class FeaturedGroup {
  id = 0;

  name = '';

  thumbnail = '';

  groupId = 0;

  constructor(id = 0, name = '', thumbnailUrl = '') {
    this.id = id;
    this.name = name;
    this.thumbnail = thumbnailUrl;
    this.groupId = id;
  }

  get rawLocation() {
    return { name: 'group-details-collections', params: { id: this.id } };
  }

  shareableObject() {
    return {
      name: this.name,
      type: 'Group',
      location: this.rawLocation,
    };
  }

  /**
   *
   * @param id
   * @param name
   * @param thumbnail
   * @returns {FeaturedGroup}
   */
  static fromObject({ id = 0, name = '', thumbnail = '' }) {
    return new FeaturedGroup(id, name, thumbnail);
  }

  /**
   *
   * @param list
   * @returns {FeaturedGroup[]}
   */
  static fromList(list) {
    const factory = FeaturedGroup.fromObject;

    return (list || []).map(factory);
  }
}

export default FeaturedGroup;
