import GraphQlClient from '@/lib/graphql/models/GraphQlClient';
import deploymentQueries from '@/admin/deployments/graphql/queries';
import DeploymentTreeConnection from '@/admin/deployments/models/DeploymentTreeConnection';

class DeploymentsClient extends GraphQlClient {
  async getDeployments(query, pageInput) {
    const selectedQuery = query || deploymentQueries.deploymentTreeQueries;
    const variables = {
      ...pageInput,
    };

    const { deployments } = await this.query(selectedQuery, variables);
    const result = new DeploymentTreeConnection(deployments);
    console.log('%cGOT DEPLOYMENTS', 'color:orange');
    console.log(result.indices);
    return result;
  }
}

export default DeploymentsClient;

export const deploymentsClient = new DeploymentsClient();
