<template>
<v-bottom-sheet :value="consoleBusy" persistent scrollable>
  <v-expand-transition mode="out-in">
      <v-card color="#f5f2ea" style="position:relative;padding-bottom:200px;" v-show="showActivity">
        <v-card-text class="pa-0 overflow-x-hidden" style="max-height:calc(100vh - 200px);">
          <v-row align="center" justify="center">
              <v-sheet max-width="840px" color="transparent" class="pa-5">
                <v-row style="color:#c58a28" align="end" justify="start" class="flex-column">
                  <upload-file-list></upload-file-list>
                </v-row>
                <div v-show="errorLength > 0" style="color:#c58a28">
            <ul class="overflow-y-auto my-4 upload_other_errors">
              <li v-for="busyError in busyErrors" :key="busyError">
                {{ busyError }}
              </li>
            </ul>
          </div>
              </v-sheet>
          </v-row>
          
        </v-card-text>
      </v-card>
    </v-expand-transition>
    <v-sheet height="200px" :color="consoleClass" style="position:absolute;width:100%;bottom:0;" class="font-weight-bold consolesheetwrapper">

        <v-row align="center" justify="center" style="height:200px;">

            <!-- <v-sheet :max-width="$vuetify.breakpoint.smAndUp ? '800px' : '90vw'" color="transparent">-->
              <v-sheet :width="$vuetify.breakpoint.mdAndUp ? '800px' : '93vw'" color="transparent">
              <v-row  align="center" justify="start" :class="finishedUploadingUI ? 'consolesuccesstext--text' : 'consolebusytext--text'">
              <v-col :class="{'col-12': $vuetify.breakpoint.xs}">
              <div class="mb-3 appbusymessage" v-html="appBusyMessage"></div>

              <v-btn
        class="mr-2 busybutton"
          v-for="button in appBusyButtons"
          :key="button.label"
          :disabled="button.disabled"
          tile
          outlined
          color="consolesuccesstext"
          @click.stop
          @click.prevent
          @click="dispatchAction(button.action)">{{button.label}}</v-btn>
              </v-col>
              <v-col cols="6" sm="auto" class="flex-grow-0 flex-shrink-0 flex-shrink-1">
                <v-btn
                  class="showactivitytoggle"
                  tile
                  outlined
                  :color="finishedUploadingUI ? 'consolesuccesstext' : 'consolebusytext'"
                  :block="$vuetify.breakpoint.xs"
                  @click="showActivity = ! showActivity">Show Activity</v-btn>
              </v-col>
              <!--<v-col cols="6" sm="auto" class="flex-grow-0 flex-shrink-1">
                <v-btn
                  :absolute="$vuetify.breakpoint.smAndUp"
                  :top="$vuetify.breakpoint.smAndUp"
                  :right="$vuetify.breakpoint.smAndUp"

                  tile
                  :text="$vuetify.breakpoint.smAndUp"
                  :outlined="$vuetify.breakpoint.xs"
                  :block="$vuetify.breakpoint.xs"
                  color="#c58a28"
                  :class="{'font-weight-bold' : $vuetify.breakpoint.smAndUp}"
                  >Close
                </v-btn>
              </v-col>-->
            </v-row>
            </v-sheet>
        </v-row>
    </v-sheet>
  </v-bottom-sheet>
    <!-- <v-overlay :value="consoleBusy" opacity="0.9" z-index="2000">
      <v-row align="center" class="flex-column" no-gutters justify="center"
      style="max-width: 50vw;">
        <div style="height: 96px;">
          <div v-show="!showBusyButtons">
            <v-progress-circular
              indeterminate
              size="96"
              color="white"
            ></v-progress-circular>
          </div>
          <div v-show="showBusyButtons">
            <v-icon size="96">mdi-check-circle-outline</v-icon>
          </div>
        </div>

        <div class="mt-3 white--text font-weight-bold"
          style="font-size:1.25rem; max-width: 50vw;"
            >asdfgh {{ appBusyMessage }}</div>

        <upload-file-list></upload-file-list>

        <v-row align="center" class="flex-column upload-file-list"
               no-gutters justify="center" v-show="busyErrors.length > 0" style="max-width:calc(50vw + 32px)">

          <div v-show="errorLength > 0" class="mt-2" style="max-width:calc(50vw + 32px)">
            <v-card color="grey" flat tile >
              <v-card-text class="pa-0 pb-2 black error--text"
              ><div class="pt-4 px-4">Errors</div>
                <ul class="overflow-y-auto ma-4 upload_other_errors">
                  <li v-for="busyError in busyErrors" :key="busyError">
                    {{ busyError }}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </div>
        </v-row>
      </v-row>

      <v-row v-show="showBusyButtons" justify="center" class="mt-3" style="max-width: 50vw;">
        <v-btn
        class="mr-2 brandcolour busybutton"
          v-for="button in appBusyButtons"
          :key="button.label"
          :disabled="button.disabled"
          @click.stop
          @click.prevent
          @click="dispatchAction(button.action)">{{button.label}}</v-btn>
      </v-row>

    </v-overlay>-->
</template>

<script>
import { mapState } from 'vuex';
import UploadFileList from '@/components/uploader/UploadFileList.vue';

export default {
  name: 'Console',

  components: {
    UploadFileList,
  },

  computed: {
    ...mapState('common', [
      'consoleBusy',
      'appBusyMessage',
      'appBusyButtons',
      'showBusyButtons',
      'showSuccessfulIcon',
      'showUnSuccessfulIcon',
      'busyErrors',
      'totalAssetErrors',
    ]),
    errorLength() {
      return this.busyErrors.length;
    },
    finishedUploadingUI() {
      return this.appBusyButtons.length !== 0;
    },
    consoleClass() {
      let consoleclass = 'consolebusy';
      if (!this.finishedUploadingUI) {
        consoleclass = 'consolebusy';
      } else if (this.errorLength < 1 && this.totalAssetErrors < 1) {
        consoleclass = 'consolesuccess';
      } else {
        consoleclass = 'error';
      }
      return consoleclass;
    },
  },
  data: () => ({
    showActivity: false,
  }),
  methods: {
    dispatchAction(action) {
      this.$store.dispatch(action, { root: true });
    },
  },
};
</script>
<style lang="scss">
#app.v-application .consolesheetwrapper {
  &.error {
    .appbusymessage {
      color: #CB262A!important;
    }
    .busybutton, .showactivitytoggle {
      color: #CB262A!important;
      caret-color: #CB262A!important;
    }
  }
}
</style>
