<template>
  <div>
    <v-expansion-panels flat hover tile v-model="panel">
      <v-expansion-panel class="portal-links-subgroup transparent" >
        <v-expansion-panel-header
           class="black--text text-uppercase globalnavlist__header font-weight-bold pa-4 pl-2">
            {{ name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="transparent navitemsinpanel">
          <v-list-item-group>
          <navigation-item v-for="navItem in items" :key="navItem.code" :nav-item="navItem">
            </navigation-item>
          </v-list-item-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    <v-divider/>
  </div> 
</template>

<script>
import PageGrouping from '@/lib/ui/models/PageGrouping';
import NavigationItem from '@/components/ui/navigation/NavigationItem.vue';

export default {
  name: 'NavigationGroup',
  data() {
    return {
      panel: this.expand ? 0 : '',
    };
  },
  components: { NavigationItem },
  props: {
    navGroup: {
      type: PageGrouping,
    },
    expand: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    items() {
      return this.navGroup?.pages || [];
    },
    name() {
      return this.navGroup?.name;
    },
  },
};
</script>

<style lang="scss">
  .active_link {
    .v-list-item__title.globalnavlist {
      font-weight: bold!important;
    }
  }
</style>
