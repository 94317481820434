class BaseMetrics {
  name = '';

  description = '';

  constructor(name, description) {
    this.name = name;
    this.description = description;
  }
}

export default BaseMetrics;
