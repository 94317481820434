import Answer from '@/lib/search/discovery/classes/Answer';
import DocumentResult from './documentResult';
import FacetList from './facetList';

class DiscoverySearchResult {
  /**
   *
   * @type {null|FacetList}
   */
  facetList = null;

  answers = null;

  assetsRepository = {};

  assetDataLoaded = false;

  constructor(searchResult) {
    this.searchResult = searchResult;

    this.$documentResult = new DocumentResult(searchResult && searchResult);

    this.facetList = new FacetList(
      this.$documentResult.facets,
      searchResult && searchResult.facetableFields,
    );

    this.facetList.setFacetsAsSelected(this.selectedFacets);

    this.setAnswers();
  }

  /**
   *
   * @returns {DocumentResult}
   */
  get documentResult() {
    return this.$documentResult;
  }

  get validDocuments() {
    return this.$documentResult?.contents?.filter(
      (document) => typeof document !== 'undefined'
        && document !== null
        && document?.id !== null,
    );
  }

  get count() {
    return (this.$documentResult && this.$documentResult.count) || 0;
  }

  get query() {
    return this.searchResult && this.searchResult.searchText;
  }

  get selectedFacets() {
    return this.searchResult && this.searchResult.selectedFilters;
  }

  get currentPage() {
    return this.searchResult && this.searchResult.currentPage;
  }

  get searchId() {
    return this.searchResult && this.searchResult.searchId;
  }

  get applicationInstrumentationKey() {
    return this.searchResult && this.searchResult.applicationInstrumentationKey;
  }

  get facetableFields() {
    return this.searchResult && this.searchResult.facetableFields;
  }

  setAnswers() {
    if (typeof this.searchResult !== 'undefined' || this.searchResult != null) {
      const { answers } = this.searchResult;

      if (!(typeof answers === 'undefined' || answers == null) && answers.length > 0) {
        this.answers = answers.map((answer) => new Answer(answer));
      }
    }
  }

  totalPages(recordsPerPage) {
    const itemCount = this.count;
    const pageCount = (itemCount - (itemCount % recordsPerPage)) / recordsPerPage;
    if (itemCount % recordsPerPage === 0) {
      return pageCount;
    }
    return pageCount + 1;
  }

  get answersAndDocuments() {
    if (this.answers == null || this.answers.length === 0) {
      return [];
    }

    /* if (!this.assetsRepository) {
      return [];
    } */

    const getAsset = (answer) => {
      const document = this.documentResult
        .contents.find((doc) => doc.docId === answer.key);

      const { asset } = document;

      if (!asset) {
        return null;
      }

      return asset;
    };

    return this.answers.map((answer) => {
      const theAsset = getAsset(answer);
      return {
        answer,
        isPublic: theAsset?.isAccessible || false,
        document: theAsset,
      };
    });
  }
}

export default DiscoverySearchResult;
