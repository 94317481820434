import AppPropertyGroup from '@/lib/customizations/AppPropertyGroup';

class AppProperty {
  appPropertyId = '';

  name = '';

  type = '';

  code = '';

  created = '';

  isVisualElement = false;

  updated = '';

  created = '';

  description = '';

  /**
   *
   * @type {AppPropertyGroup|null}
   */
  appPropertyGroup = null;

  constructor(
    name = '',
    code = '',
    type = '',
    isVisualElement = false,
    appPropertyGroup = null,
    appPropertyId = 0,
    description = '',
  ) {
    this.name = name;
    this.code = code;
    this.type = type;
    this.isVisualElement = isVisualElement;
    this.appPropertyGroup = AppPropertyGroup.fromObject(appPropertyGroup);
    this.appPropertyId = appPropertyId;
    this.description = description;
  }

  static fromObject(appProperty) {
    const {
      name = '',
      code = '',
      type = '',
      isVisualElement = false,
      appPropertyGroup = null,
      appPropertyId = 0,
      description = '',
    } = appProperty;

    return new AppProperty(name, code, type, isVisualElement, appPropertyGroup, appPropertyId, description);
  }
}

export default AppProperty;
