import FilterValue from './FilterValue';

class FilterField {
  fieldName = '';

  fieldCode = '';

  isMultiple = true;

  /**
   *
   * @type {[FilterValue]}
   */
  filterFieldValues = [];

  constructor(data) {
    this.fieldName = data.fieldName;
    this.fieldCode = data.fieldCode;
    this.isMultiple = data.isMultiple;

    this.buildFilterValues(data?.filterValues);
  }

  buildFilterValues(data) {
    this.filterFieldValues = FilterValue.fromList(data);
  }

  /**
   *
   * @param filterValue{FilterValue}
   */
  filterChanged(filterValue) {
    console.log(this);
    if (!this.isMultiple) {
      this.filterFieldValues.forEach((value) => {
        if (!value.equals(filterValue) && filterValue.selected) {
          value.selected = false;
        }
      });
    }
  }

  static fromObject(data) {
    return new FilterField(data);
  }

  static fromList(list) {
    return list?.map(FilterField.fromObject) || [];
  }
}

export default FilterField;
