import { asyncFunction } from '@/lib/util';
import VideoAppearance from '@/lib/search/discovery/classes/insights/video/VideoAppearance';

class BaseVideoInsight {
  data = null;

  /**
   *
   * @type {[VideoAppearance]}
   */
  appearances = [];

  id = 0;

  name = '';

  description = '';

  constructor(data) {
    this.data = data;

    if (data) {
      this.setAppearances();
    }

    this.id = data?.id;
    this.name = data?.name;
    this.description = data?.description;
  }

  setAppearances() {
    this.appearances = (this.data?.appearances || [])
      .map((appearance) => new VideoAppearance(appearance));
  }

  get count() {
    return this.appearances?.length || 0;
  }

  get isListable() {
    return this.count > 0;
  }
}

export default BaseVideoInsight;
