import fileTypesUtil from '@/lib/util/fileTypesUtil';
import { FacetOrderType, facetsListFactory } from '@/lib/search/discovery/classes/facet';
import FacetList from '@/lib/search/discovery/classes/facetList';
import AssetEngagementInsight from '@/lib/search/discovery/classes/charting/AssetEngagementInsight';
import Metric from '@/lib/search/discovery/classes/Metric';
import SearchRequestInsight from './charting/SearchRequestInsight';

/**
 * Filters the given by the given predicate and then counts the resulting list defined by the
 * counter callback
 * @param list
 * @returns {function(*=): function(*=): Promise<Number>}
 */
const getCountsOfType = (list) => (counter) => (predicate) => {
  const promise = new Promise((resolve) => {
    /* filter list by the predicate */
    const filtered = list?.filter(predicate);

    /* do the counting */
    const count = counter(filtered);

    resolve(count);
  });

  return promise;
};

/**
 *
 * @param filtered {[FacetValue]}
 */
const addFacetsCount = (filtered) => filtered
  .reduce((acc, facetValue) => acc + facetValue.count, 0);

/**
 *
 * @param list
 * @returns {function(*=): Promise<Number>}
 */
const countFileTypes = (list) => getCountsOfType(list)(addFacetsCount);

const metricTypes = [
  {
    name: 'Documents',
    facetKey: 'file_type_category',
    filter: (facetValue) => !['video', 'image'].includes(facetValue.name.toLowerCase().trim()),
    verb: ' ',
  },
  {
    name: 'Images',
    facetKey: 'file_type_category',
    filter: (facetValue) => facetValue.name.toLowerCase().trim() === 'image',
    verb: ' ',
  },
  {
    name: 'Videos',
    facetKey: 'file_type_category',
    filter: (facetValue) => facetValue.name.toLowerCase().trim() === 'video',
    verb: ' ',
  },
];
const summarisedMetrics = [
  {
    name: 'Total Engagement',
    extractor: (engagementInsights) => engagementInsights?.totalEngagementCount || 0,
    verb: ' ',
  },
  {
    name: 'Total Views',
    extractor: (engagementInsights) => engagementInsights?.totalViews || 0,
    verb: ' ',
  },
  {
    name: 'Total Likes',
    extractor: (engagementInsights) => engagementInsights?.totalLikes || 0,
    verb: ' ',
  },
  {
    name: 'Total Downloads',
    extractor: (engagementInsights) => engagementInsights?.totalDownloads || 0,
    verb: ' ',
  },
];
const searchRequestSummarisedMetrics = [
  {
    name: 'Total Search Requests',
    extractor: (searchRequestInsights) => searchRequestInsights?.totalRequests || 0,
    verb: ' ',
  },
];

/**
 * A class the holds metrics based on the result got from the server.
 */
class DashboardMetrics {
  /**
   *
    * @type {DiscoverySearchResult}
   */
  searchResult = null;

  /**
   * Shows the number of documents in the index
   * @type {number}
   */
  documentCount = 0;

  /**
   * Number of videos in the index
   * @type {number}
   */
  videoCount = 0;

  /**
   * Number of images in the index
   * @type {number}
   */
  imagesCount = 0;

  /**
   * Number of brands
   * @type {number}
   */
  brandsCount = 0;

  /**
   *
   * @type {number}
   */
  topicsCount = 0;

  metrics = [];

  #facets = [];

  /**
   *
   * @type {FacetList}
   */
  facetList = null;

  engagementInsights = null;

  searchRequestInsights = null;

  #summarisedInsightsCache = null;

  /**
   *
   * @param searchResult {{
   *   totalDocuments: {number},
   *   facets: {[]},
   *   assetEngagementInsight,
   *   documents: [],
   * }}
   */
  constructor(searchResult) {
    this.searchResult = searchResult;

    if (searchResult != null) {
      const facets = facetsListFactory(searchResult.facets);
      this.facetList = new FacetList(facets, []);
      this.facetList.sortBy = FacetOrderType.alphabeticAscending;

      this.engagementInsights = AssetEngagementInsight
        .fromObject(searchResult.assetEngagementInsight);

      this.searchRequestInsights = SearchRequestInsight.fromObject(searchResult);

      this.setMetrics();
    }
  }

  getMetrics(type) {
    if (type === 'tagged') {
      return this.metrics;
    }
    if (type === 'searched') {
      return this.searchRequestMetrics;
    }
    return this.engagementMetrics;
  }

  setMetrics() {
    this.metrics = [];
    this.totalDocuments = this.searchResult.totalDocuments;

    this.setFacetMetricList();

    // this.setImagesAndVideosCount();
    // this.setBrandsCount();
    // this.setTopicsCounts();
  }

  setImagesAndVideosCount() {
    const fileTypeFacet = this.facetList
      .getFacetWithKey('metadata_storage_file_extension')?.values;

    const imagesPromise = countFileTypes(fileTypeFacet)(
      (facetValue) => fileTypesUtil.isImage(facetValue.name),
    );

    const videosPromise = countFileTypes(fileTypeFacet)(
      (facetValue) => fileTypesUtil.isVideo(facetValue.name),
    );

    videosPromise.then((count) => {
      this.videoCount = count;
      this.metrics.push(new Metric('Videos', count));
    });

    imagesPromise.then((count) => {
      this.imagesCount = count;
      this.metrics.push(new Metric('Images', count));
    });
  }

  setBrandsCount() {
    const brandsFacet = this.facetList
      .getFacetWithKey('brandNames')?.values;

    countFileTypes(brandsFacet)(() => true).then((count) => {
      this.brandsCount = count;
    });
  }

  setTopicsCounts() {
    const topicsFacet = this.facetList
      .getFacetWithKey('Agencytopics/name')?.values;

    countFileTypes(topicsFacet)(() => true).then((count) => {
      this.topicsCount = count;
    });
  }

  setFacetMetricList() {
    const contentMetric = new Metric('All Content', this.totalDocuments, '')
      .setData(this.searchResult);
    this.metrics = [contentMetric];
    const dataFilter = (key) => (docFilter) => (document) => docFilter({
      name: document[key] || '',
    });
    const facetMetrics = metricTypes.map(async ({
      name, facetKey, filter, verb,
    }) => {
      const facet = this.facetList
        .getFacetWithKey(facetKey)?.values;

      const count = await countFileTypes(facet)(filter);
      return new Metric(name, count, verb, dataFilter(facetKey)(filter))
        .setData(this.searchResult);
    });

    Promise.all(facetMetrics).then((metrics) => {
      this.metrics = [contentMetric, ...metrics];
    });

    return facetMetrics;
  }

  setEngagementMetrics() {
    const engagementMetrics = summarisedMetrics.map(({ name, extractor }) => {
      const count = extractor(this.engagementInsights);
      return new Metric(name, count, ' ');
    });
    return engagementMetrics;
  }

  get engagementMetrics() {
    if (this.#summarisedInsightsCache == null) {
      this.#summarisedInsightsCache = this.setEngagementMetrics();
    }
    return this.#summarisedInsightsCache;
  }

  get searchRequestMetrics() {
    const searchMetrics = searchRequestSummarisedMetrics.map(({ name, extractor }) => {
      const count = extractor(this.searchRequestInsights);
      return new Metric(name, count, ' ');
    });
    return searchMetrics;
  }

  getFacetsWithKeys(keys) {
    return keys.map((key) => this.facetList
      .getFacetWithKey(key));
  }
}

export default DashboardMetrics;
