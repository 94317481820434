export default {
  /**
   *
   * @param state
   * @param value{AwardsResult}
   */
  setAwardsResult(state, value) {
    state.awardsResult = value;
  },

  updateAwardsData(state, awardsData) {
    state.awardsResult?.updateAwardData(awardsData);
  },

  /**
   *
   * @param state
   * @param value{LeaderboardResult}
   */
  setLeaderboardResult(state, value) {
    state.leaderboardResult = value;
  },

  /**
   *
   * @param state
   * @param value{Leaderboard}
   */
  updateLeaderboard(state, value) {
    state.leaderboardResult?.updateLeaderboard(value);
  },
};
