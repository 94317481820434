import IndexFieldDescription, { indexFieldDescriptionFactory } from './IndexFieldDescription';

class IndexDescription {
  id = 0;

  name = '';

  #fields = [];

  #rawFields = [];

  #indexFieldDescriptionFactory = indexFieldDescriptionFactory;

  #mapping = {};

  constructor(
    id = 0,
    name = '',
    rawFields = [],
    fieldDescriptionFactory = null,
  ) {
    this.id = id;
    this.name = name;
    this.#rawFields = rawFields;

    if (fieldDescriptionFactory != null) {
      this.#indexFieldDescriptionFactory = fieldDescriptionFactory;
    }
  }

  get fields() {
    if (this.#fields.length === 0) {
      this.#fields = this.#rawFields.map(this.#indexFieldDescriptionFactory);
    }

    return this.#fields;
  }

  get indexFieldDescriptionFactory() {
    return this.#indexFieldDescriptionFactory;
  }

  set indexFieldDescriptionFactory(value) {
    this.#indexFieldDescriptionFactory = value;
    this.#fields = [];
  }

  get fieldMapping() {
    if (!this.#mapping) {
      this.#mapping = this.fields.reduce((field, acc) => {
        const { name } = field;
        acc[name] = field;
        return acc;
      });
    }

    return this.#mapping;
  }

  /**
   *
   * @param name{string}
   * @returns {null|IndexFieldDescription}
   */
  getFieldDescription(name) {
    if (name in this.fieldMapping) {
      return this.fieldMapping[name];
    }

    return null;
  }

  static fromObject(data, fieldDescriptionFactory = null) {
    if (data == null) {
      return new IndexDescription();
    }

    const { id, name, fields } = data;
    return new IndexDescription(id, name, fields, fieldDescriptionFactory);
  }
}

export default IndexDescription;
