class PageElement {
  pageElementId = 0;

  name = '';

  code = '';

  hasToggle = false;

  iconValue = '';

  constructor(pageElementId, name, code, hasToggle, iconValue) {
    this.pageElementId = pageElementId;
    this.name = name;
    this.code = code;
    this.hasToggle = hasToggle;
    this.iconValue = iconValue;
  }

  static fromObject({
    pageElementId,
    name,
    code,
    hasToggle,
    iconValue,
  }) {
    return new PageElement(pageElementId, name, code, hasToggle, iconValue);
  }

  static fromList(list) {
    if (list == null || list.length === 0) {
      return [];
    }

    return list.map(PageElement.fromObject);
  }
}

export default PageElement;
