import AssetStream from '@/documentAssets/models/AssetStream';
import { documentWithUrlsFactory } from '@/lib/search/discovery/classes/documentWithUrls';
import FetchAssetDetailsChoices from '@/lib/business/models/FetchAssetDetailsChoices';

const AssetStreamRestApiMixin = (superClass) => class extends superClass {
  /**
   *
   * @type {AxiosInstance}
   */
  http = null;

  url = 'v2/assetStreams';
  /**
   *
    * @param http{AxiosInstance}
   */

  constructor(http) {
    super();
    this.http = http;
  }

  async getStreams(indexId, includeFeaturedContent = false) {
    const fetchUrl = `${this.url}/${indexId}`;
    const { data } = await this.http.get(fetchUrl, {
      params: { includeFeaturedContent },
    });

    const streams = (data && data.map((stream) => AssetStream.fromObject(stream)));

    return streams;
  }

  async fetchDataForStream(
    stream,
    count,
    indexId,
    detailsChoices = new FetchAssetDetailsChoices(),
  ) {
    const fetchUrl = `${this.url}/${indexId}/${stream.code}/assets`;
    const { thumbnailType } = stream;

    stream.loading = true;
    const { data } = await this.http.post(fetchUrl, detailsChoices);

    const documents = documentWithUrlsFactory(data, !detailsChoices.fetchThumbnails)(thumbnailType);

    stream.items = documents;

    stream.loading = false;
    return stream;
  }
};

export default AssetStreamRestApiMixin;
