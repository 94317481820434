import { ApiFactory } from '@/api/api-factory';

const awardsClient = ApiFactory.getAwardsClient();

export default {
  getAwardsData({ commit, dispatch }) {
    const action = (index) => {
      const indexId = index.deploymentIndexId;

      awardsClient.getAwardsDataObservable(indexId)
        .subscribe((result) => commit('setAwardsResult', result));
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', action, { root: true });
  },

  filterAwardsData({ commit, dispatch }, filterForm) {
    const action = (index) => {
      const indexId = index.deploymentIndexId;
      dispatch(
        'common/showBusyCursor',
        'Fetching Data ...',
        { root: true },
      );
      awardsClient.getFilteredData(indexId, filterForm)
        .subscribe((result) => {
          commit('updateAwardsData', result);
          dispatch('common/hideBusyCursor', null, { root: true });
        });
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', action, { root: true });
  },

  getLeaderboardsData({ commit, dispatch }) {
    const action = (index) => {
      const indexId = index.deploymentIndexId;

      awardsClient.getLeaderboardObservable(indexId)
        .subscribe((result) => commit('setLeaderboardResult', result));
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', action, { root: true });
  },

  filterLeaderboard({ commit, dispatch }, { filterForm, league }) {
    const action = (index) => {
      const indexId = index.deploymentIndexId;
      dispatch(
        'common/showBusyCursor',
        'Fetching Data ...',
        { root: true },
      );
      awardsClient.getFilteredLeaderboard(indexId, filterForm, league)
        .subscribe((result) => {
          commit('updateLeaderboard', result);
          dispatch('common/hideBusyCursor', null, { root: true });
        });
    };

    dispatch('common/executeWhenDeploymentIndexIsAvailable', action, { root: true });
  },
};
