const WithIndicesMixin = (superClass) => class extends superClass {
  indices = [];

  constructor(data) {
    super(data);
    this.indices = this.deploymentIndexConnection.items;
  }
};

export default WithIndicesMixin;
