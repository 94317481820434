import { of, from, map } from 'rxjs';
import DashboardMetrics from '@/lib/search/discovery/classes/dashboardMetrics';
import DiscoveryQuestion from '@/lib/search/discovery/classes/DiscoveryQuestion';
import DocumentDetailsCache from '@/lib/search/discovery/classes/DocumentDetailsCache';
import { Asset } from '@/lib/business';
import { listCollectionListFactory } from '@/lib/business/models/ListCollection';
import PaginatedResult from '@/lib/business/models/lists/PaginatedResult';
import InteractionInsight from '@/lib/insights/models/InteractionInsight';
import SearchClient from '@/lib/search/SearchClient';
import CustomizationsClient from '@/lib/customizations/CustomizationsClient';
import InsightContent from './classes/insights/insightContent';
import { DocumentInsight } from './classes/insights';

/**
 * This class interfaces with the backed api.
 */
class DiscoverySearchClient {
  /**
   *
   * @type {DocumentDetailsCache}
   */
  documentDetailsCache = null;

  previewCache = {};

  /**
   *
   * @type {SearchClient}
   */
  searchClient = null;

  constructor(httpClient) {
    this.httpClient = httpClient;
    this.documentDetailsCache = new DocumentDetailsCache();
    this.searchClient = new SearchClient(httpClient)
      .setEndPoint('v2/search');
    this.customizationsClient = new CustomizationsClient(httpClient);
  }

  /**
   * Sends a search request with the search parameters and returns a promise with the
   * result got from the api.
   * @param searchString {String}
   * @param facetList {FacetList}
   * @returns {Promise<DiscoverySearchResult>}
   */
  search(searchParams) {
    return this.searchClient.search(searchParams);
  }

  /**
   *
   * @param searchResult {DiscoverySearchResult}
   */
  getAssetsMatchingDocIds(searchResult) {
    const promise = new Promise((resolve, reject) => {
      try {
        const contents = searchResult?.documentResult?.contents;
        if (contents && contents.length > 0) {
          const docIds = contents.map((content) => content.docId);
          const answerKeys = searchResult.answers?.map((a) => a.key) || [];

          const assetIds = [...new Set([...docIds, ...answerKeys])];

          this.httpClient.post('v2/insights/assetsWithDocIds', assetIds)
            .then(({ data }) => {
              if (data) {
                const cache = {};
                contents.forEach((content) => {
                  const docId = (content.docId || '').toLowerCase();
                  const asset = data[docId] || null;
                  const assetModel = new Asset(asset);
                  cache[docId] = assetModel;
                  content.setAsset(cache[docId]);
                });

                searchResult.assetsRepository = cache;
                searchResult.answers?.forEach((answer) => {
                  const { key } = answer;
                  if (!(key in cache)) {
                    cache[key] = new Asset(data[key] || null);
                  }

                  answer.asset = cache[key];
                });
              }
            });
        }
        resolve(contents);
      } catch (e) {
        reject(e);
      }
    });
    return promise;
  }

  /**
   *
   * @param id {String}
   * @returns {Promise<InsightContent>}
   */
  async getDocument(id, indexId) {
    const params = { Id: id, indexId };
    let insightContent = null;

    try {
      insightContent = await this.documentDetailsCache.get(id);
    } catch (e) {
      const {
        data: {
          document,
          token,
          idField,
          originalFileName,
          isAccessible,
        },
      } = await this.httpClient.get('v2/document/getdocumentbyid', { params });
      document.file_name = originalFileName;
      insightContent = new InsightContent(document, token, idField);
      insightContent.isAccessible = isAccessible;
      this.documentDetailsCache.add(document);
    }

    return insightContent;
  }

  async documentDetails(id) {
    const { data } = await this.httpClient.get(`discovery/getdocument/${id}`);
    console.log(data);
  }

  /**
   *
   * @param params
   * @returns {Promise<DocumentInsight>}
   */
  async insights(params) {
    const { id } = params;
    const { data } = await this.httpClient.post(`v2/insights/get/${id}`, params);
    return new DocumentInsight(data);
  }

  async questions() {
    const { data } = await this.httpClient.get('search/questions');
    const questions = data && data.map((qa) => new DiscoveryQuestion(qa));
    return questions;
  }

  /**
   *
   * @param searchParams{
   * searchParameters: {SearchParameters}
   * insightType:{string},
   * top: {number}
   * }
   * @returns {Promise<DashboardMetrics>}
   */
  async metrics(insightParams) {
    const { indexId } = insightParams.searchParameters;
    const url = `v2/deploymentIndexes/${indexId}/insights`;

    const { data } = await this.httpClient.post(url, insightParams);

    return new DashboardMetrics(data);
  }

  async searchMetrics(insightParams) {
    const { indexId } = insightParams.searchParameters;
    const url = `v2/deploymentIndexes/${indexId}/insights/searches`;

    const { data } = await this.httpClient.post(url, insightParams);

    return new DashboardMetrics(data);
  }

  async engagementMetrics(searchParams) {
    const params = {
      searchParameters: searchParams,
      top: 10,
    };

    const { indexId } = searchParams;
    const url = `v2/deploymentIndexes/${indexId}/insights/engagement`;

    const { data } = await this.httpClient.post(url, params);

    return new DashboardMetrics(data);
  }

  /**
   *
   * @param term
   * @returns {Promise<[string]>}
   */
  async suggestions(term) {
    const { data } = await this.httpClient.get(`search/suggestions/${term || '*'}`);
    console.log('Inside Client', data);
    return data;
  }

  async graph(term) {
    const { data } = await this.httpClient.get('search/facetGraph', {
      params: {
        Query: term,
        Fields: 'keyphrases,locations',
      },
    });

    console.log(data);
  }

  async download(id, indexId) {
    const { data } = await this.httpClient.get('v2/document/download', {
      responseType: 'arraybuffer',
      params: {
        id,
        indexId,
      },
    });

    return data;
  }

  async markAsDownloaded(id, indexId) {
    const { data } = await this.httpClient.post('v2/document/markAsDownloaded', {
      id,
      indexId,
    });

    return data;
  }

  /**
   *
   * @param collection{Collection}
   * @param asset{Asset}
   * @returns {Promise<null|*>}
   */
  async pinAssetToCollection(collection, asset) {
    try {
      const collectionId = collection.id;
      const { assetId } = asset;
      const { data } = await this.httpClient.post(`v2/assets/${assetId}/pin`, {
        collectionIds: [collectionId],
      });

      asset.addToPinnedCollections([collectionId]);
      return data;
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  /**
   *
   * @param collection{Collection}
   * @param asset{Asset}
   * @returns {Promise<null|*>}
   */
  async unpinAssetToCollection(collection, asset) {
    try {
      const collectionId = collection.id;
      const { assetId } = asset;
      const { data } = await this.httpClient.post(`v2/assets/${assetId}/unpin`, {
        collectionIds: [collectionId],
      });

      asset.removeFromPinnedCollections(collectionId);

      return data;
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  async downloadSelected(ids) {
    const { data } = await this.httpClient.post('v2/document/downloadSelected', {
      ids,
    },
    {
      responseType: 'arraybuffer',
    });
    return data;
  }

  /**
   *
   * @param asset
   * @returns {Promise<Asset>}
   */
  async saveAsset(asset) {
    const { assetId } = asset;
    const { data } = await this.httpClient.put(`v2/insights/save/${assetId}`, asset);

    return new Asset(data);
  }

  /**
   *
   * @param docId{string}
   * @returns {Promise<void>}
   */
  async filePreview(docId = '', force = false) {
    if (force || !(docId in this.previewCache)) {
      const { data } = await this.httpClient.get(`v2/insights/preview/${docId}`);
      this.previewCache[docId] = data;
    }

    const preview = this.previewCache[docId];
    return preview.uri;
  }

  /**
   * Deletes document
   * @param docId
   * @returns {*}
   */
  async deleteDocument(docId) {
    try {
      const { status } = await this.httpClient.delete('v2/document/delete', {
        params: {
          id: docId,
        },
      });

      if (status === 200) {
        return true;
      }
    } catch (e) {
      console.log(e);
      return false;
    }

    return false;
  }

  /**
   *
   * @param assetId {Number}
   * @param file {File}
   * @param useAsThumbnail {Boolean}
   * @returns {Promise<void>}
   */
  async replacePreview(assetId, file, useAsThumbnail) {
    const formData = new FormData();
    formData.append('assetId', assetId);
    formData.append('file', file);
    formData.append('useAsThumbnail', useAsThumbnail);

    try {
      const uri = `v2/assets/${assetId}/preview`;
      const { data } = await this.httpClient.post(uri, formData, {
        headers: {
          'Content-Type': 'multipart-form-data',
        },
      });

      console.log(data);
    } catch (e) {
      console.log(e);
    }
  }

  async replaceAssetImagery(assetId, imageryList) {
    try {
      const uri = `v2/assets/${assetId}/replaceAssetImagery`;
      const { data } = await this.httpClient.post(uri, imageryList);
      console.log(data);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   *
   * @param assetId {Number}
   */
  async createAssetVersion(assetId, storagePath) {
    try {
      const uri = `v2/assets/${assetId}/versions`;
      const params = { assetId, storagePath };
      const { data } = await this.httpClient.post(uri, params);
      console.log(data);
    } catch (e) {
      console.log(e);
    }
  }

  /**
   *
   * @param assetId{number}
   * @param rowsPerPage{number}
   * @param page{number}
   * @returns {Promise<PaginatedResult>}
   */
  async relatedCollections(docId, rowsPerPage = 25, page = 0) {
    const url = `v2/insights/relatedCollections/${docId}`;
    const params = { rowsPerPage, page };
    try {
      const { data } = await this.httpClient.get(url, { params });
      const result = new PaginatedResult(data?.count, data?.currentPage);
      result.rows = listCollectionListFactory(data?.rows || []);
      setTimeout(() => this.customizationsClient.getCustomizationsList(result.rows));
      return result;
    } catch (e) {
      console.error(e);
      return new PaginatedResult();
    }
  }

  /**
   *
   * @param docId
   * @param field
   * @param rowsPerPage
   * @param page
   * @returns {Observable<PaginatedResult>|Observable<unknown>}
   */
  getMetadataInsightsObservable(docId, field, rowsPerPage = 25, page = 0) {
    const url = `v2/assets/${docId}/insights/metadata`;
    const params = {
      rowsPerPage,
      page,
      field,
    };

    try {
      const observable = from(this.httpClient.get(url, { params }))
        .pipe(map(({ data }) => new PaginatedResult(data?.count, data?.currentPage, data?.rows)));
      return observable;
    } catch (e) {
      console.error(e);
      return of(new PaginatedResult());
    }
  }

  async getAssetsInteractionMetrics(assets) {
    const url = 'v2/assets/insights';
    const assetIds = assets?.map((asset) => asset.assetId);

    const { data } = await this.httpClient.post(url, { assetIds });

    const dict = data.reduce((acc, metric) => {
      const insight = InteractionInsight.fromObject(metric);
      acc[insight.id] = insight;
      return acc;
    }, {});

    return dict;
  }
}

export default DiscoverySearchClient;
