<template>
  <v-chip-group class="ma-3" :column="column" :show-arrows="showArrows">
    <v-chip
      label
      v-for="({ key, value }, index) in selectedFilters"
      :key="`sfv-${index}`"
      active-class="primarybutton"
      class="primarybutton text-uppercase"
      close
      dark
      @click:close="removeFromSelection({ key, value} )"
    ><div class="chip_value_wrapper overflow-x-hidden text-truncate">{{ value }}</div>
    </v-chip>
  </v-chip-group>
</template>

<script>
import FiltersList from '@/lib/framework/filtering/FiltersList';

export default {
  name: 'CurrentFilterList',
  props: {
    facetList: {
      type: FiltersList,
      default: () => null,
    },
    column: {
      type: Boolean,
      default: true,
    },
    showArrows: {
      type: String,
      default: null,
    },
  },
  computed: {
    selectedFilters() {
      return this.facetList.getSelectedFilters() || [];
    },
  },
  methods: {
    removeFromSelection(filter) {
      const facetList = this.facetList?.remove(filter);
      this.$emit('current-filter-updated', facetList);
    },
  },
};
</script>

<style scoped>

</style>
