import setEditableFeaturesProperty from '@/lib/business/models/editable/editableType';
import { discoveryThemeFactory } from '@/lib/themes/DiscoveryTheme';
import colorPalletes from '@/lib/ui/colorPalletes';
import PageActions from '@/lib/ui/models/PageActions';

class DeploymentIndex {
  isCurrent = '';

  userId = '';

  deploymentIndexId = '';

  deploymentId = 0;

  containerName = '';

  containerUrl = '';

  name = '';

  sasToken = '';

  sasTokenExpiration = '';

  searchIndexName = '';

  isPreviewable = '';

  theme = '';

  welcomeMessage = '';

  id = '';

  metadataSetId = '';

  /**
   *
   * @type {AppObjectSetting}
   */
  customizationSettings = null;

  template = 'default';

  customizationsContainer = '';

  pageActions = new PageActions();

  constructor(index) {
    this.isCurrent = index?.isCurrent;
    this.userId = index?.userId;
    this.deploymentIndexId = index?.deploymentIndexId;
    this.deploymentId = index?.deploymentId;
    this.containerName = index?.containerName;
    this.containerUrl = index?.containerUrl;
    this.name = index?.name;
    this.sasToken = index?.sasToken;
    this.sasTokenExpiration = index?.sasTokenExpiration;
    this.searchIndexName = index?.searchIndexName;
    this.isPreviewable = index?.isPreviewable;
    this.theme = discoveryThemeFactory(index?.theme);
    this.welcomeMessage = index?.welcomeMessage || null;
    this.id = this.deploymentIndexId;
    this.metadataSetId = index?.metadataSetId;
    this.customizationSettings = null;
    this.previewContainerName = index?.previewContainerName;
    this.template = index?.pageTemplateCode || 'default';
    this.customizationsContainer = index?.customizationsContainer;
  }

  setTheme(theme) {
    console.group('Setting theme');
    this.theme = discoveryThemeFactory(theme);
    console.log(this);
    console.groupEnd();
  }

  setCustomizationSettings(settings) {
    this.customizationSettings = settings;
  }

  get welcomeText() {
    return this.welcomeMessage || `Welcome to ${this.name}!`;
  }

  fullFileUrl(filename) {
    const fileUrl = `${this.containerUrl}${this.containerName}/${filename}${this.sasToken}`;
    return fileUrl;
  }

  paletteFactory() {
    const colors = this.customizationSettings?.list('chartColors');
    const primaryColor = this.customizationSettings?.getSetting('--chartPrimaryColor');
    return colorPalletes.getPalette({
      primaryColor: primaryColor?.value,
      colors,
    });
  }
}

export default DeploymentIndex;

setEditableFeaturesProperty(DeploymentIndex.prototype, {
  hasMembers: {
    get() {
      return false;
    },
  },
  hasThumbnail: {
    get() {
      return false;
    },
  },
  editableDescriptionLabel: {
    get() {
      return 'Welcome Message';
    },
  },
  editableDescription: {
    get() {
      return this.welcomeMessage;
    },
    set(value) {
      this.welcomeMessage = value;
    },
  },
  hasIsSmartValue: {
    get() {
      return true;
    },
  },
});

export const deploymentIndexFactory = (answer) => new DeploymentIndex(answer);
export const deploymentIndexListFactory = (list) => list?.map(deploymentIndexFactory);
