import { mix } from 'mixwith/mixwith';
import BaseMetrics from './BaseMetrics';
import topValuesMixin from './topValuesMixin';

const Metrics = class Metrics extends mix(BaseMetrics).with(topValuesMixin) {
  /**
   *
   * @type {{Array<MetricItem>}}
   */
  values = [];

  /**
   *
   * @param name{string}
   * @param values{Array<MetricItem>}
   * @param description{string}
   */
  constructor(name = '', values = [], description = '') {
    super(name, description);

    this.values = values;
  }

  topChartValues(n) {
    return this.top(n);
  }
};

export default Metrics;
