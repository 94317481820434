<template>
    <component :is="currentPanel" @close="close" v-model="showPanel"></component>
</template>

<script>
import RightPanelFactory from '@/lib/ui/panels/RightPanelFactory';

export default {
  name: 'DiscoveryPanelDrawer',
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    side: {
      type: String,
      default: () => 'right',
    },
    factory: RightPanelFactory,
    panel: String,
  },
  computed: {
    showPanel: {
      get() {
        return this.value || false;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    panelPosition() {
      return this.side;
    },
    currentPanel() {
      return this.factory.get(this.panel);
    },
    currentTitle() {
      return this.factory.getTitle(this.panel);
    },
    currentIcon() {
      return this.factory.getIcon(this.panel);
    },
  },
  methods: {
    close() {
      this.showPanel = false;
    },
  },
};
</script>

<style scoped>

</style>
