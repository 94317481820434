import colorPalletes from '@/lib/ui/colorPalletes';

const isEmpty = (content) => (content == null || typeof content === 'undefined' || content === '');

/**
 *
 * @param content
 * @returns {(function(*=): (number|*))|*}
 */
const calculateOccurrences = (content) => (phrase) => {
  if (isEmpty(content) || isEmpty(phrase)) {
    return 0;
  }

  const regexp = new RegExp(phrase, 'ig');

  return content.match(regexp)?.length || 0;
};

const getColors = (color, length) => {
  const colors = colorPalletes.getPalette(color)(length);
  return colors;
};

/**
 *
 * @param chart{InsightsBubbleChart}
 */
const createSeriesData = (chart) => {
  const occurrenceCalculator = calculateOccurrences(chart.content);

  const { key, values } = chart;
  const parent = {
    id: key,
    name: key,
    parent: '',
    group: '',
  };

  setTimeout(() => {
    const series = [parent];
    values.forEach((phrase, index) => {
      const count = occurrenceCalculator(phrase);
      const data = {
        id: `${key}-${index}`,
        parent: key,
        name: phrase,
        group: phrase,
        value: count,
      };

      series.push(data);
    });

    chart.series = series;
  });

  return chart;
};

class InsightsBubbleChart {
  config = {
    type: 'bubble-pack',
    plotarea: {
      margin: 10,
    },
    options: {
      padding: 0,
      minSize: 3,
      format: {
        short: true,
        decimals: 2,
        decimalsSeparator: '.',
        thousandsSeparator: ',',
      },
    },
  };

  series = [];

  constructor(key, name, values, content, color) {
    this.key = key;
    this.name = name;
    this.values = [...new Set(values)];
    this.content = content;
    this.color = color;

    createSeriesData(this);
  }

  get chartData() {
    const config = { ...this.config };

    if (this.values?.length > 0) {
      config.options.palette = getColors(this.color, this.values?.length || 0);
    }

    return {
      ...config,
      series: this.series,
    };
  }
}

export default InsightsBubbleChart;
