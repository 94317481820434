const appPropertyGroupRepo = {};

class AppPropertyGroup {
  appDataTypeCode = '';

  appPropertyGroupId = '';

  code = '';

  name = '';

  constructor(
    name = '', code = '', appPropertyGroupId = '', appDataTypeCode = '',
  ) {
    this.name = name;
    this.code = code;
    this.appPropertyGroupId = appPropertyGroupId;
    this.appDataTypeCode = appDataTypeCode;
  }

  static fromObject(group) {
    if (group == null) {
      return null;
    }

    const { code } = group;

    if (!(code in appPropertyGroupRepo)) {
      const {
        name, appPropertyGroupId, appDataTypeCode,
      } = group;

      const newGroup = new AppPropertyGroup(name, code, appPropertyGroupId, appDataTypeCode);
      appPropertyGroupRepo[code] = newGroup;
    }

    return appPropertyGroupRepo[code];
  }
}

export default AppPropertyGroup;
