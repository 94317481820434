class InteractionInsight {
  id = 0;

  likesCount = 0;

  liked = false;

  viewsCount = 0;

  downloadsCount = 0;

  constructor(likesCount = 0, liked = false, viewsCount = 0, downloadsCount = 0, id = 0) {
    this.likesCount = likesCount;
    this.liked = liked;
    this.viewsCount = viewsCount;
    this.downloadsCount = downloadsCount;
    this.id = id;
  }

  /**
   *
   * @param data{{likes, liked, views, downloads}}
   * @returns {InteractionInsight}
   */
  static fromObject(data) {
    const {
      likes,
      liked,
      views,
      downloads,
      id,
    } = data;

    return new InteractionInsight(likes, liked, views, downloads, id);
  }
}

export default InteractionInsight;
