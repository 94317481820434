import EntityAssignment from '@/lib/acl/models/EntityAssignment';

class EntityAssignmentResult {
  /**
   *
   * @type {[EntityAssignment]}
   */
  assignments = [];

  entityId = 0;

  /**
   *
   * @param assignments{[EntityAssignment]}
   */
  constructor(assignments) {
    this.assignments = assignments;
  }

  static fromObject(obj) {
    const assignments = EntityAssignment.fromList(obj.assignments);
    return new EntityAssignmentResult(assignments);
  }

  /**
   *
   * @returns {[User]}
   */
  get users() {
    return this.assignments.map((assignment) => assignment.user);
  }

  set users(value) {
    const assignments = value.map((user) => new EntityAssignment(user, 'assetMember'));
    this.assignments = assignments;
  }
}

export default EntityAssignmentResult;
