/**
 * This function converts a list of raw Objects into a list of Pre Defined Objects
 * @param objectFactory
 * objectFactory is a function that takes in a raw object and returns its corresponding Object
 * @returns {function(...[*]=)}
 */

export const createIterableFromRawList = (objectFactory) => (list) => {
  const iterator = {
    [Symbol.iterator]() {
      const rawList = list || [];
      const { length } = rawList;
      let index = -1;

      return {
        next() {
          if (index + 1 >= length) {
            return { value: undefined, done: true };
          }

          index += 1;
          return {
            value: (objectFactory && objectFactory(rawList[index])) || rawList[index],
            done: false,
          };
        },
      };
    },
  };

  return Array.from(iterator);
};

export const asyncFunction = (func) => setTimeout(func, 0);

/**
 *
 * @param list
 * @returns {function(*): *}
 */
export const sortListByPosition = (list) => (referenceList) => (valueExtractor) => {
  const comparer = (a, b) => referenceList.indexOf(valueExtractor(a))
    - referenceList.indexOf(valueExtractor(b));

  return list.sort(comparer);
};

/**
 *
 * @param name {String}
 * @returns {String}
 */
export const initials = (name) => name && name.split(' ')
  .map((n) => n.charAt(0))
  .join('').toUpperCase();

export default { createIterableFromRawList };
