import fileTypesUtil from '@/lib/util/fileTypesUtil';

const icons = {
  image: 'default-icon-image.png',
  video: 'default-icon-video.png',
  presentation: 'default-icon-powerpoint.png',
  spreadsheet: 'default-icon-excel.png',
  document: 'default-icon-word.png',
  pdf: 'default-icon-pdf.png',
  html: 'default-icon-html.png',
  keynote: 'default-icon-keynote.png',
  default: 'default-icon-generic.png',
  indesign: 'default-icon-indesign.png',
  audio: 'default-icon-audio.png',
  psd: 'default-icon-psd.png',
  zip: 'default-icon-zip.png',
  tiff: 'default-icon-tiff.png',
};

const iconFileType = {
  image: 'fad fa-file-image',
  video: 'fad fa-file-video',
  presentation: 'fad fa-file-powerpoint',
  spreadsheet: 'fad fa-file-excel',
  document: 'fad fa-file-word',
  pdf: 'fad fa-file-pdf',
  html: 'fad fa-file-code',
  keynote: 'fad fa-file-powerpoint',
  default: 'fad fa-file',
};

// const defaultFile = 'default-icon-generic.png';

const fileTypeRepository = {};

const getFileTypeImage = (fileType) => {
  const fileGroup = fileTypesUtil.is(fileType) || 'default';
  // console.log('File type', fileType, fileGroup, icons[fileGroup]);

  const image = fileGroup && icons[fileGroup];
  fileTypeRepository[fileType] = `/images/contentTypes/${image}`;
  return fileTypeRepository[fileType];
};

const iconService = {
  get: (fileType, contentPath) => {
    if (
      contentPath
      && fileTypesUtil.isPathValid(contentPath)
      && fileTypesUtil.isImage(fileType.toLowerCase())
    ) {
      return contentPath;
    }
    const result = fileTypeRepository[fileType.toLowerCase()] || getFileTypeImage(fileType);
    return result;
  },
  getFileIcon: (fileType) => {
    const fileCategory = fileTypesUtil.is(fileType.toLowerCase());
    const icon = iconFileType[fileCategory];
    return { fileCategory, icon };
  },
};

export default iconService;
