import { Asset } from '@/lib/business';

class DocumentInsight {
  id = null;

  insights = [];

  celebrities = [];

  insightObj = null;

  /**
   *
   * @type {Asset}
   */
  asset = null;

  constructor(insightObj) {
    this.insightObj = insightObj;
    if (insightObj != null) {
      const {
        id,
        insights,
        celebrities,
        asset,
      } = insightObj;

      this.id = id;
      this.insights = insights;
      this.celebrities = celebrities;

      this.asset = new Asset(asset);
    }
  }
}

export default DocumentInsight;
