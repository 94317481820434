export default {
  /**
   * @type{AwardsResult}
   */
  awardsResult: null,

  /**
   * @type{LeaderboardResult}
   */
  leaderboardResult: null,
};
