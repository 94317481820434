import Facet, { facetFieldMapping } from '@/lib/search/discovery/classes/facet';
import { humanize } from '@/plugins/discoveryFilters';

const defaultName = (key) => humanize(key
  .replace(/^(ogilvy)/i, '')
  .replace(/^(indexer_)/i, '')
  .replace('_', ' '));

const isAutoTag = (key) => key.startsWith('metadata_storage_') || key.startsWith('indexer_');
const isManualTag = (key) => !isAutoTag(key);

const isEmpty = (content) => (content == null || typeof content === 'undefined' || content === '');

const calculateOccurrences = (content) => (phrase) => {
  if (isEmpty(content) || isEmpty(phrase)) {
    return 0;
  }

  const regexp = new RegExp(phrase, 'ig');

  return content.match(regexp)?.length || 0;
};

export default class BaseInsight {
  /**
   * As returned by the server. ex: Ogilvyawards or vid....
   * @type {string}
   */
  key = '';

  /**
   * Human readable Insight Name. Strips prefix like Ogilvy, vidInsight
   * @type {string}
   */
  name = '';

  #values = [];

  uniqueValues = []

  #isManual = false;

  isSelected = false;

  label = '';

  chartData = null;

  isVideo = false;

  constructor(key, values) {
    this.key = key;
    this.values = values;
    this.isManual = isManualTag(key);
  }

  get isManual() {
    return this.#isManual;
  }

  set isManual(value) {
    this.#isManual = value;
    this.setName();
  }

  get listKey() {
    return this.key;
  }

  getUploaderName() {
    const uploaderKey = `uploader/${this.key}`;
    return BaseInsight.getTagName(uploaderKey);
  }

  static getTagName(key) {
    if (key in facetFieldMapping) {
      return facetFieldMapping[key];
    }
    return null;
  }

  setName() {
    let name = null;
    // console.log('Getting auto name for ', this.key);
    name = BaseInsight.getTagName(this.key);

    // console.log('Name is null so getting default');
    this.name = name || defaultName(this.key);
    this.label = this.name;
  }

  get values() {
    return this.#values;
  }

  get tagType() {
    return this.isManual ? 'manual' : 'auto';
  }

  get icon() {
    return this.isManual ? 'fal fa-tag' : 'fal fa-lightbulb-exclamation';
  }

  set values(values) {
    this.#values = values;

    if (values && values.length > 0) {
      this.setUniqueValues(values);
    }
  }

  setUniqueValues(values) {
    new Promise((resolve) => {
      if (typeof values === 'undefined' || values.length === 0) {
        resolve([]);
      }
      const uniqueOnes = [...new Set(values.map((value) => value))];
      resolve(uniqueOnes);
    }).then((uniqueValues) => {
      this.uniqueValues = uniqueValues;
    });
  }

  get hasInsights() {
    return this.#values && this.#values.length > 0;
  }

  getChartData(content) {
    if (this.chartData == null) {
      this.chartData = this.createChartData(content);
    }

    return this.chartData;
  }

  createChartData(content) {
    const valueExtractor = (value) => {
      if (typeof value === 'string') {
        return value;
      }

      if ('name' in value) {
        return value['name'];
      }

      if ('tag' in value) {
        return value['tag'];
      }

      return '';
    };

    const occurrenceCounter = calculateOccurrences(content);
    const values = this.uniqueValues?.map(valueExtractor)
      .filter((value) => !isEmpty(value))
      .map((value) => {
        const count = occurrenceCounter(value);
        return { name: value, count };
      });

    return Facet.fromKeyAndValue(this.key, values, this.isVideo);
  }
}
