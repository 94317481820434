import colorPalletes from '@/lib/ui/colorPalletes';

const timeSeriesConfig = {
  type: 'line',
  'scroll-x': {},
  'scroll-y': {},
  title: {
    text: '',
    adjustLayout: true,
  },
  legend: {
    adjustLayout: true,
    align: 'center',
    verticalAlign: 'bottom',
  },
  plot: {
    valueBox: {
      text: '%v',
    },
  },
  plotarea: {
    margin: 'dynamic 50 dynamic dynamic',
  },
  scaleX: {
    // step: '1day',
    zooming: true,
    transform: {
      type: 'date',
      all: '%d %M, %h:%i %A',
    },
  },
  scaleY: {
    zooming: true,
    guide: {
      lineStyle: 'solid',
    },
    label: {
      text: '',
    },
  },
  crosshairX: {
    exact: true,
    lineColor: '#000',
    marker: {
      backgroundColor: 'white',
      borderColor: '#000',
      borderWidth: '2px',
      size: '5px',
    },
    scaleLabel: {
      borderRadius: '2px',
    },
  },
  tooltip: {
    text: '%v<br>%kl',
    borderRadius: '2px',
  },
};

/**
 *
 * @param chartList{ChartList}
 * @param title
 * @param yLabel
 * @constructor
 */
const TimeSeriesData = function TimeSeriesData(chartList, title, yLabel, color) {
  this.config = { ...timeSeriesConfig };

  this.config.title.text = title;
  this.config.scaleY.label.text = yLabel;
  this.color = colorPalletes.getChroma(color || '#EB3F43');
  const dataCount = chartList.data?.length || 1;
  const pallets = colorPalletes
    .getPalette({ primaryColor: color })('time')(chartList.data?.length);
  if (chartList != null) {
    // this.config.scaleX.minValue = chartList.startDate;
  }

  this.series = chartList.data?.map(({ key, value }, index) => ({
    text: key,
    values: value,
    'line-color': pallets[index % dataCount],
    marker: {
      'background-color': colorPalletes.getChroma(pallets[index % dataCount]).brighten(1),
      'border-color': colorPalletes.getChroma(pallets[index % dataCount]).darken(1),
    },
  }));
};

export default TimeSeriesData;
