<template>
    <v-dialog v-model="isOpen" width="450" scrollable persistent>
    <v-card color="white black--text">
        <v-card-title class="text-uppercase modalwindowheader mb-3">
        <i  class="material-symbols-outlined mr-3 notranslate" style="transform: rotate(270deg)">enable</i>
    My sites
        </v-card-title>
        <v-card-text class="black--text" >
        Select your site
        <div style="height: 190px">
            <v-radio-group class="black--text" v-model="currentIndex">
            <v-radio
                :value="mySite"
                v-for="mySite in myIndices"
                :key="`mysite-${mySite.deploymentIndexId}`">
                <template v-slot:label>
                <span class="black--text">{{ mySite.name }}</span>
                </template>
            </v-radio>
            </v-radio-group>
        </div>
        </v-card-text>
        <v-card-actions class="align-end">
        <v-spacer/>
        <v-btn @click="closeMySites" color="grey">Cancel</v-btn>
        <v-btn @click="gotoSite">Go to site</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    userSelectedIndex: null,
  }),
  computed: {
    ...mapGetters('common', [
      'myIndices',
      'selectedDeploymentIndex',
    ]),
    currentIndex: {
      get() {
        return this.selectedDeploymentIndex;
      },
      set(value) {
        this.userSelectedIndex = value;
      },
    },
    isOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    }, 
  },
  methods: {
    closeMySites() {
      this.isOpen = false;
    },
    gotoSite() {
      this.closeMySites();
      this.$emit('index-changed', this.userSelectedIndex);
    },
  },
};
</script>
