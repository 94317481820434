export default {
  children: [
    {
      path: 'home',
      name: 'CollectionDetails',
      component: () => import(
        /* webpackChunkName: "collections-details" */
        '../components/collections/collectionDetails/CollectionDetailsHome.vue'
      ),
      meta: {
        editableType: 'collection',
        showInsights: false,
        mobileName: 'collection',
        pageTitle: 'Collection Pinboard',
      },
    },
    {
      path: 'assets',
      name: 'collection-details-assets',
      component: () => import(
        /* webpackChunkName: "collections-details" */
        '../components/collections/collectionDetails/CollectionDetailsAssets.vue'
      ),
      meta: {
        editableType: 'collection',
        showInsights: true,
        mobileName: 'collection',
        pageTitle: 'Collection Assets',
      },
    },
    {
      path: 'members',
      name: 'collection-details-members',
      component: () => import(
        /* webpackChunkName: "collections-details" */
        '../components/collections/collectionDetails/CollectionDetailsMembers.vue'
      ),
      meta: {
        editableType: 'collection',
        showInsights: false,
        mobileName: 'collection',
        pageTitle: 'Collection Members',
      },
    },
  ],
};
