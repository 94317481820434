import VideoInsight from '@/lib/search/discovery/classes/insights/video/VideoInsight';
import VideoTranscriptList from '@/lib/search/discovery/classes/insights/video/VideoTranscriptList';

class VideoIndexer {
  partition = '';

  description = '';

  privacyMode = '';

  state = '';

  accountId = '';

  id = '';

  name = '';

  userName = '';

  created = '';

  isOwned = false;

  isEditable = false;

  isBase = false;

  durationInSeconds = 0;

  summarizedInsights = null;

  transcriptList = null;

  constructor(attrs) {
    if (attrs != null) {
      this.partition = attrs.partition;
      this.description = attrs.description;
      this.privacyMode = attrs.privacyMode;
      this.state = attrs.state;
      this.accountId = attrs.accountId;
      this.id = attrs.id;
      this.name = attrs.name;
      this.userName = attrs.userName;
      this.created = attrs.created;
      this.isOwned = attrs.isOwned;
      this.isEditable = attrs.isEditable;
      this.isBase = attrs.isBase;
      this.durationInSeconds = attrs.durationInSeconds;
      this.summarizedInsights = new VideoInsight(attrs.summarizedInsights, this.durationInSeconds);

      this.transcriptList = new VideoTranscriptList(attrs.videos);
    }
  }

  get chartOptions() {
    return this.summarizedInsights?.chartOptions || [];
  }

  get listableInsights() {
    return this.summarizedInsights?.listableInsights || [];
  }
}

export default VideoIndexer;
