import { mix } from 'mixwith/mixwith';
import { of, map } from 'rxjs';

import LocationTally from './LocationTally';
import locationNameMixin from './mixins/locationNameMixin';

class CityTally extends mix(LocationTally).with(locationNameMixin) {
  static fromObject(data, name = '') {
    return new CityTally(data, name);
  }

  /**
   *
   * @param data
   * @param name
   * @returns {Observable<CityTally>}
   */
  static fromObjectObservable(data, name = '') {
    const observable = of(data)
      .pipe(map((city) => CityTally.fromObject(city, name)));

    return observable;
  }
}

export default CityTally;
