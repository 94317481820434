import Page from './Page';

class PageGrouping {
  pageGroupingId = 0;

  name = '';

  code = '';

  order = 0;

  /**
   *
   * @type {[Page]}
   */
  pages = [];

  constructor(pageGroupingId, name, code, order, pages) {
    this.pageGroupingId = pageGroupingId;
    this.name = name;
    this.code = code;
    this.order = order;
    this.pages = pages;
  }

  /**
   *
   * @param pages{[Page]}
   */
  addPages(pages) {
    this.pages = [...this.pages, ...pages];
  }

  static from({
    pageGroupingId,
    name,
    code,
    order,
    pages,
  }) {
    const pageItems = Page.fromList(pages);
    return new PageGrouping(pageGroupingId, name, code, order, pageItems);
  }

  static fromList(pageGroupings) {
    return (pageGroupings || []).map(PageGrouping.from);
  }
}

export default PageGrouping;
