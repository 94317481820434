import BaseVideoInsight from '@/lib/search/discovery/classes/insights/video/BaseVideoInsight';

class VideoFace extends BaseVideoInsight {
  title = '';

  description = '';

  constructor(data) {
    super(data);

    this.title = data?.title;
    this.description = data?.description;
  }

  get isListable() {
    return super.isListable && !(this.name.toLowerCase().includes('unknown'));
  }
}

export default VideoFace;
