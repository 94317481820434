import { mix } from 'mixwith/mixwith';
import {
  ConnectionMixin,
} from '@/lib/graphql/mixins';
import StarfishUser from '@/admin/users/models/StarfishUser';
import ContainsQueryMixin from '@/lib/graphql/mixins/ContainsQueryMixin';

/**
 *
 */
const UsersPagedResult = class UsersPagedResult extends mix(ConnectionMixin(StarfishUser)).with(
  ContainsQueryMixin,
) {
  get users() {
    console.log('users');
    console.log(this.items);
    return this.items;
  }
};

export default UsersPagedResult;
