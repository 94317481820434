import Vue from 'vue';
import zingchart from 'zingchart/es6';
import zingchartVue from 'zingchart-vue';
import common from '@/config/common';

const chartLicense = common.chartLicense();
if (chartLicense) {
  zingchart.LICENSE = chartLicense;
}

Vue.component('zingchart', zingchartVue);
