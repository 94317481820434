import MetricItem from '@/lib/charts/models/MetricItem';
import withDefaultThumbnailUrl from '@/lib/charts/models/withDefaultThumbnailUrl';
import { mix } from 'mixwith/mixwith';

const AssetMetricItem = class AssetMetricItem extends mix(MetricItem)
  .with(withDefaultThumbnailUrl) {
  assetId = 0;

  constructor(assetId = 0, count = 0, name = '') {
    super(name, count);

    this.assetId = assetId;
  }

  static fromObject({ assetId, count, name }) {
    return new AssetMetricItem(assetId, count, name);
  }
};

export default AssetMetricItem;
