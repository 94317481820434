class CachingService {
  constructor(prefix = 'starfish_cache_') {
    this.prefix = prefix || 'starfish_cache_';
  }

  /**
   *
   * @param key{string}
   * @param data{*}
   * @param timeToLive{number}
   * @returns {Promise<Awaited<boolean>>}
   */
  set(key, data, timeToLive) {
    const now = Date.now();
    const ttl = timeToLive ? now + (timeToLive || 0) : null;
    const item = {
      data,
      ttl,
    };

    localStorage.setItem(`${this.prefix}_${key}`, JSON.stringify(item));
    return Promise.resolve(true);
  }

  /**
   *
   * @param key{string}
   * @returns {Promise<Awaited<boolean>>}
   */
  remove(key) {
    localStorage.removeItem(`${this.prefix}_${key}`);
    return Promise.resolve(true);
  }

  /**
   *
   * @param key
   * @returns {Promise<Awaited<null>|Awaited<unknown>>}
   */
  async get(key) {
    const itemString = localStorage.getItem(`${this.prefix}_${key}`);
    // console.log('Cache from local storage', itemString);
    if (!itemString) {
      return Promise.resolve(null);
    }

    const item = JSON.parse(itemString);
    // console.log('Cache parsed', item);

    if (item.ttl == null) {
      return Promise.resolve(item.data);
    }

    if (Date.now() > item.ttl) {
      // console.log('Cache expired', Date.now(), item.ttl, item);
      await this.remove(key);
      return Promise.resolve(null);
    }

    // console.log('Cache, returning fresh data', item.data);
    return Promise.resolve(item.data);
  }
}

export default CachingService;
