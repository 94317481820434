import Vue from 'vue';
import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import VueApollo from 'vue-apollo';

import config from '@/config/common';
import oktaConfig from '@/config/okta';

const httpLink = new HttpLink({
  uri: config.graphQlEndPoint(),
});

const options = {
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

/* creating okta auth middleware */
const authMiddleware = setContext(async () => ({
  headers: {
    Authorization: `Bearer ${await oktaConfig.token(Vue.prototype.$auth)}`,
  },
}));

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((
      { message, locations, path },
    ) => console.log(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    ));
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const apolloClient = new ApolloClient({
  link: from([errorLink, authMiddleware.concat(httpLink)]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions: options,
});

// Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

export default apolloProvider;

export const graphClient = apolloClient;
