import fileTypesUtil from '@/lib/util/fileTypesUtil';
import DescriptionList from '@/lib/search/discovery/classes/insights/DescriptionList';
import convertToSharepointDocument from '@/lib/search/discovery/classes/SharepointDocument';
import UploaderIndex from '@/lib/search/discovery/classes/UploaderIndex';
import moment from 'moment';
// import documentTitle from '../helpers';

export default class DiscoveryDocument {
  idField = 'doc_id';

  bindingField = 'doc_id';

  /**
   * Specifies whether this is a Case Study/Response/....
   * @type {string}
   */
  documentType = '';

  /**
   *
   * @type {Asset}
   */
  asset = null;

  /**
   *
   * @type {String}
   */
  thumbnailUrl = null;

  /**
   * Specifies if the document has index data.
   * @type {boolean}
   */
  hasIndexData = false;

  hasBusinessMetadata = false;

  isVideo = false;

  /**
   *
   * @type {DescriptionList|null}
   */
  imageDescription = null;

  #contentFragments = [];

  /**
   *
   * @type {UploaderIndex}
   */
  uploader = null;

  contentTypeGroups = [];

  contentTypes = [];

  created = null;

  publishedDate = null;

  assetVisibility = null;

  constructor(document, idField, bindingField) {
    this.document = document;
    this.idField = idField || 'doc_id';
    this.bindingField = bindingField || 'doc_id';
    this.docId = document[bindingField];

    this.hasIndexData = document
      && document.metadata_storage_file_extension
      && document.metadata_storage_file_extension !== '';

    this.hasBusinessMetadata = document
      && document.file_name
      && document.file_name !== '';

    if (document) {
      this.setDocumentType();
    }

    this.imageDescription = document && new DescriptionList(document.description);

    this.uploader = document?.uploader && new UploaderIndex(document.uploader);

    this.contentTypeGroups = document['content_type_groups'] || [];
    this.contentTypes = document['content_types'] || [];

    this.created = document?.created && moment(document.created);
    this.publishedDate = document['published_date'] && moment(document['published_date']);
    this.assetVisibility = document['asset_visibility'] || 'Public';

    this.toSharePointDocument();
  }

  get title() {
    return this.document?.title || this.filename;
  }

  get filename() {
    if (this.hasBusinessMetadata) {
      return this.document.file_name;
    }

    return (this.asset && this.asset.fileName) || '';
  }

  get fileType() {
    if (this.hasIndexData) {
      return this.document.metadata_storage_file_extension;
    }

    if (this.hasBusinessMetadata) {
      return `.${this.document.file_extension}`.replace(/\.{2,}/, '.');
    }

    return (this.asset && this.asset.fileExtension) || '';
  }

  get text() {
    return this.document && this.document.content;
  }

  get id() {
    const id = this.document && this.document[this.idField];
    return id;
  }

  get synopsis() {
    return this.asset?.synopsis;
  }

  get decodedPath() {
    if (this.hasBusinessMetadata) {
      return this.document.storage_path;
    }
    return this.asset && this.asset.storagePath;
  }

  path(token) {
    const url = `${this.decodedPath}${token}`;
    return url;
  }

  previewUrl(token) {
    return this.asset && this.asset.previewUrl(token);
  }

  setAsset(asset) {
    this.asset = asset;
    if (asset != null && asset.contentTypeName != null) {
      this.documentType = asset.contentTypeName;
    }
  }

  /**
   * Content path looks like this.
   * https://cistrdev.blob.core.windows.net/ci-clean/Responses/.../FileName.pdf
   * To get the collection type, we ignore the host, and storage account name.
   * What we get is something like this
   * /Responses/.../FileName.pdf
   * the first element in this string is the collection type.
   */
  setDocumentType() {
    try {
      const url = new URL(this.decodedPath);
      let [, , type] = url.pathname.split('/') || ['', '', ''];

      if (type === 'Cases') {
        type = 'Case Study';
      }
      this.documentType = type;
    } catch {
      this.documentType = 'NA';
    }
  }

  setIsVideo() {
    if (this.hasBusinessMetadata) {
      this.isVideo = fileTypesUtil.isVideo(this.fileType);
    }

    this.isVideo = this.asset && this.asset.isVideo;
  }

  toSharePointDocument() {
    if (this.document?.isSharepointDocument) {
      convertToSharepointDocument(this);
    }
  }

  extractContent(numberOfChars = 200) {
    return (this.text || '').substring(0, numberOfChars);
  }

  get defaultPdfPreview() {
    return this.asset?.defaultPdfPreview || '';
  }
}
