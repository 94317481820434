import { mix } from 'mixwith';
import WithIndicesMixin from '@/admin/deployments/models/mixins/WithIndicesMixin';
import Deployment from '@/admin/deployments/models/Deployment';

const DeploymentWithIndices = class DeploymentWithIndices extends mix(Deployment)
  .with(WithIndicesMixin) {

};

export default DeploymentWithIndices;
