import { Observable } from 'rxjs';
import PageElement from '@/lib/ui/models/PageElement';

class PageActionsService {
  /**
   *
   * @type {AxiosInstance}
   */
  http = null;

  endpoint = '';

  constructor(http) {
    this.http = http;
    this.endpoint = 'v2/acl/portal/pages';
  }

  /**
   *
   * @param page
   * @param appObjectType
   * @param id
   * @returns {Observable<unknown>}
   */
  get(page, appObjectType, id) {
    const { http } = this;
    const url = `${this.endpoint}/${page}/actions/${appObjectType}/${id}`;

    const observable = new Observable(async (observer) => {
      try {
        const { data } = await http.post(url);
        const rawActions = data.data;
        const actions = PageElement.fromList(rawActions);
        observer.next(actions);
      } catch (e) {
        observer.next([]);
      }
    });

    return observable;
  }
}

export default PageActionsService;
