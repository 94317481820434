import { mix } from 'mixwith';
import PagedResult from '@/lib/graphql/models/PagedResult';
import { IterableResultMixin, PageInfoMixin, TypedListMixin } from '@/lib/graphql/mixins';
import DeploymentUser from '@/admin/users/models/DeploymentUser';

const DeploymentUserConnection = class DeploymentUserConnection extends mix(
  PagedResult,
).with(
  PageInfoMixin,
  TypedListMixin(DeploymentUser),
  IterableResultMixin,
) {
  get deployments() {
    return this.items || [];
  }
};

export default DeploymentUserConnection;
