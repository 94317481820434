import { sortListByPosition } from '@/lib/util';

const displayableInsights = {
  content_types: 1,
  agencies: 2,
  capabilities: 3,
  topics: 4,
  regions: 5,
  countries: 6,
  clients: 7,
  brands: 8,
  partners: 9,
  tools: 10,
  industries: 11,
  sub_industries: 12,
  target_markets: 13,
  keywords: 14,
};

const isDisplayableInsight = (facet) => {
  if (facet.values?.length <= 0) {
    return false;
  }

  if (facet.facetCategory.toLowerCase().startsWith('auto')) {
    return !facet.name.startsWith('Indexer ');
  }

  return facet.key in displayableInsights;
};

const facetCategories = [
  'Standard Tags',
  'Auto Tags: Documents & Images',
  'Auto Tags: Videos',
];

class FacetInsightsList {
  facets = [];

  insights = [];

  constructor(facets) {
    this.facets = facets;
    this.setInsights();
  }

  setInsights() {
    const valueExtractor = (facet) => facet.key;
    const nonEmptyFacets = this.facets.filter(isDisplayableInsight);

    const facetNames = Object.keys(displayableInsights);
    this.insights = sortListByPosition(nonEmptyFacets)(facetNames)(valueExtractor);
  }

  get groupedInsights() {
    return facetCategories
      .map((category) => ({
        name: category,
        list: this.insights.filter((facet) => facet.facetCategory === category),
      }));
  }
}

export default FacetInsightsList;
