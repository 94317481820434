import Asset from '@/lib/business/models/Asset';

class CollectionAsset {
  collectionAssetId = 0;

  /**
   *
   * @type {boolean}
   */
  pinned = false;

  /**
   *
   * @type {number}
   */
  collectionId = 0;

  /**
   *
   * @type {Asset}
   */
  asset = null;

  constructor(collectionAsset) {
    if (collectionAsset == null) {
      return;
    }

    this.collectionAssetId = collectionAsset.collectionAssetId;
    this.collectionId = collectionAsset.collectionId;
    this.pinned = collectionAsset.pinned;

    this.asset = new Asset(collectionAsset.asset);

    if (this.collectionId && this.collectionId > 0 && this.pinned) {
      this.asset.addToPinnedCollections([this.collectionId]);
    }
  }

  static createList(list) {
    return list?.map((asset) => new CollectionAsset(asset));
  }

  get rawLocation() {
    return this.asset.rawLocation;
  }

  shareableObject() {
    return {
      name: this.asset.fileName,
      type: 'Document',
      location: this.rawLocation,
    };
  }
}

export default CollectionAsset;
