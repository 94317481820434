<template>
  <v-list dense nav class="portal-links mt-3 pl-0">
    <v-list-item>
      <v-select
        outlined
        dense
        :items="deployments"
        label="Deployment Name"
        item-text="name"
        return-object
        v-model="deployment"
      ></v-select>
    </v-list-item>
    <v-list-item>
      <v-select
        outlined
        dense
        :items="deploymentIndexes"
        label="Data Source"
        item-text="name"
        return-object
        v-model="deploymentIndex"
      ></v-select>
    </v-list-item>

<!--    <v-list-item>-->
<!--      <v-select-->
<!--        outlined-->
<!--        dense-->
<!--        :items="metadataSets"-->
<!--        label="Deployment Metadata Set"-->
<!--        item-text="Name"-->
<!--        item-value="SetId"-->
<!--        v-model="deployment.metadataSetId"-->
<!--      ></v-select>-->
<!--    </v-list-item>-->
<!--    <v-list-item>-->
<!--      <v-select-->
<!--        outlined-->
<!--        dense-->
<!--        :items="metadataSets"-->
<!--        label="Deployment Index Metadata Set"-->
<!--        item-text="Name"-->
<!--        item-value="SetId"-->
<!--        v-model="localIndexMetadataSetId"-->
<!--      ></v-select>-->
<!--    </v-list-item>-->

<!--    <v-list-item>
      <div>Index Metadata Data</div>
    </v-list-item>
    <v-list-item>
      <v-select :items="wppOffers"
                item-text="FieldValue"
                label="WPP Offers"></v-select>
    </v-list-item>
    <v-list-item>
      <recursive-list
        v-for="(setFieldValue, index) in nodeSetFieldValues"
        :key="index"
        :setFieldValue="setFieldValue"
        :setFields="setFields"
        :setFieldValues="setFieldValues"
      >
      </recursive-list>
    </v-list-item>-->
  </v-list>

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

// import RecursiveList from '@/components/uploader/RecursiveList.vue';

export default {
  name: 'DeploymentSelector',
  components: {
    // RecursiveList,
  },
  mounted() {
    // this.getMetadataSets();
  },
  computed: {
    ...mapState('common', [
      'userDeployments',
    ]),
    ...mapState('uploader', [
      // 'metadataSets',
      'deploymentMetadataSet',
      'indexMetadataSet',
      'metadataSetData',
    ]),
    deployment: {
      /**
       *
       * @returns {Deployment}
       */
      get() {
        return this.userDeployments.currentDeployment;
      },
      set(value) {
        this.userDeployments.setCurrentDeployment(value);
        this.setCurrentIndex(null);
      },
    },
    deploymentIndex: {
      get() {
        return this.deployment?.currentIndex;
      },
      /**
       *
       * @param value {DeploymentIndex}
       */
      set(value) {
        this.setCurrentIndex(value);
      },
    },
    deployments() {
      return this.userDeployments?.deployments || [];
    },
    deploymentIndexes() {
      return this.deployment?.deploymentIndexes || [];
    },
    // localDeploymentMetadataSet: {
    //   get() {
    //     return this.deploymentMetadataSet;
    //   },
    //   set(value) {
    //     this.setDeploymentMetadataSet(value);
    //   },
    // },
    // deploymentIndex.metadataSetId
    // localIndexMetadataSetId: {
    //   get() {
    //     return this.deploymentIndex.metadataSetId;
    //   },
    //   set(value) {
    //     // this.setIndexMetadataSet(value);
    //     this.deploymentIndex.metadataSetId = value;
    //     // this.getMetadataSetDataID(value);
    //   },
    // },
    // localIndexMetadataSet: {
    //   get() {
    //     return this.indexMetadataSet;
    //   },
    //   set(value) {
    //     this.setIndexMetadataSet(value);
    //     this.getMetadataSetData(value);
    //   },
    // },
    // setFields() {
    //   if (this.metadataSetData) {
    //     return this.metadataSetData.setFields;
    //   }
    //   return [];
    // },
    // setFieldValues() {
    //   if (this.metadataSetData) {
    //     return this.metadataSetData.setFieldValues;
    //   }
    //   return [];
    // },
    // node() {
    //   const startNode = this.setFields?.find((sf) => sf.FieldCode === 'wpp_global');
    //   if (startNode) {
    //     return startNode;
    //   }
    //   return { Name: 'None' };
    // },
    // nodeSetFieldValues() {
    //   // on very start, top level, using SetField in place of SetFieldValue
    //   return this.setFieldValues?.filter((sfv) => sfv.SetFieldId === this.node.SetFieldId);
    // },
    // wppOffers() {
    //   if (this.metadataSetData) {
    //     return this.metadataSetData.setFieldValues.filter((sfv) => sfv.FieldCode === 'wpp_offer');
    //   }
    //   return [];
    // },

  },
  methods: {
    ...mapActions('common', [
      'setDeploymentIndex',
    ]),
    // ...mapActions('uploader', [
    //   // 'getMetadataSets',
    //   'getMetadataSetData',
    //   'getMetadataSetDataID',
    // ]),
    // ...mapMutations('uploader', [
    //   'setDeploymentMetadataSet',
    //   'setIndexMetadataSet',
    // ]),
    setCurrentIndex(index) {
      if (index == null) {
        console.log('index is null', index);
        this.userDeployments.currentDeployment.setCurrentIndex(null);
      } else {
        console.log('index is not null', index);
        this.userDeployments.setCurrentIndex(index);
      }

      this.$emit('index-changed', index || this.deployment.currentIndex);
    },
  },
};
</script>

<style scoped>

</style>
