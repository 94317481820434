import EntityAssignmentResult from '@/lib/acl/models/EntityAssignmentResult';
import { userFactory } from '@/lib/business/models/User';
import EntityAssignmentInput from '@/lib/acl/models/EntityAssignmentInput';

class AssetAclService {
  http = null;

  endpoint = 'v2/assets/';

  /**
   * @param http{AxiosInstance}
   */
  constructor(http) {
    this.http = http;
  }

  /**
   *
   * @param assetId{int}
   */
  async canView(assetId) {
    const url = `${this.endpoint}${assetId}/acl/checkPermission/accessPrivateAsset`;

    const { data } = await this.http.get(url);
    return data.data;
  }

  async getAssetAssignments(assetId) {
    const url = `${this.endpoint}${assetId}/acl/assignments`;
    const { data } = await this.http.get(url);

    const assignmentResult = EntityAssignmentResult.fromObject(data.data);

    return assignmentResult;
  }

  async availableUsers(assetId) {
    const url = `${this.endpoint}${assetId}/acl/assignments/availableUsers`;
    const { data } = await this.http.get(url);

    const users = userFactory.createUsers(data.data);
    return users;
  }

  /**
   *
   * @param assignments{EntityAssignmentResult}
   * @returns {Promise<void>}
   */
  async assign(assignments) {
    const assetId = assignments.entityId;
    const url = `${this.endpoint}${assetId}/acl/assign`;

    const input = EntityAssignmentInput
      .fromEntityAssignmentResult(assignments, 'assetMember');

    const { data } = await this.http.post(url, input);

    return data.data;
  }
}

export default AssetAclService;
