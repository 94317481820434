/**
 *
 * @param deployments{[WithIndicesMixin]}
 * @param indices
 */
const extractIndices = (deployments) => deployments.flatMap(
  (deployment) => deployment.indices,
);

const DeploymentConnectionWithFlatIndicesMixin = (superClass) => class extends superClass {
  indices = [];

  constructor(data) {
    super(data);

    setTimeout(() => {
      this.indices = extractIndices(this.deployments);
    });
  }
};

export default DeploymentConnectionWithFlatIndicesMixin;
