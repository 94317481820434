const expandedValueMixin = (superClass) => class extends superClass {
  isOpen = false;

  get open() {
    return this.isOpen;
  }

  set open(value) {
    this.isOpen = value;
  }
};

export default expandedValueMixin;
