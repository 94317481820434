import FilterGroup from './FilterGroup';
import FiltersList from '../../../lib/framework/filtering/FiltersList';

class FilterForm extends FiltersList {
  filterFormName = '';

  /**
   *
   * @type {[FilterGroup]}
   */
  filterGroups = [];

  currentFilterValues = [];

  #allFilterValueMap = {};

  constructor(data) {
    super();
    this.filterFormName = data.filterFormName;
    this.filterGroups = FilterGroup.fromList(data?.filterGroups || []);
    this.setCurrentFilters(data.currentFilterValues || []);
  }

  /**
   *
   * @returns {[FilterValue]}
   */
  get allFilterValues() {
    return this.filterGroups?.flatMap((group) => group.allFilterValues) || [];
  }

  get selectedFilterValues() {
    return this.allFilterValues.filter((filter) => filter.selected);
  }

  get filterValueMap() {
    if (!this.#allFilterValueMap) {
      this.#allFilterValueMap = this.allFilterValues.reduce((acc, filterValue) => {
        acc[filterValue.fieldCode] = filterValue;
        return acc;
      });
    }

    return this.#allFilterValueMap;
  }

  /**
   *
   * @param currentFilteredValues{[FilterValue]}
   */
  setCurrentFilters(currentFilteredValues) {
    currentFilteredValues.forEach((filterValue) => {
      if ((filterValue.fieldCode in this.filterValueMap)) {
        const selected = this.filterValueMap[filterValue.fieldCode];
        selected.selected = true;
      }
    });
  }

  getSelectedFilters() {
    return this.selectedFilterValues;
  }

  remove(filter) {
    const theFilter = this.selectedFilterValues.find(
      (fv) => fv.key === filter.key && fv.value === filter.value,
    );

    theFilter.selected = false;
  }

  clear() {
    (this.selectedFilterValues || [])
      .forEach((filterValue) => {
        filterValue.selected = false;
      });

    return this;
  }
}

export default FilterForm;
