/**
 *
 * @param list[]
 */
const extractCaptions = (list) => {
  const promise = new Promise((resolve) => {
    if (typeof list === 'string') {
      resolve([list]);
    } else {
      const captions = list && list.flatMap(
        (description) => description.captions?.map((c) => c.text),
      );
      resolve(captions);
    }
  });
  return promise;
};

const DescriptionList = function DescriptionList(data) {
  this.desciptions = data;
  this.captions = [];
  setTimeout(() => {
    extractCaptions(data).then((captions) => {
      this.captions = captions;
    });
  });
};

export default DescriptionList;
