import modelListFactory from '@/lib/business/models/helper';

class Role {
  roleId = 0;

  name = '';

  code = '';

  constructor(role) {
    if (role == null) {
      return;
    }
    this.roleId = role.roleId;
    this.name = role.name;
    this.code = role.code;
  }

  static createList(list) {
    return modelListFactory((role) => new Role(role))(list);
  }
}

export default Role;

const RolesFactory = function rolesFactory() {
  this.cache = {};
};

/**
 *
 * @param role
 * @returns {*|Role}
 */
RolesFactory.prototype.create = function createRole(role) {
  if (role == null) {
    return null;
  }

  if (!this.cache[role.Id]) {
    this.cache[role.roleId] = new Role(role);
  }

  return this.cache[role.roleId];
};

export const roleFactory = new RolesFactory();
