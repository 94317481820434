import responseGeneratorRoutes from '@/router/tools/responseGenerator';

export default {
  path: '/tools',
  components: {
    main: () => import(/* webpackChunkName: "tools" */ '../../views/ToolPage.vue'),
  },
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      path: '',
      name: 'Tools',
      component: () => import(
        /* webpackChunkName: "tools-home" */ '../../views/tools/ToolsHome.vue'
      ),
      meta: {
        requiresAuth: true,
        title: 'Tools',
      },
    },
    {
      path: 'response-generator',
      component: () => import(
        /* webpackChunkName: "tools-new-business" */
        '../../views/tools/ResponseGeneratorView.vue'
      ),
      meta: {
        requiresAuth: true,
        title: 'New Business Generator',
      },
      children: [
        ...responseGeneratorRoutes,
      ],
    },
    {
      path: 'response-builder',
      name: 'response-generator-builder',
      component: () => import(
        /* webpackChunkName: "response-generator-builder" */
        '../../components/responseGenerator/ResponseGeneratorBuilder.vue'
      ),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: 'response-complete',
      name: 'response-complete',
      component: () => import(
        /* webpackChunkName: "response-complete" */
        '../../views/tools/ResponseBuilderComplete.vue'
      ),
    },
  ],
};
