export const shareableLocation = (rawLocation) => (preview) => {
  if (preview) {
    return { ...rawLocation, query: { preview: true } };
  }

  return rawLocation;
};

export default {
  shareableLocation,
};
