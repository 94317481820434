class PagedResult {
  nodes = null;

  pageInfo = null;

  totalCount = 0;

  constructor(data = {
    nodes: null,
    pageInfo: null,
    totalCount: 0,
  }) {
    this.nodes = data.nodes;
    this.pageInfo = data.pageInfo;
    this.totalCount = data.totalCount;
  }
}

export default PagedResult;
