import AppObjectSettingValue from './AppObjectSettingValue';

/**
 *
 * @param settings{[AppObjectSettingValue]}
 * @param cssRoot{string}
 */
const cssMaker = (settings, cssRoot) => {
  const css = settings
    .map((setting) => `${setting.appProperty.code}: ${setting.value};`)
    .join('\n');

  return `${cssRoot} {
    ${css}
  }`;
};

class AppObjectSetting {
    appObjectSettingId = 0;

    userId = 0;

  /**
   *
   * @type {[AppObjectSettingValue]}
   */
  settings = [];

  constructor(appObjectSettingId = 0, userId = 0, settings = []) {
    this.appObjectSettingId = appObjectSettingId;
    this.user = userId;
    this.settings = settings;
  }

  static fromObject(data) {
    if (data == null) {
      return null;
    }

    const { appObjectSettingId, userId, settings } = data; // need to check this once
    return new AppObjectSetting(appObjectSettingId, userId, AppObjectSettingValue.fromObjects(settings));
  }

  static fromObjects(list) {
    if (list == null) {
      return null;
    }

    return list.map((data) => AppObjectSetting.fromObject(data));
  }

  /**
   *
   * @param propertyCode
   * @returns {AppObjectSettingValue}
   */
  getSetting(propertyCode) {
    const val = this.settings.find((record) => record.appPropertyCode === propertyCode);
    return val;
  }

  /**
   *
   * @returns {AppObjectSettingValue[]}
   */
  get nonVisualSettings() {
    return this.settings
      .filter((setting) => !setting.appProperty.isVisualElement);
  }

  /**
   *
   * @returns {AppObjectSettingValue[]}
   */
  get visualSettings() {
    return this.settings
      .filter((setting) => setting.appProperty.isVisualElement);
  }

  /**
   *
   * @returns {AppObjectSettingValue[]}
   */
  get fileSettings() {
    return this.visualSettings
      .filter((setting) => setting.appProperty.type === 'file');
  }

  /**
   *
   * @returns {AppObjectSettingValue[]}
   */
  get nonFileSettings() {
    return this.visualSettings
      .filter((setting) => setting.appProperty.type !== 'file');
  }

  /**
   *
   * @returns {{}}
   */
  get groupedVisualElements() {
    return this.nonFileSettings.reduce((acc, setting) => {
      const groupCode = setting.appProperty.appPropertyGroup.code;

      if (!(groupCode in acc)) {
        const group = {
          name: setting.appProperty.appPropertyGroup.name,
          settings: [],
        };

        acc[groupCode] = group;
      }

      acc[groupCode].settings = [...acc[groupCode].settings, setting];

      return acc;
    }, {});
  }

  get nonVisualElementAccordions() {
    const properties = this.nonVisualSettings;
    const accordiansList = properties.reduce((accordians, property) => {
      const accordianKey = property.propertyGroupName || 'Page Elements';
      const accordianObj = accordians.find((accordian) => accordian.title === accordianKey);
    
      if (!accordianObj) {
        accordians.push({ title: accordianKey, propertiesList: [property] });
      } else {
        accordianObj.propertiesList.push(property);
      }
    
      return accordians;
    }, []);
    return accordiansList;
  }

  get propertyAccordions() {
    const properties = this.fileSettings;
    const accordiansList = properties.reduce((accordians, property) => {
      const accordianKey = property.propertyGroupName || 'Visual Elements';
      const accordianObj = accordians.find((accordian) => accordian.title === accordianKey);
    
      if (!accordianObj) {
        accordians.push({ title: accordianKey, propertiesList: [property] });
      } else {
        accordianObj.propertiesList.push(property);
      }
    
      return accordians;
    }, []);
    return accordiansList;
  }

  css(cssName) {
    const settings = this.getGroupSettings(cssName);
    return cssMaker(settings, ':root');
  }

  /**
   *
   * @param listName
   * @returns {string[]}
   */
  list(listName) {
    const settings = this.getGroupSettings(listName);
    return settings.map((setting) => setting.value);
  }

  getGroupSettings(groupName) {
    const { visualSettings } = this;

    const groupSettings = visualSettings.filter((setting) => setting
      .appProperty
      .appPropertyGroup != null
      && setting
        .appProperty
        .appPropertyGroup.code === groupName);

    return groupSettings;
  }
}
export default AppObjectSetting;
