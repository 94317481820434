import { graphClient } from '@/plugins/apollo';

class GraphQlClient {
  #client = graphClient;

  async query(query, variables = {}) {
    try {
      const { data } = await this.#client.query({
        query,
        variables,
      });
      // console.log('From GraphQlClient');
      // console.log(data);
      return data;
    } catch (e) {
      console.error('Error in GraphQl', e);
      throw e;
    }
  }
}

export default GraphQlClient;
