export default {
  path: '/bidashboards',
  components: {
    main: () => import(/* webpackChunkName: "bi-dashboards-page" */ '../views/biDashboard/BIDashboardView.vue'),
  },
  meta: {
    requiresAuth: true,
    hideSearchFromHeader: false,
    showRightPanel: false,
  },
  redirect: { name: 'bi-dashboards-home' },
  children: [
    {
      path: 'bidashboardshome',
      name: 'bi-dashboards-home',
      component: () => import('@/biDashboard/DashboardsHome.vue'),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        showRightPanel: false,
        showFilters: false,
        mobileName: '',
        pageTitle: 'BI Dashboards',
      },
    },
    {
      path: 'canneslionsbrandrankings',
      name: 'cannes-lions-brand-rankings',
      component: () => import('@/biDashboard/awards/pages/CannesLionsBrandRankings.vue'),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        showRightPanel: false,
        showFilters: false,
        mobileName: '',
        pageTitle: 'Cannes Lions - Brand Rankings',
      },
    },
    {
      path: 'canneslionsholdingcompanies',
      name: 'cannes-lions-holding-companies',
      component: () => import('@/biDashboard/awards/pages/CannesLionsHoldingCompanies.vue'),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        showRightPanel: false,
        showFilters: false,
        mobileName: '',
        pageTitle: 'Cannes Lions - Holding Companies',
      },
    },
    {
      path: 'canneslionsnetworkcompanies',
      name: 'canneslionsnetworkcompanies',
      component: () => import('@/biDashboard/awards/pages/CannesLionsNetworkCompanies.vue'),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        showRightPanel: false,
        showFilters: false,
        mobileName: '',
        pageTitle: 'Cannes Lions - Network Companies',
      },
    },
    {
      path: 'canneslionsbrandrankingspreview',
      name: 'cannes-lions-brand-rankings-preview',
      component: () => import('@/biDashboard/awards/pages/CannesLionsBrandRankingsPreview.vue'),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        showRightPanel: false,
        showFilters: false,
        mobileName: '',
        pageTitle: 'Cannes Lions - Brand Rankings [preview]',
      },
    },
  ],  
};
