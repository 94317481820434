import { Asset } from '@/lib/business/models';

export const AssetEditProperty = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  SUMMARY: 'summary',
  SUBMISSION_DATE: 'submissionDate',
};

/**
 * EditableAsset encapsulates the document that the user is trying to update and the property
 * of the document being updated.
 * @param document {InsightContent}
 * @param documentInsight {DocumentInsight}
 * @param property {String}
 * @constructor
 */
const EditableAsset = function EditableAsset(document, documentInsight, property) {
  console.log(document);
  console.log(documentInsight);
  this.asset = documentInsight.asset || new Asset({ docId: document.id });

  if (this.asset.id <= 0) {
    this.asset.docId = documentInsight.id;
  }

  this.document = document;
  this.property = property;
  this.inputType = property === AssetEditProperty.SUBMISSION_DATE ? 'date' : 'text';

  const self = this;

  /* next getter and setter will return the appropriate property that needs to be updated */
  Object.defineProperty(self, 'editableProperty', {

    get() {
      return self.asset[self.property] || null;
    },

    set(value) {
      if (self.property in self.asset) {
        self.asset[self.property] = value;
      }
    },
  });

  this.originalValue = this.editableProperty;
};

export default EditableAsset;
