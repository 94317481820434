class StarfishRouteHelper {
  alternateRoutes = {};

  /**
   * Sets an alternate route for the original route
   * @param originalRoute
   * @param alternateRoute
   * @returns {StarfishRouteHelper}
   */
  setAlternateRoute(originalRoute, alternateRoute) {
    console.log('setting alternate route', originalRoute, alternateRoute);

    if (originalRoute !== alternateRoute) {
      this.alternateRoutes[originalRoute] = alternateRoute;
    }
    return this;
  }

  /**
   * Returns a route alternate to the original one if it's registered
   * @param originalRoute
   * @returns {string}
   */
  getRoute(originalRoute) {
    if (originalRoute in this.alternateRoutes) {
      return this.alternateRoutes[originalRoute];
    }

    return originalRoute;
  }
}

export default StarfishRouteHelper;
