import {
  mapState,
  mapMutations,
  mapActions,
} from 'vuex';

const getQueryWithNewOrderBy = (query) => (newOrderBy) => {
  const { orderBy, ...rest } = query;

  if (newOrderBy == null || newOrderBy === '') {
    return rest;
  }

  return { ...rest, orderBy: encodeURI(newOrderBy) };
};

const getQueryWithNewDocumentsPerPage = (query) => (newCount) => {
  const { documentsPerPage, ...rest } = query;

  if (newCount == null || newCount === 0 || Number.isNaN(newCount)) {
    return { documentsPerPage: 30, ...rest };
  }

  return { ...rest, documentsPerPage: newCount };
};

const getQueryWithNewPage = (query) => (newPage) => {
  const { page, ...rest } = query;

  if (newPage == null || newPage === 0 || Number.isNaN(newPage)) {
    return { page: 1, ...rest };
  }

  return { ...rest, page: newPage };
};

export default {
  computed: {
    ...mapState('discovery', [
      'rowsPerPage',
    ]),
    ...mapState('common', [
      'deploymentIndex',
    ]),

    documentsPerPage() {
      const { documentsPerPage } = this.$route.query;
      const docsPerPage = parseInt(documentsPerPage, 10) || this.rowsPerPage;
      if (docsPerPage == null || docsPerPage === 0 || Number.isNaN(docsPerPage)) {
        return 30;
      }
      return docsPerPage;
    },

    currentPageNumber() {
      const { page } = this.$route.query;
      const pageNumber = parseInt(page, 10) || 1;
      if (pageNumber == null || pageNumber === 0 || Number.isNaN(pageNumber)) {
        return 1;
      }
      this.setPageWithoutInitiatingSearch(pageNumber);
      return pageNumber;
    },
  },
  methods: {
    ...mapActions('discovery', [
      'setPageWithoutInitiatingSearch',
    ]),
    ...mapMutations('discovery', [
      'setDocumentsPerPage',
    ]),
    addFacetsToURL(newQuery) {
      const query = this.facetList.updateQuery(newQuery || this.$route.query || {});
      this.$router.push({ query });
    },
    clearSearchStringFromUrl(newQuery) {
      const query = { ...(newQuery || this.$route.query), q: '', semantic: false };

      this.$router.push({ query });
    },
    async addOrderByToUrl(newOrderBy) {
      // we need to avoid the duplicate navigation issue. So, if the new order by is same os the
      // old one, we ignore.
      const existingOrderBy = decodeURIComponent(this.$route.query?.orderBy || '');
      if (newOrderBy === existingOrderBy) {
        return;
      }
      const resultQuery = getQueryWithNewOrderBy(this.$route.query)(newOrderBy);
      const query = await this.removePageNumberFromUrl(resultQuery);
      await this.$router.push({ query });
    },

    async addDocumentsPerPageToUrl(newDocumentsPerPage) {
      const existingDocumentsPerPage = this.$route.query?.documentsPerPage;
      if (newDocumentsPerPage === existingDocumentsPerPage) {
        return;
      }
      const resultQuery = getQueryWithNewDocumentsPerPage(this.$route.query)(newDocumentsPerPage);
      const query = await this.removePageNumberFromUrl(resultQuery);
      await this.$router.push({ query });
    },

    async addPageNumberToUrl(page) {
      const oldPage = this.$route.query?.page;
      if (page === oldPage) {
        return;
      }
      const query = getQueryWithNewPage(this.$route.query)(page);
      await this.$router.push({ query });
    },

    async removePageNumberFromUrl(query) {
      const { page, ...rest } = query;
      return { ...rest };
    },

    async updateDocumentsPerPage(value) {
      this.resetCurrentPage();
      await this.setDocumentsPerPage(value);
      await this.addDocumentsPerPageToUrl(value);
    },

    resetCurrentPage() {
      this.setPageWithoutInitiatingSearch(1);
    },

    setNumberOfDocumentsPerPage() {
      const docsPerPage = this.documentsPerPage || 30;
      this.setDocumentsPerPage(docsPerPage);
    },

    setPageNumner() {
      const page = this.currentPageNumber || 1;
      this.setPageWithoutInitiatingSearch(page);
    },

    /**
     *
     * @param facet{Facet}
     */
    startSearchWithFacet(facet) {
      const query = {
        q: '',
        facets: encodeURI(facet.queryString),
      };
      this.$router.push({ name: 'indexSearch', parameters: { indexId: this.deploymentIndex.id }, query });
    },

    startSearchByAssetStream(facet, order) {
      const query = {
        ...(facet?.queryString && { facets: encodeURI(facet.queryString) }),
        ...(order && { orderBy: order }),
      };
      this.$router.push({ name: 'indexSearch', parameters: { indexId: this.deploymentIndex.id }, query });
    },

    startKeywordSearch(keyword) {
      const query = {
        q: keyword,
      };

      this.$router.push({ name: 'Search', query });
    },
  },
};
