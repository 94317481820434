import { Observable } from 'rxjs';
import moment from 'moment';

class Metric {
  name = '';

  count = 0;

  action = '';

  filter = null;

  data = [];

  dataObservable = null;

  constructor(name, count, action, filter = () => true) {
    this.name = name;
    this.count = count;
    this.action = action;
    this.filter = filter;
  }

  /**
   *
   * @param data{}
   * @returns {Metric}
   */
  setData(data) {
    this.dataObservable = new Observable((observer) => {
      const inputData = data || [];
      if (this.filter == null) {
        observer.next(inputData);
      }

      const documents = data
        .documents?.filter(this.filter)
        .sort((a, b) => moment(a.created || new Date()).diff(b.created || new Date()));

      observer.next(documents);
    });

    return this;
  }
}

export default Metric;
