export default {
  // errors
  hasUploaderError: (state) => {
    const errors = state.assetInfos.filter((ai) => ai.HasError);
    const hasError = (errors.length > 0);
    return hasError;
  },
  metadataDataForServer: (state) => {
    const newSetFieldArray = [];

    Object.entries(state.selectedMetadataSetFieldValuesMulti).forEach((entry) => {
      const [key, value] = entry;
      console.log(key, value);
      newSetFieldArray.push({ setField: key, setFieldValues: value });

      return newSetFieldArray;
    });

    return newSetFieldArray;
  },
};
