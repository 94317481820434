const ExternalUrlActionTypes = {
  EMBED: 'embed',
  NEW_WINDOW: 'new window',
  POWER_BI_EMBED: 'power bi embed',
  POWER_BI_EMBED_WITH_ACTIONS_BAR: 'power bi embed w/actions bar',
  POWER_BI_EMBED_WITH_STARFISH_TOOLBAR: 'power bi embed w/starfish toolbar',
  POWER_BI_EMBED_WITH_ACTIONS_AND_STARFISH_TOOLBAR: 'power bi embed w/actions & starfish tool bar',
  NONE: 'none',
}; 

export default ExternalUrlActionTypes;
