import { mix } from 'mixwith';
import { ConnectionMixin, ContainsQueryMixin } from '@/lib/graphql/mixins';
import DeploymentConnectionWithFlatIndicesMixin
from '@/admin/deployments/models/mixins/DeploymentConnectionWithFlatIndicesMixin';
import DeploymentWithIndices from '@/admin/deployments/models/DeploymentWithIndices';

const DeploymentTreeConnection = class DeploymentTreeConnection extends
  mix(ConnectionMixin(DeploymentWithIndices)).with(
    ContainsQueryMixin,
    DeploymentConnectionWithFlatIndicesMixin,
  ) {
  get deployments() {
    return this.items;
  }
};

export default DeploymentTreeConnection;
