// import ActionTypes from './ActionTypes';
import { deploymentsClient } from '../client/DeploymentsClient';
import mutationTypes from './MutationTypes';

export default {
  async getDeployments({ commit, state }) {
    if (!state.userEditStaticDataFetched) {
      const deployments = await deploymentsClient.getDeployments();
      commit(mutationTypes.setDeployments, deployments);
    }
  },
};
