import SearchParameters from '@/lib/search/discovery/classes/SearchParameters';
import page from '@/lib/ui/models/Page';

class CollectionAssetSearchInput {
  #collectionId = 0;

  /**
   * @type{SearchParameters}
   */
  searchParameters;

  constructor() {
    this.searchParameters = new SearchParameters('');
  }

  get collectionId() {
    return this.#collectionId;
  }

  /**
   *
   * @param value{number}
   */
  set collectionId(value) {
    if (value !== this.collectionId) {
      this.reset();
    }

    this.#collectionId = value;
  }

  get itemsPerPage() {
    return this.searchParameters.documentsPerPage;
  }

  set itemsPerPage(value) {
    this.searchParameters.documentsPerPage = value;
  }

  get pageNum() {
    return this.searchParameters.page;
  }

  set pageNum(value) {
    this.searchParameters.page = value;
  }

  get searchQuery() {
    return this.searchParameters.q;
  }

  set searchQuery(value) {
    this.searchParameters.q = value;
  }

  get orderBy() {
    return this.searchParameters.orderBy;
  }

  set orderBy(value) {
    this.searchParameters.orderBy = value;
  }

  /**
   *
   * @returns {CollectionAssetSearchInput}
   */
  reset() {
    this.searchParameters = new SearchParameters();
    this.itemsPerPage = 30;
    this.pageNum = 1;

    return this;
  }

  /**
   *
   * @param filters
   * @returns {CollectionAssetSearchInput}
   */
  setFilters(filters = []) {
    this.searchParameters.filters = filters;
    return this;
  }
}

export default CollectionAssetSearchInput;
