import UserWithRole from '@/lib/business/models/UserWithRole';
import { userFactory } from '@/lib/business/models/User';
import moment from 'moment/moment';
import Collection, { collectionListFactory } from '@/lib/business/models/Collection';
import { discoveryThemeFactory } from '@/lib/themes/DiscoveryTheme';
import setEditableFeaturesProperty from '@/lib/business/models/editable/editableType';

const idGetter = (collection) => {
  if (collection instanceof Collection) {
    return collection.id;
  }

  return collection;
};

const filterUserByRole = (groupUsers) => (filter) => groupUsers?.filter(filter)
  .map((user) => user.user);

const Group = function Group(group, currentUser = null) {
  this.group = group;

  this.pinned = group?.pinned;
  this.starred = group?.starred;
  this.id = group?.groupId;
  this.groupId = this.id;
  this.name = group?.name;
  this.description = group?.description;
  this.isFeatured = group?.isFeatured;
  this.isFeaturedDate = group?.isFeaturedDate;
  this.collectionGroupsCount = group?.collectionGroupsCount;
  this.isConfidential = group?.isConfidential;
  this.featuredDeploymentIndexes = group?.featuredDeploymentIndexes || {};
  this.currentUser = currentUser;

  this.isMember = false;
  this.isOwner = false;
  this.isEditor = false;
  this.isEditable = false;

  /**
   *
   * @type {User}
   */
  this.createdByUser = userFactory.create(group?.createdByUser);

  /**
   *
   * @type {User}
   */
  this.updatedByUser = userFactory.create(group?.updatedByUser);

  /**
   *
   * @type {moment.Moment}
   */
  this.created = moment(group?.created);

  this.updatedByUserId = group?.updatedByUserId;
  this.createdByUserId = group?.createdByUserId;

  /**
   *
   * @type {moment.Moment}
   */
  this.updated = moment(group?.updated);

  this.theme = discoveryThemeFactory(group?.theme);

  this.collections = collectionListFactory(group?.collections);

  const self = this;

  Object.defineProperty(self, 'rawLocation', {
    get() {
      return { name: 'group-details-collections', params: { id: self.id } };
    },

  });

  this.setMembers(group?.groupUsers);
  if (currentUser != null) {
    this.updateIsMember(this.currentUser.email);
  }

  this.customizationSettings = null;

  this.viewsCount = 0;
  this.appObjectSettingId = group?.appObjectSettingId;
};

Object.defineProperty(Group.prototype, 'thumbnailImage', {
  get() {
    return this.theme && this.theme?.thumbnailImage;
  },
});

Group.prototype.updateId = function updateId(group) {
  if (!group) {
    return this;
  }

  this.groupId = group.groupId;
  this.id = this.groupId;
  this.groupUsersCount = group.groupUsersCount;

  return this;
};

Group.prototype.setMembers = function setMembers(groupUsers) {
  if (groupUsers == null) {
    return this;
  }

  /**
   * @type{UserWithRole[]}
   */
  this.groupUsers = groupUsers?.map((gu) => new UserWithRole(gu));

  const memberFactory = filterUserByRole(this.groupUsers);
  /**
   *
   * @type {User[]}
   */
  this.editors = memberFactory(({ role }) => role.code === 'group_editor');

  /**
   *
   * @type {User[]}
   */
  this.viewers = memberFactory(({ role }) => role.code === 'group_viewer');

  /**
   * @type {User[]}
   */
  this.owners = memberFactory(({ role }) => role.code === 'group_owner');

  this.groupUsersCount = groupUsers?.length || 0;
  return this;
};

Group.prototype.shareableObject = function shareableObject() {
  return {
    name: this.name,
    type: 'Group',
    location: this.rawLocation,
  };
};

/**
 * This method appends new collections to the existing collection list
 * @param collections{Collection[]}
 */
Group.prototype.addCollections = function addCollections(collections) {
  this.collections = [...this.collections, ...collections];
  this.collectionGroupsCount = this.collections.length;
};

/**
 * Removes a collection from a group's collection list.
 * @param collection{Collection|int}
 */
Group.prototype.removeCollection = function removeCollection(collection) {
  const collectionId = idGetter(collection);
  this.collections = this.collections.filter((c) => collectionId !== c.id);
};

Group.prototype.isFeaturedInIndex = function isFeaturedInIndex(indexId) {
  return indexId in this.featuredDeploymentIndexes && this.featuredDeploymentIndexes[indexId];
};

setEditableFeaturesProperty(Group.prototype, {
  hasConfidentialProperty: {
    get() {
      return true;
    },
  },
  hasIsSmartValue: {
    get() {
      return false;
    },
  },
});

/**
 *
 * @param userEmail{string}
 * @returns {Group}
 */
Group.prototype.updateIsMember = function updateIsMember(userEmail) {
  const isUserPresent = (userObj) => userObj.email.toLowerCase() === userEmail.toLowerCase();

  this.isMember = this.groupUsers.some((gu) => isUserPresent(gu.user));
  // console.log('Is user member', this.isMember);

  this.isOwner = this.owners?.some(isUserPresent);

  this.isEditor = this.editors?.some(isUserPresent);

  this.isEditable = this.isOwner || this.isEditor;

  this.collections = this.collections?.map((collection) => collection.updateIsMember(userEmail));

  return this;
};

Group.prototype.setCustomizationSettings = function setCustomizationSettings(settings) {
  this.customizationSettings = settings;
};

Group.prototype.setViewsCount = function setViewsCount(count) {
  this.viewsCount = count;
};

export default Group;

export const groupFactory = (group, user = null) => new Group(group, user);
