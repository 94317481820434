const facetFieldMapping = {
  'Agencyclients/name': 'science',
  'Ogilvyclients/name': 'science',
  brandNames: 'lightbulb',
  'Agencytopics/name': 'science',
  'Ogilvytopics/name': 'science',
  people: 'lightbulb',
  organizations: 'lightbulb',
  locations: 'lightbulb',
  keyphrases: 'lightbulb',
  'Agencyawards/name': 'science',
  'Ogilvyawards/name': 'science',
  'categories/name': 'lightbulb',
  landmarkNames: 'lightbulb',
  celebrityNames: 'lightbulb',
  'indexer_videoIndexer/vidsummarizedInsights/brands/name': 'videocam',
  'videoIndexer/summarizedInsights/brands/name': 'videocam',
  'indexer_videoIndexer/vidsummarizedInsights/faces/name': 'videocam',
  'indexer_videoIndexer/summarizedInsights/faces/name': 'videocam',
  'indexer_videoIndexer/summarizedInsights/namedPeople/name': 'videocam',
  'indexer_videoIndexer/vidsummarizedInsights/keywords/name': 'videocam',
  'indexer_videoIndexer/summarizedInsights/keywords/name': 'videocam',
  'indexer_videoIndexer/vidsummarizedInsights/topics/iptcName': 'videocam',
  'indexer_videoIndexer/vidsummarizedInsights/topics/name': 'videocam',
  'indexer_videoIndexer/summarizedInsights/topics/iptcName': 'videocam',
  'indexer_videoIndexer/vidsummarizedInsights/namedLocations/name': 'videocam',
  'indexer_videoIndexer/summarizedInsights/namedLocations/name': 'videocam',
  'indexer_videoIndexer/summarizedInsights/topics/name': 'videocam',
  metadata_storage_file_extension: 'lightbulb',
  'objects/object': 'lightbulb',
  'description/tags': 'lightbulb',
  isOgilvyOS: 'science',
  'uploader/keywords': 'sell',
  'uploader/collections': 'sell',
  'uploader/brands': 'sell',
  'uploader/products': 'sell',
  'uploader/city': 'sell',
  'uploader/topics': 'sell',
  'uploader/archive_tier': 'sell',
  'uploader/tags': 'sell',
  'uploader/contributors': 'sell',
  'uploader/country': 'sell',
  'uploader/client': 'sell',
  'uploader/confidential_users': 'sell',
  'uploader/capabilities': 'sell',
  'uploader/business_transformations': 'sell',
  'uploader/organizations': 'sell',
  'uploader/organization': 'sell',
  'uploader/content_type': 'sell',
};

const defaultFacetIcon = 'lightbulb';

export const isAutoTag = (key) => (key || '').startsWith('indexer_') || (key || '')
  .startsWith('metadata_storage_');

export const isManualTag = (key) => !isAutoTag(key);

const customTags = [
  'indexer_Agencytopics/name',
  'indexer_Agencyawards/name',
  'indexer_Agencyclients/name',
  'indexer_Agencykeywords/name',
];

export const isCustomTag = (key) => customTags.includes(key);

export const isVideoTag = (key) => key.startsWith('indexer_videoIndexer');

export const getFacetIcon = (key = 'default') => {
  if (key.startsWith('uploader/')) return 'sell';
  return facetFieldMapping[key] || defaultFacetIcon;
};

/* const getFacetIconByType = (isManual, isCustomTag, isVideoTag) => {
  if (isManual) {
    return 'sell';
  }

  if (isCustomTag) {
    return 'science';
  }

  if (isVideoTag) {
    return 'videocam';
  }

  return 'lightbulb';
};
*/

const getFacetIconByKey = (key) => {
  if (isManualTag(key)) {
    return 'sell';
  }

  if (isCustomTag(key)) {
    return 'science';
  }

  if (isVideoTag(key)) {
    return 'videocam';
  }

  return 'lightbulb';
};

export default getFacetIconByKey;
