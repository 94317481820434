const setContentUrl = (urlPath) => {
  if (!urlPath) {
    return '';
  }
  const host = window.location.origin;
  const contentPageUrl = `${host}${urlPath}`;
  return contentPageUrl;
};

class AssetReport {
  assetId = 0;

  assetName = '';

  userId = 0;

  userFullName ='';

  userEmail = '';

  date = '';

  year = 0;

  month = '';

  day = '';

  reportName = '';

  title = '';

  docId = '';

  url = '';

  deploymentIndexName = '';
  
  deploymentIndexId = 0;

  constructor(assetReport) {
    if (assetReport == null) {
      return;
    }

    this.assetId = assetReport.assetId;
    this.assetName = assetReport.assetName;
    this.userId = assetReport.userId;
    this.userFullName = assetReport.userFullName;
    this.userEmail = assetReport.userEmail;
    this.date = assetReport.date;
    this.year = assetReport.year;
    this.month = assetReport.month;
    this.day = assetReport.day;
    this.reportName = assetReport.reportName;
    this.title = assetReport.title;
    this.docId = assetReport.docId;
    this.deploymentIndexId = assetReport.deploymentIndexId;
    this.deploymentIndexName = assetReport.deploymentIndexName;
  }

  static fromObject(data) {
    if (data == null) {
      return null;
    }

    return new AssetReport(data);
  }

  static fromObjects(list) {
    if (list == null) {
      return null;
    }

    return list.map((data) => this.fromObject(data));
  }

  generateContentPageUrl(router) {
    const resolvedRoute = router.resolve({
      name: 'content-page',
      params: { id: this.docId },
    });
  
    const path = resolvedRoute.href;
    this.url = setContentUrl(path);
    return this;
  }
}

export default AssetReport;
