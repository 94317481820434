import moment from 'moment/moment';
import TimeSeriesData from '@/lib/charts/timeSeriesHelper';

export const byCreatedDate = (item) => moment.utc(item.timeRange)
  .local()
  .startOf('day')
  .valueOf();

export const byPublishedDate = (item) => {
  const { publishedDate } = item;
  console.log(publishedDate);

  if (publishedDate == null) {
    return null;
  }

  const mpd = moment(publishedDate)
    .startOf('day')
    .valueOf();

  console.log('Moment published Date', mpd);
  return mpd;
};

const groupListFn = (groupBy) => (list) => list && list.reduce((acc, item) => {
  const key = groupBy(item);
  console.log('Got key', key);
  if (key == null) {
    return acc;
  }

  if (!(key in acc)) {
    acc[key] = [];
  }

  (acc[key]).push(item);
  return acc;
}, {});

const ChartGroup = function ChartGroup(key) {
  this.key = key;
  this.data = [];
};

ChartGroup.prototype.add = function add(item) {
  this.data.push(item);
};

ChartGroup.prototype.count = function count(groupFunction, series) {
  if (groupFunction == null) {
    return this.data?.count;
  }

  const groups = groupFunction(this.data);

  if (series == null) {
    return Object.values(groups)?.map((values) => values.length);
  }

  const values = series.map((key) => (groups[key] && [key, (groups[key]).length]) || [key, 0]);
  console.log(`Returning values for ${this.key}`, values);
  return values;
};

ChartGroup.prototype.getData = function data(groupFunction, series) {
  return { key: this.key, value: this.count(groupFunction, series) };
};

const ChartList = function ChartList(groups) {
  this.groups = groups;
  this.keys = Object.keys(groups);
  this.values = [];
  this.data = [];
  this.startDate = moment().startOf('day').valueOf();
  this.timeSeriesData = null;

  Object.defineProperty(this, 'hasData', {
    get() {
      return this.data && this.data.length > 0;
    },
  });
};

ChartList.prototype.getTimeSeriesData = function timeSeriesData(label, yLabel, color) {
  if (this.data == null) {
    return null;
  }

  if (this.timeSeriesData != null) {
    return this.timeSeriesData;
  }

  this.timeSeriesData = new TimeSeriesData(this, label, yLabel, color);
  return this.timeSeriesData;
};

ChartList.prototype.setData = function setData(groupBy, series) {
  const chartGroups = Object.values(this.groups);
  chartGroups.forEach((group) => {
    const data = group.getData(groupBy, series);
    this.data.push(data);
    this.values.push(data.value);
  });

  return this;
};

/**
 *
 * @param data
 * @returns {function(*=): function(*): (null|ChartList)}
 */
const createTimeSeries = (data) => (groupBy) => (keyExtractor) => {
  if (data == null || data.length <= 0) {
    return null;
  }

  const yearMonths = [...new Set(data?.map(groupBy))]
    .filter((value) => value != null);
  const groups = data?.reduce((acc, item) => {
    const key = keyExtractor(item);
    let keys = key;
    if (!Array.isArray(key)) {
      keys = [key];
    }

    acc = keys.reduce((racc, itemKey) => {
      if (!(itemKey in racc)) {
        racc[itemKey] = new ChartGroup(itemKey);
      }

      (racc[itemKey]).add(item);

      return racc;
    }, acc);

    return acc;
  }, {});

  const chartList = new ChartList(groups);
  chartList.setData(groupListFn(groupBy), yearMonths);

  if (data != null && data.length > 0) {
    const earliest = data[data.length - 1];
    chartList.startDate = moment(earliest.created)
      .startOf('day')
      .valueOf();
  }

  console.log(chartList);
  return chartList;
};

const AssetDataInsight = function AssetDataInsight(data, indexId) {
  this.data = data;
  this.timeSeries = null;
  this.chartFactory = createTimeSeries(data);

  this.deploymentIndexId = indexId;

  Object.defineProperty(this, 'indexId', {
    get() {
      return this.deploymentIndexId;
    },
    set(value) {
      this.deploymentIndexId = value;
    },
  });
};

/**
 *
 * @returns {ChartList}
 */
AssetDataInsight.prototype.getFileExtensionData = function groupByFileExtension(groupValuesBy) {
  return this.chartFactory(groupValuesBy || byCreatedDate)((item) => item.fileExtension);
};

AssetDataInsight.prototype.getFileTypeCategoryData = function groupByFileExtension(groupValuesBy) {
  return this.chartFactory(groupValuesBy || byCreatedDate)((item) => item.fileTypeCategory);
};

AssetDataInsight.prototype.getTimeSeries = function getTimeSeries(keyExtractor) {
  return this.chartFactory(byCreatedDate)(keyExtractor);
};

AssetDataInsight.prototype.getContentTypeSeries = function getContentType(groupValuesBy) {
  return this.chartFactory(groupValuesBy || byCreatedDate)((item) => item.contentTypeGroups);
};

AssetDataInsight.prototype.getCreatedByTimeSeries = function getContentType(groupValuesBy) {
  return this.chartFactory(groupValuesBy || byCreatedDate)((item) => item.createdBy);
};

export default AssetDataInsight;
export { ChartList, ChartGroup };
