const WithAuthMixin = (superClass) => class extends superClass {
  get lastLogin() {
    return this.auth?.lastLogin;
  }

  get profilePic() {
    return this.auth?.profilePic;
  }
};

export default WithAuthMixin;
