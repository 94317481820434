class SelectedSet {
  selectedValueMap = {};

  constructor(data) {
    const values = data?.selectedValues || [];

    this.selectedValueMap = values.reduce((acc, value) => {
      const { flattenedFieldCode, softDeleted } = value;
      const existingList = acc[flattenedFieldCode] || [];

      if (!softDeleted) {
        acc[flattenedFieldCode] = [...existingList, value];
      }

      return acc;
    }, {});
  }

  getSelectedValue(property) {
    if (property in this.selectedValueMap) {
      return this.selectedValueMap[property];
    }

    return null;
  }

  /**
   *
   * @param property
   * @returns {*|*[String]|null}
   */
  getValue(property) {
    const value = this.getSelectedValue(property);
    if (value == null) {
      return null;
    }

    const values = value?.map((val) => val.value) || [];
    return values;
  }
}

export default SelectedSet;
