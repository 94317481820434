<template>
    <v-overlay :value="appBusy" opacity="0.9" z-index="2000">
      <v-row align="center" class="flex-column" no-gutters justify="center"
      style="max-width: 50vw;">
        <div style="height: 150px;">
          <div v-show="!showBusyButtons">
            <loader-graphic width="200" height="143" />
          </div>
          <div v-show="showBusyButtons">
            <v-icon size="96" class="material-symbols-outlined">check_circle</v-icon>
          </div>
        </div>

        <div class="mt-3 white--text font-weight-bold"
          style="font-size:1.25rem; max-width: 50vw;"
            >{{ appBusyMessage }}</div>

      <!-- <upload-file-list></upload-file-list>-->

        <v-row align="center" class="flex-column upload-file-list"
               no-gutters justify="center" v-show="busyErrors.length > 0" style="max-width:calc(50vw + 32px)">

          <div v-show="errorLength > 0" class="mt-2" style="max-width:calc(50vw + 32px)">
            <v-card color="grey" flat tile >
              <v-card-text class="pa-0 pb-2 black error--text"
              ><div class="pt-4 px-4">Errors</div>
                <ul class="overflow-y-auto ma-4 upload_other_errors">
                  <li v-for="(busyError, index) in busyErrors" :key="index">
                    {{ busyError }}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </div>

        </v-row>
      </v-row>

      <v-row v-show="showBusyButtons" justify="center" class="mt-3" style="max-width: 50vw;">
        <v-btn
        class="mr-2 brandcolour busybutton"
          v-for="button in appBusyButtons"
          :key="button.label"
          :disabled="button.disabled"
          @click.stop
          @click.prevent
          @click="dispatchAction(button.action)">{{button.label}}</v-btn>
      </v-row>

    </v-overlay>
</template>

<script>
import { mapState } from 'vuex';
import LoaderGraphic from '@/components/LoaderGraphic.vue';
// import UploadFileList from '@/components/uploader/UploadFileList.vue';

export default {
  name: 'BusyCursor',

  components: {
    // UploadFileList,
    LoaderGraphic,
  },

  computed: {
    ...mapState('common', [
      'appBusy',
      'appBusyMessage',
      'appBusyButtons',
      'showBusyButtons',
      'showSuccessfulIcon',
      'showUnSuccessfulIcon',
      'busyErrors',
    ]),
    errorLength() {
      return this.busyErrors.length;
    },
  },
  data: () => ({
  }),
  methods: {
    dispatchAction(action) {
      this.$store.dispatch(action, { root: true });
    },
  },
};
</script>
