class PanelManager {
  openPanels = {};

  openStack = [];

  openPanel(panel) {
    this.openPanels[panel] = true;
    this.openStack = [...this.openStack, panel];
  }

  closePanel(panel) {
    delete this.openPanels[panel];
    this.openStack = this.openStack.filter((p) => panel !== p);
  }

  close() {
    const panel = this.openStack.pop();
    this.closePanel(panel);
  }

  isOpen(panel) {
    return (panel in this.openPanels) && this.openPanels[panel];
  }

  get topPanel() {
    const { length } = this.openStack;
    if (length === 0) {
      return null;
    }

    const panel = this.openStack[length - 1];
    return panel;
  }

  get hasUnclosedPanels() {
    return this.openStack.length > 0;
  }

  emptyStack() {
    this.openStack = [];
  }
}

export default PanelManager;
