export default {
  children: [
    {
      path: 'home',
      name: 'content-page',
      meta: {
        mobileName: '',
        pageTitle: 'Content Page',
      },
      component: () => import(
        /* webpackChunkName: "analyzer-home" */ '@/components/content/analyzer/pages/AnalyzerHomePage.vue'
      ),
    },
    {
      path: 'insights',
      name: 'analyzer-insights',
      meta: {
        mobileName: '',
        pageTitle: 'Content Insights',
      },
      component: () => import(
        /* webpackChunkName: "analyzer-insights" */ '@/components/content/analyzer/pages/AnalyzerInsightsPage.vue'
      ),
    },
    {
      path: 'tags',
      name: 'analyzer-tags',
      meta: {
        mobileName: '',
      },
      component: () => import(
        /* webpackChunkName: "analyzer-insights" */ '@/components/content/analyzer/pages/AnalyzerTagsPage.vue'
      ),
    },
  ],
};
