import helper from '@/lib/util/dataSimulator/helper';

const ogilvyColors = [
  '#D6D972',
  '#24298F',
  '#F46161',
  '#848484',
];

const getRandomColors = (count) => {
  const colorLength = ogilvyColors.length;
  return helper.randomIndices(0, count).map((index) => ogilvyColors[index % colorLength]);
};

const getRandomColor = () => ogilvyColors[helper.getRandomInt(0, ogilvyColors.length - 1)];

const colors = {
  getRandomColor,
  getRandomColors,
};

export default colors;
