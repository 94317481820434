/**
 *
 * @param index{DeploymentIndex}
 * @param data
 * @returns {DeploymentIndex}
 */
export const updateDeploymentIndex = (index, data = {
  id: null,
  deploymentId: 0,
  deploymentIndexId: 0,
  name: '',
}) => {
  const {
    id,
    deploymentIndexId,
    name,
    deploymentId,
  } = data;

  if (name) index.name = name;
  if (deploymentIndexId) {
    index.deploymentIndexId = deploymentIndexId;
  }

  if (id) {
    index.id = id;
  }

  if (deploymentId) {
    index.deploymentId = deploymentId;
  }

  return index;
};

class DeploymentIndex {
  deploymentIndexId = 0;

  name = '';

  id = null;

  constructor(data) {
    updateDeploymentIndex(this, data);
  }
}

export default DeploymentIndex;
