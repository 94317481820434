import { mix } from 'mixwith/mixwith';
import assetStreamRestApiMixin from '@/documentAssets/client/mixins/assetStreamRestApiMixin';
import starfishHttpClient from '@/api/starfishHttpClientFactory';
import AssetStreamsService from '@/documentAssets/client/AssetStreamsService';

export default class AssetStreamRestService extends mix(AssetStreamsService)
  .with(assetStreamRestApiMixin) {

}

export const assetStreamRestServiceFactory = () => new AssetStreamRestService(starfishHttpClient());
