import StateObserver from '@/plugins/DiscoveryObserver/StateObserver';
import moment from 'moment';

class StateNotifier {
  constructor(initialValue = null, name = '') {
    this.name = name;
    this.value = initialValue;
    this.setTime = null;
    this.observer = new StateObserver();
    this.key = `${this.name}${moment(new Date()).valueOf().toString()}`;
  }

  execute(callback) {
    // console.log(this.name, 'Waiting to execute', callback);
    if (this.setTime == null) {
      // console.log(this.name, 'Subscribing to execute');
      this.observer.subscribe(this.key, callback);
    } else if (this.value != null) {
      // console.log(this.name, 'Calling callback immediately', callback);
      callback(this.value);
    }
  }

  setValue(value) {
    this.value = value;
    this.setTime = moment(new Date());
    this.observer.notify(this.key, this.value);
  }
}

export default StateNotifier;
