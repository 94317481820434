import { mix } from 'mixwith/mixwith';
import Stream from '@/lib/business/models/Stream';
import FetchAssetDetailsChoices from '@/lib/business/models/FetchAssetDetailsChoices';
import InteractionInsight from '@/lib/insights/models/InteractionInsight';
import GraphQlStreamMixin from './GraphQlStreamMixin';

class AssetStream extends mix(Stream).with(GraphQlStreamMixin) {
  fieldKey = '';

  /**
   *
   * @type {AssetConnection}
   */
  items = null;

  updated = new Date();

  renderer = 'default';

  thumbnailType = 'default';

  assetDetailChoices = new FetchAssetDetailsChoices();

  constructor(name, code, filters, order, fieldKey, renderer = 'default', thumbnailType = 'default') {
    super(name, code, filters, order);
    this.fieldKey = fieldKey;
    this.renderer = renderer;
    this.thumbnailType = thumbnailType;
  }

  setMetric(metrics) {
    const assets = this.items?.assets || [];
    assets.forEach((asset) => {
      const { assetId } = asset;
      if (assetId in metrics) {
        asset.interactionInsight = metrics[assetId];
      }
      asset.title = 'yahoo';
    });

    this.updated = new Date();
  }

  /**
   *
   * @param choice{FetchAssetDetailsChoices}
   * @returns {AssetStream}
   */
  setDetailChoices(choice) {
    this.assetDetailChoices = choice;
    return this;
  }

  static fromObject({
    name,
    code,
    filters,
    order,
    renderer,
    fetchThumbnails = false,
    thumbnailType = 'default',
    fetchVisibility = false,
    fetchInteractionMetrics = false,
  }) {
    const stream = new AssetStream(name, code, filters, order, '', renderer);
    return stream.setDetailChoices(new FetchAssetDetailsChoices(
      fetchThumbnails,
      thumbnailType,
      fetchInteractionMetrics,
      fetchVisibility,
    ));
  }
}

export default AssetStream;

export const assetStreamCreator = ({ name, code, thumbnailType }) => {
  const stream = new AssetStream(name, code);
  stream.thumbnailType = thumbnailType;
  stream.assetDetailChoices.thumbnailType = thumbnailType;

  return stream;
};
