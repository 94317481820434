import gql from 'graphql-tag';

export const userListFields = gql`
    fragment userFields on User {
      id,
      givenName,
      lastName,
      deploymentUsers{
        totalCount
      }
      deploymentIndexUsers{
        totalCount
      }
      auth{
        lastLogin
        status
        profilePic
      }
    }
`;

export const userDetailFields = gql`
  fragment userDetailFields on User {
    id
    givenName
    lastName
    email
    deploymentUsers {
      nodes{
        deployment {
          deploymentId
          name
        }
      }
      totalCount
    }
    deploymentIndexUsers {
      totalCount
      nodes {
        isCurrent
        deploymentIndex {
          id
          deploymentIndexId
          name
        }
      }
    }
  }
`;

export default {
  userListFields,
  userDetailFields,
};
