const createTypedList = (rawList, type) => {
  if (rawList == null || typeof rawList === 'undefined' || rawList.length === 0) {
    return [];
  }

  /* eslint new-cap: ["error", { "newIsCap": false }] */
  const result = rawList.map((item) => new type(item));
  return result;
};

const createTypedListAsync = (rawList, type, list) => {
  if (rawList == null || typeof rawList === 'undefined' || rawList.length === 0) {
    return [];
  }

  const result = list || [];
  rawList.forEach((item) => {
    /* eslint new-cap: ["error", { "newIsCap": false }] */
    result.push(new type(item));
  });

  return result;
};

/**
 * This mixins lets paged result classes have result list with elements that confirm to a type
 * @param itemClass
 * @returns {function(*): *}
 * @constructor
 */
const TypedListMixin = (type) => (superClass) => class extends superClass {
  /**
   *
   * @type {[type]|null}
   */
  #typedList = null;

  get items() {
    if (this.#typedList != null) {
      return this.#typedList;
    }

    this.#typedList = createTypedList(this.nodes, type, []);

    return this.#typedList;
  }

  get itemsAsync() {
    if (this.#typedList != null) {
      return this.#typedList;
    }

    this.#typedList = [];

    setTimeout(() => {
      createTypedListAsync(this.nodes, type, this.#typedList);
    });

    return this.#typedList;
  }

  setItems(items) {
    console.log('Setting typed list');
    this.#typedList = items;
  }
};

export default TypedListMixin;
