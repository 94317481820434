import { createIterableFromRawList } from '@/lib/util';
import VideoFace from '@/lib/search/discovery/classes/insights/video/VideoFace';
import VideoSentiment from '@/lib/search/discovery/classes/insights/video/VideoSentiment';
import VideoNamedLocation from '@/lib/search/discovery/classes/insights/video/VideoNamedLocation';
import VideoTopic from '@/lib/search/discovery/classes/insights/video/VideoTopic';
import content from '@/router/content';
import VideoKeyword from '@/lib/search/discovery/classes/insights/video/VideoKeyword';
import { videoBrandFactory } from '@/lib/search/discovery/classes/insights/video/VideoBrand';
import VideoTagInsight from '@/lib/search/discovery/classes/insights/video/VideoTagInsight';

const insightsInstanceFactory = {
  keywords: (keyword) => new VideoKeyword(keyword),
  faces: (face) => new VideoFace(face),
  sentiments: (sentiment) => new VideoSentiment(sentiment),
  namedLocations: (location) => new VideoNamedLocation(location),
  topics: (topic) => new VideoTopic(topic),
  brands: (brand) => videoBrandFactory(brand),
};

/**
 *
 * @param item{BaseVideoInsight}
 * @returns {boolean|*}
 */
const isListable = (item) => item.isListable;

const isListableList = (list) => list?.some(isListable);

class VideoInsight {
  #data = null;

  name = '';

  id = '';

  privacyMode = '';

  thumbnailVideoId = '';

  thumbnailId = '';

  /**
   *
   * @type {[VideoFace]}
   */
  faces = [];

  /**
   *
   * @type {[VideoSentiment]}
   */
  sentiments = [];

  /**
   *
   * @type {[VideoTopics]}
   */
  topics = [];

  /**
   *
   * @type {[VideoNamedLocation]}
   */
  namedLocations = [];

  /**
   *
   * @type {[VideoKeyword]}
   */
  keywords = [];

  /**
   * Duration of the video in seconds
   * @type {number}
   */
  durationInSeconds = 0;

  #complexFields = ['faces', 'brands', 'namedLocations', 'sentiments', 'topics', 'keywords'];

  #insightList = null;

  listableFields = [
    {
      name: 'Keywords',
      code: 'videoKeywords',
    },
    {
      name: 'Topics',
      code: 'videoTopics',
    },
    {
      code: 'namedLocations',
      name: 'Locations',
    },
    {
      code: 'videoFaces',
      name: 'Faces',
    },
  ];

  chartOptionFields = [
    {
      label: 'Sentiments',
      chartKey: 'sentiments',
      field: 'sentiments',
      isFaceInsight: false,
    },
    {
      label: 'Topics',
      chartKey: 'videoTopics',
      isFaceInsight: false,
      field: 'topics',
    },
    {
      label: 'Celebrities',
      chartKey: 'celebrities',
      isFaceInsight: false,
      field: 'faceNames',
    },
    {
      label: 'Keywords',
      chartKey: 'videoKeywords',
      isFaceInsight: false,
      field: 'keywords',
    },
    {
      chartKey: 'namedLocations',
      label: 'Video Locations',
      isFaceInsight: false,
      field: 'namedLocations',
    },
    {
      chartKey: 'videoFaces',
      label: 'Faces',
      isFaceInsight: false,
      field: 'faces',
    },
  ];

  constructor(data, totalDuration) {
    this.#data = data;

    this.durationInSeconds = totalDuration;

    if (data != null) {
      this.setInsights();
    }
  }

  setInsights() {
    this.#complexFields.map((field) => this.setField(field));

    this.name = content?.name;
    this.thumbnailId = content?.thumbnailId;
    this.thumbnailVideoId = content?.thumbnailVideoId;
  }

  /**
   *
   * @param fieldName
   * @returns {Promise<unknown>}
   */
  setField(fieldName) {
    this[fieldName] = createIterableFromRawList(
      insightsInstanceFactory[fieldName],
    )(this.#data[fieldName]);

    return this[fieldName];
  }

  get faceNames() {
    return this
      .faces?.filter((face) => !face.name.startsWith('Unknown'))
      .map((face) => face.name);
  }

  get chartOptions() {
    const hasValues = (key) => this[key] != null && isListableList(this[key]);

    const options = this.chartOptionFields.filter((option) => hasValues(option.field));
    return options;
  }

  createListableInsight() {
    const self = this;
    const list = this.chartOptionFields.map(({ label, field, chartKey }) => {
      const values = self[field];
      return new VideoTagInsight(label, chartKey, values);
    });

    this.#insightList = list;
    return this.#insightList;
  }

  get listableInsights() {
    if (this.#insightList == null) {
      this.#insightList = this.createListableInsight();
    }
    return this.#insightList;
  }
}

export default VideoInsight;
