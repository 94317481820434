<template>
  <v-card-text class="text-center pt-0 pb-15 searchexpandedpanelelement">
    <v-row justify="center" no-gutters class="search-query-types ml-6 ml-sm-0 flex-column flex-sm-row my-8">
      <v-radio-group v-model="isSemantic" row class="mb-2"
      hide-details>
        <v-radio label="Enter Keywords" :value="falseValue" />
        <v-radio label="Ask a question" :value="trueValue" />
      </v-radio-group>

      <v-checkbox label="Exact Phrase" v-model="isFullTextQuery" hide-details
      class="mb-2"
        :disabled="isSemantic"/>
    </v-row>
    <v-row justify="center" no-gutters>
      <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 6" :style="{'padding-left:68.64px;' : $vuetify.breakpoint.smAndUp}">
        <v-card
          class="search_box_wrapper"
          color="transparent"
          flat
          tile
        >
          <v-form ref="searchBox" @submit.prevent="search" id="flyOutSearchForm">
            <v-text-field v-model="queryString" ref="searchInput"
              class="my-0 noautocomplete search-input search-input2 deployment_menu_attach white--text"
              solo flat clearable height="60"
              background-color="transparent"
              :placeholder="placeholder">
              <template v-slot:message="{ message }">
                <div class="mt-3 mb-3 text-center searchexpandedpanelelement--text subtitle-1"
                     v-html="sanitizeContent(message)"></div>
                <div class="mt-3 mb-3 text-center searchexpandedpanelelement--text subtitle-1">
                  <v-row align="center" style="height:64px">
                    <span v-if="showCurrentFilters">{{ showCurrentFiltersCount }}</span>
                    <current-filter-list :facet-list="facetList" />
                  </v-row>

                </div>
              </template>

              <template v-slot:append>
                <v-btn
                  color="searchexpandedpanelelement"
                  height="58px"
                  class="no-background-hover"

                  @click="search" elevation="0" :ripple="false"><v-icon class="material-symbols-outlined primarybutton">search</v-icon></v-btn>
              </template>
              <template v-slot:append-outer >
                <div style="height:60px;" class="d-none d-sm-flex align-center">
                  <v-btn @click="reset" text color="searchexpandedpanelelement" class="btn_standout font-weight-bold ml-2">Clear</v-btn>
                </div>
              </template>
            </v-text-field>
            <!-- <span>
              Your query will be: <span class="font-italic">{{ searchTextPreview }}</span>
            </span> -->
            <slot name="search-options"></slot>
          </v-form>
        </v-card>
      </v-col>

      <v-col cols="12" justify="center">
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
/* eslint vue/no-mutating-props: 0 */
import { mapActions } from 'vuex';
import { FacetList } from '@/lib/search/discovery';
import CurrentFilterList from '@/components/search/filter/CurrentFilterList.vue';
import sanitizeHtmlContentMixin from '@/lib/mixins/sanitizeHtmlContentMixin';

export default {
  name: 'SearchBox',
  components: {
    CurrentFilterList,
  },
  props: {
    value: Object,
    facetList: {
      type: FacetList,
    },
  },
  mixins: [
    sanitizeHtmlContentMixin,
  ],
  data: () => ({
    trueValue: true,
    falseValue: false,
    searchInput: '',
  }),
  computed: {
    placeholder() {
      if (this.isSemantic) {
        return 'Ask a question here...';
      }
      return 'Enter keywords here...';
    },
    queryHint() {
      if (!this.canSubmit) {
        return `Semantic search will look for answers to your question
        within the content uploaded to this site. If a match is found,
        it will be displayed at the top of your search results. If no
        match is found, a keyword search will be performed based on
        the terms in your question.`;
      }
      return `Your query will be: <span class="font-italic">${this.searchTextPreview}</span>`;
    },
    searchTextPreview() {
      return this.value.preview(this.searchInput);
    },
    queryString: {
      get() {
        return this.value?.q || '';
      },
      set(value) {
        this.searchInput = value;
      },
    },
    isSemantic: {
      get() {
        return this.value?.isSemanticSearch || false;
      },
      set(value) {
        this.value.isSemanticSearch = value;
        if (value) {
          this.isFullTextQuery = false;
        }
        this.$emit('semantic-search-option-changed', value);
        this.updateModel();
      },
    },
    isFullTextQuery: {
      get() {
        return this.value?.isfullTextQuery || false;
      },
      set(value) {
        this.value.isfullTextQuery = value;
        this.updateModel();
      },
    },
    canSubmit() {
      if (!this.isSemantic) {
        return true;
      }

      return this.searchInput && !['', '*'].includes(this.searchInput.trim());
    },
    showCurrentFilters() {
      return this.facetList?.filterCount > 0;
    },
    showCurrentFiltersCount() {
      if (this.facetList?.filterCount > 1) {
        return `(${this.facetList?.filterCount}) Active Filters`;
      }

      if (this.facetList?.filterCount === 1) {
        return '(1) Active Filter';
      }

      return '';
    },
    allowExactPhrase() {
      return !this.isSemantic;
    },
  },
  methods: {
    ...mapActions('discovery', [
      'searchIndexSuggestions',
      'discoverySuggestions',
      'setPageWithoutInitiatingSearch',
    ]),
    startSearch() {
      this.$emit('search', this.value.q);
    },
    search() {
      if (!this.canSubmit) {
        return;
      }

      this.value.q = this.searchInput;
      this.updateModel();
      setTimeout(() => {
        this.startSearch();
      });
    },
    updateModel() {
      this.$emit('input', this.value);
    },
    reset() {
      this.isSemantic = false;
      this.isFullTextQuery = false;
      this.queryString = '';

      this.setPageWithoutInitiatingSearch(1);
      this.search();
    },
  },
  mounted() {
    this.searchInput = this.value?.q;
  },
};
</script>

<style lang="scss">

.no-background-hover::before {
  background-color: transparent !important;
}

</style>
