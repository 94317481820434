export default {
  path: 'users',
  component: () => import(
    /* webpackChunkName: 'admin-users' */ '@/admin/users/ui/pages/UsersHome.vue'
  ),
  children: [
    {
      path: '',
      name: 'users-home',
      component: () => import(
        /* webpackChunkName: 'admin-users-landing' */
        '../ui/pages/UserLandingPage.vue'
      ),
      meta: {
        requiresAuth: true,
        title: 'Manage Users',
        breadcrumbs: [
          {
            name: 'Manage',
            href: { path: '/admin' },
          },
          {
            name: 'Users',
          },
        ],
      },
    },
    {
      path: 'list',
      name: 'users-list',
      component: () => import(
        /* webpackChunkName: 'admin-users-list' */
        '../ui/pages/userList/UsersListPage.vue'
      ),
      meta: {
        requiresAuth: true,
        title: 'Manage Users',
        breadcrumbs: [
          {
            name: 'Manage',
            href: { path: '/admin' },
          },
          {
            name: 'Users',
            href: { name: 'users-home' },
          },
          {
            name: 'List',
          },
        ],
      },
    },
  ],
};
