class Stream {
  /**
   * Filter by which the list
   * @type {{}}
   */
  filters = [];

  /**
   * order the list by
   * @type {{}}
   */
  order = {};

  name = '';

  code = '';

  constructor(name, code, filters, order) {
    this.filters = filters;
    this.order = order;
    this.name = name;
    this.code = code;
  }
}

export default Stream;
