/**
 * The purpose of this mixins is to create a piece of code that sets the properties of an object
 * from the incoming data in the contructor; This could be used anywhere.
 * @param factories
 * @returns {function(*): *}
 * @constructor
 */
const AutomaticConstructor = (factories = {}) => (
  superClass,
) => class AutomaticConstructorMixin extends superClass {
  constructor(data) {
    super(data);

    const keys = Object.keys(data);

    keys.forEach((key) => {
      if (key in factories) {
        super[key] = factories[key](data[key]);
      } else {
        super[key] = data[key];
      }
    });
  }
};

export default AutomaticConstructor;
