import Vue from 'vue';
import VueRouter from 'vue-router';
import '../../setup/auth-provider';

const MaintenancePage = () => import('../../views/MaintenancePage.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'start',
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    components: {
      default: MaintenancePage,
      main: MaintenancePage,
    },
    meta: {
      hideHeader: true,
      requiresAuth: false,
      hideSearchFromHeader: true,
      pageTitle: 'Login',
    },
  },
  {
    path: '/implicit/callback',
    // component: Auth.handleCallback(),
    components: {
      main: () => import('@/components/okta/DiscoveryCallback.vue'),
    },
    meta: {
      pageTitle: 'Loading...',
    },
  },
  { path: '*', redirect: '/maintenance' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  /*
    If the user is authenticated, both start and welcome should take the user to dashboard
   */
  if (to.name === 'start') {
    if (await Vue.prototype.$auth.isAuthenticated()) {
      next({ name: 'maintenance' });
    } else {
      next({ name: 'maintenance' });
    }
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  // document.title = to.name;
  document.title = to.meta?.pageTitle || to.name;
  next();
});

router.beforeEach(async (to, from, next) => {
  const auth = Vue.prototype.$auth;
  if (to.matched.some((record) => record.meta.requiresAuth) && !(await auth.isAuthenticated())) {
    auth.login(to.fullPath);
  } else {
    next();
  }
});

router.beforeEach((_, __, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
