import Facet, { facetFieldMapping, ignoredFacetFields } from '@/lib/search/discovery/classes/facet';
import { sortListByPosition } from '@/lib/util';
import FiltersList from '@/lib/framework/filtering/FiltersList';
import FacetInsightsList from '@/lib/search/discovery/FacetInsightsList';

const escapeComma = (value) => {
  console.log('escaping', value);
  console.log(value);
  return (value || '').replace(/,/g, '~~~');
};

const unescapeComma = (value) => {
  console.log('un escaping', value);
  console.log(value);
  return (value || '').replace(/~~~/g, ',');
};

export default class FacetList extends FiltersList {
  facetedFields = [];

  /**
   *
   * @type {[]}
   */
  facets = [];

  facetMaps = {};

  selectedFacets = [];

  #sortBy = 'numeric-desc';

  /**
   *
   * @type {FacetInsightsList}
   */
  insightsList = null;

  get sortBy() {
    return this.#sortBy;
  }

  set sortBy(value) {
    this.#sortBy = value || 'numeric-desc';
    if (this.facets && this.facets.length > 0) {
      this.facets.forEach((facet) => {
        facet.currentSort = this.#sortBy;
      });
    }
  }

  get filterCount() {
    return (this.selectedFilters || []).length;
  }

  get selectedFilters() {
    if (this.selectedFacets == null) {
      return [];
    }

    const addFacetKeyToEveryValue = (facet) => {
      if (facet.value?.length === 0) {
        return [];
      }

      const { key } = facet;
      return facet.value.map((value) => ({ key, value }));
    };

    const filters = this.selectedFacets.flatMap(addFacetKeyToEveryValue);
    return filters;
  }

  constructor(facets, facetedFields) {
    super();
    this.facets = facets;
    this.facetedFields = facetedFields;
  }

  setFacetMaps() {
    this.facetMaps = this.facets && this.facets.reduce((acc, facet) => {
      const key = facet.getKey();
      acc[key] = facet;
      return acc;
    }, {});
  }

  queryString() {
    const createQueryStringForFacet = (facetObj) => {
      const { key, value: values } = facetObj;
      return values.map((value) => `${key}_${escapeComma(value)}`).join(',');
    };

    return this.selectedFacets
      .map(createQueryStringForFacet)
      .join();
  }

  updateQuery(query) {
    const newQuery = { ...query };
    const facetQueryString = this.queryString();

    if (facetQueryString) {
      newQuery.facets = encodeURI(facetQueryString);
    } else {
      delete newQuery.facets;
    }

    return newQuery;
  }

  /**
   * This method sets values of each facets used in the current search as selected
   * @param selectedFacets
   * @returns {Promise<unknown>|null}
   */
  setFacetsAsSelected(selectedFacets) {
    this.selectedFacets = selectedFacets || [];
    if (!selectedFacets) {
      return null;
    }

    const promise = new Promise((resolve) => {
      this.setFacetMaps();

      selectedFacets.forEach(({ key, value }) => {
        const facet = this.facetMaps[key];

        if (facet) {
          facet.setValuesAsSelected(value);
        }
      });

      resolve(this.facets);
    });

    return promise;
  }

  clear() {
    this.facets.forEach((facet) => {
      facet.clear();
    });
    this.selectedFacets = [];

    return this;
  }

  /**
   *
   */
  updatedSelectedFacets() {
    this.selectedFacets = this.facets
      .map((facet) => facet.queryValues)
      .filter((queryValue) => queryValue !== null);
  }

  removeFacetFromSelection(key, value) {
    console.log('Removing filter from selection', key, value);
    console.log(this.selectedFacets);
    const selectedFacet = this.facets.find((facet) => facet.key === key);
    if (selectedFacet) {
      selectedFacet.unSelectFacetValue(value);
    }

    /* removing the selected values from the key */
    const selectedFilter = this.selectedFacets.find((filter) => filter.key === key);
    console.log('selected filter', selectedFilter);
    selectedFilter.value = selectedFilter.value.filter((currentValue) => currentValue !== value);

    /* removing the current key from selected filters */
    this.selectedFacets = this.selectedFacets.filter((facet) => facet.key !== key);

    /* if the key has more values, set the key back */
    if (selectedFilter.value && selectedFilter.value.length > 0) {
      this.selectedFacets = [...this.selectedFacets, selectedFilter];
    }

    // this.updatedSelectedFacets();
    return this;
  }

  setSelectedFacetsFromQueryString(queryString) {
    console.log(` --> setSelectedFacetsFromQueryString : ${queryString} `);
    if (!queryString) {
      return;
    }

    const splitKeyValue = (s) => {
      const index = s.lastIndexOf('_');
      return [s.substring(0, index), s.substring(index + 1)];
    };

    const addKeyAndValueToObject = (acc, [key, value]) => {
      acc[key] = [...(acc[key] || []), unescapeComma(value)];
      return acc;
    };

    const intermediary = decodeURI(queryString)
      .split(',')
      .map(splitKeyValue)
      .reduce(addKeyAndValueToObject, {});

    this.selectedFacets = Object.entries(intermediary)
      .flatMap(([key, value]) => [{ key, value }]);
  }

  get filterableFacets() {
    const valueExtractor = (facet) => facet.name;
    const nonEmptyFacets = this.facets.filter(
      (facet) => facet.values?.length > 0 && facet.displayInFilter,
    );
    const facetNames = nonEmptyFacets.map((facet) => facet.name);
    return sortListByPosition(nonEmptyFacets)(facetNames)(valueExtractor);
  }

  /**
   *
   * @returns {FacetInsightsList}
   */
  get insights() {
    if (this.insightsList == null) {
      this.insightsList = new FacetInsightsList(this.facets);
    }

    return this.insightsList;
  }

  getFacet(facetName) {
    return this.facets?.find((facet) => facet.key === facetName);
  }

  getFacetWithKey(key) {
    return this.facets?.find((facet) => facet.key === key);
  }

  /**
   *
   * @param facet{Facet}
   */
  addFacetToList(facet, replace = false) {
    console.log('%cAdding facet to list', 'color: yellow', facet);
    const existingFacet = this.getFacetWithKey(facet.key);
    console.log('%cexisting facet', 'color: yellow', existingFacet);
    if (existingFacet) {
      if (!replace) {
        return false;
      }

      this.facets = this.facets.filter((f) => f.key !== existingFacet.key);
    }

    this.facets.push(facet);
    console.log('%cAdding facet to list>', 'color: brown', this.facets);

    this.updatedSelectedFacets();

    return true;
  }

  getOrDefault(key, alias) {
    const facet = this.getFacetWithKey(key) || new Facet({ name: key, values: [] });
    facet.alias = alias;
    return facet;
  }

  getSelectedFilters() {
    return this.selectedFilters || [];
  }

  remove({ key, value }) {
    this.removeFacetFromSelection(key, value);
  }
}
