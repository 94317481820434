import gql from 'graphql-tag';

export const pageInfo = gql`
  fragment pageInfoFields on PageInfo {
      hasNextPage
      hasPreviousPage
      endCursor
      startCursor
  }
`;

export default {
  pageInfo,
};
