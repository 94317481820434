import moment from 'moment';

class VideoTranscript {
  /**
   *
   * @type {string}
   */
  text = '';

  /**
   *
   * @type {string}
   */
  #startTime = '';

  get startTime() {
    return this.#startTime;
  }

  set startTime(value) {
    this.#startTime = value;

    if (value) {
      this.startSeconds = moment.duration(value).asSeconds();
    }
  }

  /**
   *
   * @type {number}
   */
  startSeconds = 0;

  /**
   *
   * @type {string}
   */
  #endTime = '';

  get endTime() {
    return this.#startTime;
  }

  set endTime(value) {
    this.#endTime = value;

    if (value) {
      this.endSeconds = moment.duration(value).asSeconds();
    }
  }

  /**
   *
   * @type {number}
   */
  endSeconds = 0;

  /**
   *
   * @type {number}
   */
  confidence = 0.0;

  constructor(props) {
    this.props = props;

    this.text = props?.text;
    this.confidence = props?.confidence;

    this.extractTime();
  }

  extractTime() {
    const instance = this.props?.instances && this.props.instances[0];
    this.startTime = instance?.start;
    this.endTime = instance?.end;
  }
}

export default VideoTranscript;
