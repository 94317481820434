import urlTransformerConfig from '@/config/urlTransformerConfig';
import UrlTransformer from '@/lib/ui/navigation/navigationHelper';

const navUrlTransformer = {
  install(Vue) {
    Vue.prototype.$urlTransformer = new UrlTransformer(urlTransformerConfig);
  },
};

export default navUrlTransformer;
