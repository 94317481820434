import AwardsTally from '@/awards/models/AwardsTally';

class LocationTally {
  data = {}

  numberAdvertisers = 0;

  numberAgencies = 0;

  peopleCredits = 0;

  numberShows = 0;

  totalNumberAwards = 0;

  totalNumberPoints = 0;

  awardsTally = null;

  /**
   *
   * @param data { {
   * totalNumberAwards,
   * totalNumberPoints,
   * dynamicAwardTally,
   * numberAdvertisers,
   * numberAgencies,
   * peopleCredits } }
   */
  constructor(data) {
    // this.data = data;
    this.totalNumberAwards = data.totalNumberAwards;
    this.totalNumberPoints = data.totalNumberPoints;
    this.numberAdvertisers = data.numberAdvertisers;
    this.numberAgencies = data.numberAgencies;
    this.peopleCredits = data.peopleCredits;
    this.numberShows = data.numberShows;

    this.awardsTally = AwardsTally.fromObject(data.dynamicAwardTally || {});
  }

  get awardNames() {
    return this.awardsTally?.awardNames || [];
  }

  getTally(awardName) {
    return this.awardsTally?.get(awardName) || 0;
  }

  setTally(tally) {
    this.awardsTally = AwardsTally.fromObject(tally);
    return this;
  }

  static fromObject(data) {
    return new LocationTally(data);
  }
}

export default LocationTally;
