import AssetReport from '../business/models/AssetReport';
import SearchReport from '../business/models/SearchReport';

class ReportsClient {
  http = null;
  
  endpoint = 'v2/assets/';

  /**
   * 
   * @param {*} http 
   */
  constructor(http) {
    this.http = http;
  }

  async assetsReport(deploymentIndexId, reportType) {
    const url = `${this.endpoint}${deploymentIndexId}/reports/${reportType}`;

    const { data } = await this.http.get(url);
    const reports = AssetReport.fromObjects(data);
    return reports;
  }

  async searchReport(deploymentIndexId) {
    const url = `${this.endpoint}${deploymentIndexId}/reports/searches`;

    const { data } = await this.http.get(url);
    const reports = SearchReport.fromObjects(data);
    return reports;
  }
}

export default ReportsClient;
