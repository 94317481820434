class DeploymentUser {
  deploymentId = 0;

  userId = 0;

  deployment = null;

  constructor({ userId, deploymentId, deployment }) {
    this.userId = userId;
    this.deploymentId = deploymentId;
    this.deployment = deployment;
  }
}

export default DeploymentUser;
