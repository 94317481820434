import gql from 'graphql-tag';
import { pageInfo } from '@/lib/graphql/fragments';

const deploymentsTreeFields = gql`
    fragment deploymentTreeFields on Deployment {
      id
      deploymentId
      name
      deploymentIndices {
        pageInfo {
          ...pageInfoFields
        }
        nodes {
          id
          deploymentId
          deploymentIndexId
          name
        }
        totalCount
      }
    }
    ${pageInfo}
`;

const deploymentFields = {
  deploymentsTreeFields,
};

export default deploymentFields;
