class LeaderboardItem {
  agencyName = '';

  currentYearPoints = 0;

  previousYearPoints = 0;

  currentYear = 0;

  previousYear = 0;

  currentYearIndex = 0;

  previousYearIndex = 0;

  constructor(data) {
    this.agencyName = data.agencyName;

    this.currentYearPoints = data.currentYearPoints;
    this.previousYearPoints = data.previousYearPoints;

    this.currentYearIndex = data.currentYearIndex;
    this.previousYearIndex = data.previousYearIndex;

    this.currentYear = data.currentYearLabel;
    this.previousYear = data.previousYearLabel;
  }

  getMaxPoints(maxPoints) {
    if (this.currentYearPoints > maxPoints) {
      return this.currentYearPoints;
    }

    return maxPoints;
  }

  getRelativePoint(maxScore) {
    const ratio = (this.currentYearPoints || 0) / maxScore;
    return ratio * 100;
  }

  /**
   *
   * @param data{ {agencyName: string, currentYearPoints: number, yearPoints: {} } }
   * @returns {LeaderboardItem}
   */
  static fromObject(data) {
    return new LeaderboardItem(data);
  }

  /**
   *
   * @param list{[{agencyName: string, currentYearPoints: number, yearPoints: {} }]}
   * @returns {LeaderboardItem[]}
   */
  static fromList(list = []) {
    return (list || []).map(LeaderboardItem.fromObject);
  }
}

export default LeaderboardItem;
