import { userFactory } from '@/lib/business/models/User';
import Group, { groupFactory } from '@/lib/business/models/groups/Group';

const ListGroup = function ListGroup(data) {
  this.currentUser = userFactory.create(data?.currentUser);
  this.group = new Group(data?.group);

  this.name = this.group?.name;
  this.groupId = this.group?.groupId;
  this.description = this.group?.description;
  this.id = this.groupId;

  this.pinned = data?.pinned;
  this.starred = data?.starred;

  this.created = this.group?.created;

  const self = this;

  Object.defineProperty(self, 'rawLocation', {
    get() {
      return { name: 'group-details-home', params: { id: self.id } };
    },

  });
};

ListGroup.prototype.shareableObject = function shareableObject() {
  return {
    name: this.name,
    type: 'Group',
    location: this.rawLocation,
  };
};

export default ListGroup;

export const listGroupFactory = (listGroup) => {
  const { group, pinned, starred } = listGroup;
  group.pinned = pinned;
  group.starred = starred;

  const newGroup = groupFactory(group, listGroup.currentUser);

  return newGroup;
};

export const createGroupsFromList = (list) => list?.map(listGroupFactory);
