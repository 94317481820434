export default {
  children: [
    {
      path: 'home',
      name: 'dashboard-home',
      component: () => import(
        /* webpackChunkName: "home-holding" */
        '@/components/dashboard/pages/home/HomePageHolding.vue'
      ),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        showRightPanel: false,
        showFilters: false,
        shouldRetrieveMetrics: false,
        shouldRetrieveDashboardInsights: false,
        mobileName: 'sitename',
        pageTitle: 'Home',
        hideHomeButton: true,
      },
    },
    {
      path: 'insights',
      name: 'dashboard-insights',
      component: () => import(
        /* webpackChunkName: "dashboard-data" */
        '@/components/dashboard/pages/DashboardDataInsights.vue'
      ),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        hideHeader: true,
        showRightPanel: false,
        showFilters: true,
        shouldRetrieveMetrics: true,
        shouldRetrieveDashboardInsights: false,
        mobileName: 'Analytics',
        pageTitle: 'Analytics & Insights',
        showPageTitleInToolbar: true,
      },
    },
    {
      path: 'patterns',
      name: 'dashboard-patterns',
      component: () => import(
        /* webpackChunkName: "dashboard-patterns" */
        '@/components/dashboard/pages/DashboardPatternInsights.vue'
      ),
      meta: {
        requiresAuth: true,
        hideSearchFromHeader: false,
        hideHeader: true,
        showRightPanel: false,
        showFilters: true,
        shouldRetrieveMetrics: true,
        shouldRetrieveDashboardInsights: true,
        mobileName: 'Pattern Analysis',
        pageTitle: 'Pattern Analysis',
        showPageTitleInToolbar: true,
      },
    },
  ],
};
