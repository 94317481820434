import { mapActions, mapMutations } from 'vuex';
import SearchParameters from '@/lib/search/discovery/classes/SearchParameters';

export default {
  methods: {
    ...mapActions('common', [
      'setUserUserSelectedIndex',
    ]),
    ...mapActions('discovery', [
      'getMetrics',
    ]),
    ...mapMutations('discovery', [
      'setDashboardInsightType',
    ]),

    async initialiseDashboard() {
      const { shouldRetrieveMetrics, shouldRetrieveDashboardInsights } = this.$route.meta;

      if (shouldRetrieveDashboardInsights || shouldRetrieveMetrics) {
        const { page } = this.$route.query;
        await this.setDashboardInsightType(page || 'tagged');
        const searchParams = new SearchParameters().setSelectFields([
          'file_type_category',
          'created',
          'doc_id',
          'published_date',
        ]);
        searchParams.documentsPerPage = 2000;
        searchParams.filters = await this.addDefaultFilters();
        await this.getMetrics({
          initialize: true,
          searchParams,
        });
      }
    },

    async initialiseSiteChange(index) {
      await this.setUserUserSelectedIndex(index);
      await this.initialiseDashboard();
      await this.$router.push({ name: 'defaultHome' });
    },

    async addDefaultFilters() {
      const filter = [{
        key: 'published_date',
        value: ['-3 months'],
      }];
      return filter;
    },
  },
};
